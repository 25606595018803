<template>
    <div>
        <div class="px-5 pb-5 pt-2">
            <div class="row">
                <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="card w-100 mb-lg-5">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>CONTRATOS</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 px-1 mb-2">
                                        <div class="input-group">
                                            <span class="input-group-text text-white bg-main"><i
                                                    class="fas fa-search"></i></span>
                                            <input @keyup="calculateData(7)" v-model="search" type="text"
                                                class="form-control" placeholder="Buscar" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 btn-home">
                                        <button type="button" class="btn btn-bm-noradius w-100"
                                            @click="mtdModalShow(0, null)">
                                            <i class="fas fa-plus"></i> Nuevo
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2" id="content-timelinetable">
                                <div class="table-responsive">
                                    <table id="tb-cxc" class="table table-bordered border-main text-xs">
                                        <caption class="py-0 my-0"></caption>
                                        <thead class="bg-main">
                                            <tr class=" table-th text-white text-center">
                                                <th class="table-th-number">N°</th>
                                                <th class="table-th-name-personal">COLABORADOR</th>
                                                <th>CONTRATO</th>
                                                <th>TIPO DE CONTRATO</th>
                                                <th>FECHA INICIO</th>
                                                <th>FECHA FIN</th>
                                                <th>CARGO</th>
                                                <th>DESCRIPCIÓN</th>
                                                <th class="table-th-acciones">ESTADO</th>
                                                <th class="table-th-acciones">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody class="border-main">
                                            <tr class="table-body text-center" v-if="cpData.length === 0">
                                                <td colspan="25" class="text-center">No se encontraron resultados</td>
                                            </tr>
                                            <tr class="table-body text-center" v-for="(item, index) in cpData"
                                                :key="index">
                                                <td>
                                                    {{ mtdNumber(index) }}
                                                </td>
                                                <td>
                                                    {{ item.name }} {{ item.last_name }}
                                                </td>
                                                <td>
                                                    <div id="state_speciality" v-if="item.renewal == 1">
                                                        <div class="bg-info text-white">Renovación</div>
                                                    </div>
                                                    <div id="state_speciality" v-else-if="item.renewal == 0">
                                                        <div class="bg-primary text-white">Nuevo</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ item.type_contract_name }}
                                                </td>
                                                <td class="">
                                                    {{ item.date_start | formatDate("%d/%m/%y") }}
                                                </td>
                                                <td class="">
                                                    {{ item.date_end | formatDate("%d/%m/%y") }}
                                                </td>
                                                <td class="">
                                                    {{ item.position_name }}
                                                </td>
                                                <td class="">
                                                    {{ item.description }}
                                                </td>
                                                <td>
                                                    <div id="state_speciality" v-if="item.state == 1">
                                                        <div class="bg-success text-white">Activo</div>
                                                    </div>
                                                    <div id="state_speciality" v-else>
                                                        <div class="bg-secondary text-white">Inactivo</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="text-center d-flex justify-content-center">
                                                        <!-- <button class="btn btn-sm a-warning text-white"
                                                            title="Ficha Empleado"
                                                            @click="mtdModalShowEmployee(item, 'view')">
                                                            <i class="fa fa-id-card"></i>
                                                        </button> -->
                                                        <!-- <button class="btn btn-sm a-success text-white" title="Editar"
                                                            @click="mtdModalShow(1, item)">
                                                            <i class="fa fa-edit"></i>
                                                        </button> -->
                                                        <button class="btn btn-sm a-success text-white" title="Editar"
                                                            @click="mtdModalShow(1, item)">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                        <div v-if="!item.file">
                                                            <button class="btn btn-sm a-upload"
                                                                @click="inputFile(index)">
                                                                <i class="fas fa-solid fa-upload"></i>
                                                            </button>
                                                            <input type="file" @change="uploadFile($event, index, item)"
                                                                :ref="'fileInput' + index" style="display : none">
                                                        </div>
                                                        <div v-else>
                                                            <button class="btn btn-sm a-view"
                                                                @click="openFile(item.file)">
                                                                <i class="fas fa-file-pdf"></i>
                                                            </button>
                                                        </div>
                                                        <div v-if="item.state == 1">
                                                            <button class="btn btn-sm a-word" v-if="item.renewal == 1"
                                                                @click="openReportContract(item.id)">
                                                                <i class="fas fa-solid fa-file-word"></i>
                                                            </button>
                                                            <button class="btn btn-sm a-word"
                                                                v-else-if="item.renewal == 0"
                                                                @click="openReportContract(item.id)">
                                                                <i class="fas fa-solid fa-file-word"></i>
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button class="btn btn-sm a-ban"
                                                                @click="Datadetail(item.id, 2)">
                                                                <i class="fas fa-ban"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="backPage">Anterior</a>
                                            </li>
                                            <template v-if="!isMobile">
                                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                                </li>
                                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                                <li v-for="(item, index) in limitedStepPagination" :key="index"
                                                    class="page-item">
                                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)" @click="selectedPage(item)">{{ item
                                                        }}</a>
                                                </li>
                                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                                    class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                                    class="page-item">
                                                    <a :class="selectPage === stepPagination.length ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)"
                                                        @click="selectedPage(stepPagination.length)">{{
                                                            stepPagination.length }}</a>
                                                </li>
                                            </template>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crudContract :boo_modal="modal.modal_form" :payload="modal.payload" :title="modal.title" :num="modal.num"
            @closeModal="closeModal" @Datadetail="Datadetail" :positionList="positionList" :employeeList="employeeList"
            :employeeOptions="employeeOptions" :contractTypes="contractTypes" />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import crudContract from '@/components/RecursosHumanos/Modals/cModalAddContract.vue';

export default {
    name: "c-mantenedores-pages-contract",
    components: {
        SweetAlert,
        crudContract,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            data: [],
            positionList: [],
            employeeList: [],
            employeeOptions: [],
            contractTypes: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modal_view: {
                title: '',
                modal_form: false,
                view: '',
                data: [],
                idemployee: null
            },
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
            isMobile: false,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
        this.mtdGetEmployee();
        this.mtdGetPosition();
        this.mtdGetContracts();
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkIfMobile);
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/contracts",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.data = response.data;
                    for (let i = 0; i < this.data.length; i++) {
                        this.data[i].name = response.data[i].employee.name;
                        this.data[i].last_name = response.data[i].employee.last_name;
                        this.data[i].position_name = response.data[i].position.name;
                        this.data[i].type_contract_name = response.data[i].type_contract.name;
                    }
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetEmployee: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/employees",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.employeeList = response.data;

                    let uniqueEmployeeNames = new Set();
                    this.employeeList.forEach(item => {
                        let employee = '';
                        let objEmployee = [];
                        if (item.last_name !== null) {
                            if (item.name !== null) {
                                employee = item.last_name + " " + item.name;
                            } else {
                                employee = item.last_name;
                            }
                            objEmployee = {
                                id: item.id,
                                name: employee
                            }
                            uniqueEmployeeNames.add(objEmployee);
                        }
                    });
                    this.employeeOptions = [...Array.from(uniqueEmployeeNames)];
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetPosition: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/position",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.positionList = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetContracts: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/typeOfContract",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.contractTypes = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    let last_name = element.last_name.toString().toUpperCase();
                    return name.includes(query) || last_name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            if (this.action == 'd') {
                if (this.dataShow.length == 1) {
                    if (indexInitial > 1) {
                        indexInitial -= 1;
                    } else {
                        indexInitial = 1;
                    }
                }
            }
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Contrato' : 'Editar Contrato';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;
        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action: 'd',
                    id: payload_detail
                }

                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            this.addDatadetail(data, num_detail);
                        }
                    });
            }
        },
        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                url: this.$store.getters.get__url + "/RecursosHumanos/crudContract",
                token: this.$store.getters.get__token,
                params: payload_detail,
            })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.action = payload_detail.action;
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },

        // Subir Archivo
        inputFile(index) {
            this.$refs['fileInput' + index][0].click();
        },
        uploadFile(event, index, item) {
            let file = event.target.files[0];
            let allowedExtensions = /\.(png|jpg|jpeg|pdf)$/i;

            if (!allowedExtensions.test(file.name)) {
                let fileType = "imagen o PDF";
                Swal.fire({
                    text: `Seleccione un archivo formato ${fileType}`,
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs['fileInput' + index][0];
                        input.type = "text";
                        input.type = "file";
                    }
                });
            } else {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('id', item.id);
                this.post({
                    url: this.$store.getters.get__url + "/RecursosHumanos/uploadFile",
                    token: this.$store.getters.get__token,
                    params: formData
                }).then((response) => {
                    if (response.statusCode == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Archivo Subido Correctamente",
                            confirmButtonColor: "#900052",
                        });
                        this.mtdGetData();
                    }
                }).catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
        },
        openFile: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
        //Fin

        //Reporte N o R
        openReportContract(id) {
            window.open(
                this.$store.getters.get__url + "/viewpdfContract/" + id,
                "_blank"
            );
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 7 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
        checkIfMobile() {
            this.isMobile = window.innerWidth <= 700;
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    }
}
</script>


<style scoped>

    .a-ban,
    .a-upload,
    .a-view,
    .a-word {
        font-size: 25px;
    }

    .a-word {
        color: #00aeff;
    }

    .a-word i:hover {
        color: #0051ff;
    }

    .a-ban {
        color: #FFA500;
    }

    .a-ban i:hover {
        color: #ff5e00;
    }

    .a-upload {
        color: #0051ff;
    }

    .a-upload i:hover {
        color: #0000ff;
    }

    .a-view {
        color: #ff1e00;
    }

    .a-view i:hover {
        color: #ff0000;
    }

</style>
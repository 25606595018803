<template>
<div>
    <div class="d-flex flex-row flex-wrap justify-content-around mb-3">
        <div id="div-listcola-sop" class="card m-3" v-for="(room, index) in data" :key="index">
            <div class="card-header text-white h6 header-main">
                <strong>{{room.room}}</strong>
            </div>
            <div class="card-body" id="content-timeline">
                <div class="row">
                    <div class="px-sm-1 px-md-3">
                        <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end hover-effect" style="cursor: pointer;" v-for="(item,indexc) in room.cola" :key="indexc">
                            <div @click="mtdGetDataMedicalAct(item.patient_id, item.reservation_id, item.id, item.medical_act_id, item.status_history)">
                                <span class="text-paciente">{{ item.patient }}</span>
                                <br />
                                <span class="text-datos">{{item.date}} {{ item.hour }} - {{item.servicio}}</span>
    
                                <br>
                                <span class="text-datos">Chek in: {{ item.checkin }}</span>
                                <!-- <br>
                                        <span class="text-datos">Tiempo Transcurrido</span> -->
                            </div>
                        </div>
                        <div v-if="room.cola.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                            <div class="h6 text-center color-main">
                                NO HAY PACIENTES
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-Sop-cola',
    data() {
        return {
            //property: 'value',
            swal: null,
            data: [],
            colaRoom1: [],
            colaRoom2: [],
            sop_id: null,
            patient_id: null,
            reservation_id: null,
            medical_act_id: null,
            clinica_history: {
                id: null,
                user_id: null,
                measures: [
                    {
                        id: 1,
                        label: "Peso (Kg)",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Talla (m)",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "IMC",
                        value: ''
                    },
                ],
                biological_function: [{
                    id: 1,
                    label: "Apetito",
                    value: ''
                },
                {
                    id: 2,
                    label: "Sed",
                    value: ''
                },
                {
                    id: 3,
                    label: "Orina",
                    value: ''
                },
                {
                    id: 4,
                    label: "Heces",
                    value: ''
                },
                {
                    id: 5,
                    label: "Sueño",
                    value: ''
                },

                ],
                physical_exam: [{
                    id: 1,
                    label: "Estado general",
                    value: ''
                },
                {
                    id: 2,
                    label: "SNC",
                    value: ''
                },
                {
                    id: 3,
                    label: "Respiratorio",
                    value: ''
                },
                {
                    id: 4,
                    label: "Tórax Cv Hemo",
                    value: ''
                },
                {
                    id: 5,
                    label: "Abdominal",
                    value: ''
                },
                {
                    id: 6,
                    label: "Renal",
                    value: ''
                },
                {
                    id: 7,
                    label: "Otros",
                    value: ''
                }
                ],
                lab_question: '',
                symploms: [{
                    id: 1,
                    label: "Tos sin flema",
                    state: false
                },
                {
                    id: 2,
                    label: "Tos con flema",
                    state: false
                },
                {
                    id: 3,
                    label: "Moco",
                    state: false
                },
                {
                    id: 4,
                    label: "Dolor de pecho",
                    state: false
                },
                {
                    id: 5,
                    label: "Dolor de cabeza",
                    state: false
                },
                {
                    id: 6,
                    label: "Dolor de espalda",
                    state: false
                },
                {
                    id: 7,
                    label: "Nauseas / vómitos x/día",
                    state: false
                },
                {
                    id: 8,
                    label: "Diarrea x/día",
                    state: false
                },
                {
                    id: 9,
                    label: "Otros",
                    state: false,
                    value: ''
                }
                ],
                vital_sings: [{
                    id: 1,
                    label: "FC",
                    value: ''
                },
                {
                    id: 2,
                    label: "T (°C)",
                    value: ''
                },
                {
                    id: 3,
                    label: "FR",
                    value: ''
                },
                {
                    id: 4,
                    label: "SATO2",
                    value: ''
                },
                {
                    id: 5,
                    label: "HGT",
                    value: ''
                },
                {
                    id: 6,
                    label: "FIO 2",
                    value: ''
                },
                {
                    id: 7,
                    label: "PAS",
                    value: ''
                },
                {
                    id: 8,
                    label: "PAD",
                    value: ''
                },
                {
                    id: 9,
                    label: "PAM",
                    value: ''
                }
                ],
                fe: '',
                fi: '',
                course: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: [{
                    id: 1,
                    label: "DM",
                    state: false,
                    value: ''
                },
                {
                    id: 2,
                    label: "AR",
                    state: false,
                    value: ''
                },
                {
                    id: 3,
                    label: "HTA",
                    state: false,
                    value: ''
                },
                {
                    id: 4,
                    label: "Otro",
                    state: false,
                    value: ''
                },
                {
                    id: 5,
                    label: "Alergias",
                    state: false,
                    value: ''
                },
                {
                    id: 6,
                    label: "Cirugías",
                    state: false,
                    value: ''
                },
                {
                    id: 7,
                    label: "Medicamentos Frecuentes",
                    state: false,
                    value: ''
                }
                ],
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta: '',
                insuline: '',
                grupo: '',
                factor: ''
            },
        };
    },
    created() {
        this.mtdSelectGroup();
    },
    props: {
        rolname: String
    },
    components: {
        SweetAlert,
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdSelectGroup: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/cola",
                    token: this.$store.getters.get__token,
                    params: {
                        //floor_id: id,
                        campu_id: this.$store.getters.get__campus,
                        user_id: this.$store.getters.get__user.id
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    this.colaRoom1 = response.data.salaGrande;
                    this.colaRoom2 = response.data.salaPequeña;

                    //this.tab = id;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdGetDataMedicalAct: function (patient_id, reservation_id, sop_id, medical_act_id = 0, status) {
            this.patient_id = patient_id;
            this.reservation_id = reservation_id;
            this.sop_id = sop_id;
            this.medical_act_id = medical_act_id;
            if ((this.rolname == 'ENFERMERA(O)' || this.rolname == "ENFERMERA TECNICA") && status != 3) {
                this.$refs.SweetAlert.showWarning("El paciente aún no ha sido atendido por el médico");
            } else {
                if (status == 3) {
                    this.mtdViewAct(this.patient_id, this.medical_act_id);
                } else {
                    this.mtdNewAct(this.patient_id, this.reservation_id, this.sop_id, this.medical_act_id);
                }
            }


        },
        mtdNewAct: function (patient_id, reservation_id, emergencies_id, medical_act_id) {
            this.post({
                url: this.$store.getters.get__url + "/Sop/add",
                token: this.$store.getters.get__token,
                params: {
                    clinica_history: this.clinica_history,
                    patient_id: this.patient_id,
                    reservation_id: this.reservation_id,
                    sop_id: this.sop_id,
                    medical_act_id: this.medical_act_id
                }
            })
                .then((response) => {
                    //this.data = ;
                    this.patient_id = null;
                    this.$emit('mtdHistorial', response.data)
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdViewAct: function (patient_id, medical_act_id) {
            this.get({
                url: this.$store.getters.get__url + "/Sop/history/" + medical_act_id,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    let viewActMedical = response.data;
                    this.$emit('mtdHistorialtable', viewActMedical);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    },
}
</script>
<style scoped>
    .hover-effect {
        transition: background-color 0.3s, color 0.3s;
    }

    .hover-effect:hover {
        background-color: #f4869a;
    }

    .hover-effect:hover .text-paciente,
    .hover-effect:hover .text-datos {
        color: white;
    }
</style>

<template>
    <div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    name: 'SweetAlert-c',
    props: {
        swal: String,

    },
    methods: {
        showAlert(title, text, icon, accion) {
            Swal.fire({
                title: title + "--" + this.swal,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: accion,
                cancelButtonText: "Cancelar",
            });
        },
        showConfirmSimple2(title, text, icon, accion) {
            return new Promise((resolve) => {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonText: accion,
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    resolve(result);
                });
            });
        },
        showSuccess(text) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: text,
            });
        },
        showWarning(text) {
            Swal.fire({
                icon: "warning",
                title: "Alerta",
                text: text,
            });
        },
        showInfo(text) {
            Swal.fire({
                icon: "info",
                title: "¡Atención!",
                text: text,
            });
        },
        showInfoNumber(text) {
            Swal.fire({
                icon: "info",
                title: "¡Atención!",
                text: text,
                showConfirmButton: false,
                timer: 1000,
                didOpen: () => {
                    const swalModal = Swal.getHtmlContainer();
                    swalModal.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.close();
                        }
                    });
                }
            });
        },

        showError(text) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: text,
            });
        },
        showDelete(index, msg) {
            Swal.fire({
                title: "¿Está Seguro?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                didOpen: () => {
                    const swalModal = Swal.getHtmlContainer();
                    swalModal.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "Ha sido eliminado correctamente.",
                        icon: "success",
                    });
                    this.$emit(msg, index);
                }
            });
        },


        showUpdateState(id, type) {
            Swal.fire({
                icon: "warning",
                title: "¿Quieres cambiar el estado a Pendiente?",
                width: "400px",
                showDenyButton: false,
                denyButtonText: `Cancelar`,
                confirmButtonText: "Cambiar a Pendiente",
                confirmButtonColor: "#d33",
                showCancelButton: true,
                cancelButtonColor: "#3085d6",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdPermissionRollback', id, result.value, type, 1)
                }
            });
        },

        showPermisions(id, type, msg, state) {
            Swal.fire({
                icon: "warning",
                title: "¿Qué accción deseas tomar para este requerimiento?",
                input: msg,
                inputAttributes: {
                    autocapitalize: "off",
                    placeholder: "OBSERVACION",
                },
                width: "400px",
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdPermissionCommit', id, result.value, type, 2);
                } else if (result.isDenied) {
                    this.$emit('mtdPermissionCommit', id, result.value, type, 3)
                }
            });
        },

        showActionBeads(title, buttonText, action) {
            Swal.fire({
                title: title,
                icon: "warning",
                width: "400px",
                /** buttons */
                showDenyButton: true,
                confirmButtonText: buttonText,
                confirmButtonColor: "#900052",
                showCancelButton: false,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    if (action == 1) {
                        this.$emit('mtdSendPermissionCloseBead');
                    } else {
                        this.$emit('mtdCloseFinal');
                    }
                }
            });
        },

        showUpdateTime(payload) {
            Swal.fire({
                title: "Desea aplicar el medicamento?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cambiar",
                didOpen: () => {
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdChangenew', payload);
                }
            });
        },

        cancellation(payload) {
            Swal.fire({
                title: "Desea anular el medicamento?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cambiar",
                didOpen: () => {
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdCancellation', payload);
                }
            });
        },


        confirmCheckin(item) {
            Swal.fire({
                title: "Desea confirmar checkin?",
                text: "Ingreso del paciente",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('confirm', item);
                }
            });
        },
        confirmCheckinSinAsis(item) {
            Swal.fire({
                title: "Desea confirmar checkin?",
                text: "Ingreso del paciente",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('confirmSinAsis', item);
                }
            });
        },

        showComment(payload) {
            Swal.fire({
                title: 'Ingrese su observación',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Escriba aquí'
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage('Por favor, ingrese una observación')
                    }
                    return value;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    payload.observation = result.value
                    this.$emit('mtdChangenew', payload);
                }
            });
        },

        showCommentKardex(payloadK, payloadK2) {
            let date = payloadK;
            let type = payloadK2;
            Swal.fire({
                title: 'Ingrese su descripción',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Escriba aquí'
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage('Por favor, ingrese una descripción')
                    }
                    return value;
                }
            }).then((result) => {
                // if (result.isConfirmed) {
                //     payloadK = result.value;
                //     const additionalInput = null;
                //     this.$emit('mtdInputKardexnew', payloadK, date, type, additionalInput);
                // }
                if (result.isConfirmed) {
                    payloadK = result.value;
                    const currentDate = new Date();
                    Swal.fire({
                        title: 'Ingrese la hora',
                        html: `
                            <div>
                                <label for="swal-time">Hora:</label>
                                <input type="time" id="swal-time" value="${currentDate.toTimeString().split(' ')[0].substring(0, 5)}" class="swal2-input" />
                            </div>
                        `,
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                        didOpen: () => {
                            const swalContainer = Swal.getHtmlContainer();
                            swalContainer.addEventListener('keydown', (event) => {
                                if (event.key === 'Enter') {
                                    Swal.clickConfirm();
                                }
                            });
                        },
                        preConfirm: () => {
                            const timeMod = document.getElementById('swal-time').value;
                            if (!timeMod) {
                                this.$swal.showValidationMessage('Debe ingresar una fecha');
                                return false;
                            }
                            return timeMod;
                        }
                    }).then((timeResult) => {
                        if (timeResult.isConfirmed) {
                            const timeNew = timeResult.value;
                            const additionalInput = null;
                            this.$emit('mtdInputKardexnew', payloadK, date, type, additionalInput, timeNew);
                        }
                    });
                }
            });
        },


        showCommentKardexGlicemiaTerapiaa(payloadK, payloadK2) {
            let date = payloadK;
            let type = payloadK2;

            Swal.fire({
                title: 'Ingrese su descripción',
                //input: 'textarea',
                html: `
                    <input id="swal-input1" class="swal2-input" placeholder="Veces que se realizará">
                    <input id="swal-input2" class="swal2-input" placeholder="Cada cuantas horas">
                `,
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: () => {
                    const input1 = document.getElementById('swal-input1').value;
                    const input2 = document.getElementById('swal-input2').value;

                    const isNumber = (value) => /^\d+$/.test(value);

                    if (!isNumber(input1) || !isNumber(input2)) {
                        Swal.showValidationMessage('Ambos campos deben contener solo números.');
                        return;
                    }

                    if (!input1 || !input2) {
                        Swal.showValidationMessage('Por favor, complete todos los campos')
                    }
                    return { input1, input2 };
                }
            }).then((result) => {
                // if (result.isConfirmed) {
                //     payloadK = result.value.input1;
                //     const additionalInput = result.value.input2;
                //     this.$emit('mtdInputKardexnew', payloadK, date, type, additionalInput);
                // }
                if (result.isConfirmed) {
                    payloadK = result.value.input1;
                    const additionalInput = result.value.input2;
                    const currentDate = new Date();
                    Swal.fire({
                        title: 'Ingrese la hora',
                        html: `
                            <div>
                                <label for="swal-time">Hora:</label>
                                <input type="time" id="swal-time" value="${currentDate.toTimeString().split(' ')[0].substring(0, 5)}" class="swal2-input" />
                            </div>
                        `,
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                        preConfirm: () => {
                            const timeMod = document.getElementById('swal-time').value;
                            if (!timeMod) {
                                this.$swal.showValidationMessage('Debe ingresar una fecha');
                                return false;
                            }
                            return timeMod;
                        }
                    }).then((timeResult) => {
                        if (timeResult.isConfirmed) {
                            const timeNew = timeResult.value;
                            this.$emit('mtdInputKardexnew', payloadK, date, type, additionalInput, timeNew);
                        }
                    });
                }
            });
        },
        showCommentKardexGlicemiaTerapia(payloadK, payloadK2) {
            let date = payloadK;
            let type = payloadK2;

            Swal.fire({
                title: 'Ingrese su descripción',
                html: `
                    <input id="swal-input1" class="swal2-input" placeholder="Veces que se realizará">
                    <input id="swal-input2" class="swal2-input" placeholder="Cada cuantas horas">
                `,
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: () => {
                    const input1 = document.getElementById('swal-input1').value;
                    const input2 = document.getElementById('swal-input2').value;

                    const isNumber = (value) => /^\d+$/.test(value);

                    if (!isNumber(input1) || !isNumber(input2)) {
                        Swal.showValidationMessage('Ambos campos deben contener solo números.');
                        return;
                    }

                    if (!input1 || !input2) {
                        Swal.showValidationMessage('Por favor, complete todos los campos');
                    }
                    return { input1, input2 };
                },
                didOpen: () => {
                    // Agregar listener para detectar la tecla Enter
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    payloadK = result.value.input1;
                    const additionalInput = result.value.input2;
                    const currentDate = new Date();
                    Swal.fire({
                        title: 'Ingrese la hora',
                        html: `
                            <div>
                                <label for="swal-time">Hora:</label>
                                <input type="time" id="swal-time" value="${currentDate.toTimeString().split(' ')[0].substring(0, 5)}" class="swal2-input" />
                            </div>
                        `,
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                        preConfirm: () => {
                            const timeMod = document.getElementById('swal-time').value;
                            if (!timeMod) {
                                Swal.showValidationMessage('Debe ingresar una hora');
                                return false;
                            }
                            return timeMod;
                        },
                        didOpen: () => {
                            const swalTimeContainer = Swal.getHtmlContainer();
                            swalTimeContainer.addEventListener('keydown', (event) => {
                                if (event.key === 'Enter') {
                                    Swal.clickConfirm();
                                }
                            });
                        }
                    }).then((timeResult) => {
                        if (timeResult.isConfirmed) {
                            const timeNew = timeResult.value;
                            this.$emit('mtdInputKardexnew', payloadK, date, type, additionalInput, timeNew);
                        }
                    });
                }
            });
        },


        commentKardex(text, type) {
            let commentHTML = '';
            let commentHTML2 = '';
            if (text.comment) {
                commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            }
            if (text.comment2) {
                if (type == 1) {
                    commentHTML2 = `<h3 style="font-size: 15px;">Cada: ${text.comment2} horas.</h3>`;
                } else {
                    if (text.comment2 == 'mecanica') {
                        commentHTML2 = `<h3 style="font-size: 15px;">(VENTILADOR MECÁNICO)</h3>`;
                    } else
                        if (text.comment2 == 'canula') {
                            commentHTML2 = `<h3 style="font-size: 15px;">(CÁNULA)</h3>`;
                        } else
                            if (text.comment2 == 'simple') {
                                commentHTML2 = `<h3 style="font-size: 15px;">(MASCARILLA SIMPLE)</h3>`;
                            } else
                                if (text.comment2 == 'reservorio') {
                                    commentHTML2 = `<h3 style="font-size: 15px;">(MASCARILLA RESERVORIO)</h3>`;
                                } else
                                    if (text.comment2 == 'venturi') {
                                        commentHTML2 = `<h3 style="font-size: 15px;">(MASCARILLA VÉNTURI)</h3>`;
                                    } else
                                        if (text.comment2 == 'gafas') {
                                            commentHTML2 = `<h3 style="font-size: 15px;">(GAFAS NASALES)</h3>`;
                                        } else
                                            if (text.comment2 == 'nebulizadora') {
                                                commentHTML2 = `<h3 style="font-size: 15px;">(MÁSCARA NEBULIZADORA)</h3>`;
                                            } else
                                                if (text.comment2 == 'caf') {
                                                    commentHTML2 = `<h3 style="font-size: 15px;">(CAF)</h3>`;
                                                } else {
                                                    commentHTML2 = `<h3 style="font-size: 15px;"></h3>`;
                                                }
                }
            }
            Swal.fire({
                html: `<h2 style="font-size: 20px;">${text.name}</h2>
                       <h3 style="font-size: 17px;">${text.date}</h3>
                       ${commentHTML}
                       ${commentHTML2}
                       <h5 style="font-size: 11px;">Fecha de registro: ${text.date_comment}</h5>
                `,
            });
        },

        commentAnswer(text) {
            Swal.fire({
                html: `<h3 style="font-size: 17px;"> <strong> Doctor Solicitante: </strong> ${text.doctor} </h3>
                       <h3 style="font-size: 17px;"> <strong> Doctor Interconsultista: </strong> ${text.doctorI}  </h3>
                       <h3 style="font-size: 14px;"> Fecha de respuesta: ${text.fecha}</h3>
                       <h4 style="font-size: 19px;">  ${text.respuesta}</h4>`,
            });
        },
        comment(text) {
            let commentHTML = '';
            let commentHTMLglucosa = '';
            let commentHTMLinsulina = '';
            if (text.comment) {
                commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            }
            if (text.glucosa) {
                commentHTMLglucosa = `<h3 style="font-size: 15px;"><strong>Glucosa: </strong>${text.glucosa}</h3>`;
            }
            if (text.insulina) {
                commentHTMLinsulina = `<h3 style="font-size: 15px;"><strong>Insulina: </strong>${text.insulina}</h3>`;
            }
            Swal.fire({
                html: `<h2 style="font-size: 20px;">${text.name}</h2>
                       <h3 style="font-size: 17px;">${text.date}</h3>
                       ${commentHTML}
                       ${commentHTMLglucosa}
                       ${commentHTMLinsulina}
                `,
            });
        },

        viewIndicaciones(text) {
            let commentHTML = '';
            
            if(text.value === null || text.value === ''){
                commentHTML = `<h3 style="font-size: 12px;">No hay indicaciones.</h3>`;
            } else {
                commentHTML = `<h3 style="font-size: 15px;">${text.value}</h3>`;
            }
            Swal.fire({
                html: `
                       ${commentHTML}
                `,
            });
        },
        horario(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Ingrese la hora',
                html: `<input type="time" id="timeInput" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora" 
                onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">`,
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdhorario', result.value, item)
                }
            });
        },
        horarioFull(item) {
            const now = new Date();
            const currentDate = now.toLocaleDateString('en-CA');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Ingrese la hora',
                html: `
                    <input type="date" id="dateInput" class="swal2-input" value="${currentDate}" placeholder="Seleccione la fecha" onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">
                    <input type="time" id="timeInput" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora" 
                    onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">

                `,
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const date = document.getElementById('dateInput').value;
                    const hour = document.getElementById('timeInput').value;
                    if (!date || !hour) {
                        Swal.showValidationMessage('¡Necesitas ingresar una fecha y hora!');
                    }
                    return {date, hour};
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const {date, hour}  = result.value;
                    this.$emit('mtdhorario', date, hour, item)
                }
            });
        },

        horarioPrn(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                html: `
                    <p> Se tomará fecha: ${date} </p>
                    <input type="time" id="timeInput" style="margin-top: 0px " class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora"
                    onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">
                `,
                // icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdhorario',null, result.value, item);
                }
            });
        },
        horarioFrequency(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                html: `
                    <p> Se tomará fecha: ${date} </p>
                    <input type="time" id="timeInput" style="margin-top: 0px " class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora"
                    onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">
                `,
                // icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdhorarioFrecuencia', result.value, item);
                }
            });
        },


        horarioPrnInsulinaa(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                html: `
                        <div style="padding:0px 25px 0px 25px">
                            <p>Se tomará la fecha: <strong>${date}</strong></p>
                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Hora:</strong></label>
                                <input type="time" id="timeInput" style="width: 200px; font-size: 14px; padding-top: 0px;" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">
                            </div>

                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                                <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                            </div>
                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                                <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                            </div>
                        </div>
                    `,
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const time = document.getElementById('timeInput').value;
                    if (!glucosa || !escalaRecogida || !time) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida,
                        time
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    this.$emit('mtdhorario', result.value.time, item);
                }
            });
        },
        horarioPrnInsulina(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                html: `
                    <div style="padding:0px 25px 0px 25px">
                        <p>Se tomará la fecha: <strong>${date}</strong></p>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Hora:</strong></label>
                            <input type="time" id="timeInput" style="width: 200px; font-size: 14px; padding-top: 0px;" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">
                        </div>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                            <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                        </div>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                            <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                        </div>
                    </div>
                `,
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const time = document.getElementById('timeInput').value;
                    if (!glucosa || !escalaRecogida || !time) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }
                    return { glucosa, escalaRecogida, time };
                },
                didOpen: () => {
                    // Agregar listener para tecla Enter
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa;
                    item.escala_recogida = result.value.escalaRecogida;
                    this.$emit('mtdhorario', result.value.time, item);
                }
            });
        },


        showInsuline(text) {
            Swal.fire({
                title: 'Escala Correctiva',
                html: `<textarea 
            style="width: 350px; 
                   height: 120px; 
                   border: none; 
                   border-radius: 8px; 
                   resize: none; 
                   overflow: hidden; 
                   background-color: #ffffff; /* Fondo blanco */
                   color: #000; 
                   font-family: monospace;" 
            readonly>${text}</textarea>`,
            });
        },


        Showescalaa(item) {
            Swal.fire({
                title: 'Información',
                html: `
                  <div style="padding:0px 25px 0px 25px">
                     <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                            <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                        </div>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                            <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                        </div>

                    </div>

                    `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;

                    if (!glucosa || !escalaRecogida) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    this.$emit('mtdaddinsulina', item);
                }
            });
        },
        Showescala(item) {
            Swal.fire({
                title: 'Información',
                html: `
                    <div style="padding:0px 25px 0px 25px">
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                            <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                        </div>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                            <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                        </div>
                    </div>
                `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;

                    if (!glucosa || !escalaRecogida) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida
                    };
                },
                didOpen: () => {
                    // Listener para tecla Enter
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa;
                    item.escala_recogida = result.value.escalaRecogida;
                    this.$emit('mtdaddinsulina', item);
                }
            });
        },


        Showescalaobservationn(item) {
            Swal.fire({
                title: 'Información',
                html: `
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                    <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                    <input type="text" id="glucosa" class="swal2-input" style="width: 300px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                </div>
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                    <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                    <input type="text" id="escala_recogida" class="swal2-input" style="width: 300px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                </div>
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 10px;">
                    <label for="observacion" style="margin-right: 10px; min-width: 150px;"><strong>Observación:</strong></label>
                    <textarea id="observacion" class="swal2-textarea" style="width: 300px; font-size: 14px; height: 120px;" placeholder="Ingrese observación"></textarea>
                </div>
                 `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const observation = document.getElementById('observacion').value;
                    if (!glucosa || !escalaRecogida || !observation) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida,
                        observation
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    item.observation = result.value.observation
                    this.$emit('mtdChangenew', item);
                }
            });
        },
        Showescalaobservation(item) {
            Swal.fire({
                title: 'Información',
                html: `
                    <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                        <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                        <input type="text" id="glucosa" class="swal2-input" style="width: 300px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                    </div>
                    <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                        <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                        <input type="text" id="escala_recogida" class="swal2-input" style="width: 300px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                    </div>
                    <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 10px;">
                        <label for="observacion" style="margin-right: 10px; min-width: 150px;"><strong>Observación:</strong></label>
                        <textarea id="observacion" class="swal2-textarea" style="width: 300px; font-size: 14px; height: 120px;" placeholder="Ingrese observación"></textarea>
                    </div>
                `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const observation = document.getElementById('observacion').value;

                    if (!glucosa || !escalaRecogida || !observation) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida,
                        observation
                    };
                },
                didOpen: () => {
                    // Listener para tecla Enter
                    const swalContainer = Swal.getHtmlContainer();
                    swalContainer.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa;
                    item.escala_recogida = result.value.escalaRecogida;
                    item.observation = result.value.observation;
                    this.$emit('mtdChangenew', item);
                }
            });
        },


        ViewNotesDetalle(text) {
            // let commentHTML = '';
            // if (text.comment) {
            //     commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            // }
            Swal.fire({
                html: `
                 <div  style="padding : 0px 20px 0px 20px">
                    <h2 style="font-size: 20px;">${text.name}</h2>
                    <h3 style="font-size: 17px;">${text.date}</h3>
                    <div style=" display: flex; ">
                        <h5 style="font-size: 16px;"><strong>Zona: </strong> ${text.zona}</h5>
                        <h5 style="font-size: 16px; margin-left: 150px"> <strong>Grado: </strong> ${text.grado}</h5> 
                    </div>
                    <h5 style="font-size: 16px; text-align: left;"><strong>Detalle de la Curación:</strong></h5>
                    <textarea 
                        style="width: 450px; 
                            // height: 220px; 
                            border: none; 
                            border-radius: 8px; 
                            resize: none; 
                            overflow: hidden; 
                            background-color: #ffffff; /* Fondo blanco */
                            color: #000; 
                            font-family: monospace;
                            font-size: 16px;" 
                        readonly>${text.comment}</textarea> 
                </div>

                `,
            });
        },

        aplication(payload) {
            Swal.fire({
                title: "¿Desea aplicar el plan?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Aplicar",
                cancelButtonText: "Cancelar",
                didOpen: () => {
                    const swalModal = Swal.getHtmlContainer();
                    swalModal.addEventListener('keydown', (event) => {
                        if (event.key === 'Enter') {
                            Swal.clickConfirm();
                        }
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('aplicaciondata', payload);
                }
            });
        },

        devolucionPendiente(payload) {
            Swal.fire({
                title: "Pendiente de devoluciones",
                text: "¿Desea devolver?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, devolver",
                cancelButtonText: "No, cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('devolucionCancelada', payload);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.$emit('openRecipeDetail', payload);
                }
            });
        },

        addlote(payload) {
            Swal.fire({
                title: "¿Está seguro de realizar esta acción?",
                text: "Esta acción no se puede revertir.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, continuar",
                cancelButtonText: "No, cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    // Emitir el evento para continuar con la acción
                    this.$emit('loteadd', payload);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.$emit('mtdClose');
                }
                // No es necesario manejar el caso de cancelación, ya que no se hará nada
            });
        },
        // ViewInfusion(text) {
        //     const inputs = text.recipedetail_ids
        //         .map(
        //             (item, index) =>
        //                 `<div style="font-size: 14px; margin-bottom: 10px;">
        //                     <label for="detail_${index}" style="width: 89%; text-align: left;"><strong>${item.name}:</strong> (${item.spare})</label>
        //                     <input type="text" id="detail_${index}" class="swal2-input" style="width: 90%; font-size: 14px;" placeholder="Ingrese ${item.name}">
        //                 </div>`
        //         )
        //         .join("");

        //     Swal.fire({
        //         html: `
        //             <div style="padding: 20px 0px 0px 0px">
        //                 <div style="font-size: 20px; margin-bottom: 10px;">
        //                     <label for="mezcla_total" style="width: 89%; text-align: left;"><strong>Dosis: ${text.quantity}</strong></label>
        //                 </div>
        //                 <div style="font-size: 14px; margin-bottom: 10px;">
        //                     <label for="mezcla_total" style="width: 89%; text-align: left;"><strong>MEZCLA TOTAL:</strong></label>
        //                     <input type="text" id="mezcla_total" class="swal2-input" style="width: 90%; font-size: 14px;" placeholder="Ingrese la mezcla total">
        //                 </div>
        //                 <div style="font-size: 14px; margin-bottom: 10px;">
        //                     <label for="tiempo_total" style="width: 89%; text-align: left;"><strong>TIEMPO TOTAL ${text.time == 1 ? '(HORAS)' : '(MINUTOS)'}:</strong></label>
        //                     <input type="text" id="tiempo_total" class="swal2-input" style="width: 90%; font-size: 14px;"  placeholder="Ingrese la tiempo total">
        //                 </div>
        //                 ${inputs}
                        
        //             </div>
        //         `,
        //         showCancelButton: true,
        //         cancelButtonText: 'Cancelar',
        //         confirmButtonText: 'Aceptar',
        //         preConfirm: () => {
        //             const data = text.recipedetail_ids.map((_, index) => {
        //                 const inputValue = document.getElementById(`detail_${index}`).value.trim();
        //                 return {
        //                     id: text.recipedetail_ids[index].idsDetails,
        //                     value: parseFloat(inputValue) || 0, // Asegurarse de que sea un número
        //                     spare: parseFloat(text.recipedetail_ids[index].spare) || 0
        //                 };
        //             });

        //             const mezclaTotal = document.getElementById("mezcla_total").value.trim();
        //             const tiempoTotal = document.getElementById("tiempo_total").value;

        //             // Validar campos vacíos
        //             const invalidFields = data.filter(item => !item.value).length > 0 || !mezclaTotal || !tiempoTotal;

        //             // Validar que value no exceda spare
        //             const invalidValues = data.filter(item => item.value > item.spare);

        //             if (invalidFields) {
        //                 Swal.showValidationMessage("Por favor, complete todos los campos.");
        //                 return false;
        //             }

        //             if (invalidValues.length > 0) {
        //                 Swal.showValidationMessage("Uno o más valores exceden la cantidad disponible.");
        //                 return false;
        //             }

        //             return { detalles: data, mezclaTotal, tiempoTotal };
        //         },
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             const { detalles, mezclaTotal, tiempoTotal } = result.value;

        //             const data = {
        //                 recipedetail_id: text.recipedetail_id,
        //                 hour: text.hour,
        //                 detalles: detalles,
        //                 mezcla: mezclaTotal,
        //                 tiempoTotal: tiempoTotal
        //             };
        //             this.$emit('addinfusion', data);
        //         }
        //     });
        // },


        ViewInfusion(text) {
                const inputs = text.recipedetail_ids
                    .map(
                        (item, index) =>
                            `<div style="font-size: 14px; margin-bottom: 10px;">
                                <label for="detail_${index}" style="width: 89%; text-align: left;"><strong>${item.name}:</strong> (${item.spare})</label>
                                <input type="text" id="detail_${index}" class="swal2-input" style="width: 90%; font-size: 14px;" placeholder="Ingrese ${item.name}">
                            </div>`
                    )
                    .join("");

                Swal.fire({
                    html: 
                        `<div style="padding: 20px 0px 0px 0px">
                            <div style="font-size: 20px; margin-bottom: 10px;">
                                <label for="mezcla_total" style="width: 89%; text-align: left;"><strong>Dosis: ${text.quantity}</strong></label>
                            </div>
                            <div style="font-size: 14px; margin-bottom: 10px;">
                                <label for="tiempo_total" style="width: 89%; text-align: left;">
                                    <strong>TIEMPO TOTAL ${text.time == 1 ? '(HORAS)' : '(MINUTOS)'}:</strong>
                                </label>
                                <input type="text" id="tiempo_total" class="swal2-input" 
                                    style="width: 90%; font-size: 14px;" 
                                    placeholder="Ingrese tiempo total" 
                                    oninput="updateMezclaTotal(this.value)">
                            </div>
                          
                            
                            ${inputs}
                            <div style="font-size: 14px; margin-bottom: 10px;">
                                <label for="mezcla_total" style="width: 89%; text-align: left;">
                                    <strong>MEZCLA TOTAL:</strong>
                                </label>
                                <input type="text" id="mezcla_total" 
                                    class="swal2-input" 
                                    style="width: 90%; font-size: 14px;" 
                                    value="0" 
                                    disabled>
                            </div>
                        </div>`,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Aceptar',
                    preConfirm: () => {
                        const data = text.recipedetail_ids.map((_, index) => {
                            const inputValue = document.getElementById(`detail_${index}`).value.trim();
                            return {
                                id: text.recipedetail_ids[index].idsDetails,
                                value: parseFloat(inputValue) || 0, 
                                spare: parseFloat(text.recipedetail_ids[index].spare) || 0
                            };
                        });

                        const mezclaTotal = document.getElementById("mezcla_total").value.trim();
                        const tiempoTotal = document.getElementById("tiempo_total").value.trim();

                        const invalidFields = data.filter(item => !item.value).length > 0;
                        const invalidValues = data.filter(item => item.value > item.spare);

                        if (invalidFields) {
                            Swal.showValidationMessage("Por favor, complete todos los campos.");
                            return false;
                        }

                        if (invalidValues.length > 0) {
                            Swal.showValidationMessage("Uno o más valores exceden la cantidad disponible.");
                            return false;
                        }

                        return { detalles: data, mezclaTotal, tiempoTotal };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const { detalles, mezclaTotal, tiempoTotal } = result.value;

                        const data = {
                            recipedetail_id: text.recipedetail_id,
                            hour: text.hour,
                            detalles: detalles,
                            mezcla: mezclaTotal,
                            tiempoTotal: tiempoTotal
                        };
                        this.$emit('addinfusion', data);
                    }
                });

                // Función para actualizar el valor de mezclaTotal dinámicamente
                window.updateMezclaTotal = (tiempoTotal) => {
                    const mezclaTotalInput = document.getElementById("mezcla_total");
                    const tiempo = parseFloat(tiempoTotal) || 0;
                    const mezclaTotal = 
                        text.unidad === 3
                            ? (text.quantity_real * tiempo * 3).toFixed(2)
                            : (text.quantity_real * tiempo).toFixed(2);

                    mezclaTotalInput.value = mezclaTotal;
                };
            },



   
        
        horarioPrnInfusion(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                html: `
                    <p> Se tomará fecha: ${date} </p>
                    <input type="time" id="timeInput" style="margin-top: 0px " class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">
                `,
                // icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdaddInfusion', result.value, item);
                }
            });
        },

        commentInfusion(text) {
                // Construimos el contenido de los medicamentos
                const medicamentosHtml = text.medicamentos
                    .map(
                        (medicamento) =>
                            `<li style="text-align: left; font-size: 14px;"><strong>${medicamento.name}</strong>:  ${medicamento.quantity}</li>`
                    )
                    .join(""); // Unimos todos los elementos en una lista

                Swal.fire({
                    html: `
                        <h2 style="font-size: 20px;">${text.name}</h2>
                        <h3 style="font-size: 17px;">${text.date}</h3>
                        <h3 style="font-size: 15px; text-align: left;"><strong>Medicamentos:</strong></h3>
                        <ul>
                            ${medicamentosHtml}
                        </ul>
                        <h3 style="font-size: 15px; text-align: left;"><strong>Tiempo Total: </strong>${text.total_time} ${text.time == 1 ?'H':'MIN'}</h3>
                        <h3 style="font-size: 15px; text-align: left;"><strong>Mezcla Total: </strong>${text.total_mix} ${text.unidad == 1 ?'CC':(text.unidad == 2 ? 'ML':'GOTAS')}</h3>
                        
                        
                       
                    `,
                });
            },

        addhorarioFrequency(item) {
                const now = new Date();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const date = `${year}/${month}/${day}`;
                const currentTime = `${hours}:${minutes}`;

                Swal.fire({
                    title: 'Información',
                    html: `
                        <p> Se tomará fecha: ${date} </p>
                        <input type="time" id="timeInput" style="margin-top: 0px " class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora"
                        onkeydown="if(event.key === 'Enter') document.querySelector('.swal2-confirm').click()">
                    `,
                    // icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    preConfirm: () => {
                        const time = document.getElementById('timeInput').value;
                        if (!time) {
                            Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                        }
                        return time;
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('mtdhorarioFrecuenciaModal', result.value, item);
                    }
                });
        },


    },
};
</script>

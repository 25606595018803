<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>NOTAS DE INGRESO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row mx-0">
                                   
                                </div>
                            </div>
                            <div class="col-md-12" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class=" text-center text-white border-white bg-main">
                                            <th scope="col">N°</th>
                                            <th scope="col">FECHA DE REGISTRO</th>
                                            <th scope="col">USUARIO SOLICITANTE</th>
                                            <th scope="col">ALMACEN</th>
                                            <th scope="col">OBSERVACION</th>
                                            <th scope="col" class="table-th-acciones">ESTADO</th>
                                            <th scope="col" class="table-th-acciones">ACCION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="cpData.length === 0">
                                            <td class="text-center align-middle" colspan="7">Sin Notas de Ingreso</td>
                                        </tr>
                                        <tr v-for="(item, index) in cpData" :key="index" class="font-size">
                                            <td class="text-center align-middle">
                                                {{index + 1}}
                                            </td>
                                            <td class="text-center align-middle">
                                                {{ item.date_register | formatDateTime }}
                                            </td>
                                            <td class="text-center align-middle">
                                                {{ item.user_name}}
                                            </td>
                                            <td class="text-center align-middle">
                                                {{ item.almacen_name }}
                                            </td>
                                            <td class="text-center align-middle">
                                                {{ item.observation ? item.observation : '-'}}
                                            </td>
                                            <td class="text-center align-middle">
                                                <div id="state_speciality" v-if="item.state == 1">
                                                    <div class="bg-secondary text-white">No Aprobado</div>
                                                </div>
                                                <div id="state_speciality" v-else>
                                                    <div class="bg-success text-white">Aprobado</div>
                                                </div>
                                            </td>
                                            <td class="text-center align-middle">
                                                <div class="text-center d-flex justify-content-center">
                                                    <button class="btn btn-sm btn-info text-white mx-1" @click="openModal(item.id, item.noteDetail, item.state)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center px-3 mt-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index"
                                            class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                            class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                            class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)"
                                                @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalNoteDetailVue :item="modal.item" :idNote="modal.idNote" :state="modal.state" :title="modal.title" :boo_modal="modal.modal_form" 
        @updatePermiso="updatePermiso" @closeModal="closeModal"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalNoteDetailVue from '../modal/cModalNoteDetail.vue';
export default {
    name: "c-gerencia-permisoLogistica",
    components: {
        SweetAlert,
        cModalNoteDetailVue
    },
    data() {
        return {
            search: "",
            dataShow: [],
            data: [],
            filter: {
                init: null,
                end: null,
            },
            modal: {
                title: '',
                modal_form: false,
                item: [],
                idNote: null,
                state: null
            },
            //Pag
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            swal: null,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        moment.locale("es");
        this.filter.end =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Permisions/permisosLogistica",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.data = response.data;
                this.calculateData(10);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        openModal(id, detail, state){
            this.modal.title = 'Detalle de Nota de Ingreso';
            this.modal.idNote = id;
            this.modal.item = detail;
            this.modal.state = state;
            this.modal.modal_form = true;
        },

        closeModal(){
            this.modal = {
                title: '',
                modal_form: false,
                idNote: null,
                item: [],
                state: null
            }
        },

        updatePermiso(idNote){
            this.get({
                url: this.$store.getters.get__url + "/NotaIngreso/registerStock/" + idNote,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess("Nota de Ingreso Aceptada, Stocks movidos");
                    this.closeModal();
                    this.mtdGetData();
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginado
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
}
</script>
<style>
.font-size{
    font-size: 13px !important;
}
.align-middle {
    vertical-align: middle;
}
</style>
<template>
    <div>
        <div class="px-4">
            <div class="row">
                <div class=" btn-home col-md-12 mb-3 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                        <i class="fas fa-recycle"></i> Atras
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class=" card w-100">
                    <div class="text-center card-header text-white h5 bg-main">
                        <strong>SUB SUB CATEGORIA</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1 mt-2">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                            <v-select @input="mtdhidenproducto" :options="clasificacion" v-model="filter.type" class="flex-grow-1 custom-select-height" :reduce="(name) => name.id" label="name" 
                                                placeholder="Seleccione Clasificación" required />
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12 px-1 mt-2">
                                        <div class="input-group input-group">
                                            <div class="input-group input-group">
                                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                                <input @keyup.enter="mtdSearchData" @input="mtdSearchData" v-model="filter.name" type="text" class="form-control form-control" placeholder="Ingrese un Nombre"  :disabled="validationinput"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Paginado-->
                            <div v-if="dataSubsubcategory.length > 0 && cpData.length > 0" class="d-flex justify-content-end ml-5 mt-3">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0 ">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-md-12 mt-2" id="content-timelinetable px-1">
                                <table class="table table-bordered border-main">
                                    <caption></caption>
                                    <thead class="bg-main">
                                        <tr class=" table-th text-white text-center font-size">
                                            <th class="table-th-number">N°</th>
                                            <th class="nowrap">MARCA
                                                <i class="fa fa-arrow-up arrow-order" @click="sortData('brand', 'asc')"></i>
                                                <i class="fa fa-arrow-down arrow-order" @click="sortData('brand', 'desc')"></i>
                                            </th>
                                            <th>NOMBRE</th>
                                            <th>DOSIS</th>
                                            <th class="table-th-number">ESTADO</th>
                                            <th class="table-th-number">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="table-body text-center" v-if="dataSubsubcategory.length == 0 && cpData.length == 0">
                                            <td colspan="6" class="text-center">Sin Registros</td>
                                        </tr>
                                        <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                                            <td>
                                                {{mtdNumber(index) }}
                                            </td>
                                            <td> {{ item.brand ? item.brand : '-'  }}</td>
                                            <td class="table-th-name"> {{ item.name }}</td>
                                            <td class="fw-semibold"> {{ item.dosis ? item.dosis : '-'  }}</td>
                                            <td>
                                                <div id="state_speciality" v-if="item.state == 1">
                                                    <div class="bg-success text-white">Activo</div>
                                                </div>
                                                <div id="state_speciality" v-else>
                                                    <div class="bg-secondary text-white">Inactivo</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center d-flex justify-content-center">
                                                    <button class="btn btn-sm a-success text-white" @click="mtdModalShow(item)">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalSubSub :boo_modal="modal.modal_form" :title="modal.title" :payload="modal.payload" :filter="modal.filter"
        @closeModal="closeModal" @Datadetail="Datadetail"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {mapActions} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalSubSub from "@/components/Logistica/modals/Mantenimiento/cModalSubSub.vue";
export default {
    name: "c-logistica-mantenedores-subsub",
    components: {
        SweetAlert,
        cModalSubSub
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            data: [],
            filter: {
                name: null,
                type: null,
            },
            clasificacion:[],
            dataSubsubcategory: [],
            option: "",
            //Pag
            totalPagesToShow: 5,
            search: "",
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            // Estado de orden
            sortKey: '',
            sortOrder: '',
            //Modal
            modal: {
                title: "",
                action: "",
                modal_form: false,
                payload: {},
                filter: {},
            },
        }

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        validationinput(){
            return !this.filter.type
        },
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdClasificacion();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdClasificacion(){
            this.get({
                url: this.$store.getters.get__url + "/ProductDetailClassification",
                token: this.$store.getters.get__token,
            })
            .then(response => {
                if (response.statusCode == 200) {
                    this.clasificacion = response.data;
                }
            })
            .catch(error => {
                this.$refs.SweetAlert.showError(error);
            })
        },

        mtdhidenproducto(){
            this.dataSubsubcategory = [],
            this.dataShow = []
            this.filter.name = null
        },

        mtdSearchData () {
            if (this.filter.name != null) {
                if (this.filter.name.length > 4) {
                    this.post({
                            url: this.$store.getters.get__url + "/Medicament/filter",
                            token: this.$store.getters.get__token,
                            params: this.filter,
                        })
                        .then((response) => {
                            this.dataSubsubcategory = response.data;
                            this.option = "";
                            this.selectPage = 1;
                            this.calculateData(10);
                        })
                        .catch((errors) => {
                            this.$refs.SweetAlert.showError(errors);
                        });
                }
            } else {
                this.$refs.SweetAlert.showWarning("Debe digitar  un Nombre");
            }
        },

        calculateData(items) {
            if (!this.dataSubsubcategory) {
                this.dataShow = [];
            }
            let filteredData = this.dataSubsubcategory;
            let selectPage = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        sortData(key, order) {
            if (this.sortKey && this.sortKey !== key) {
                this.sortOrder = ''; 
            }
            this.sortKey = key;
            this.sortOrder = order;

            this.dataSubsubcategory.sort((a, b) => {
                let result = 0;
                const valueA = a[key];
                const valueB = b[key];

                if (valueA < valueB) result = -1;
                if (valueA > valueB) result = 1;

                return order === 'asc' ? result : -result;
            });
            this.calculateData(10); 
        },

        //Modal
        mtdModalShow: function (item) {
            this.modal.title = "Editar Dosis";
            this.modal.modal_form = true;
            this.modal.payload = item;
            this.modal.filter = this.filter;
        },

        closeModal() {
            this.modal= {
                title: "",
                action: "",
                modal_form: false,
                payload: {},
                filter: {},
            }
        },

        Datadetail: function (payload_detail, filter) {
            this.post({
                url: this.$store.getters.get__url + "/logistics/maintenance/editSubDosis",
                token: this.$store.getters.get__token,
                params: payload_detail,
            })
            .then((response) => {
                if (response.statusCode == 200) {
                    this.$refs.SweetAlert.showSuccess("Editado Correctamente");
                    this.filter.name = filter.name;
                    this.filter.type = filter.type;
                    this.mtdSearchData();
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },

        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },

        selectedPage(page) {
            this.selectPage = page;
        },

        mtdNumber: function (index) {
            return index + 1 + (this.selectPage - 1) * 10;
        },

        changePage(page) {
            this.$emit('changePage', page)
        },
       
    }
}
</script>

import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false
Vue.prototype.$log = console.log.bind(console)
Vue.component("v-select", vSelect);
Vue.prototype.$NoInsumos = []
Vue.prototype.$miVariableGlobalEv = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    /* vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'FR',
        value: ''
    },
    {
        id: 3,
        label: 'PA',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'FIO2',
        value: ''
    },
    {
        id: 6,
        label: 'T*',
        value: ''
    },
    {
        id: 7,
        label: 'HGT',
        value: ''
    },
    {
        id: 8,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 9,
        label: 'BH',
        value: ''
    },
    ], */
    vital_sings: [{
            id: 1,
            label: 'FC',
            value: ''
        },
        {
            id: 2,
            label: 'T*',
            value: ''
        },
        {
            id: 3,
            label: 'FR',
            value: ''
        },
        {
            id: 4,
            label: 'SO2',
            value: ''
        },
        {
            id: 5,
            label: 'HGT',
            value: ''
        },
        {
            id: 6,
            label: 'FIO2',
            value: ''
        },
        {
            id: 7,
            label: 'PAS',
            value: ''
        },
        {
            id: 8,
            label: 'PAD',
            value: ''
        },
        {
            id: 9,
            label: 'PAM',
            value: 0
        },
        {
            id: 10,
            label: 'DIURESIS',
            value: ''
        },
        {
            id: 11,
            label: 'BH',
            value: ''
        },
        
    ],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalEv_plantilla = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    /* vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'FR',
        value: ''
    },
    {
        id: 3,
        label: 'PA',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'FIO2',
        value: ''
    },
    {
        id: 6,
        label: 'T*',
        value: ''
    },
    {
        id: 7,
        label: 'HGT',
        value: ''
    },
    {
        id: 8,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 9,
        label: 'BH',
        value: ''
    },
    ], */
    vital_sings: [{
            id: 1,
            label: 'FC',
            value: ''
        },
        {
            id: 2,
            label: 'T*',
            value: ''
        },
        {
            id: 3,
            label: 'FR',
            value: ''
        },
        {
            id: 4,
            label: 'SO2',
            value: ''
        },
        {
            id: 5,
            label: 'HGT',
            value: ''
        },
        {
            id: 6,
            label: 'FIO2',
            value: ''
        },
        {
            id: 7,
            label: 'PAS',
            value: ''
        },
        {
            id: 8,
            label: 'PAD',
            value: ''
        },
        {
            id: 9,
            label: 'PAM',
            value: 0
        },
        {
            id: 10,
            label: 'DIURESIS',
            value: ''
        },
        {
            id: 11,
            label: 'BH',
            value: ''
        },
        
    ],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalRp = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalRp_plantilla = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalSheet = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
Vue.prototype.$miVariableGlobalSheet_plantilla = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};

//Uci
Vue.prototype.$NoInsumosUci = []
Vue.prototype.$miVariableGlobalEvUci = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalEvUci_plantilla = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalRpUci = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalRpUci_plantilla = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalSheetUci = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
Vue.prototype.$miVariableGlobalSheetUci_plantilla = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
//Fin Uci
//Consulta
Vue.prototype.$NoInsumosCon = []
Vue.prototype.$miVariableGlobalEvCon = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Pelianexos',
        value: ''
    },
    {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    },
    {
        id: 7,
        label: 'Gastroinstestinal',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalEvCon_plantilla = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Pelianexos',
        value: ''
    },
    {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    },
    {
        id: 7,
        label: 'Gastroinstestinal',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalRpCon = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: ''
};
Vue.prototype.$miVariableGlobalRpCon_plantilla = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: ''
};
Vue.prototype.$miVariableGlobalSheetCon = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
Vue.prototype.$miVariableGlobalSheetCon_plantilla = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
//Fin Consulta
//Emergencia
Vue.prototype.$NoInsumosEmergency = []
Vue.prototype.$miVariableGlobalEvEmergency = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalEvEmergency_plantilla = {
    date_name: 'EV ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Anexos',
        value: ''
    },
    /* {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    }, */
    {
        id: 6,
        label: 'Gastroinstestinal',
        value: ''
    },
    {
        id: 7,
        label: 'Otros',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalRpEmergency = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalRpEmergency_plantilla = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalSheetEmergency = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
Vue.prototype.$miVariableGlobalSheetEmergency_plantilla = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
//Fin Emergencia
//SOP
Vue.prototype.$miVariableGlobalRpSop = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalRpSop_plantilla = {
    date_name: 'RP ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day_hosp: 0,
    day_sop: 0,
    day_uci: 0,
    doctor_name: '',
    user_id: null,
    recipe: [],
    observation: '',
    plan: [],
    recomendation: '',
    dieta: '',
    insuline:''
};
Vue.prototype.$miVariableGlobalUrpa = {
    date_name: 'NOTA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Pelianexos',
        value: ''
    },
    {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    },
    {
        id: 7,
        label: 'Gastroinstestinal',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalUrpa_plantilla = {
    date_name: 'NOTA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    vital_sings: [{
        id: 1,
        label: 'FC',
        value: ''
    },
    {
        id: 2,
        label: 'T*',
        value: ''
    },
    {
        id: 3,
        label: 'FR',
        value: ''
    },
    {
        id: 4,
        label: 'SO2',
        value: ''
    },
    {
        id: 5,
        label: 'HGT',
        value: ''
    },
    {
        id: 6,
        label: 'FIO2',
        value: ''
    },
    {
        id: 7,
        label: 'PAS',
        value: ''
    },
    {
        id: 8,
        label: 'PAD',
        value: ''
    },
    {
        id: 9,
        label: 'PAM',
        value: 0
    },
    {
        id: 10,
        label: 'DIURESIS',
        value: ''
    },
    {
        id: 11,
        label: 'BH',
        value: ''
    }],
    subjective: '',
    physical_exam: [{
        id: 1,
        label: 'Estado General',
        value: ''
    },
    {
        id: 2,
        label: 'Neurologico',
        value: ''
    },
    {
        id: 3,
        label: 'Respiratorio',
        value: ''
    },
    {
        id: 4,
        label: 'Hemodinamico',
        value: ''
    },
    {
        id: 5,
        label: 'Pelianexos',
        value: ''
    },
    {
        id: 6,
        label: 'Metabo/Infecc',
        value: ''
    },
    {
        id: 7,
        label: 'Gastroinstestinal',
        value: ''
    },
    ],
    current_analytics: [{
        id: 1,
        label: 'LEUCOCITOS',
        value: ''
    },
    {
        id: 2,
        label: 'LINFOCITOS',
        value: ''
    },
    {
        id: 3,
        label: 'ABASTONADOS',
        value: ''
    },
    {
        id: 4,
        label: 'SEGMENTADOS',
        value: ''
    },
    {
        id: 5,
        label: 'HB/HCTO',
        value: ''
    },
    {
        id: 6,
        label: 'LDH',
        value: ''
    },
    {
        id: 7,
        label: 'PCR',
        value: ''
    },
    {
        id: 8,
        label: 'FERRTININA',
        value: ''
    },
    {
        id: 9,
        label: 'DIMERO 0',
        value: ''
    },
    {
        id: 10,
        label: 'TGO/TGP',
        value: ''
    },
    {
        id: 11,
        label: 'PH',
        value: ''
    },
    {
        id: 12,
        label: 'PO2',
        value: ''
    },
    {
        id: 13,
        label: 'PCO2',
        value: ''
    },
    {
        id: 14,
        label: 'HCO3',
        value: ''
    },
    {
        id: 15,
        label: 'NA+',
        value: ''
    },
    {
        id: 16,
        label: 'K+',
        value: ''
    },
    {
        id: 17,
        label: 'D(A-a)O2',
        value: ''
    },
    ],
    appreciation: '',
    diagnostics: [],
    plan: [],
};
Vue.prototype.$miVariableGlobalSheetSop = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
Vue.prototype.$miVariableGlobalSheetSop_plantilla = {
    date_name: 'GRÁFICA ACTUAL',
    id: null,
    medical_act_id: null,
    date: null,
    hour: null,
    day: 0,
    doctor_name: '',
    user_id: null,
    day_po: '',
    weight: '',
    deposition: '',
    urine: '',
    hydric_balance: '',
    values: [{
        id: 1,
        label: 'PAS',
        value: '',
        value2: '',
        inputs: 2
    },
    {
        id: 2,
        label: 'PAD',
        value: '',
        inputs: 1
    },
    {
        id: 3,
        label: 'FC',
        value: '',
        inputs: 1
    },
    {
        id: 4,
        label: 'FR',
        value: '',
        inputs: 1
    },
    {
        id: 5,
        label: 'T*',
        value: '',
        inputs: 1
    },
    {
        id: 6,
        label: 'SAT02',
        value: '',
        inputs: 1
    }
    ],
};
//FIN DE SOP
//Balance Hidrico
Vue.prototype.$hidricoIngreso = []
Vue.prototype.$hidricoEgreso = []
export const bus = new Vue();

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    template: '<App/>',
    components: {
        App
    }
})
//.$mount('#app')

<template lang="es">
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true" :title="title">

    <div class="row d-flex px-3 py-2 mt-2">
        <div class="px-3 py-2 text-white col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3" style="background-color: #900052; border-radius: 10px; white-space: nowrap;">
            <div style="white-space: normal; word-wrap: break-word;">
                {{ cabecera.name }}
            </div>
        </div>
        <div class="col-xl-8 col-lg-7 col-md-9">
            <div class="row d-flex">
                <!--EDITAR Guia de remision-->
                <div class="bg-white py-1 col-auto">GUIA DE REMISION
                </div>
                <div class="me-3 col-auto">
                    <select class="form-control form-control-sm " id="exampleFormControlSelect1" v-model="selectGuide">
                        <option disabled value="">Seleccione ....</option>
                        <option v-bind:value="1">Aplica</option>
                        <option v-bind:value="2">
                            No aplica
                        </option>
                    </select>
                </div>
                <div class="me-2 col-auto">
                    <input class="form-control form-control-sm w-100" v-model="guide_remission" id="exampleFormControlTextarea1" placeholder="Numero de Guia" :disabled="mtdDisableImput" />
                </div>
                <div class="mb-3 col-auto" v-if="selectGuide ==1">
                    <input class="form-control form-control-sm" id="formFileSm" type="file" @change="getFile" lang="es" name="file" ref="fileInput">
                </div>
                <!-- FIN EDITAR Guia de remision-->
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3">
            <div class="px-2 py-2 bg-main text-white" style="border-radius: 10px">
                {{ codigo }}
            </div>
        </div>
    </div>

    <div class="px-2">
        <div class="col-md-12 table-responsive table-container" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered">
                <thead class="bg-main text-white" style="font-size: 11px">
                    <tr class="text-align-center">
                        <th scope="col" class="align-middle">N°</th>
                        <th scope="col" class="align-middle">MOLECULA</th>
                        <th scope="col" class="align-middle">COMERCIAL</th>
                        <th scope="col" class="align-middle">MARCA</th>
                        <!-- <th scope="col" class="align-middle">PRESENTACIÓN</th>
                        <th scope="col" colspan="1" class="align-middle">DOSIS</th>
                        <th scope="col" colspan="1" class="align-middle">FORMA</th>
                        <th scope="col" colspan="1" class="align-middle">S/PRECIO UNITARIO</th>
                        <th scope="col" colspan="1" class="align-middle">CANT. UNIDADES</th> -->
                        <th class="align-middle">CANT. PEDIDA</th>
                        <th class="align-middle">CANT. RECIBIDA TOTAL</th>
                        <th class="align-middle">CANT. PENDIENTE</th>
                        <th class="align-middle">CANT. ENTRANTE</th>
                        <th class="align-middle">BONUS</th>
                        <th class="align-middle">LOTE</th>
                        <th class="align-middle">CODIGO DE BARRAS</th>
                        <th scope="col" colspan="1" class="align-middle">F.VENCIMIENTO</th>
                        <th scope="col" colspan="1" class="align-middle">REG.SANITARIO</th>
                        <th scope="col" colspan="1" class="align-middle">S/SUBTOTAL SIN IGV</th>
                        <th scope="col" colspan="1" class="align-middle">DESCUENTO%</th>
                        <th scope="col" colspan="1" class="align-middle">TOTAL CON DESC</th>
                        <th scope="col" colspan="1" class="align-middle">AÑADIR</th>
                    </tr>
                </thead>

                <tbody style="font-size: 11px" class="border-main">
                    <tr class="tbody-table1" v-for="(item, index) in dataordendetalle" :key="index" :class="getRowClass(item)">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>
                            {{ item.productodetail.molecule }}
                        </td>
                        <td>
                            {{ item.productodetail.commercial }}
                        </td>
                        <td>
                            {{ item.productodetail.brand ? item.productodetail.brand.name : '-'  }}
                        </td>
                        <!-- <td>
                            {{ item.productodetail.presentation }}
                        </td>
                        <td>
                            {{ item.productodetail.dosis }}
                        </td>
                        <td>
                            {{ item.productodetail.format }}
                        </td>
                        <td>
                            {{ item.price_unit }}
                        </td> 

                        <td>
                            {{ item.quantity_unit }}
                        </td>-->
                        <!---cantidad pedida-->
                        <td>
                            {{ item.quantity_purchase }}
                        </td>
                        <!---cantidad recibida-->
                        <td>
                            {{ mtdCalculeQuantityReceived(index) }}
                        </td>
                        <td>
                            {{item.quantity_purcharse_pending}}
                        </td>
                        <!---cantidad entrante-->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="text" class="input-p form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].incoming_quantity" @keydown="filterKey" @input="validateInput(index)" @click="mtdCompletedData(index)" placeholder="C.ENTRANTE" />
                                <div v-if="!isValidInput[index]" class="error-message">
                                    Cantidad no permitida
                                </div>
                            </template>
                            <template v-else> Cantidad completada </template>
                        </td>
                        <!---cantidad de bonus-->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="text" class="input-p form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].bonus" @keydown="filterKey" placeholder="C.BONUS" />
                            </template>
                            <template v-else> - </template>
                        </td>
                        <!--lote---->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="text" class="input-p input-lote form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].lote" placeholder="LOTE" />
                            </template>
                            <template v-else> - </template>
                        </td>
                        <!--barcode---->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="text" class="input-p input-barcode form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].barcode" @keydown="filterKey" maxlength="11" placeholder="CODIGO DE BARRAS" />
                            </template>
                            <template v-else> - </template>
                        </td>
                        <!--date-->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="date" class="input-p form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].date" :min="fechaMinima" />
                            </template>
                            <template v-else> - </template>
                        </td>
                        <!--register_sanitary-->
                        <td>
                            <template v-if="item.quantity_purcharse_pending !== 0">
                                <input type="text" class="input-p form-control form-control-sm text-center" v-model="dataBillDetailEdit[index].register_sanitary" placeholder="Reg. Sanitario" />
                            </template>
                            <template v-else> - </template>
                        </td>
                        <td>
                            {{ item.price_purchase }}
                        </td>
                        <td>
                            {{ item.discount !== null ? parseInt(item.discount) + '%' : '0%' }}
                        </td>
                        <td>
                            {{item.price_discount !== null ? item.price_discount : '0.00'}}
                        </td>
                        <td class="align-middle  d-flex justify-content-center">
                            <button v-if="dataBillDetailEdit[index].state == 1" class="btn btn-success btn-sm text-xxs" :disabled="cpdisableMore(item,index)" @click="addItem(index,item)">
                                <i class="fas fa-plus text-white"></i>
                            </button>
                            <button v-if="dataBillDetailEdit[index].state == 2" class="btn btn-danger btn-sm text-xxs" :disabled="item.quantity_purcharse_pending === 0" @click="removeItem(index)">
                                <i class="fas fa-minus text-white"></i>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="d-flex justify-content-between responsive-footer mt-4">
        <div class="d-flex text-white observation  ">
            <div class="ms-4 w-100">
                <div id="app" class="">
                    <!-- Componente Textarea -->
                    <textarea v-model="observation" class="form-control " rows="2" placeholder="Escribe tus observaciones"></textarea>
                </div>
            </div>
        </div>

        <div class="d-flex responsive-footer-div">
            <div class="px-3 py-1 pt-2 bg-white">
                NO GRAVADA S/: {{ cabecera.not_engraved }}
            </div>
            <div class="px-3 py-1 pt-2 bg-white">
                VALOR DE VENTA S/: {{ cabecera.total_sin_IGV }}
            </div>
            <div class="px-3 py-1 pt-2 bg-white">IGV S/: {{ mtdGetIgv() }}</div>
            <div class="px-3 py-1 pt-2 mx-1 bg-white">
                TOTAL C/ IGV: {{ cabecera.total_IGV }}
            </div>
            <button type="button" class="px-4 border-0 text-white btn-register" @click="mtdBeforeInsert" :disabled="cpdisableButton">
                OK
            </button>
        </div>
    </div>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-storeDispatch",
    components: {
        CModal,
    },
    props: {
        title: String,
        boo_modal: Boolean,
        codigo: String,
        bill: Number,
        dataordendetalle: {
            type: Array,
            default: () => [],
        },
        dataBillDetailEdit: {
            type: Array,
            default: () => [],
        },

        cabecera: Object,
    },

    data() {
        return {
            dataAlmacen: [],
            filter: {
                campus_id: null,
            },
            almacen: {
                id: null,
                name: "",
            },
            isValidInput: [],
            guide_remission: null,
            selectGuide: "",
            observation: "",
            file: "",
        };
    },
    computed: {
        fechaMinima() {
            // Obtener la fecha actual y aumentarle un día
            const fecha = new Date();
            fecha.setDate(fecha.getDate() + 1);
            const fechaActualizada = fecha.toISOString().split('T')[0];
            return fechaActualizada;

        },
        
        cpdisableButton: function () {
            let showButton = 0;
            let showButtonCompleted = 0;
            this.dataordendetalle.forEach((element, index) => {
                if (element.quantity_purcharse_pending !== 0) {
                    if (
                        this.dataBillDetailEdit[index].incoming_quantity !== null &&
                        this.dataBillDetailEdit[index].incoming_quantity !== "" &&
                        this.dataBillDetailEdit[index].date !== null &&
                        this.dataBillDetailEdit[index].lote !== "" &&
                        this.dataBillDetailEdit[index].barcode !== "" &&
                        this.dataBillDetailEdit[index].barcode.length === 11 &&
                        this.dataBillDetailEdit[index].register_sanitary !== ""
                    ) {
                        //si ya escribieron
                        if (this.isValidInput[index] === true) {
                            showButton = showButton + 1;
                        }

                    }
                } else {
                    showButton += 1;
                    showButtonCompleted += 1;
                }
            });
            return (showButton !== this.dataordendetalle.length || showButtonCompleted === showButton);
        },
        mtdDisableImput() {
            return (this.selectGuide !== 1)
        }
    },
    watch: {
        dataordendetalle: {
            immediate: true, // Para ejecutar el watcher inmediatamente al inicio
            handler(newArrayDetalle) {
                this.isValidInput = Array(newArrayDetalle.length).fill(true);
                this.vercampus();
            }
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        cpdisableMore (item){
            //poner en disabled cuando es menor de 2 y cuando ya se haya completado el valor
            let resp = false;
            this.dataordendetalle.forEach((element,index) => {
                if(item.id == element.id){
                    let quantityInput = this.dataBillDetailEdit[index].incoming_quantity;
                    if(item.quantity_purcharse_pending < 2 ){
                        resp = true;
                    }else{
                        //verificar que no hay otro como el mismo id
                        resp = this.dataBillDetailEdit[index].incoming_quantity !== null &&
                        this.dataBillDetailEdit[index].incoming_quantity !== ""? item.quantity_purcharse_pending == quantityInput : false;
                    }
                }
            });
            return resp;
        },
        getRowClass(item) {
            return {
                'table-success': item.quantity_purcharse_pending === 0,
            };
        },
        mtdClose() {
            this.almacen = {
                id: null,
                name: "",
            };
            this.guide_remission = null;
            this.$emit("mtdCloseModalFactura");
        },

        mtdGetIgv: function () {
            if (this.cabecera && this.cabecera.total_sin_IGV) {
                const igv = 0.18 * this.cabecera.total_sin_IGV;
                return parseFloat(igv).toFixed(2);
            } else {
                return 0.0;
            }
        },
        mtdCalculeQuantityReceived: function (index) {
            return this.dataordendetalle[index].quantity_purchase - this.dataordendetalle[index].quantity_purcharse_pending;

        },
        validateInput(index) {
            const inputValue = this.dataBillDetailEdit[index].incoming_quantity;
            const id_product = this.dataBillDetailEdit[index].id_product_details;
            let sumQuantity = 0;
            // Validación personalizada: permitir solo valores 0 y menores a la cantidad pedida
            this.dataBillDetailEdit.forEach((element,i) => {
                if(id_product == element.id_product_details){
                    if(index !== i){
                        let valueOther = element.incoming_quantity && element.incoming_quantity !== '' ? element.incoming_quantity : 0;
                        sumQuantity+=valueOther;
                    }
                }
            });

            if (
                isNaN(inputValue) ||
                inputValue < 1 || inputValue == '' ||
                inputValue > (this.dataordendetalle[index].quantity_purcharse_pending-sumQuantity)
            ) {
                this.isValidInput[index] = false;

            } else {
                this.isValidInput[index] = true;
            }

        },
        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                Swal.fire({
                    text: 'Seleccione un archivo formato PDF, PNG O JPG',
                    icon: 'info',
                    confirmButtonColor: '#900052',
                });
                fileInput.value = '';
            } else {
                this.file = file;
            }
        },
        mtdBeforeInsert: function () {
            //validar que se ha seleccionado un almacen
            if (this.almacen.id === null) {
                Swal.fire({
                    icon: "warning",
                    title: "No hay almacen seleccionado",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                let confirmTitle = "¿Desea registrar los datos?";

                if (this.selectGuide == 1 && this.guide_remission === null) {
                    Swal.fire({
                        icon: "warning",
                        title: "Debe completar el número de guía de remisión",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    return; // Salir del bloque si no se cumple la condición
                }

                Swal.fire({
                    title: confirmTitle,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, registrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.mtdInsertDispatch();
                    }
                });
            }

        },
        mtdCompletedData: function (index) {
            this.dataBillDetailEdit[index].quantity_pending_order = this.dataordendetalle[index].quantity_purcharse_pending;
            this.dataBillDetailEdit[index].id_product_details = this.dataordendetalle[index].productodetail.id;
            this.dataBillDetailEdit[index].id_purcharse_order_detail = this.dataordendetalle[index].id;
        },
        mtdInsertDispatch: function () {
            let formData = new FormData();
            formData.append("purchase_orders_id", this.dataordendetalle[0].purchase_orders_id);
            formData.append("bill_id", this.bill);
            formData.append("guide_remission", this.guide_remission === null ? 'No' : this.guide_remission);
            formData.append("file", this.file);
            formData.append("id_almacen", this.almacen.id);
            formData.append("detail", JSON.stringify(this.dataBillDetailEdit));
            formData.append("observation", this.observation);
            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/oc/storeDispatch",
                    token: this.$store.getters.get__token,
                    params: formData
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Datos Guardados",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.mtdClose();
                    }
                })
                .catch((errors) => {});
        },

        vercampus: function () {
            this.filter.campus_id = this.$store.getters.get__campus;
            this.post({
                    url: this.$store.getters.get__url + "/Almacen/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    if (response.data.length == 0) {
                        Swal.fire({
                            title: "Advertencia!",
                            text: "Agregue Almacenes",
                            icon: "warning",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    } else {
                        this.dataAlmacen = response.data;
                        this.almacen.name = this.dataAlmacen[0].name;
                        this.almacen.id = this.dataAlmacen[0].id;
                    }
                })
                .catch((errors) => {});
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdalmacen: function (payload) {
            this.almacen.id = payload.id;
            this.almacen.name = payload.name;
        },
        mtdclosemodal() {
            this.almacen = {
                id: null,
                name: "",
            };
            this.guide_remission = null;
            this.$emit('mtdCloseModalFactura')
        },
        //añadir un item igual
        addItem(indice, nuevoItem) {
            this.dataordendetalle.splice(indice + 1, 0, nuevoItem);
            let itemEdit = {
                id_product_details: null,
                lote: "",
                date: null,
                incoming_quantity: null,
                register_sanitary: "",
                quantity_pending_order: null,
                id_purcharse_order_detail: null,
                barcode: "",
                bonus: null,
                state: 2,
            };
            this.dataBillDetailEdit.splice(indice + 1, 0, itemEdit);
        },
        removeItem(index) {
            this.dataordendetalle.splice(index, 1);
            this.dataBillDetailEdit.splice(index, 1);

        }
    },
};
</script>

<style scoped>
.input-p::placeholder {
    font-size: 10px;
}

.input-p {
    font-size: 11px;
}

.btn-register {
    background-color: rgb(144, 0, 82);
    height: 40px;
}

.btn-register:disabled {
    background-color: rgb(208, 138, 177) !important;
    cursor: not-allowed;
}

@media (max-width: 992px) {
    .responsive-proveedor {
        flex-direction: column;
    }

    .responsive-contador {
        flex-direction: column;
    }

    .responsive-contador>div {
        margin-top: 10px;
        width: 100% !important;
    }

    .responsive-footer {
        flex-direction: column;
    }

    .responsive-footer-div {
        justify-content: end;
    }
}

@media (max-width: 365px) {
    .responsive-footer>.text-white {
        flex-direction: column;
        padding: 0px 20px 0px 20px;
    }

    .responsive-footer>.text-white>div {
        margin: 0px 0px 9px 0px !important;
    }
}

#input-cantidad {
    padding: 7px;
    width: 170px;
}

.error-message {
    color: red;
    font-size: 9px;
}

.input-lote {
    width: 55px;
}

.input-barcode {
    width: 110px;
}

#input-lote {
    padding: 7px;
    width: 100px;
}

#input-fecha {
    padding: 7px;
    width: 190px;
}

#input-registro {
    padding: 7px;
    width: 180px;
}

.anterior-siguiente:focus {
    background: #900052;
}

.anterior-siguiente:hover {
    background: #bb026c;
}

.dropdown-toggle {
    width: 175px !important;
    height: 30px;
}

.observation {
    width: 550px;
}
</style>

<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="d-lg-flex mt-3">
            <div class="col-xl-1 mb-2">
                <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackSop">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7 col-md-12 rounded-3 px-0 bg-blue mb-2 text-white">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-2 mb-2 px-1 px-md-1 px-xl-3">
                <div class="card border border-main pt-1">
                    <span class="color-main fw-bold text-uppercase">IntraOperatorio</span>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 mb-1 d-none d-lg-flex">
                <button type="button" class="btn text-white btn-sm bg-main text-xs w-100 " @click="mtdaddall">
                    <i class="fas fa-save"></i>  GUARDAR
                </button>
            </div>
            <div class="col-xl-2 col-lg-2 mb-1 d-md-block d-lg-none">
                <button type="button" class="btn text-white btn-sm bg-main text-xs w-100 " @click="mtdaddRp">
                    <i class="fas fa-save"></i>  GUARDAR RP
                </button>
            </div>
        </div>
    </div>
    <!-- TABs -->
    <div class="px-2 mt-2 ">

        <ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hojaanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'hojaanestecia' }" id="profile-tab" type="button">
                    <strong class="text-xxs">HOJA DE ANESTECIA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rp')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button">
                    <strong class="text-xxs">R.P DE SOP</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('listaverificacion')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'listaverificacion' }" id="profile-tab" type="button">
                    <strong class="text-xxs">LISTA DE VERIF.</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('kardex')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button">
                    <strong class="text-xxs">KARDEX SOP</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('notaenfermeria')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'notaenfermeria' }" id="profile-tab" type="button">
                    <strong class="text-xxs">NOTAS ENF. SOP</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('balance')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'balance' }" id="profile-tab" type="button">
                    <strong class="text-xxs">BALANCE</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('postanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'postanestecia' }" id="profile-tab" type="button">
                    <strong class="text-xxs">POST ANESTECIA</strong>
                </button>
            </li>
        </ul>

        <div class="tab-content mb-3 d-none d-lg-block" id="myTabContentHijo">
            <Hojaanestecia v-if="subpage=='hojaanestecia'" :medical_act_id="medical_act_id" :addhojaanestecia="addhojaanestecia" @mtdaddrpexit="mtdaddrpexit" />
            <Rp v-if="subpage=='rp'" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :medical_act_id="datapatients.medical_act_id" :dete_out="date_out" :rolname="rolname" :dete_out_doctor="date_out_doctor" :process="sop_process"/>
            <Listaverificacion v-if="subpage=='listaverificacion'" :medical_act_id="medical_act_id" :addChecklist="addChecklist" @mtdaddrpexit="mtdaddrpexit" />
            <Notaenfermeria v-if="subpage == 'notaenfermeria'" :process="sop_process" :medical_act_id="medical_act_id"  :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"/>
            <Kardex v-if="subpage == 'kardex'" :process="sop_process" :medical_act_id="medical_act_id" :datapatients="datapatients" :dete_out="date_out" :rolname="rolname" :dete_out_doctor="date_out_doctor"/>
            <Balance v-if="subpage=='balance'" :medical_act_id="medical_act_id" :rolname="rolname" :process="sop_process"/>
            <Postanestecia v-if="subpage=='postanestecia'" :medical_act_id="medical_act_id" :addPostAnestecia="addPostAnestecia" @mtdaddrpexit="mtdaddrpexit"/>
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="Hoja de anestecia" component="Hojaanestecia" :medical_act_id="medical_act_id"></dropdown>
                <dropdown title="Rp" component="Rp" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :process="sop_process" :medical_act_id="datapatients.medical_act_id" :date_out="date_out" :rolname="rolname"  :date_out_doctor="date_out_doctor"></dropdown>
                <dropdown title="Kardex" component="Kardex" :medical_act_id="medical_act_id" :process="sop_process" :datapatients="datapatients" :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></dropdown>
                <dropdown title="Nota de Enfermeria" component="Notaenfermeria" :process="sop_process" :medical_act_id="medical_act_id"  :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></dropdown>
                <dropdown title="Lista de verificacion" component="Listaverificacion" :medical_act_id="medical_act_id"></dropdown>
                <dropdown title="Balance" component="Bhe" :medical_act_id="medical_act_id" :process="sop_process" :rolname="rolname"></dropdown>                
                <dropdown title="Post anestecia" component="Postanestecia" :medical_act_id="medical_act_id"></dropdown>                
            </div>
        </div>
        <!--  -->
        <SweetAlert :swal="swal" ref="SweetAlert" />

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue'
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Listaverificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';
import Hojaanestecia from '@/components/Asistencial/Sop/Historia/Hojaanestecia.vue';
import Notaenfermeria from '@/components/Asistencial/Sop/Historia/Notaenfermeria.vue';
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Balance from '../Historia/Bhe.vue';

export default {
    name: 'c-asistencial-sop-intraoperatorio',
    props: {
        rolname: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
    },
    components: {
        Rp,
        Postanestecia,
        Listaverificacion,
        Hojaanestecia,
        Dropdown,
        Kardex,
        Notaenfermeria,
        SweetAlert,
        Balance

    },
    data() {
        return {
            sop_process: 1,
            subpage: 'hojaanestecia',
            swal: null,
            medical_act_id: 0,
            date_out: false,
            date_out_doctor:false,
            addRp: null,
            addhojaanestecia: null,
            addChecklist: null,
            addPostA: null,
            addPostAnestecia: null,
        }
    },
    watch:{
        datapatients: {
            handler: function (newValue) {
                if (newValue) {
                    this.medical_act_id = newValue.medical_act_id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdBackSop: function () {
            this.$emit('mtdBackSop');
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        mtdaddRp: function(){
            this.subpage = 'rp';
            this.mtdaddall();
        },
        mtdaddall: function () {
            const areEqualrp = JSON.stringify(this.$miVariableGlobalRpSop) === JSON.stringify(this.$miVariableGlobalRpSop_plantilla);
            if (this.subpage == 'rp') {
                if(!areEqualrp){
                    this.mtdaddrp();
                }else{
                    this.$refs.SweetAlert.showWarning('Debe registrar un Rp');
                }
            }else if(this.subpage == 'hojaanestecia'){
                this.addhojaanestecia = 1;
            }else if(this.subpage == 'listaverificacion'){
                this.addChecklist = 1;
            }else if(this.subpage == 'postanestecia'){
                this.addPostAnestecia = 1;
            }
        },
        mtdaddrp: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeRp",
                    token: this.$store.getters.get__token,
                    params: {
                        ... this.$miVariableGlobalRpSop,
                        process: 1,
                    },
                })
                .then((response) => { //   
                    this.addRp = 1
                    this.$refs.SweetAlert.showSuccess(response.message);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdaddrpexit: function () {
            this.addRp = null;
            this.addhojaanestecia = null;
            this.addChecklist= null;
            this.addPostA = null;
            this.addPostAnestecia = null;
        },
    }
}
</script>

<template>
    <div>
        <div class="px-5 mt-2 pb-5">      
            <div class="d-flex"> 
                <div  class="btn-home mb-4 ms-auto">
                    <!-- <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button> -->
                </div>
            </div>

            <!-- <ul class="nav pb-0 mt-3" id="myTab" role="tablist">
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('price')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'price' }"
                      id="home-tab" 
                      type="button" 
                     >
                       <strong>Precios de compra</strong> 
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('provider')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'provider' }"
                      id="home-tab" 
                      type="button" 
                     >
                        <strong>Proveedores</strong> 
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button 
                    @click="changePage('medic')" 
                    :class="{ 'item-button':true,'nav-link': true, 'active': page === 'medic' }"
                    id="profile-tab"  
                    type="button"  
                    >
                        <strong>Medicamentos</strong>
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button 
                    @click="changePage('insumos')" 
                    :class="{ 'item-button':true,'nav-link': true, 'active': page === 'insumos' }"
                    id="profile-tab"  
                    type="button"  
                    >
                        <strong>Insumos</strong>
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button 
                    @click="changePage('soluciones')" 
                    :class="{ 'item-button':true,'nav-link': true, 'active': page === 'soluciones' }"
                    id="profile-tab"  
                    type="button"  
                    >
                        <strong>Soluciones</strong>
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button 
                    @click="changePage('servicios')" 
                    :class="{ 'item-button':true,'nav-link': true, 'active': page === 'servicios' }"
                    id="profile-tab"  
                    type="button"  
                    >
                        <strong>Servicios</strong>
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button 
                        @click="changePage('unit')" 
                        :class="{ 'item-button':true,'nav-link': true, 'active': page === 'unit' }"
                        id="home-tab" 
                        type="button" 
                    >
                        <strong>Unidades</strong>
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button 
                        @click="changePage('marcas')" 
                        :class="{ 'item-button':true,'nav-link': true, 'active': page === 'marcas' }"
                        id="home-tab" 
                        type="button" 
                    >
                        <strong>Marcas</strong>
                    </button>
                </li> -->
                <!-- <li class="nav-item " role="presentation">
                    <button 
                        @click="changePage('inventario')" 
                        :class="{ 'item-button':true,'nav-link': true, 'active': page === 'inventario' }"
                        id="home-tab" 
                        type="button" 
                    >
                        <strong>Inventario 2023</strong>
                    </button>
                </li> -->
            <!-- </ul> -->
            <!-- <div class="tab-content border border-main mb-4 p-2" style="height: 50%;" > -->
                <!-- <br> -->
                 <Home v-if="page == 'home'" @returnHome="returnHome" @changePage="changePage"/>
                <InsumosVue v-if="page == 'insumos'" @changePage="changePage" />
                <PrecioCompraVue
                    v-if="page == 'price'"
                    :providers="providers"
                    @changePage="changePage" 
                />
                <Services v-if="page == 'servicios' " @changePage="changePage" />
                <ProveedoresVue v-if="page == 'provider'"  @changePage="changePage" />
                <MedicineVue v-if="page == 'medic'"  @changePage="changePage" />
                <UnitsVue v-if="page=='unit'"  @changePage="changePage" />
                <InventarioVue v-if="page=='inventario'"  @changePage="changePage" />
                <SolucionesVue v-if="page=='soluciones'" @changePage="changePage" />
                <Marcas v-if="page == 'marcas'"  @changePage="changePage" />
                <Almacen v-if="page == 'almacen'"  @changePage="changePage" />
                <Central v-if="page == 'central'"  @changePage="changePage" />
                <Subsub v-if="page == 'subsub'"  @changePage="changePage" />
            <!-- </div> -->
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import InsumosVue from "./Mantenimiento/Insumos.vue";
import SolucionesVue  from './Mantenimiento/Soluciones.vue'
import PrecioCompraVue from "./Mantenimiento/PrecioCompra.vue";
import ProveedoresVue from "./Mantenimiento/Proveedores.vue";
import MedicineVue from "./Mantenimiento/Medicine.vue";
import UnitsVue from './Mantenimiento/Units.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import InventarioVue from "../pages/Mantenimiento/Inventario.vue";
import Services from "../pages/Mantenimiento/Service.vue";
import Marcas from "../pages/Mantenimiento/Brand.vue";
import Home  from '../pages/Mantenimiento/Home.vue'
import Central from '../pages/Mantenimiento/CentralCosto.vue';
import Almacen from "../pages/Mantenimiento/Almacen.vue";
import Subsub from "../pages/Mantenimiento/SubSub.vue";
export default {

name:"logis-maintenance",
components:{
    SweetAlert,
    InsumosVue,
    InventarioVue,
    PrecioCompraVue, 
    ProveedoresVue, 
    MedicineVue, 
    UnitsVue,
    SolucionesVue,
    Services,
    Marcas,
    Home,
    Almacen,
    Central,
    Subsub
},
data(){
    return{
        swal: null,
        page: "home",
        providers: [],
        products: [],
    }
  },
created() {
    this.mtdGetData();
},
 methods:{
    ...mapActions(["get", "post"]),

    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.$emit("returnHome");
    },
    mtdGetData: function () {
        this.get({
            url: this.$store.getters.get__url + "/logistics/getProviders",
            token: this.$store.getters.get__token,
        })
        .then((response) => {
            this.providers = response.data;
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },
 }
}
</script>

<style scoped>
.h4-borde{
    border-radius: 50px;
}
#myTabContent{
    border:#900052 1.9px solid;
}

.item-button{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 155px;   
}
.item-button1{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 160px !important;    
}

.nav-link:hover{
    background:#900052 !important
}
.active {
  background-color: #900052 !important ;
 
}
.nav-item{
    width: 159.39px;
}
</style>
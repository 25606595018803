<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
    :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
      <div class="row mx-0 px-0 d-flex">
        <div class="col-lg-4 col-md-6">
          <label for="dni" class="mb-3 mt-2">DNI</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-id-card"></i>
              </span>
            </div>
            <!-- <input type="text" class="form-control" id="dni" placeholder="12345678" @keydown="filterKeyDni" v-model="data_detail.dni" maxlength="8" /> -->
            <input id="dni" type="text" class="form-control" name="documento"
              placeholder="Ingrese documento y presione enter" v-model="data_detail.dni"
              @keypress.enter="mtdSearchDocEmployee" maxlength="8" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label for="name" class="mb-3 mt-2">Nombre</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <input type="text" class="form-control" id="name" placeholder="Nombres" v-model="data_detail.name"
              @keydown="filterKeyLetter" :disabled="disabledEmployee" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label for="lastName" class="mb-3 mt-2">Apellidos</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <input type="text" class="form-control" id="lastName" @keydown="filterKeyLetter" placeholder="Apellidos"
              v-model="data_detail.last_name" :disabled="disabledEmployee" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label class="mb-3 mt-2">Especialidad</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <v-select :options="specialtys" class="form-control form-control-sm" :reduce="(name) => name.id"
              label="name" placeholder="---- Seleccione una especialidad----" required
              v-model="data_detail.specialtie_id" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label for="honorario" class="mb-3 mt-2">Honorarios Res. Individual</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="Honorarios Res.Individual" id="honorario"
              v-model="data_detail.honorario_res_individual" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label for="cpm" class="mb-3 mt-2">CPM</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="CPM" id="cpm" v-model="data_detail.cpm"
              @input="validateCPM" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <label for="rne" class="mb-3 mt-2">RNE</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-user-md"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="RNE" id="rne" v-model="data_detail.rne" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6" :class="num == 1 ? 'col-lg-3' : 'col-lg-4'">
          <label for="ic" class="mb-3 mt-2">Precio IC</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-money-check"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="0.00" id="ic" v-model="data_detail.price_ic" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6" :class="num == 1 ? 'col-lg-3' : 'col-lg-4'">
          <label for="consulta" class="mb-3 mt-2">Precio Consulta</label>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-money-check"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="0.00" id="consulta"
              v-model="data_detail.price_consulta" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6" :class="num == 1 ? 'col-lg-3' : 'col-lg-4'">
          <label class="mb-3 mt-2 form-label" for="formFile">FIRMA</label>
          <div class="input-group input-group-sm d-flex flex-nowrap">
            <div class="input-group-text">
              <span style="font-size: 20px; border: none" class="input-group-text">
                <i class="fa fa-signature"></i>
              </span>
            </div>
            <div class="input-group-text w-auto">
              <input class="form-control form-control-sm" type="file" id="formFile" ref="fileInput" lang="es"
                name="file" @change="getFile">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-5 d-sm-flex" :class="num == 1 ? 'col-lg-3' : 'col-lg-4'">
          <label class="me-3 mt-2">Tipo</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1"
              v-model="data_detail.type">
            <label class="form-check-label" for="inlineRadio1">Titular</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2"
              v-model="data_detail.type">
            <label class="form-check-label" for="inlineRadio2">Interconsultista</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-5" v-if="num === 1" :class="num == 1 ? 'col-lg-3' : 'col-lg-4'">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
              v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
            <label class="form-check-label" for="flexSwitchCheckChecked">
              {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
        {{ (num == 0) ? 'Registrar' : 'Editar' }}
      </button>
      &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />

  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

import {
  mapActions
} from "vuex";

export default {
  name: "c-maintenance-modal-doctor",
  components: {
    CModal,
    SweetAlert,
  },
  data() {
    return {
      disabledEmployee: true,
      data_detail: {
        employee_id: null,
        name: "",
        last_name: "",
        dni: "",
        specialtie_id: "",
        // sub_specialtie_id: "",
        cpm: "",
        rne: "",
        price_ic: 0,
        price_consulta: 0,
        state: 1,
        type: 1,
        honorario_res_individual: "",
        file: "",
      },
      swal: "",
    };
  },
  watch: {
    payload: {
      handler(newVal) {
        if (this.num == 1) {
          if (newVal) {
            this.data_detail = {
              ...newVal
            };
          }
        }
      },
      immediate: true,
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    num: Number,
    payload: {
      type: Object,
      default: null
    },
    specialtys: Array,
  },
  computed: {
    cpButton() {
      return !(this.data_detail.name != '');

    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    getFile(e) {
      let fileInput = this.$refs.fileInput;
      let file = e.target.files[0];
      if (!/\.(jpeg)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
        this.$refs.SweetAlert.showInfo('Seleccione un archivo formato JPEG, PNG O JPG');
        fileInput.value = '';
      } else {
        this.data_detail.file = file;
      }
    },
    mtdClose() {
      this.data_detail = {
        employee_id: null,
        name: "",
        dni: "",
        last_name: "",
        id: "",
        state: "",
        specialtie_id: "",
        cpm: "",
        rne: "",
        price_ic: 0,
        price_consulta: 0,
        subsub_categorie_id: "",
        type: 1,
        honorario_res_individual: "",
      };
      this.$emit("closeModal");
    },
    filterKeyDni: function (e) {
      let b = false;
      b = /^\d$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    filterKeyLetter: function (e) {
      let b = false;
      b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    validateCPM() {
      // Remover caracteres no numéricos
      this.data_detail.cpm = this.data_detail.cpm.replace(/[^\d]/g, "");

      // Limitar a 6 caracteres
      if (this.data_detail.cpm.length > 6) {
        this.data_detail.cpm = this.data_detail.cpm.slice(0, 6);
      }
    },
    DataDetail() {
      this.$emit('Datadetail', this.data_detail, this.num)
      this.mtdClose();
    },
    mtdSearchDocEmployee: function () {
      this.get({
        url: this.$store.getters.get__url + "/RecursosHumanos/getDocEmployee/" + this.data_detail.dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.boo == 0) {
            this.$refs.SweetAlert.showWarning("El colaborador: " + response.data.name + " " + response.data.last_name + " se encuentra inhabilitado.");
            this.disabledEmployee = true;
            this.data_detail.name = "";
            this.data_detail.last_name = "";
            this.data_detail.employee_id = null;
          } else
            if (response.data.boo == 1) {
              if (response.data.hasOwnProperty("employee")) {
                this.data_detail.name = response.data.name;
                this.data_detail.last_name = response.data.last_name;
                this.data_detail.employee_id = response.data.id;
              } else {
                this.data_detail.name = "";
                this.data_detail.last_name = "";
                this.data_detail.employee_id = null;
                this.disabledEmployee = true;
              }
            } else
              if (response.data.boo == 2) {
                this.$refs.SweetAlert.showWarning("El colaborador: " + response.data.name + " " + response.data.last_name + " ya tiene usuario creado.");
                this.disabledEmployee = true;
                this.data_detail.name = "";
                this.data_detail.last_name = "";
                this.data_detail.employee_id = null;
              } else
                if (response.data.boo == 3) {
                  this.$refs.SweetAlert.showWarning("Documento no encontrado.");
                  this.disabledEmployee = true;
                  this.data_detail.name = "";
                  this.data_detail.last_name = "";
                  this.data_detail.employee_id = null;
                } else {
                  this.$refs.SweetAlert.showWarning("Error al buscar empleado.");
                  this.disabledEmployee = true;
                  this.data_detail.name = "";
                  this.data_detail.last_name = "";
                  this.data_detail.employee_id = null;
                }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },

};
</script>

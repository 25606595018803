<template>
<div class="px-4 mb-4">
    <div class="col-md-12 table-responsive" id="div-tabla-anular">
        <table id="tb-anular" class="table table-bordered">
            <caption></caption>
            <thead class="bg-main" style="font-size: 11px;">
                <tr class="text-white text-center">
                    <th scope="col" class="align-middle">
                        MARCA
                    </th>
                    <th scope="col" class="align-middle">
                        MOLECULA
                    </th>
                    <th scope="col" class="align-middle">
                        PRESENTACIÓN
                    </th>
                    <th scope="col" class="align-middle">
                        COMERCIAL
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        CONCENTRACIÓN
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        S/ KAIROS UNITARIO
                    </th>
                </tr>
            </thead>

            <tbody class="border-main" style="font-size: 11px;">
                <tr class="tbody-table align-middle" v-for="(producto, index) in cpData" :key="index">
                    <td  class="clickCarrito" @click="mtdAddItemCarrito(producto)">
                        {{ producto.brand }}
                    </td>
                    <td>
                        {{ producto.molecule }}
                    </td>
                    <td>
                        {{ producto.presentation }}
                    </td>
                    <td>
                        {{ producto.commercial }}
                    </td>
                    <td>
                        {{ producto.dosis ?  producto.dosis : '-' }}
                    </td>
                    <td>
                        <div>S/ {{ producto.precio_farmacia_kairos }}</div>
                    </td>
                </tr>
                <tr v-if="!cpData.length > 0" class="text-center">
                    <th scope="col" colspan="6">NO SE ENCONTRARON RESULTADOS</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center" style="align-items: center">
        <nav v-if="cpData.length > 0" aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item">
                    <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                </li>
                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                </li>
                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                    <span class="page-link">...</span>
                </li>
                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                </li>
                <li v-if="
              limitedStepPagination[limitedStepPagination.length - 1] <
              stepPagination.length - 1
            " class="page-item disabled">
                    <span class="page-link">...</span>
                </li>
                <li v-if="
              limitedStepPagination[limitedStepPagination.length - 1] !==
              stepPagination.length
            " class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                </li>
                <li class="page-item">
                    <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                </li>
            </ul>
        </nav>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";

export default {
    name: "Medicamento",
    components: {},
    data() {
        return {
            // filter: {},
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 7,
            PurchaseDetails: [],
            dataRecommendedProducts: []
        };
    },
    computed: {
        cpData() {
            let filteredData = this.medicaments;
            let totalPages = Math.ceil(filteredData.length / 10);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 10;
            let endIndex = startIndex + 9;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    props: {
        medicaments: {
            type: Array,
            default: () => [],
        },
        carrito: {
            type: Array,
            default: () => [],
        },
    },

    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        returnHome: function () {
            this.$emit("returnHome");
        },
        changePage(page) {
            this.$emit("changePage", page);
        },

        mtdGetData: function () {
            /** paginación */
            let cantidad = parseInt(this.medicaments.length / 20);
            let residuo = parseInt(this.medicaments.length % 20);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
        },

        /** metodos carrito */
        mtdAddItemCarrito: function (payload) {
            this.clear();
            this.$emit("mtdAddItemCarrito", payload);
        },
        mtdviewcarrito: function () {
            this.$emit("mtdviewcarrito");
        },

        clear: function () {
            this.dataShow = [],
            this.search = "",
            this.page = 0,
            this.selectPage = 1,
            this.stepPagination = [],
            this.totalPagesToShow = 7
        },
        
        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
};
</script>

<style scoped>
#input-cantidad:focus-within input {
    border: none;
    outline: none;
    box-shadow: none;
}

#input-cantidad:hover {
    border: solid 1px #900052;
}

.bg-color1 {
    background-color: #D5DBDB;
}

.bg-color2 {
    background-color: #BFC9CA;
}

@media (max-width: 1200px) {
    .responsive-proveedor {
        flex-direction: column;
    }

    .responsive-contador {
        flex-direction: column;
    }

    .responsive-contador {
        margin-top: 10px;
        width: 100% !important;
    }
}

@media (max-width: 536px) {
    .responsive-default {
        flex-direction: column;
    }

    .responsive-default>.py-1 {
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 0px;
    }

    .responsive-default-div {
        border-radius: 10px !important;
        text-align: center !important;
        font-size: 11px;
    }
}

.anterior-siguiente:focus {
    background: #900052;
}

.anterior-siguiente:hover {
    background: #bb026c;
}

.btn-ver:focus {
    background: #900052;
}

.btn-ver:hover {
    background: #bb026c;
}

.clickCarrito {
    cursor: pointer;
}

.clickCarrito:hover {
    background-color: #F4AEC1;

}
</style>

<template>
<div class="my-2">
    <div class="row">
        <div class="col-lg-6 col-md-12 mt-2">
            <div id="content-timelinetable">
                <div class="bg-main">
                    <div class="py-2 text-white">
                        <h5>Para cirugía u Otros Procedimientos Médicos</h5>
                    </div>
                </div>
                <div id="div-graph-sop" class="c-app align-items-center">

                    <div class="d-grid gap-2 mx-auto text-center col-sm-12 col-xs-12">
                        <iframe v-if="documents.anesthesia_consent" :src="'https://clinicabm.v2.howertpi.com/assets/'+documents.anesthesia_consent" width="100%" height="650px" title="Pdf"></iframe>
                        <div v-else>
                            <span class="color-main">No se ha subido un archivo aún</span>
                            <div class="mt-2">
                                <button class="btn btn-warning text-white ms-3 py-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Subir archivo" @click="triggerFileInput(1)">
                                    <i class="fas fa-upload text-black"></i>
                                </button>
                                <input type="file" @change="getFile" ref="fileInput0" style="display : none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 mt-2">
            <div id="content-timelinetable">
                <div class="bg-main">
                    <div class="py-2 text-white">
                        <h5>Consentimiento Informado para Anestesia</h5>
                    </div>
                </div>
                <div id="div-graph-sop" class="c-app align-items-center">

                    <div class="d-grid gap-2 mx-auto text-center col-sm-12 col-xs-12">

                        <iframe v-if="documents.operation_consent" :src="'https://clinicabm.v2.howertpi.com/assets/'+documents.operation_consent" width="100%" height="650px" title="Pdf"></iframe>
                        <div v-else>
                            <span class="color-main">No se ha subido un archivo aún</span>
                            <div class="mt-2">
                                <button class="btn btn-warning text-white ms-3 py-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Subir archivo" @click="triggerFileInput(2)">
                                    <i class="fas fa-upload text-black"></i>
                                </button>
                                <input type="file" @change="getFile" ref="fileInput1" style="display : none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
    name: 'c-asistencial-sop-historia-consentimiento',
    components: {
        SweetAlert
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdView();
                }
            },
            deep: true,
            immediate: true,
        }
    },
    data() {
        return {
            swal: null,
            documents: {
                id: null,
                anesthesia_consent: null,
                operation_consent: null,
            },
            option : null,
            file: null,
        }
    },
    methods: {
        ...mapActions(['get', 'post']),

        triggerFileInput(option) {
            this.option = option;
            if(option == 1){
                this.$refs['fileInput0'].click();
            }else{
                this.$refs['fileInput1'].click();
            }
        },
        getFile(e) {
            let fileInput = this.option == 1 ? this.$refs.fileInput0 : this.$refs.fileInput1;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                fileInput.value = '';
            } else {
                this.file = file;
                let formData = new FormData();
                formData.append("id", this.documents.id);
                formData.append("medical_act", this.medical_act_id);
                formData.append("file", this.file);
                formData.append('option',this.option)
                //GUARDAR EL ARCHIVO EN LA BD
                this.post({
                    url: this.$store.getters.get__url + "/Sop/storeConsents",
                    token: this.$store.getters.get__token,
                    params: formData
                }).then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.documents.id = response.data.id;
                    }
                }).catch((errors) => {});
            }
        },
        mtdView: function(){
            this.post({
                    url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'consentimiento'
                    }
                })
                .then((response) => {
                    let data = response.data;
                    if (data) {
                        this.documents = data;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    }
}
</script>

<template>
    <div>

        <div class="card-body">

            <div class="row">
                <div class="col-md-12 py-3" style="color: #900052; text-align: center; position: relative;">
                    <div class="d-flex justify-content-end" >
                        <div>
                            <span class="libre mt-2"></span> Libre &nbsp;
                            <span class="reservado mt-2"></span> Reservado &nbsp;
                            <span class="ocupado mt-2"></span> Ocupado &nbsp;
                            <span class="limpieza mt-2"></span> En Limpieza
                        </div>
                    </div>
                    <div id="floorSelect" class="d-flex justify-content-evenly">
                        <ul class="nav nav-pills w-100 d-flex justify-content-center">
                            <li v-for="(item, index) in dataFloor" :key="index" class="nav-item"
                                @click="mtdSelectGroup(item.id)">
                                <a :class="tab == item.id ? 'nav-link active' : 'nav-link'" aria-current="page"
                                    href="javascript:void(0)">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>

            <div class="row tab-content pb-3" id="camas" v-if="tab != 0">
                <div class="tab-pane fade show active" v-bind:id="tab + '-tab-pane'" role="tabpanel"
                    v-bind:arialabeledby="tab + '-tab'" tabindex="0">
                    <div align="center" class="row m-2 p-2 d-flex justify-content-center">
                        <div class="card m-3 p-2" v-for="(itemg, index) in dataBedGroupsD" :key="index" :style="'border: 2px solid #900052; max-width: 400px; min-height: ' +
                            155 * itemg.beds.length + 'px'" v-if="itemg.beds.length > 0">
                            <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-7 d-flex flex-column justify-content-center align-items-center">
                                        <div><img class="img-fluid rounded-start"
                                                src="../../../assets/cama_sin_fondo.png" width="180px" alt="" />
                                        </div>
                                        <button v-if="itemd.status == 2 && rol== 7"  type="button"
                                            class="btn btn-warning btn-sm circle_button"
                                            @click="mtdShowModalFreeBead(itemd)" data-toggle="tooltip"
                                            title="Cambiar Cama"><i class="fas fa-exchange-alt"></i></button>
                                        <button v-else-if="itemd.status == 3 && rol== 7"  type="button"
                                            class="btn btn-dark btn-sm circle_button no-hover" @click="mtdFreeBed(itemd)" data-toggle="tooltip"
                                            title="Fallecimiento"><i class="fas fa-dove"></i></button>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="card-body">
                                            <div v-if="itemd.status == 4">
                                                <span class="room card-text h5 circle p-2 text-white"
                                                    style="background: orange">{{ itemd.name }}</span>
                                            </div>
                                            <div v-else-if="itemd.status == 2">
                                                <span class="room card-text h5 circle p-2 text-white"
                                                    style="background: red"
                                                    @click="mtdGetDataMedicalAct(itemd.patient_id, itemd.reservation_id, itemd.hospitalization_id, itemd.medical_act_id, itemd.status_history)">{{
                                                        itemd.name }}</span>
                                            </div>
                                            <div v-else-if="itemd.status == 3">
                                                <span class="card-text h5 circle p-2 text-white"
                                                    style="background: blue">{{ itemd.name }}</span>
                                            </div>
                                            <div v-else>
                                                <span class="card-text h5 circle p-2 text-white"
                                                    style="background: green">{{ itemd.name }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <span class="text-paciente">{{ itemd.patient }}</span>
                                        </div>
                                        <div>
                                            <span v-if="itemd.status_medical == 1" class="text-paciente">(Pendiente de
                                                alta)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <cModalFreeBedsVue :title="cModalBed.title" :boo_modal="cModalBed.modal_form"
                @mtdclosemodalBed="mtdclosemodalBed" :bead_id="cModalBed.id" :floor_id="cModalBed.floor_id"
                :info_id="cModalBed.info_id" :item="cModalBed.item" />
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
        <!-- <cModalBed :bed="bed" :title="cModalBed.title" :boo_modal="cModalBed.modal_form" @mtdclosemodalBed="mtdclosemodalBed" @mtdstoredata="mtdstoredata" @mtdaddbed="mtdaddbed" /> -->
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalFreeBedsVue from './modals/cModalFreeBeds.vue';
export default {
    name: 'c-hospitalizacion-cola',
    data() {
        return {
            rol: null,
            swal: null,
            tab: 0,
            dataFloor: [],
            dataBedGroupsD: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            mostrar: false,
            clinica_history: {
                id: null,
                user_id: null,
                measures: [
                    {
                        id: 1,
                        label: "Peso (Kg)",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Talla (m)",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "IMC",
                        value: ''
                    },
                ],
                biological_function: [{
                    id: 1,
                    label: "Apetito",
                    value: ''
                },
                {
                    id: 2,
                    label: "Sed",
                    value: ''
                },
                {
                    id: 3,
                    label: "Orina",
                    value: ''
                },
                {
                    id: 4,
                    label: "Heces",
                    value: ''
                },
                {
                    id: 5,
                    label: "Sueño",
                    value: ''
                },

                ],
                physical_exam: [{
                    id: 1,
                    label: "Estado general",
                    value: ''
                },
                {
                    id: 2,
                    label: "SNC",
                    value: ''
                },
                {
                    id: 3,
                    label: "Respiratorio",
                    value: ''
                },
                {
                    id: 4,
                    label: "Tórax Cv Hemo",
                    value: ''
                },
                {
                    id: 5,
                    label: "Abdominal",
                    value: ''
                },
                {
                    id: 6,
                    label: "Renal",
                    value: ''
                },
                {
                    id: 7,
                    label: "Otros",
                    value: ''
                }
                ],
                lab_question: '',
                symploms: [{
                    id: 1,
                    label: "Tos sin flema",
                    state: false
                },
                {
                    id: 2,
                    label: "Tos con flema",
                    state: false
                },
                {
                    id: 3,
                    label: "Moco",
                    state: false
                },
                {
                    id: 4,
                    label: "Dolor de pecho",
                    state: false
                },
                {
                    id: 5,
                    label: "Dolor de cabeza",
                    state: false
                },
                {
                    id: 6,
                    label: "Dolor de espalda",
                    state: false
                },
                {
                    id: 7,
                    label: "Nauseas / vómitos x/día",
                    state: false
                },
                {
                    id: 8,
                    label: "Diarrea x/día",
                    state: false
                },
                {
                    id: 9,
                    label: "Otros",
                    state: false,
                    value: ''
                }
                ],
                vital_sings: [{
                    id: 1,
                    label: "FC",
                    value: ''
                },
                {
                    id: 2,
                    label: "T (°C)",
                    value: ''
                },
                {
                    id: 3,
                    label: "FR",
                    value: ''
                },
                {
                    id: 4,
                    label: "SATO2",
                    value: ''
                },
                {
                    id: 5,
                    label: "HGT",
                    value: ''
                },
                {
                    id: 6,
                    label: "FIO 2",
                    value: ''
                },
                {
                    id: 7,
                    label: "PAS",
                    value: ''
                },
                {
                    id: 8,
                    label: "PAD",
                    value: ''
                },
                {
                    id: 9,
                    label: "PAM",
                    value: ''
                }
                ],
                fe: '',
                fi: '',
                course: '',
                background_parent: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                regional_exam: [{
                    id: 1,
                    label: "Cabeza y Cuello",
                    value: '',
                    rows: 1
                },
                {
                    id: 2,
                    label: "Piel y Faneras",
                    value: '',
                    rows: 1
                },
                {
                    id: 3,
                    label: "TCSC",
                    value: '',
                    rows: 1
                },
                {
                    id: 4,
                    label: "Sist. Linfático",
                    value: '',
                    rows: 2
                },
                {
                    id: 5,
                    label: "Ap. Respiratorio",
                    value: '',
                    rows: 2
                },
                {
                    id: 6,
                    label: "Ap. Cardiovascular",
                    value: '',
                    rows: 2
                },
                {
                    id: 7,
                    label: "Ap. Digestivo",
                    value: '',
                    rows: 2
                },
                {
                    id: 8,
                    label: "Ap. Genitourinario",
                    value: '',
                    rows: 2
                },
                {
                    id: 9,
                    label: "Sist. Nervioso",
                    value: '',
                    rows: 2
                },
                {
                    id: 10,
                    label: "Ap. Locomotor",
                    value: '',
                    rows: 2
                }
                ],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: [{
                    id: 1,
                    label: "DM",
                    state: false,
                    value: ''
                },
                {
                    id: 2,
                    label: "AR",
                    state: false,
                    value: ''
                },
                {
                    id: 3,
                    label: "HTA",
                    state: false,
                    value: ''
                },
                {
                    id: 4,
                    label: "Otro",
                    state: false,
                    value: ''
                },
                {
                    id: 5,
                    label: "Alergias",
                    state: false,
                    value: ''
                },
                {
                    id: 6,
                    label: "Cirugías",
                    state: false,
                    value: ''
                },
                {
                    id: 7,
                    label: "Medicamentos Frecuentes",
                    state: false,
                    value: ''
                }
                ],
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta: '',
                insuline: '',
                grupo: '',
                factor: ''
            },
            patient_id: null,
            reservation_id: null,
            hospitalization_id: null,
            medical_act_id: null,
            cModalBed: {
                title: "",
                modal_form: false,
                id: 0,
                floor_id: 0,
                info_id: 0,
                item: {},
            },
        }
    },
    mounted() {
        const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
        if (tooltips) {
            tooltips.forEach(tooltip => {
                tooltip.addEventListener('mouseenter', () => {
                    // Show tooltip
                });
                tooltip.addEventListener('mouseleave', () => {
                    // Hide tooltip
                });
            });
        }
    },
    created() {
        this.mtdGetData();
        this.getRol();
    },
    props: {
        rolname: String
    },
    components: {
        SweetAlert,
        cModalFreeBedsVue,
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdFreeBed: function(item){    
            Swal.fire({
                title: '¿Está seguro de liberar la cama?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/admision/releasebed",
                        token: this.$store.getters.get__token,
                        params: {
                            bead_id: item.id,
                            medical_id: item.medical_act_id,
                        },
                    }).then((response) => {
                        if(response.statusCode == 200){
                            this.dataFloor = [];
                            this.dataBedGroupsD = [];
                            this.mtdGetData();
                        }
                    }).catch((errors) => {
                        console.log("Error al enviar los datos");
                    });
                }
            });
        },
        getRol() {
            if (this.$store.getters.get__user && this.$store.getters.get__user.roles[0]) {
                this.rol = this.$store.getters.get__user.roles[0].role_id;
            }
        },
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/admision/floor/" + this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.dataFloor = response.floors;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdGetDataMedicalAct: function (patient_id, reservation_id, hospitalization_id, medical_act_id = 0, status) {
            this.patient_id = patient_id;
            this.reservation_id = reservation_id;
            this.hospitalization_id = hospitalization_id;
            this.medical_act_id = medical_act_id;
            if ((this.rolname == 'ENFERMERA(O)' || this.rolname == "ENFERMERA TECNICA") && status != 3) {
                this.$refs.SweetAlert.showWarning("El paciente aún no ha sido atendido por el médico");
            } else {
                if (status == 3) {
                    // this.mtdNewAct(this.patient_id, this.reservation_id, this.hospitalization_id, this.medical_act_id);
                    this.mtdViewAct(this.patient_id, this.medical_act_id);
                } else {
                    this.mtdNewAct(this.patient_id, this.reservation_id, this.hospitalization_id, this.medical_act_id);
                }
            }
        },
        mtdNewAct: function (patient_id, reservation_id, hospitalization_id, medical_act_id) {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/add",
                token: this.$store.getters.get__token,
                params: {
                    clinica_history: this.clinica_history,
                    patient_id: this.patient_id,
                    reservation_id: this.reservation_id,
                    hospitalization_id: this.hospitalization_id,
                    medical_act_id: this.medical_act_id
                }
            })
                .then((response) => {
                    this.data = response.data;
                    this.patient_id = null;
                    this.$emit('mtdHistorial', {
                        patient: this.data
                    })
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdViewAct: function (patient_id, medical_act_id) {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/history/" + medical_act_id,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    let newhistory = response.data;
                    this.$emit('mtdHistorialtable', {
                        patient: newhistory
                    })
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdSelectGroup: function (id) {
            this.post({
                url: this.$store.getters.get__url + "/admision/bedgroup/filter",
                token: this.$store.getters.get__token,
                params: {
                    floor_id: id,
                },
            })
                .then((response) => {
                    this.dataBedGroupsD = response.bedgroups_details;
                    this.tab = id;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdBehindGroup: function (data) {
            this.post({
                url: this.$store.getters.get__url + "/admision/bedgroup/filter",
                token: this.$store.getters.get__token,
                params: {
                    floor_id: data[2],
                },
            })
                .then((response) => {
                    let dataBedGroupsD;
                    dataBedGroupsD = response.data.bedgroups_details;
                    dataBedGroupsD[data[0]].beds[data[1]].status = 1;
                })
        },
        mtdReserveHour: function (bed, pos1, pos2) { //pos1->grupo , pos2->cama
            if (this.dataBedGroupsD[pos1].beds[pos2].status == 1) {
                if (this.verifSelectBed == true) {
                    //verificar si es un grupo del mismo piso
                    if (this.oldPosSelectBed[2] != this.tab) {
                        this.mtdBehindGroup(this.oldPosSelectBed);
                    } else {
                        this.dataBedGroupsD[this.oldPosSelectBed[0]].beds[this.oldPosSelectBed[1]].status = 1;
                    }
                }
                this.oldPosSelectBed = [pos1, pos2, this.tab];
                this.verifSelectBed = true;
                this.dataBedGroupsD[pos1].beds[pos2].status = 4;
            }
            this.mostrar = true;
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        },
        mtdShowModalFreeBead: function (item) {
            this.cModalBed = {
                title: "CAMAS LIBRES",
                id: item.id,
                floor_id: item.floor_id,
                info_id: item.hospitalization_id,
                modal_form: true,
                item: item,
            }
        },
        mtdclosemodalBed: function () {
            this.cModalBed = {
                id: "",
                floor_id: "",
                info_id: "",
                title: "",
                modal_form: false,
                item: {},
            };
            this.dataFloor = [];
            this.dataBedGroupsD = [];
            this.mtdGetData();
        },
    }
}
</script>

<style scoped>
.nav-link:hover {
    color: #ffffff !important;
}

.nav-link {
    color: #900052 !important;
    font-weight: bold;
}

.nav-link.active {
    background: #900052 !important;
    color: #ffffff !important;
}

.circle {
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.circle_button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.no-hover:hover{
    background-color: #212529 !important; 
    color: inherit !important;          
    cursor: default;                    
}
.superior-derecha {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.libre {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
}

.reservado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
}

.ocupado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}

.limpieza {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: blue;
    border-radius: 50%;
}
</style>

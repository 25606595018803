<template>
    <div class="py-3" v-if="graficData.length > 0">
        <div class="order-md-1 order-lg-2 py-1 py-xl-0 px-0 d-flex justify-content-between">
            <div class="row d-flex px-1 mx-1">
                <div class="ol-md-12 d-inline-flex px-0 w-100 ms-1">
                    <button type="button" class="btn btn-sm text-white btn-sm bg-danger text-xs py-0" 
                    @click="pdfGrafica">
                        <i class="fa fa-file-pdf"></i> Exportar PDF
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-end px-2">
                <div class="col-12 px-0">
                    <nav aria-label="Page navigation example" class="pagination-container">
                        <ul class="pagination pagination-xs justify-content-end mb-0">
                            <li class="page-item" :class="{ disabled: currentPage === 0 }">
                                <a style="cursor: pointer" class="page-link" @click="backPage">«</a>
                            </li>
                            <li class="page-item">
                                <span class="page-link active">{{ currentPageLabel }}</span>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === grafigPages.length - 1 }">
                                <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                            </li>
                        </ul>
                    </nav>
                </div> 
            </div>
        </div>
    
        <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-3" v-if="grafigPages[currentPage]">
                <div class="row">
                    <!-- PAS -->
                    <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12"  id="content-timelinetable">
                        <span class="encabezado-rounded-1 px-2 fw-bold">PAS</span> <span class="encabezado-rounded-2 px-2 fw-bold">PAD</span>
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div id="chart" class="content-graph ancho">
                                <img :src="grafigPages[currentPage].chartPas" alt="ChartPas" class="img-chart" />
                            </div>
                        </div>
                    </div> 
    
                    <!-- FC -->
                    <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                        <span class="encabezado-rounded-3 px-2 fw-bold">FC</span>
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div id="chart" class="content-graph ancho">
                                <img :src="grafigPages[currentPage].chartFc" alt="ChartFc" class="img-chart" />
                            </div>
                        </div>
                    </div>
    
                    <!-- FR -->
                    <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                        <span class="encabezado-rounded-4 px-2 fw-bold">FR</span>
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div id="chart" class="content-graph ancho">
                                <img :src="grafigPages[currentPage].chartFr" alt="ChartFr" class="img-chart" />
                            </div>
                        </div>
                    </div>
    
                    <!-- T° -->
                    <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                        <span class="encabezado-rounded-5 px-2 fw-bold">T°</span>
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div id="chart" class="content-graph ancho">
                                <img :src="grafigPages[currentPage].chartT" alt="ChartT" class="img-chart" />
                            </div>
                        </div>
                    </div>
    
                    <!-- SatO2 -->
                    <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                        <span class="encabezado-rounded-6 px-2 fw-bold">SatO2</span>
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div id="chart" class="content-graph ancho">
                                <img :src="grafigPages[currentPage].chartSat" alt="ChartSat" class="img-chart" />
                            </div>
                        </div>
                    </div>
    
    
                    <div class="p-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                        <div class="col-md-12 table-responsive px-0" id="content-timelinetable">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main" style="font-size: 13px">
                                    <tr  class="text-white text-center align-middle">
                                        <th scope="col">FECHA</th>
                                        <th scope="col" v-for="(data, fecha) in grafigPages[currentPage].tGra" :key="fecha">
                                            {{ fecha }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="border-main" style="font-size: 12px">
                                    <tr class="text-center align-middle">
                                        <th scope="row" class="wide-nameCabe-gra">PESO</th>
                                        <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'weight-' + index" class="wide-name-gra text-center">
                                            {{ data.weightGraphic || '-' }}
                                        </td>
                                    </tr>
                                    <tr class="text-center align-middle">
                                        <th scope="row" class="wide-nameCabe-gra">DEPOSICIONES</th>
                                        <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'deposition-' + index" class="wide-name-gra text-center">
                                            {{ data.deposition || '-' }}
                                        </td>
                                    </tr>
                                    <tr class="text-center align-middle">
                                        <th scope="row" class="wide-nameCabe-gra">ORINA</th>
                                        <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'urine-' + index" class="wide-name-gra text-center">
                                            {{ data.urine || '-' }}
                                        </td>
                                    </tr>
                                    <tr class="text-center align-middle">
                                        <th scope="row" class="wide-nameCabe-gra">BALANCE HIDRICO</th>
                                        <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'hydric_balance-' + index" class="wide-name-gra text-center">
                                            {{ data.hydric_balance || '-' }}
                                        </td>
                                    </tr>
    
                                </tbody>
                            </table>
                        </div>
                    </div>
    
    
                </div>
            </div>
    
        </div>
    </div>
    <div v-else>
        <div class="text-center mt-5">
            <h2>No se encontraron resultados</h2>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex';
    import VueApexCharts from 'vue-apexcharts'
    
    export default {
        name: 'c-asistencial-sop-historia-hojagrafica',
        components: {
            apexchart: VueApexCharts,
        },
        data() {
            return {
                validation: false,
                swal: null,
                sheets: [],
                graphic_sheets: {
                    date_name: 'GRÁFICA ACTUAL',
                    id: null,
                    medical_act_id: null,
                    date: null,
                    hour: null,
                    day: 0,
                    doctor_name: '',
                    user_id: null,
                    day_po: '',
                    weight: '',
                    deposition: '',
                    urine: '',
                    hydric_balance: '',
                    values: [{
                            id: 1,
                            label: 'PAS',
                            value: '',
                            value2: '',
                            inputs: 2
                        },
                        {
                            id: 2,
                            label: 'PAD',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 3,
                            label: 'FC',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 4,
                            label: 'FR',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 5,
                            label: 'T*',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 6,
                            label: 'SAT02',
                            value: '',
                            inputs: 1
                        }
                    ],
                },
                pos: null,
    
                // Arrays
                datatime: [],
                series_pas: [],
                series_pad: [],
                categories_pad: [],
                annotations_pad: [],
                series_fc: [],
                categories_fc: [],
                annotations_fc: [],
                series_fr: [],
                categories_fr: [],
                annotations_fr: [],
                series_t: [],
                categories_t: [],
                annotations_t: [],
                series_sat: [],
                categories_sat: [],
                annotations_sat: [],
                // Parámetros
                data_pas: [350, 300, 250, 200, 150, 100, 50, 0],
                data_pad: [70, 60, 50, 40, 30, 20, 10, 0],
                data_fc: [160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40],
                data_fr: [60, 50, 40, 30, 20, 10, 0],
                data_temp: ['42°', '41°', '40°', '39°', '38°', '37°', '36°', '35°'],
                data_sat: ['100%', '80%', '60%', '40%', '20%', '0%'],
    
                // PAS
                /* series_pas: [{
                    name: 'Mañana',
                    data: [{
                      x: '11-07-2024',
                      y: [90, 200]
                    }, {
                      x: '12-07-2024',
                      y: [98, 180]
                    }, {
                      x: '13-07-2024',
                      y: [78, 195]
                    }, {
                      x: '14-07-2024',
                      y: [70, 185]
                    }, {
                      x: '15-07-2024',
                      y: [75, 189]
                    }, {
                      x: '16-07-2024',
                      y: [84, 200]
                    }, {
                      x: '17-07-2024',
                      y: [100, 210]
                    }]
                  }, {
                    name: 'Tarde',
                    data: [{
                      x: '11-07-2024',
                      y: [69, 198]
                    }, {
                      x: '12-07-2024',
                      y: [85, 203]
                    }, {
                      x: '13-07-2024',
                      y: [90, 210]
                    }, {
                      x: '14-07-2024',
                      y: [97, 185]
                    }, {
                      x: '15-07-2024',
                      y: [85, 199]
                    }, {
                      x: '16-07-2024',
                      y: [67, 197]
                    }, {
                      x: '17-07-2024',
                      y: [94, 195]
                    }]
                  }, {
                    name: 'Noche',
                    data: [{
                      x: '11-07-2024',
                      y: [89, 195]
                    }, {
                      x: '12-07-2024',
                      y: [85, 200]
                    }, {
                      x: '13-07-2024',
                      y: [70, 170]
                    }, {
                      x: '14-07-2024',
                      y: [97, 187]
                    }, {
                      x: '15-07-2024',
                      y: [95, 190]
                    }, {
                      x: '16-07-2024',
                      y: [100, 210]
                    }, {
                      x: '17-07-2024',
                      y: [105, 205]
                    }]
                }], */
                chartOptionsPas: {
                    chart: {
                        type: 'rangeBar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    dataLabels: {
                        enabled: true
                    },
                    colors: ['#FFC107', '#008FFB', '#FF4560'], // Colores de las barras
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        show: true,
                        position: 'bottom', // Posición del pie de página
                        horizontalAlign: 'left',
                        labels: {
                            colors: ['#000'], // Color del texto de la leyenda
                            useSeriesColors: false
                        }
                    },
                },
                // FIN PAS
    
                // PAD
                /* series_pad: [{
                  name: 'PAD',
                  data: [
                      21, 0, 25,  // Valores del 11-07-2024
                      30, 20, 22,  // Valores del 12-07-2024
                      25, 24, 23,  // Valores del 13-07-2024
                      20, 22, 23,  // Valores del 14-07-2024
                      18, 19, 20,  // Valores del 15-07-2024
                      26, 27, 28   // Valores del 16-07-2024
                  ]
                }], */
                chartOptionsPad: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        align: 'left'
                    },
                    colors: ['#62CDFF'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -45
                        }
                    },
                    annotations: {
                        xaxis: []
                    }
                },
                // FIN PAD
    
                // FC
                /* series_fc: [{
                  name: 'FC',
                  data: [
                      40, 30, 60,  // Valores del 11-07-2024
                      80, 50, 60,  // Valores del 12-07-2024
                      120, 100, 80,  // Valores del 13-07-2024
                      75, 50, 80,  // Valores del 14-07-2024
                      80, 110, 90,  // Valores del 15-07-2024
                      80, 90, 100   // Valores del 16-07-2024
                  ]
                }], */
                chartOptionsFc: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        align: 'left'
                    },
                    colors: ['#81FFBA'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -45
                        }
                    },
                    annotations: {
                        xaxis: []
                    }
                },
                // FIN FC
    
                // FR
                /* series_fr: [{
                  name: 'FR',
                  data: [
                      40, 30, 60,  // Valores del 11-07-2024
                      55, 50, 60,  // Valores del 12-07-2024
                      55, 40, 30,  // Valores del 13-07-2024
                      25, 30, 28,  // Valores del 14-07-2024
                      30, 40, 50,  // Valores del 15-07-2024
                      50, 55, 60   // Valores del 16-07-2024
                  ]
                }], */
                chartOptionsFr: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        align: 'left'
                    },
                    colors: ['#FFBC81'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -45
                        }
                    },
                    annotations: {
                        xaxis: []
                    }
                },
                // FIN FR
    
                // T°
                /* series_t: [{
                  name: 'T°',
                  data: [
                      36.50, 35, 38,  // Valores del 11-07-2024
                      38, 36, 36,  // Valores del 12-07-2024
                      39, 37.4, 35.8,  // Valores del 13-07-2024
                      40, 37, 35.5,  // Valores del 14-07-2024
                      38, 37, 36.8,  // Valores del 15-07-2024
                      35.8, 33, 35   // Valores del 16-07-2024
                  ]
                }], */
                chartOptionsT: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        align: 'left'
                    },
                    colors: ['#FF818B'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -45
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return value + '°'; // Para grados
                            }
                        }
                    },
                    annotations: {
                        xaxis: []
                    }
                },
                // FIN T°
    
                // Sat
                /* series_sat: [{
                  name: 'Sat',
                  data: [
                      36.50, 55, 68,  // Valores del 11-07-2024
                      98, 96, 96,  // Valores del 12-07-2024
                      99, 97.4, 95.8,  // Valores del 13-07-2024
                      40, 97, 95.5,  // Valores del 14-07-2024
                      98, 97, 36.8,  // Valores del 15-07-2024
                      35.8, 13, 35   // Valores del 16-07-2024
                  ]
                }], */
                chartOptionsSat: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        align: 'left'
                    },
                    colors: ['#DB81FF'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -45
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return value + '%'; // Para porcentaje
                            }
                        }
                    },
                    annotations: {
                        xaxis: []
                    }
                },
                // FIN Sat
                activePasIndex: 0,
                activeSatIndex: 0,
                activeFcIndex: 0,
                activeFrIndex: 0,
                activeTIndex: 0,
                chartsPas: [],
                chartsSat: [],
                chartsFc: [],
                chartsFr: [],
                chartsT: [],
                currentPage: 0, 
                grafigPages: [],
                graficData: []  
            }
        },
        props: {
            medical_act_id: {
                type: Number,
                default: 0
            },
            dete_out: {
                type: Boolean
            },
            rolname: String,
            dete_out_doctor: {
                type: Boolean
            },
        },
        watch: {
            medical_act_id: {
                handler: function (newValue) {
                    if (newValue != 0) {
                        this.mtdday();
                        this.mtdGetSheets();
                        this.mtdsheetday();
                    }
                },
                deep: true,
                immediate: true,
            },
            addSheet: {
                handler: function (newValue) {
                    if (newValue == 1) {
                        //this.mtdday();
                        this.mtdstoredata()
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        created() {
            this.setCurrentDateTime();
            //this.mtdsheetday();
            //this.mtdsheetdata();
        },
        mounted() {
            //this.mtdsheetdata();
        },
        computed: {
            currentPageLabel() {
                return `Página ${this.currentPage + 1}`;
            }
        },
        methods: {
            ...mapActions(['get', 'post']),
            mtdhidde: function () {
                this.graphic_sheets = {
                        date_name: 'GRÁFICA ACTUAL',
                        id: null,
                        medical_act_id: null,
                        date: null,
                        hour: null,
                        day: 0,
                        doctor_name: '',
                        user_id: null,
                        day_po: '',
                        weight: '',
                        deposition: '',
                        urine: '',
                        hydric_balance: '',
                        values: [{
                                id: 1,
                                label: 'PAS',
                                value: '',
                                value2: '',
                                inputs: 2
                            },
                            {
                                id: 2,
                                label: 'PAD',
                                value: '',
                                inputs: 1
                            },
                            {
                                id: 3,
                                label: 'FC',
                                value: '',
                                inputs: 1
                            },
                            {
                                id: 4,
                                label: 'FR',
                                value: '',
                                inputs: 1
                            },
                            {
                                id: 5,
                                label: 'T*',
                                value: '',
                                inputs: 1
                            },
                            {
                                id: 6,
                                label: 'SAT02',
                                value: '',
                                inputs: 1
                            }
                        ],
                    },
                    this.$miVariableGlobalSheetSop.day = this.graphic_sheets.day
                this.$miVariableGlobalSheetSop.day_po = this.graphic_sheets.day_po
                this.$miVariableGlobalSheetSop.weight = this.graphic_sheets.weight
                this.$miVariableGlobalSheetSop.deposition = this.graphic_sheets.deposition
                this.$miVariableGlobalSheetSop.urine = this.graphic_sheets.urine
                this.$miVariableGlobalSheetSop.hydric_balance = this.graphic_sheets.hydric_balance
                this.$miVariableGlobalSheetSop.values = this.graphic_sheets.values
                this.$miVariableGlobalSheetSop.medical_act_id = null
            },
            setCurrentDateTime() {
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset() * 60000;
                const localDate = new Date(now.getTime() - timezoneOffset);
                this.$miVariableGlobalSheetSop.date = localDate.toISOString().split('T')[0];
                this.$miVariableGlobalSheetSop.hour = now.toTimeString().split(' ')[0];
                this.$miVariableGlobalSheetSop.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
                this.$miVariableGlobalSheetSop.user_id = this.$store.getters.get__user.id;
                this.$miVariableGlobalSheetSop.medical_act_id = this.medical_act_id;
    
                this.$miVariableGlobalSheetSop_plantilla.date = localDate.toISOString().split('T')[0];
                this.$miVariableGlobalSheetSop_plantilla.hour = now.toTimeString().split(' ')[0];
                this.$miVariableGlobalSheetSop_plantilla.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
                this.$miVariableGlobalSheetSop_plantilla.user_id = this.$store.getters.get__user.id;
                this.$miVariableGlobalSheetSop_plantilla.medical_act_id = this.medical_act_id;
            },
            mtdstoredata: function () {
                this.mtdhidde()
                this.mtdday()
                this.setCurrentDateTime();
                this.mtdGetSheets();
                this.mtdsheetday();
                //this.mtdsheetdata();
                this.$emit('mtdaddsheetexit')
            },
            mtdGetSheets: function () {
            this.get({
                url: this.$store.getters.get__url + "/Sop/indexSheet/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.graficData = response.data;
                    let dataSheets = response.data;
                    this.sheets = []
                    dataSheets.forEach(element => {
                        let sheetdetails = {
                            doctor_name: element.nurse.name,
                            date_name: element.datename,
                            id: element.id,
                            medical_act_id: element.medical_act_id,
                            date: element.date,
                            hour: element.hour,
                            day: element.day,
                            day_po: element.day_po,
                            weight: element.weight,
                            deposition: element.deposition,
                            urine: element.urine,
                            hydric_balance: element.hydric_balance,
                            values: JSON.parse(element.values)
                        }
                        this.sheets.push(sheetdetails)
                    });
                    this.graphic_sheets = this.$miVariableGlobalSheet
                    this.sheets.push(this.graphic_sheets);
                    if (this.graficData.length == 0) {
                        }else{
                            this.mtdsheetdata();
                        }
                   /*  this.pos = this.sheets.length - 1
                    if (this.dete_out ||this.rolname !='ENFERMERA(O)' || this.rolname !='DOCTOR(A)' || this.dete_out_doctor) {
                        this.sheets.pop();
                        if (this.sheets.length > 0) {
                            this.backPage()
                        }
                    } */
                }
            });
        },
            mtdday: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Sop/day/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        let day = response.data.sop.date_entry;
                        let currentDate = new Date();
                        let entryDate = new Date(day);
                        currentDate.setHours(0, 0, 0, 0);
                        entryDate.setHours(0, 0, 0, 0);
                        let differenceInMilliseconds = currentDate - entryDate;
                        let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                        this.graphic_sheets.day = differenceInDays + 1;
                        this.$miVariableGlobalSheetSop.day = this.graphic_sheets.day
                        this.$miVariableGlobalSheetSop_plantilla.day = this.graphic_sheets.day
                    }
                });
            },
            mtdsheetday: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Sop/sheetday/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        this.datatime = response.data.dates;
                    }
                });
            },
            mtdsheetdata: function () {
                this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/sheetdata",
                    token: this.$store.getters.get__token,
                    params:{
                        id: this.medical_act_id,
                        service: 6,
                    }
                }).then((response) => {
                    if (response.statusCode === 200) {
                        const grafig = response.data.grafig;
                        this.grafigPages = Object.values(grafig);
    
                    }
                });
            },
    
            nextChart(type) {
                if (type === 'pas' && this.activePasIndex < this.chartsPas.length - 1) {
                    this.activePasIndex++;
                } else if (type === 'sat' && this.activeSatIndex < this.chartsSat.length - 1) {
                    this.activeSatIndex++;
                } else if (type === 'fc' && this.activeFcIndex < this.chartsFc.length - 1) {
                    this.activeFcIndex++;
                } else if (type === 'fr' && this.activeFrIndex < this.chartsFr.length - 1) {
                    this.activeFrIndex++;
                } else if (type === 't' && this.activeTIndex < this.chartsT.length - 1) {
                    this.activeTIndex++;
                }
            },
            prevChart(type) {
                if (type === 'pas' && this.activePasIndex > 0) {
                    this.activePasIndex--;
                } else if (type === 'sat' && this.activeSatIndex > 0) {
                    this.activeSatIndex--;
                } else if (type === 'fc' && this.activeFcIndex > 0) {
                    this.activeFcIndex--;
                } else if (type === 'fr' && this.activeFrIndex > 0) {
                    this.activeFrIndex--;
                } else if (type === 't' && this.activeTIndex > 0) {
                    this.activeTIndex--;
                }
            },
    
            updateChartOptions() {
                this.chartOptionsPad = {
                    ...this.chartOptionsPad,
                    xaxis: {
                        ...this.chartOptionsPad.xaxis,
                        categories: this.categories_pad
                    },
                    annotations: {
                        xaxis: this.annotations_pad
                    }
                };
                this.chartOptionsFc = {
                    ...this.chartOptionsFc,
                    xaxis: {
                        ...this.chartOptionsFc.xaxis,
                        categories: this.categories_fc
                    },
                    annotations: {
                        xaxis: this.annotations_fc
                    }
                };
                this.chartOptionsFr = {
                    ...this.chartOptionsFr,
                    xaxis: {
                        ...this.chartOptionsFr.xaxis,
                        categories: this.categories_fr
                    },
                    annotations: {
                        xaxis: this.annotations_fr
                    }
                };
                this.chartOptionsT = {
                    ...this.chartOptionsT,
                    xaxis: {
                        ...this.chartOptionsT.xaxis,
                        categories: this.categories_t
                    },
                    annotations: {
                        xaxis: this.annotations_t
                    }
                };
                this.chartOptionsSat = {
                    ...this.chartOptionsSat,
                    xaxis: {
                        ...this.chartOptionsSat.xaxis,
                        categories: this.categories_sat
                    },
                    annotations: {
                        xaxis: this.annotations_sat
                    }
                };
            },
            validateDecimal: function (evt) {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            
            backPage() {
                if (this.currentPage > 0) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage < this.grafigPages.length - 1) {
                    this.currentPage++;
                }
            },
    
            //Pdf
            pdfGrafica(){
                window.open(
                    this.$store.getters.get__url + "/viewpdfGraficaHospSop/6/" + this.medical_act_id,
                    "_blank"
                );
            }
    
        }
    }
    </script>
    
    <style scoped>
    .table-responsive-graph {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
    }
    
    #pas thead tr th {
        background-color: #A3B8F5;
    }
    
    #pad thead tr th {
        background-color: #62CDFF;
    }
    
    #t thead tr th {
        background-color: #FF818B;
    }
    
    #sat thead tr th {
        background-color: #DB81FF;
    }
    
    #fr thead tr th {
        background-color: #FFBC81;
    }
    
    #fc thead tr th {
        background-color: #81FFBA;
    }
    
    .scroll-graph {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
    
    /* .content-graph {
        display: inline-block;
        min-width: 1000px;
    }
    
    .ancho {
        width: 100% !important;
        height: auto;
    }
    
    .img-chart {
        width: 1220px;
        height: auto;     
        margin: 0 auto;  
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    
    .content-graph {
        display: flex;
        align-items: flex-start;
        min-width: 100%; 
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    
    .img-chart {
        min-width: 1200px;
        width: auto;
        height: auto; 
        display: inline-block;
    }
    
    #content-timelinetable {
        overflow-x: auto; 
        white-space: nowrap;
    }
    </style>
    
<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="modalSize" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div v-if="pos == 1" class="row mx-0  px-0">

        <div class="row mx-0 px-0" v-if="data.length > 0">
            <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in data" :key="index">
                <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100 border border-main border-3" @click="mtdpresentation(item)">
                    <button type="button" class="btn w-100 text-xs h-100 ">
                        {{ item.molecule}}
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center fw-bold" v-else>
            <span>No hay registros</span>
        </div>

    </div>
    <div v-else class="row mx-0  px-2">

        <div class="row mx-0 px-0" v-if="datapresentation.length > 0">
            <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in datapresentation" :key="index">
                <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100  border border-main border-3" @click="mtdaddpresntation(item)">
                    <button type="button" class="btn w-100 text-xs h-100">
                        {{ item.presentation ? item.presentation : item.commercial}} {{ item.dosis}}
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center fw-bold" v-else>
            <span>No hay registros</span>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            data: [],
            datapresentation: [],
            pos: null,
            windowWidth: window.innerWidth
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    computed: {
        modalSize() {
            return this.windowWidth >= 992 ? 'xl' : 'md';
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    props: {
        title: String,
        boo_modal: Boolean,
        receta: {
            type: String,
            default: ''
        },
        posmedicine: {
            type: Number,
            default: null
        },
        type: {
            type: Number,
            default: 1
        },
       
    },
    watch: {
        posmedicine: {
            handler: function (newValue) {
                if (newValue != null) {
                    this.mtdmedicine();

                }
            },
            deep: true,
            immediate: true,
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.pos = null;
            this.$emit('mtdclosemodalmedicine')
        },
        mtdmedicine: function () {
            this.pos = 1;
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/productdetails",
                    token: this.$store.getters.get__token,
                    params: {
                        search: this.receta,
                        type: this.type
                    }
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.data = response.data;

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mtdpresentation: function (payload) {
            this.pos = 2;
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/presentacion",
                    token: this.$store.getters.get__token,
                    params: payload

                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.datapresentation = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mtdaddpresntation: function (payload) {
            this.$emit('addreceta', payload)
            this.pos = null
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    }
}
</script>

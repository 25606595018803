<template>
    <div>
        <div class="container mt-4">
            <div class="d-flex justify-content-center">
                <div class="card w-100">
                    <div class="card-header bg-bm text-white text-center h5">
                        <strong class="text-uppercase">Agregar Insumos</strong>
                    </div>
                    <div class="card-body bg-gris">
                        <div class="row d-flex">
                            <div class="col-md-6 ">
                                <div class="row d-flex align-items-center">
                                    <label class="col-xl-2 text-xl-end " for=""><Strong>Origen:
                                        </Strong></label>
                                    <v-select class="col-xl-9 p-0 text-xl-start" v-model="almacen"
                                        label="name" :options="dataAlmacenes"
                                        placeholder="-- Selecionar Almacenes de Origen --"
                                        :reduce="(almacen) => almacen.id" required :clearable="false">
                                        <template slot="no-options">
                                            No hay Almacenes para la búsqueda.
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-6 ">
                                <div class="row d-flex align-items-center">
                                    <label class="col-xl-2 text-xl-end " for=""><Strong>Destino:
                                        </Strong></label>
                                    <v-select class="col-xl-9 p-0 text-xl-start " v-model="destino"
                                        label="name" :options="dataDestino"
                                        placeholder="-- Selecionar Almacenes de Destino --"
                                        :reduce="(almacen) => almacen.id" required :clearable="false"
                                        :disabled="!almacen">
                                        <template slot="no-options">
                                            No hay Almacenes para la búsqueda.
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-lg-6 div-venta-body">
                                <div class="input-group">
                                    <span class="input-group-text bg-bm text-white"> <i class="fas fa-search"></i>
                                    </span>
                                    <input type="search" id="search_product" class="form-control"
                                        placeholder="Digitar Insumo" aria-describedby="search_product"
                                        v-model="search_product" @keyup="mtd_filter_product" />
                                </div>
                                <div class="row py-1">
                                    <div v-for="(item, index) in data_product_filter" :key="index"
                                        class="col-md-12 py-1 d-flex align-items-center item-products"
                                        style="background-color: #e6e6e6; height: 80px">
                                        <div class="p-1 d-flex flex-column w-100 justify-content-center"
                                            style="background-color: #ffffff; height: 100%" @click="addCarrito(item)">
                                            <div class="align-items-center" id="name-product">
                                                <p class="d-flex align-items-center mb-0 m-1">
                                                    <strong
                                                        class="mb-0 item-name custom-tooltip d-flex justify-content-between align-items-center">
                                                        {{ item.molecule }} - {{ item.presentation }} - {{ item.dosis }}
                                                        {{ item.brand ? "(" + item.brand.name + ")" : "" }}
                                                    </strong>

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 div-venta-body">
                                <div
                                    class="input-group-text bg-bm text-white d-flex justify-content-center align-items-center">
                                    <span class="text-uppercase fw-bold">Insumos Agregados</span>
                                </div>
                                <div class="list-monto">
                                    <div class="container-list">
                                        <div class="" v-if="carrito.length > 0">
                                            <div class="box item-monto" v-for="(item, index) in carrito" :key="index">
                                                <p class="text-monto">{{ item.molecule }} - {{ item.dosis }} - {{
                                                    item.brand.name }} | <span>Cantidad: </span>{{ item.cantidad }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-bm">
                        <div class="d-flex justify-content-between">
                            <button class="btn border border-white text-white text-uppercase fw-bold"
                                @click="retroceder">Atrás</button>
                            <button class="btn border border-white text-white text-uppercase fw-bold"
                                @click="mtdNextStep">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalCarroVentaVue :title="modalReci.title" :boo_modal="modalReci.boo_modal" :item="modalReci.item"
            @mtdCloseModalDeta="mtdCloseModalReci" @registrarReci="registrarReci" />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>

import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalCarroVentaVue from '@/components/Asistencial/Requirements/modals/CModalAddRequirement.vue';

export default {
    name: "c-requirements_carrito",
    components: {
        SweetAlert,
        cModalCarroVentaVue
    },
    props: {
        type: Number,
    },
    data() {
        return {
            swal: null,
            view: "carrito",
            dataAlmacenes: [],
            dataDestino: [],
            almacen: null,
            destino: null,
            data_product_details: [],
            data_product_filter: [],
            search_product: "",
            carrito: [],
            item_carrito: {},
            modalReci: {
                title: "",
                item: [],
                boo_modal: false,
                pos: null,
            },
        };
    },
    created() {
        this.mtdGetAlmacenes();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetDataProductDetail: function () {
            this.get({
                url:
                    this.$store.getters.get__url +
                    "/Requirements/" +
                    "types?search_product=" +
                    this.search_product,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    // console.log(response);

                    this.data_product_details = response.data;
                    this.data_product_filter = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        registrarReci: function (item) {
            this.carrito.push(item);
        },
        addCarrito(item) {
            this.modalReci.title = item.molecule + " - " + item.dosis + " - " + item.brand.name;
            this.modalReci.boo_modal = true;
            let bo = false;
            if (this.carrito.length == 0) {
                this.item_carrito = {
                    id: item.id,
                    molecula: item.molecula,
                    dosis: item.dosis,
                    brand_name: item.brand.name,
                };

                this.modalReci.item = item;
                if (this.modalReci.boo_modal !== true) {
                    this.carrito.push(this.item_carrito);
                }

                bo = true;
            } else {
                this.carrito.forEach((element) => {
                    if (element.id == item.id) {
                        this.modalReci.boo_modal = false;
                        Swal.fire({
                            text: "Ya está agregado el Insumo en el Carrito",
                            icon: "warning",
                            confirmButtonColor: "#900052",
                        });
                        //element.quantity = element.quantity + 1;
                        bo = true;
                    }
                });
            }
            if (!bo) {
                this.item_carrito = {
                    id: item.id,
                    molecula: item.molecula,
                    dosis: item.dosis,
                    brand_name: item.brand.name,
                };
                this.modalReci.item = item;
                if (this.modalReci.boo_modal !== true) {
                    this.carrito.push(this.item_carrito);
                }
                bo = false;
            }
            this.search_product = "";

        },
        retroceder() {
            this.$emit("mtdBack");
        },
        mtd_filter_product: function () {
            if (search_product.value.length >= 5 && search_product.value != "") {
                let q = search_product.value.toString().toUpperCase();
                this.mtdGetDataProductDetail(q);
                this.data_product_filter = [];
                let i = 0;
                this.data_product_details.forEach((element) => {
                    let molecule = element.molecule.toString().toUpperCase();
                    if (molecule.includes(q)) {
                        this.data_product_filter.push(element);
                    }
                });
            } else {
                this.data_product_filter = this.data_product_details;
            }
        },
        mtdNextStep: function () {
            if (!this.almacen) {
                this.$refs.SweetAlert.showWarning("Debe seleccionar un Origen primero.");
                return;
            }

            if (!this.destino) {
                this.$refs.SweetAlert.showWarning("Debe seleccionar un destino");
                return;
            }

            if (this.carrito.length <= 0) {
                this.$refs.SweetAlert.showWarning("Seleccione un Insumo");
            } else {
                const data = {
                    carrito: this.carrito,
                    almacen_id: this.almacen,
                    almacen_destino: this.destino
                };

                this.post({
                    url: this.$store.getters.get__url + "/Requirements/addCarrito",
                    token: this.$store.getters.get__token,
                    params: data
                }).then((response) => {
                    // Swal.fire({
                    //     title: "Envío Exitoso",
                    //     text: "Perfecto!",
                    //     icon: "success",
                    //     width: "400px",
                    //     confirmButtonColor: "rgb(170, 2, 95)",
                    // });
                    this.almacen = null;
                    this.destino = null;
                    this.carrito = [];
                    this.search_product = "";
                    this.data_product_filter = [];
                    this.$refs.SweetAlert.showSuccess("Datos enviados correctamente.");
                }).catch((error) => {
                    this.$refs.SweetAlert.showError(error);
                })
            }
        },

        mtdGetAlmacenes: function () {
            this.get({
                url: this.$store.getters.get__url + "/Requirements/dataAlmacenes/" + this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
            }).then((response) => {
                // console.log('response', response);
                this.dataAlmacenes = response.data.almacen;
                this.dataDestino = response.data.almacenDestino;
                // console.log(this.dataDestino);

            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },

        mtdCloseModalReci: function () {
            this.modalReci = {
                title: "",
                item: [],
                boo_modal: false,
                pos: null,
            };
        },
    }
}
</script>

<style scoped>

    .container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .card {
        flex-grow: 1;
    }

    .card-body {
        overflow-y: auto;
        max-height: calc(100vh - 160px);
    }

    .div-venta-body {
        height: 400px;
        overflow: auto;
        box-sizing: border-box;
        padding: 1rem 1rem;
    }


    .div-venta-body::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .div-venta-body::-webkit-scrollbar:vertical {
        width: 10px;
    }

    .div-venta-body::-webkit-scrollbar-button:increment,
    .div-venta-body::-webkit-scrollbar-button {
        display: none;
    }

    .div-venta-body::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    .div-venta-body::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 2px solid #f1f2f3;
    }

    .div-venta-body::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    .bg-bm {
        background-color: #900052;
    }

    .bg-gris {
        background-color: #e6e6e6;
    }

    .item-products {
        text-align: center;
    }

    .item-products:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    .text-agregados {
        color: #900052;
        font-weight: bold;
    }
    

</style>
<template>
  <div>
    <div class="p-2 m-2">
      <div class="row">
        <div class="col-md-12">
          <div id="btn-return" class="d-flex justify-content-end pt-1 mt-1">
            <div class="btn-home" role="group">
              <button
                type="button"
                class="btn btn-sm btn-outline-main bg-main text-white"
                @click="mtdBackCampus"
              >
                <i class="fas fa-recycle"></i> Cambiar de Sede
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12 pt-2">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="(item, index) in months"
                  :key="index"
                  class="col-md-1"
                >
                  <button
                    :class="
                      parseInt(selectMonth) - 1 == index
                        ? 'btn btn-sm btn-bm w-100'
                        : 'btn btn-sm btn-secondary w-100'
                    "
                    :disabled="index + 1 > month ? true : false"
                    @click="mtdSelectMounth(index, item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="(item, index) in reports"
                  :key="index"
                  class="col-md-12 mt-1"
                  @click="mtdSelectReport(item)"
                >
                  <div
                    :class="
                      reportSelect == item.key
                        ? 'card card-book shadow active'
                        : 'card card-book shadow'
                    "
                    style="height: 7em"
                  >
                    <h4>{{ item.name }}</h4>
                    <div class="icon-book" style="font-size: 2em">
                      <i :class="item.icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="filter.date != null" class="col-md-9">
          <div class="card">
            <div class="card-header">
              {{ filter.date }}
            </div>
            <div class="card-body" v-if="reportSelect != null">
              <div id="chart2" v-if="reportSelect == 'readmision' || reportSelect=='satisfaccion'">
                <apexchart
                  ref="apexchart2"
                  :options="chartOptionsPie"
                  :series="seriesPie"
                ></apexchart>
              </div>
              <div id="chart1" v-else>
                <apexchart
                  ref="apexchart1"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>

      <cModalSelectDaysVue
        :title="modal.title"
        :boo_modal="modal.modal_form"
        @closeModal="closeModal"
        :days="modal.days"
        @mtdSelectDates="mtdSelectDates"
        :arDay="modal.arDay"
      />
    </div>
  </div>
</template>
      
  <script>
import moment from "moment";
import { mapActions } from "vuex";
import cModalSelectDaysVue from "./Modals/cModalSelectDays.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "HomeGerenciaGeneralComponent",
  props: {},
  components: { cModalSelectDaysVue, apexchart: VueApexCharts },
  data() {
    return {
      anio: null,
      month: null,
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      selectMonth: null,
      lastDayPrevMonth: 0,
      filterDay: [],
      modal: {
        title: "ESCOGER DIAS",
        modal_form: false,
        days: [],
        arDay: [0, 0],
        // click:0
      },
      filter: {
        mes: null,
        date: null,
        desde: null,
        hasta: null,
      },
      /** reportes */
      reports: [
        {
          name: "Tiempo de espera ",
          key: "tiempoespera",
          icon: "fas fa-user-clock",
        },
        { name: "Camas ocupadas", key: "camasocupadas", icon: "fad fa-bed" },
        {
          name: "Promedio de estancia",
          key: "estancia",
          icon: "fas fa-hospital-user",
        },
        {
          name: "Tasa de readmisión",
          key: "readmision",
          icon: "fas fa-user-injured",
        },
        {
          name: "Satisfacción del paciente",
          key: "satisfaccion",
          icon: "fas fa-user-check",
        },
        { name: "Egresos brutos", key: "egresobruto", icon: "" },
        {
          name: "Visualización de tramite documentario",
          key: "tramitedocumentario",
          icon: "fas fa-file-import",
        },
        { name: "Costo por paciente", key: "costopaciente", icon: "" },
        {
          name: "Gastos operativos por paciente",
          key: "gastosoperativos",
          icon: "",
        },
        {
          name: "Costo por Servicio o departamento",
          key: "costoservicio",
          icon: "",
        },
        { name: "Productividad del personal", key: "productividad", icon: "" },
      ],
      reportSelect: null,
      // /** options chart */
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        yaxis: {
          min: 0,
          tickAmount: 4,
        },
      },
      series: [],
      /** chart pie */
      seriesPie:[],
      chartOptionsPie: {
        chart: {
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  },
  created() {
    this.anio = moment().format("YYYY");
    this.month = moment().format("MM");
  },
  mounted() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdSelectMounth: function (pos, mes) {
      pos++;
      this.selectMonth = pos.toString().length == 1 ? "0" + pos : pos;
      this.lastDayPrevMonth = moment(this.anio + "-" + this.selectMonth + "-01")
        .endOf("month")
        .format("DD");
      let days = [];
      for (let index = 1; index <= this.lastDayPrevMonth; index++) {
        days.push({
          day: index,
          class: "btn btn-sm btn-secondary w-100 mt-2",
        });
      }
      this.modal.modal_form = true;
      this.modal.title = "ESCOGER DIAS DEL MES " + mes.toUpperCase();
      this.modal.days = days;
      this.filter.mes = mes.toUpperCase();
    },
    closeModal: function () {
      this.modal = {
        title: "ESCOGER DIAS",
        modal_form: false,
        days: [],
        arDay: [0, 0],
        click: 0,
      };
    },
    mtdSelectDates: function (arDay) {
      this.filter.date =
        "DESDE EL " +
        arDay[0] +
        " DE " +
        this.filter.mes +
        // "/" +
        // this.anio +
        " HASTA EL " +
        arDay[1] +
        " DE " +
        this.filter.mes +
        " DEL " +
        this.anio;
      this.filter.desde = this.anio + "-" + this.selectMonth + "-" + arDay[0];
      this.filter.hasta = this.anio + "-" + this.selectMonth + "-" + arDay[1];
      this.closeModal();
    },
    mtdSelectReport: function (item) {
      if (this.filter.date == null) {
        alert("no hay fechas");
      } else {        
        this.reportSelect = item.key;
        this.get({
          url:
            this.$store.getters.get__url_v3 +
            "/kpi/" +
            this.reportSelect +
            "/" +
            this.filter.desde +
            "/" +
            this.filter.hasta,
          token: this.$store.getters.get__token,
        }).then((response) => {
          this.mtdUpdateChart(response);
        });
      }
    },
    mtdUpdateChart: async function (payload) {
      await this.mtdSetChartOptions(payload);
      if (this.reportSelect == 'readmision' || this.reportSelect == 'satisfaccion') {
        this.$nextTick(() => {
          if (this.$refs.apexchart2) {
            this.$refs.apexchart2.updateSeries(this.seriesPie);
            this.$refs.apexchart2.updateOptions(this.chartOptionsPie);
          }
        });
      }else{
        this.$nextTick(() => {
          if (this.$refs.apexchart1) {
            this.$refs.apexchart1.updateSeries(this.series);
            this.$refs.apexchart1.updateOptions(this.chartOptions);
          }
        });
      }
    },
    mtdSetChartOptions: async function (payload) {
      this.chartOptions={};
      switch (this.reportSelect) {
        case "tiempoespera":
          this.series = payload.data.data;
          this.chartOptions = {
            chart: {
              type: payload.data.grafic,
              height: 350,
              zoom: {
                enabled: false,
              },
            },
            stroke: {
              curve: "smooth",
            },
            yaxis: {
              min: 0,
              tickAmount: 4,
            },
            xaxis: {
              categories: payload.data.categories,
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              hover: {
                sizeOffset: 4
              }
            }
          };
          break;
        case "camasocupadas":
          this.series = payload.data.data;
          this.chartOptions = {
            chart: {
              type: payload.data.grafic,
              height: 350,
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "last", // 'all', 'last'
                dataLabels: {
                  total: {
                    enabled: false,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              categories: payload.data.categories,
              colors: ["#2E93fA", "#66DA26"],
            },
            yaxis: {
              min: 0,
              max: 120,
              tickAmount: 4,
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
            dataLabels: {
              enabled: false,
            },
          };
          break;
        case "estancia":
          this.series = payload.data.data;
          this.chartOptions = {
            chart: {
              type: payload.data.grafic,
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "last", // 'all', 'last'
                dataLabels: {
                  total: {
                    enabled: false,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              categories: payload.data.categories,
              colors: ["#2E93fA", "#66DA26"],
            },
            yaxis: {
              min: 0,
              max: 120,
              tickAmount: 4,
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
            dataLabels: {
              enabled: false,
            },
          };
          break;
        case "readmision":
          this.seriesPie = payload.data.data;
          this.chartOptionsPie = {
            chart: {
              type: payload.data.grafic,
              height: 350,
            },
            labels: payload.data.labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ]
          };
          break;
        case "satisfaccion":
          this.seriesPie = payload.data.data;
          this.chartOptionsPie = {
            chart: {
              type: payload.data.grafic,
              height: 350,
            },
            labels: payload.data.labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ]
          };
          break;
        default:
          break;
      };
      
    },
  },
};
</script>
<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show.sync="boo_modal"
      :close-on-backdrop="false"
      :centered="false"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row mx-0  mb-2">
                <div class="col-xl-8 px-0">
                    <label for="exampleFormControlInput1" class="form-label">Medicamento</label>
                    <input v-model="table_detail.name" type="text" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Digite Medicamento">
                </div> 
                <div class="col-xl-2 ps-xl-1 px-0">
                    <label for="exampleFormControlInput2" class="form-label">Cantidad</label>
                    <input v-model="table_detail.quantity" @keypress="validateNumberInput" type="text" class="form-control form-control-sm" id="exampleFormControlInput2" placeholder="Digite Cantidad">
                </div> 
                <div class="col-xl-2 ps-xl-1 mt-xl-0 mt-1 px-0 d-flex align-items-end">
                    <div class="w-100">
                        <button :disabled="mtddisabled"  @click="mtdadd" type="button" class="btn btn-success btn-sm w-100">Agregar</button>
                    </div>
                </div> 

            </div>
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered mb-0">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 13px">
                        <tr class="text-white text-center align-middle">
                          <th scope="col">N°</th>
                          <th scope="col">Medicamento</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody v-if=" table.length > 0" class="border-main" style="font-size: 12px">
                      <tr v-for="(det, index) in table" :key="index" class="text-center align-middle">
                          <td>{{ index + 1 }}</td>
                          <td>{{ det.name }}</td>
                          <td>{{ det.quantity }}</td>
                          <td>
                                <button type="button" @click="mtddelete(index, 'mtddeletefrequency')"
                                class="btn btn-danger btn-sm px-1 py-0" >
                                <i class="fas fa-trash-alt text-white"></i>
                                </button>
                            </td>
                      </tr>
                      
                    </tbody>
                    <tbody v-else>
                        <tr >
                            <td colspan="4" >
                                <div  class="d-flex justify-content-center align-items-center fw-bold">
                                    No Hay resultado
                                </div>
                             </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Recomendaciones</label>
                <textarea  v-model="recomendation" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
  
        </div>
      </div>
      <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdGetData"  :disabled = "mtddisabledsave" >
            <i class="fas fa-save" />&nbsp; Agregar 
        </button>
        <button class="btn btn-danger btn-sm" @click="mtdClose"  >
            <i class="fas fa-times" />&nbsp; Cerrar
        </button>
     </template>
     <SweetAlert :swal="swal" ref="SweetAlert"  @mtddeletefrequency="mtddeletefrequency" />
    </CModal>
  </template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import { mapActions } from "vuex";
  export default {
    name: "c-pharmacy-cModalDetalle",
    components: {
      CModal,
      SweetAlert
    },
    data() {
      return {
        swal:null,
        table: [],
        table_detail:{
            name:'',
            quantity: ''
        },
        recomendation:'',
      };
    },
    created () {
    },
    computed:{
      mtddisabled:function(){
        return !(this.table_detail.name && this.table_detail.quantity)
      },
      mtddisabledsave:function(){
        return !this.table.length >0
      }
    },
    props: {
      title: String,
      boo_modal: Boolean,
      medical_act_id:Number,
      patient_id:Number
    },
    methods: {
      ...mapActions(["get", "post"]),
      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },
  
      mtdClose() {
        this.$emit("mtdCloseModalData");
        this.table=[]
        this.recomendation=''
      },
      mtdadd:function(){
        this.table.push(this.table_detail);
        // if (this.recomendation) {
        //     this.recomendation += ` \n${this.table_detail.name} ${this.table_detail.quantity}`;
        // } else {
        //     this.recomendation = `${this.table_detail.name} ${this.table_detail.quantity}`;
        // }
        this.table_detail = {
            name:'',
            quantity: ''
        }
      },
      mtddelete: function (index, msg) {
        this.$refs.SweetAlert.showDelete(index, msg);
      },
      mtddeletefrequency: function (index) {
        this.table.splice(index, 1);
      },
      mtdGetData: function () {
            let data ={
                medical_act_id: this.medical_act_id,
                patient_id: this.patient_id,
                table: this.table,
                recomendation:  this.recomendation
            }
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/AddFrequency",
                token: this.$store.getters.get__token,
                params:data
            }).then((response) => {
                this.patient = response.data;
                this.$refs.SweetAlert.showSuccess(response.message);
                this.mtdClose()
            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },
    },
  };
  </script>
  <style scoped>
  .align-middle{
    vertical-align: middle;
  }
  </style>
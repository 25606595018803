<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="modalSize" :btn_cancel="true" :btn_ok="true" :booTitile="true" >
    <div class="px-0">
        <div class=" row col-xl-12  mx-0 px-0 ">
            <div class="mt-2">
                <div class="row mx-0 px-0 mt-2">
                    <div class="col-xl-6 px-1">
                        <div>
                            <h6>Medicamento no Selecionados</h6>
                        </div>
                        <div class="col-md-12 table-responsive mt-3  " id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">MEDICAMENTO</th>
                                        <th scope="col" class="align-middle">TOTAL</th>
                                        <!-- <th scope="col" class="align-middle">DURACION</th> -->
                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in NoSeleccionados" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.molecule }} {{ item.presentation }} {{ item.dosis }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.total }}
                                        </td>
                                        <!-- <td class="align-middle">
                                            {{ item.duration }}
                                        </td> -->
                                        <td class="align-middle text-center">
                                            <button @click="mtdnoselecionado(item)" type="button" class=" btn btn-success btn-sm px-1 py-0 ">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!NoSeleccionados.length > 0" class="text-center">
                                        <th scope="col" colspan="5">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-xl-6 px-0">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h6>Medicamento Seleccionados {{ (pos != null ) ? name : '' }}</h6>
                            </div>
                            <div v-if="Seleccionados.length > 1">
                                <button @click="mtdgroup" type="button" class=" btn btn-success btn-sm px-4 py-0 " :disabled="quantity ==''">
                                    Agrupar
                                </button>
                            </div>
                        </div>

                        <div class="col-md-12 table-responsive mt-2" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">MEDICAMENTO</th>
                                        <th scope="col" class="align-middle">TOTAL</th>
                                        <!-- <th scope="col" class="align-middle">DURACION</th> -->
                                        <!-- <th scope="col" class="align-middle">CANTIDAD</th> -->
                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in Seleccionados" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.molecule }} {{ item.presentation }} {{ item.dosis }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.total }}
                                        </td>
                                        <!-- <td class="align-middle">
                                            {{ item.duration }}
                                        </td> -->
                                        <!-- <td style="width: 80px;" class="align-middle">
                                            <input v-on:keypress="isNumber($event)" type="text" v-model="item.quantity " placeholder="Cantidad Entrante" class="form-control form-control-input form-control-sm" />
                                        </td> -->
                                        <td class="align-middle text-center">
                                            <button type="button" class=" btn btn-danger btn-sm px-1 py-0" @click="mtdselecionado(item)">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!Seleccionados.length > 0" class="text-center">
                                        <th scope="col" colspan="6">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="Seleccionados.length > 1" class="d-flex justify-content-end">
                             <div class="w-25">
                                <input class="form-control form-control-sm" v-model="quantity" type="text" placeholder=" Dosis de la Mezcla" >
                             </div>
                             <div class="w-25 mx-1">
                                <select class="form-control form-control-sm" v-model = "medida" aria-label="Default select example">
                                    <option value="1" selected >CC</option>
                                    <option value="2">ML</option>
                                    <option value="3">GOTAS</option>
                                </select>
                            </div>
                            <div class="w-25">
                                <select class="form-control form-control-sm"  v-model = "tiempo" aria-label="Default select example">
                                    <option value="1" selected >HORA</option>
                                    <option value="2">MINUTOS</option>
                                </select>

                            </div>
                           
                         </div>

                    </div>
                </div>

            </div>

            <div class="row mx-0 px-2" v-if="group.length > 0">
                <div class="mb-2">
                    <strong>Listado de Grupos Formados</strong>
                </div>
                <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in group" :key="index">
                    <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100 border border-main border-3">
                        <button @click="selectGroup(index)" type="button" class="btn w-100 text-xs h-100 fw-bold ">
                            {{ item.name}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <template #footer>
        <button :disabled="disablegroup" @click="mtdaddGroup" class="btn btn-success btn-sm px-4">
            &nbsp;Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    elements
} from "chart.js";

import {
    mapActions
} from "vuex";

export default {
    name: "c-asistencial-hospitalizacion-modals-cModalMezclas",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            NoSeleccionados: [],
            Seleccionados: [],
            group: [],
            medicamentos_details: [],
            insumos: [],
            pos: null,
            name: '',
            swal: null,
            quantity:'',
            windowWidth: window.innerWidth,
            medida:1,
            tiempo:1
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        medicinemezclas: Array
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        medicinemezclas: {
            handler: function (newValue) {
                if (newValue.length > 0) {
                    this.addrecetass(JSON.parse(JSON.stringify(newValue)));
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        disablegroup: function () {
            return !(this.group.length > 0 && this.pos ==null && this.Seleccionados.length == 0);
        },
        modalSize() {
            return this.windowWidth >= 992 ? 'xl' : 'md';
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalmezclas')
            this.group = []
            this.pos = null
        },

        addrecetass: function (payload) {
            this.NoSeleccionados=[]
            payload.forEach(item => {
                item.group = 0
                if (item.type == 1 && item.duration =='-') {
                    this.NoSeleccionados.push(item)
                } else {
                    this.insumos.push(item)
                }
            })
        },

        mtdnoselecionado: function (item) {
            const index = this.NoSeleccionados.findIndex(medicine => medicine === item);
            const index2 = this.Seleccionados.findIndex(medicine =>
                medicine.frequency === item.frequency &&
                medicine.duration === item.duration
            );
            if (this.Seleccionados.length > 0) {
                // if (index2 !== -1) {
                    if (index !== -1) {
                        const ServicioQuitado = this.NoSeleccionados.splice(index, 1)[0];
                        this.Seleccionados.push(ServicioQuitado);
                    }
                // } else {
                //     this.$refs.SweetAlert.showWarning('El medicamento seleccionado debe tener igual frecuencia y duración');
                // }

            } else {
                if (index !== -1) {
                    const ServicioQuitado = this.NoSeleccionados.splice(index, 1)[0];
                    this.Seleccionados.push(ServicioQuitado);
                }

            }

        },
        mtdselecionado: function (item) {
            const index = this.Seleccionados.findIndex(medicine => medicine === item);
            if (index !== -1) {
                const ServicioQuitado = this.Seleccionados.splice(index, 1)[0];
                this.NoSeleccionados.push(ServicioQuitado);
            }
            if (this.pos != null && this.Seleccionados.length == 0) {
                this.group.splice(this.pos, 1)
                this.pos = null
                if (this.group.length > 0) {
                    for (let i = 0; i < this.group.length; i++) {
                        this.group[i].name = 'GRUPO ' + (i + 1)
                        this.group[i].grupo.forEach(item => {
                            item.group = i + 1
                        })
                    }
                }
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        mtdgroup: function () {
            if (this.pos != null) {
                this.group[this.pos].grupos = []
                this.Seleccionados.forEach(item => {
                    item.group = this.pos + 1
                    this.group[this.pos].grupos.push(item)
                })
                this.Seleccionados = []
            } else {
                this.Seleccionados.forEach(item => {
                    item.group = this.group.length + 1
                    item.quantity = this.quantity
                    item.medida = this.medida
                    item.tiempo = this.tiempo
                })
                let num = this.group.length + 1
                let grupos = {
                    name: 'GRUPO ' + num,
                    grupo: this.Seleccionados
                }
                this.group.push(grupos)
                this.Seleccionados = []
                 this.quantity=''

            }
            this.pos = null
        },

        mtdaddGroup: function () {
            let num = 0
            this.group.forEach(item => {
                if (item.grupo.length == 1) {
                    num += 1
                }
            })
            if (num == 0) {
                this.NoSeleccionados.forEach(item => {
                    item.group = 0
                    this.medicamentos_details.push(item)
                })
                this.group.forEach(item => {
                    item.grupo.forEach(item2 => {
                        item2.prn = true
                        this.medicamentos_details.push(item2)
                    })
                })
                this.insumos.forEach(item3 => {
                    this.medicamentos_details.push(item3)
                })
                this.$emit('mtdchange', this.medicamentos_details)
                this.group = []
                this.medicamentos_details = []
                this.Seleccionados = []
                this.NoSeleccionados = []
                this.insumos = []
                this.pos = null
                this.quantity = ''
            } else {
                this.$refs.SweetAlert.showWarning('Grupo con un solo Medicamento');
            }

        },
        selectGroup: function (ind) {

            if (this.Seleccionados.length > 0 && this.pos == null) {
                this.Seleccionados.forEach(item => {
                    this.NoSeleccionados.push(item)
                })
                this.Seleccionados = this.group[ind].grupo
            } else {
                this.Seleccionados = this.group[ind].grupo
            }
            this.pos = ind
            this.name = ' del grupo ' + (ind + 1)
            this.quantity =  this.group[ind].grupo[0].quantity
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

    },
};
</script>

<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
        :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row px-4">
            <div class="col-xl-12 col-md-6 py-1">
                <div id="div-device" class="form-group row align-left">
                    <label for="device" class="col-xl-2 color-main fw-bold">DISPOSITIVO</label>
                    <div class="col-xl-10">
                        <select class="form-control form-control-sm" v-model="device" id="device"
                            placeholder="- Seleccionar -">
                            <option value="">- Seleccionar -</option>
                            <option value="mecanica">VENTILADOR MECÁNICO</option>
                            <option value="canula">CÁNULAS NASALES</option>
                            <option value="simple">MASCARILLA SIMPLE</option>
                            <option value="reservorio">MASCARILLA RESERVORIO</option>
                            <option value="venturi">MASCARILLA VÉNTURI</option>
                            <option value="gafas">GAFAS NASALES</option>
                            <option value="nebulizadora">MÁSCARA NEBULIZADORA</option>
                            <option value="caf">CAF</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-md-6 py-1">
                <div id="div-minutes" class="form-group row align-left">
                    <label for="minutes" class="col-xl-5 color-main fw-bold">MINUTOS/HORAS</label>
                    <div class="form-group col-xl-6">
                        <div class="input-group">
                            <select v-model="time" class="form-control form-control-sm" id="time">
                                <option value="minute">MINUTOS</option>
                                <option value="hour">HORAS</option>
                            </select>
                            <input type="text" v-model="minutes" v-on:keypress="validateDecimal($event)"
                                class="form-control form-control-sm" id="minutes">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 py-1">
                <div id="div-fio2" class="form-group row align-left">
                    <label for="fio2" class="col-xl-5 color-main fw-bold">FIO2 %</label>
                    <div class="col-xl-7">
                        <input @keyup="obtenerLitraje" v-model="fio2" v-on:keypress="validateDecimal($event)"
                            type="text" class="form-control form-control-sm" id="fio2">
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 py-1">
                <div id="div-liter" class="form-group row align-left">
                    <label for="liter" class="col-xl-5 color-main fw-bold">LITROS</label>
                    <div class="col-xl-7">
                        <input v-model="liter" v-on:keypress="validateDecimal($event)" readonly type="text"
                            class="form-control form-control-sm" id="liter">
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-6 py-3 align-center">
                <button type="button" class="btn btn-success btn-sm text-white" @click="mtdvalidation"><i
                        class="fas fa-save"></i> Guardar</button>
            </div>
        </div>

        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-oxigeno",

    data() {
        return {

            swal: null,
            receta: '',
            validation: false,
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null
            },
            type: true,
            recipes: [],
            insumos: [],

            sale: [],
            insurance_id: null,
            type_patient: '',
            device: '',
            time: 'minute',
            liter: '',
            device: '',
            fio2: '',
            minutes: ''
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical: {
            type: Number,
            default: 0
        },
        patient_id: {
            type: Number,
            default: 0
        }
    },

    watch: {
        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdtypepatient();
                }
            },
            deep: true,
            immediate: true,
        },
        patient_id: {
            handler: function (newValue) {

            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        // this.mtdtypepatient();
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.minutes = '';
            this.liter = '';
            this.fio2 = '';
            this.device = '';
            this.$emit('mtdclosemodaloxigeno')
        },
        mtdoxygennew: function (dateMod, timeMod) {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/storeOxygen",
                token: this.$store.getters.get__token,
                params: {
                    type_patient: this.type_patient,
                    insurance_id: this.insurance_id,
                    medical_act_id: this.id_medical,
                    liter: this.liter,
                    device: this.device,
                    fio2: this.fio2,
                    minutes: this.minutes,
                    time: this.time,
                    dateMod: dateMod,
                    timeMod: timeMod
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if (response.data != []) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.minutes = '';
                        this.liter = '';
                        this.fio2 = '';
                        this.device = '';
                        this.mtdclosemodal();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                }
            });
        },
        mtdtypepatient: function () {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/salePatient",
                token: this.$store.getters.get__token,
                params: {
                    patient_id: this.patient_id,
                    medical_act_id: this.id_medical
                },
            })
                .then((response) => { //   
                    this.sale = response.data,
                        this.type_patient = response.data.type_patient
                    this.insurance_id = response.data.insurance_id
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdvalidation: function () {
            if (this.device === '' || this.device === null) {
                this.$refs.SweetAlert.showInfo('Seleccione un Dispositivo.');
            } else if (this.minutes === '' || this.minutes === null) {
                this.$refs.SweetAlert.showInfo('Ingrese tiempo en Hora o Minutos válido.');
            } else if (this.fio2 === '' || this.fio2 === null) {
                this.$refs.SweetAlert.showInfo('Ingrese un valor válido de Fio2.');
            } else if (this.liter === '' || this.liter === null || this.liter === 0 || this.liter === '0') {
                this.$refs.SweetAlert.showInfo('No se ecuentra valor de litraje.');
            } else {
                // this.validation = true,
                // this.mtdoxygennew();
                this.validation = true;
                const currentDate = new Date();
                Swal.fire({
                    title: 'Confirmar datos',
                    html: `
                        <div>
                            <label for="swal-date">Fecha:</label>
                            <input type="date" id="swal-date" value="${currentDate.toLocaleDateString('en-CA')}" class="swal2-input" />
                        </div>
                        <div>
                            <label for="swal-time">Hora:</label>
                            <input type="time" id="swal-time" value="${currentDate.toTimeString().split(' ')[0].substring(0, 5)}" class="swal2-input" />
                        </div>
                    `,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    didOpen: () => {
                        const swalContainer = Swal.getHtmlContainer();
                        swalContainer.addEventListener('keydown', (event) => {
                            if (event.key === 'Enter') {
                                Swal.clickConfirm();
                            }
                        });
                    },
                    preConfirm: () => {
                        const dateMod = document.getElementById('swal-date').value;
                        const timeMod = document.getElementById('swal-time').value;
                        if (!dateMod || !timeMod) {
                            this.$swal.showValidationMessage('Debe ingresar una fecha y hora válidas');
                            return false;
                        }
                        return { dateMod, timeMod };
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        const { dateMod, timeMod } = result.value;
                        this.mtdoxygennew(dateMod, timeMod);
                    }
                });
            }
        },
        obtenerLitraje: function () {
            if (this.device === '' || this.device === null) {
                this.$refs.SweetAlert.showInfo('Seleccione un Dispositivo.');
            } else {
                this.post({
                    url: this.$store.getters.get__url + "/Emergency/literValue",
                    token: this.$store.getters.get__token,
                    params: {
                        device: this.device,
                        fio2: this.fio2,
                    },
                })
                    .then((response) => {
                        this.liter = response.data;
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }
        },
        validateDecimal: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

    },
}
</script>

<template>
    <div class="row px-3">
        <div class="col-md-12" id="content-timelinetable" v-if="columns.length > 0">

            <!--Hora-->
            <div class="col-xl-7 col-lg-12 col-md-12 px-2 mb-3">
                <div class="row d-flex border border-main text-xs">
                    <div class="py-1 px-2 border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        <span class="color-main fw-bold">FECHA DE ING.</span>
                    </div>
                    <div class="py-1 px-2 text-center border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        {{ data[0].date_entry | formatDate}}
                    </div>
                   <!--  <div class="col-xl-2 col-lg-2 col-md-2 py-1 px-2 border border-main" v-if="isToday ">
                        <span class="color-main fw-bold">HORA DE ING.</span>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 py-1 px-2 text-center border border-main" v-if="isToday ">
                        {{ data[0].hourBalance }}
                    </div> -->
                    <div class="py-1 px-2 border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        <span class="color-main fw-bold">HORAS</span>
                    </div>
                    <div class="py-1 px-2 text-center border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        {{ data[0].hourNum }}
                    </div>
                    <div class="py-1 px-2 border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        <span class="color-main fw-bold">PESO</span>
                    </div>
                    <div class="py-1 px-2 text-center border border-main"
                    :class="isToday  ? 'col-xl-2 col-lg-2 col-md-2' : 'col-xl-2 col-lg-2 col-md-2'">
                        {{ data[0].weight }} Kg
                    </div>
                </div>
            </div>
            
            <!-- Tabla de Ingresos -->
            <div class="card-header bg-main text-white text-center py-1">
                <h5 class="mb-0">INGRESOS</h5>
            </div>
           <div class="table-responsive">
             <table class="tablesheet table-bordered text-center border-main mt-2 text-xs table-padding">
                 <thead>
                     <tr>
                         <th v-for="(col, index) in columns" :key="index" :colspan="index === 0 ? 2 : 1" class="py-2">
                             {{ col.label }}
                         </th>
                         <th v-for="(col, index) in columnsInMa" :key="'ma' + index"  class="py-2">
                            <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'inMa'" @update-label="updateHour" />
                            </template>
                            <template v-else>
                                {{ col.label }}
                            </template>
                         </th>
                         <th class="py-2 bg-li-blue nowrap">6 HRS</th>
                         <th v-for="(col, index) in columnsInTa" :key="'ta' + index"  class="py-2">
                            <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'inTa'" @update-label="updateHour" />
                            </template>
                            <template v-else>
                                {{ col.label }}
                            </template>
                         </th>
                         <th class="py-2 bg-li-blue nowrap">6 HRS</th>
                         <th class="py-2 bg-li-yellow nowrap">12 HRS</th>
                         <th v-for="(col, index) in columnsInNo" :key="'no' + index"  class="py-2">
                            <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'inNo'" @update-label="updateHour" />
                            </template>
                            <template v-else>
                                {{ col.label }}
                            </template>
                         </th>
                         <th class="py-2 bg-li-blue nowrap">12 HRS</th>
                         <th class="py-2 bg-li-yellow nowrap">24 HRS</th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(row, rowIndex) in ingresoRows" :key="rowIndex">
                         <template v-if="isFirstRowOfGroup(rowIndex, 'ingreso')">
                             <td :rowspan="getRowSpan(rowIndex, 'ingreso')" class="color-main">
                                 <div class="py-2 px-3 fw-semibold">{{ getRowTitle(rowIndex, 'ingreso') }}</div>
                             </td>
                         </template>
                         <td v-for="(col, colIndex) in columns" :key="colIndex">
                             <div  class="fw-semibold" :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                 {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                             </div>
                         </td>
                         <td v-for="(col, colIndex) in columnsInMa" :key="'ma' + colIndex">
                             <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                 {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                             </div>
                         </td>
                         <td class="py-2 bg-li-blue">{{ row.totalMa }}</td>
                         <td v-for="(col, colIndex) in columnsInTa" :key="'ta' + colIndex">
                             <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                 {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                             </div>
                         </td>
                         <td class="py-2 bg-li-blue">{{ row.totalTa }}</td>
                         <td class="py-2 bg-li-yellow">{{ row.totalDiurno }}</td>
                         <td v-for="(col, colIndex) in columnsInNo" :key="'no' + colIndex">
                             <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                 {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                             </div>
                         </td>
            
                         <td class="py-2 bg-li-blue">{{ row.totalNo }}</td>
                         <td class="py-2 bg-li-yellow">{{ row.total}}</td>
                     </tr>
                     <tr>
                         <td class="py-2 color-main fw-semibold" colspan="2">AGUA METABOLICA</td>
                         <td class="py-2" v-if="columnsInMa.length > 0" :colspan="columnsInMa.length"></td>
                         <td class="py-2 bg-li-blue">{{ metabolic_water[0].mananaIn}}</td>
                         <td class="py-2" v-if="columnsInTa.length > 0" :colspan="columnsInTa.length"></td>
                         <td class="py-2 bg-li-blue">{{ metabolic_water[0].tardeIn}}</td>
                         <td class="py-2 bg-li-yellow">{{ metabolic_water[0].parcialIn}}</td>
                         <td class="py-2" v-if="columnsInNo.length > 0" :colspan="columnsInNo.length"></td>
                         <td class="py-2 bg-li-blue">{{ metabolic_water[0].nocheIn}}</td>
                         <td class="py-2 bg-li-yellow">{{ metabolic_water[0].totalIn}}</td>
                     </tr>
                     <tr>
                         <td class="py-2 color-main fw-bold" colspan="2">TOTAL INGRESOS</td>
                         <td class="py-2" v-if="columnsInMa.length > 0" :colspan="columnsInMa.length"></td>
                         <td class="py-2 bg-li-blue fw-bold">{{ totalIngresos.manana }}</td>
                         <td class="py-2" v-if="columnsInTa.length > 0" :colspan="columnsInTa.length"></td>
                         <td class="py-2 bg-li-blue fw-bold">{{ totalIngresos.tarde }}</td>
                         <td class="py-2 bg-li-yellow fw-bold">{{ totalIngresos.parcial }}</td>
                         <td class="py-2" v-if="columnsInNo.length > 0" :colspan="columnsInNo.length"></td>
                         <td class="py-2 bg-li-blue fw-bold">{{ totalIngresos.noche }}</td>
                         <td class="py-2 bg-li-yellow fw-bold">{{ totalIngresos.total }}</td>
                     </tr>
                 </tbody>
             </table>
           </div>
    
            <!-- Tabla de Egresos -->
            <div class="card-header bg-main text-white text-center py-1 mt-3">
                <h5 class="mb-0">EGRESOS</h5>
            </div>
            <div class="table-responsive">
                <table class="tablesheet table-bordered text-center border-main mt-2 mb-2 text-xs table-padding">
                    <thead>
                        <tr>
                            <th v-for="(col, index) in columns" :key="index" :colspan="index === 0 ? 2 : 1" class="py-2">
                                {{ col.label }}
                            </th>
                            <th v-for="(col, index) in columnsEgMa" :key="'maEg' + index" class="py-2">
                                <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                    <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'egMa'" @update-label="updateHour" />
                                </template>
                                <template v-else>
                                    {{ col.label }}
                                </template>
                            </th>
                            <th class="py-2 bg-li-blue nowrap">6 HRS</th>
                            <th v-for="(col, index) in columnsEgTa" :key="'taEg' + index" class="py-2">
                                <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                    <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'egTa'" @update-label="updateHour" />
                                </template>
                                <template v-else>
                                    {{ col.label }}
                                </template>
                            </th>
                            <th class="py-2 bg-li-blue nowrap">6 HRS</th>
                            <th class="py-2 bg-li-yellow nowrap">12 HRS</th>
                            <th v-for="(col, index) in columnsEgNo" :key="'noEg' + index" class="py-2">
                                <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                                    <editable-cell :typeInput="'hour'" :value="col.label" :index="index" :turno="'egNo'" @update-label="updateHour" />
                                </template>
                                <template v-else>
                                    {{ col.label }}
                                </template>
                            </th>
                            <th class="py-2 bg-li-blue nowrap">12 HRS</th>
                            <th class="py-2 bg-li-yellow nowrap">24 HRS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, rowIndex) in egresoRows" :key="rowIndex">
                            <template v-if="isFirstRowOfGroup(rowIndex, 'egreso')">
                                <td :rowspan="getRowSpan(rowIndex, 'egreso')" class="color-main">
                                    <div class="py-2 px-3 fw-semibold">{{ getRowTitle(rowIndex, 'egreso') }}</div>
                                </td>
                            </template>
                            <td v-for="(col, colIndex) in columns" :key="colIndex">
                                <div class="fw-semibold" :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                    {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                                </div>
                            </td>
                            <td v-for="(col, colIndex) in columnsEgMa" :key="'maEg' + colIndex">
                                <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                    {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                                </div>
                            </td>
                            <td class="py-2 bg-li-blue">{{ row.totalMa }}</td>
                            <td v-for="(col, colIndex) in columnsEgTa" :key="'taEg' + colIndex">
                                <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                    {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                                </div>
                            </td>
                            <td class="py-2 bg-li-blue">{{ row.totalTa }}</td>
                            <td class="py-2 bg-li-yellow">{{ row.totalDiurno }}</td>
                            <td v-for="(col, colIndex) in columnsEgNo" :key="'noEg' + colIndex">
                                <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                                    {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                                </div>
                            </td>
                            <td class="py-2 bg-li-blue">{{ row.totalNo }}</td>
                            <td class="py-2 bg-li-yellow">{{ row.total }}</td>
                        </tr>
                        <tr>
                            <td class="py-2 color-main fw-semibold" colspan="2">PERDIDAS INSENSIBLES</td>
                            <td class="py-2" v-if="columnsEgMa.length > 0" :colspan="columnsEgMa.length"></td>
                            <td class="py-2 bg-li-blue">{{ insensible_losses[0].mananaEg}}</td>
                            <td class="py-2" v-if="columnsEgTa.length > 0" :colspan="columnsEgTa.length"></td>
                            <td class="py-2 bg-li-blue">{{ insensible_losses[0].tardeEg}}</td>
                            <td class="py-2 bg-li-yellow">{{ insensible_losses[0].parcialEg}}</td>
                            <td class="py-2" v-if="columnsEgNo.length > 0" :colspan="columnsEgNo.length"></td>
                            <td class="py-2 bg-li-blue">{{ insensible_losses[0].nocheEg}}</td>
                            <td class="py-2 bg-li-yellow">{{ insensible_losses[0].totalEg}}</td>
                        </tr>
                        <tr>
                            <td class="py-2 color-main fw-bold" colspan="2">TOTAL EGRESOS</td>
                            <td class="py-2" v-if="columnsEgMa.length > 0" :colspan="columnsEgMa.length"></td>
                            <td class="py-2 bg-li-blue fw-bold">{{ totalEgresos.manana }}</td>
                            <td class="py-2" v-if="columnsEgTa.length > 0" :colspan="columnsEgTa.length"></td>
                            <td class="py-2 bg-li-blue fw-bold">{{ totalEgresos.tarde }}</td>
                            <td class="py-2 bg-li-yellow fw-bold">{{ totalEgresos.parcial }}</td>
                            <td class="py-2" v-if="columnsEgNo.length > 0" :colspan="columnsEgNo.length"></td>
                            <td class="py-2 bg-li-blue fw-bold">{{ totalEgresos.noche }}</td>
                            <td class="py-2 bg-li-yellow fw-bold">{{ totalEgresos.total }}</td>
                        </tr>
                        <tr>
                            <td class="py-2 fw-bold text-white bg-main" colspan="2">BALANCE HIDRICO</td>
                            <td class="py-2" v-if="columnsEgMa.length > 0" :colspan="columnsEgMa.length"></td>
                            <td class="py-2 bg-li-blue color-main fw-bold">{{ totalBalance.manana }}</td>
                            <td class="py-2" v-if="columnsEgTa.length > 0" :colspan="columnsEgTa.length"></td>
                            <td class="py-2 bg-li-blue color-main fw-bold">{{ totalBalance.tarde }}</td>
                            <td class="py-2 bg-li-yellow color-main fw-bold">{{ totalBalance.parcial }}</td>
                            <td class="py-2" v-if="columnsEgNo.length > 0" :colspan="columnsEgNo.length"></td>
                            <td class="py-2 bg-li-blue color-main fw-bold">{{ totalBalance.noche }}</td>
                            <td class="py-2 bg-li-yellow color-main fw-bold">{{ totalBalance.total }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";
import EditableCell from '@/components/shared/column/EditableLabel.vue';
export default {
    data() {
        return {
            swal: null,
            data: [],
            columns: [],
            rows: [],
            rowGroups: [],
            parsedIngresoData: {}, 
            parsedEgresoData: {}, 
            columnsInMa: [], 
            columnsInTa: [],
            columnsInNo: [], 
            columnsEgMa: [],
            columnsEgTa: [], 
            columnsEgNo: [],
            ingresoRows: [], 
            egresoRows: [],
            //Balance
            metabolic_water: [],
            insensible_losses: [],
            valueInMa: [],
            valueInTa: [],
            valueInNo: [],
            valueEgMa: [],
            valueEgTa: [],
            valueEgNo: [],
            //Variable Hora
            isToday: false,
        };
    },
    props: {
        sendSheet: {
            type: Array
        },
        pos: Number,
        medical_act_id: {
            type: Number,
            default: 0
        },
        pageOrigin: {
            type: String,
            default: ''
        },
        rolname: {
            type: String,
            default: ''
        },
        datatime: {
            type: Array
        },
        ultimoId: Number,
        ultimoHourUci: String,
        variable: Number,
    },
    components: {
        SweetAlert,
        EditableCell
    },
    watch: {
        sendSheet: {
            handler: function (newValue) {
                if (newValue && newValue.length > 0) {
                    const updatedItems = newValue.map(item => {
                    return { ...item, checked: (item.status == 1) };
                    });
                    this.data = updatedItems;
                    this.mtgetsheet();
                    this.calculateSumIn();
                    this.calculateSumEg();
                    /* if(this.variable == 1){
                        this.calculateTotal();
                    } */
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        totalIngresos() {
            return {
                manana: parseFloat((this.ingresoRows.reduce((sum, row) => sum + (row.totalMa || 0), 0) + parseFloat(this.metabolic_water[0]?.mananaIn || 0)).toFixed(2)),
                tarde: parseFloat((this.ingresoRows.reduce((sum, row) => sum + (row.totalTa || 0), 0) + parseFloat(this.metabolic_water[0]?.tardeIn || 0)).toFixed(2)),
                parcial: parseFloat((this.ingresoRows.reduce((sum, row) => sum + (row.totalDiurno || 0), 0) + parseFloat(this.metabolic_water[0]?.parcialIn || 0)).toFixed(2)),
                noche: parseFloat((this.ingresoRows.reduce((sum, row) => sum + (row.totalNo || 0), 0) + parseFloat(this.metabolic_water[0]?.nocheIn || 0)).toFixed(2)),
                total: parseFloat((this.ingresoRows.reduce((sum, row) => sum + (row.total || 0), 0) + parseFloat(this.metabolic_water[0]?.totalIn || 0)).toFixed(2)),
            };
        },
        totalEgresos() {
            return {
                manana: parseFloat((this.egresoRows.reduce((sum, row) => sum + (row.totalMa || 0), 0) + parseFloat(this.insensible_losses[0]?.mananaEg || 0)).toFixed(2)),
                tarde: parseFloat((this.egresoRows.reduce((sum, row) => sum + (row.totalTa || 0), 0) + parseFloat(this.insensible_losses[0]?.tardeEg || 0)).toFixed(2)),
                parcial: parseFloat((this.egresoRows.reduce((sum, row) => sum + (row.totalDiurno || 0), 0) + parseFloat(this.insensible_losses[0]?.parcialEg || 0)).toFixed(2)),
                noche: parseFloat((this.egresoRows.reduce((sum, row) => sum + (row.totalNo || 0), 0) + parseFloat(this.insensible_losses[0]?.nocheEg || 0)).toFixed(2)),
                total: parseFloat((this.egresoRows.reduce((sum, row) => sum + (row.total || 0), 0) + parseFloat(this.insensible_losses[0]?.totalEg || 0)).toFixed(2)),
            };
        },
        totalBalance() {
            return {
                manana: parseFloat((this.totalIngresos.manana - this.totalEgresos.manana).toFixed(2)),
                tarde: parseFloat((this.totalIngresos.tarde - this.totalEgresos.tarde).toFixed(2)),
                parcial: parseFloat((this.totalIngresos.parcial - this.totalEgresos.parcial).toFixed(2)),
                noche: parseFloat((this.totalIngresos.noche - this.totalEgresos.noche).toFixed(2)),
                total: parseFloat((this.totalIngresos.total - this.totalEgresos.total).toFixed(2)),
            };
        },
       /*  isToday() {
            const currentDate = moment().format('YYYY-MM-DD');
            return this.data[0].date_entry === currentDate;
        } */
    },
    methods: {
        ...mapActions(['get', 'post']),

        isFirstRowOfGroup(rowIndex, type) {
            const rowGroups = type === 'ingreso' ? this.ingresoGroups : this.egresoGroups;
            let cumulativeRows = 0;
            for (let groupSize of rowGroups) {
                if (rowIndex === cumulativeRows) {
                    return true;
                }
                cumulativeRows += groupSize;
            }
            return false;
        },
        getRowSpan(rowIndex, type) {
            const rowGroups = type === 'ingreso' ? this.ingresoGroups : this.egresoGroups;
            let cumulativeRows = 0;
            for (let groupSize of rowGroups) {
                if (rowIndex === cumulativeRows) {
                    return groupSize;
                }
                cumulativeRows += groupSize;
            }
            return 1;
        },

        getRowTitle(rowIndex, type) {
            const parsedData = type === 'ingreso' ? this.parsedIngresoData : this.parsedEgresoData;
            const titlesMap = {
                valuesParen: 'PARENTERAL',
                valuesSedo: 'SEDOANALGESIA',
                valuesIno: 'INOTRÓPICOS',
                valuesVasoActivo: 'VASOACTIVOS',
                valuesReto: 'RETOS',
                valuesMani: 'MANITOL/SOL. HIPERTÓNICA',
                valuesHemo: 'HEMODERIVADOS',
                valuesTrata: 'TRATAMIENTO EV',
                valuesEn: 'ENTERAL',
                valuesDiu: 'DIURESIS',
                valuesDep: 'DEPOSICIÓN',
                valuesVom: 'VÓMITO',
                valuesDre: 'DRENAJE',
                valuesTem: 'TEMPERATURA',
                valuesSeBo: 'SECRECIÓN POR BOCA',
                valuesSeTra: 'SECRECIÓN TRAQUEAL',
                valuesPe: 'PERIODO MENSTRUAL'
            };
            let cumulativeRows = 0;
            for (const [key, valueArray] of Object.entries(parsedData)) {
                const groupSize = valueArray.length;
                if (rowIndex === cumulativeRows) {
                    return titlesMap[key] || 'OTROS';
                }
                cumulativeRows += groupSize;
            }
            return 'OTROS';
        },

        mtgetsheet() {
            this.processNewItems();
            this.columns = [{ label: "", field: "name" }];
            this.columnsInMa = [];
            this.columnsInTa = [];
            this.columnsInNo = [];
            this.columnsEgMa = [];
            this.columnsEgTa = [];
            this.columnsEgNo = [];


            this.ingresoRows.forEach(row => Object.keys(row).forEach(key => delete row[key]));
            this.egresoRows.forEach(row => Object.keys(row).forEach(key => delete row[key]));

            this.createRow(); 

            // Procesar datos
            this.data.forEach((element, index) => {
                const newField = `value${index}`;
                let period = "";

                // Determinar el período del día
                if (element.hourUci >= "07:31:00" && element.hourUci < "13:31:00") {
                    period = "Ma";
                } else if (element.hourUci >= "13:31:00" && element.hourUci < "19:30:00") {
                    period = "Ta";
                } else {
                    period = "No";
                }

                // Agregar columna según el período
                const formattedHour = this.formatHour(element.hourUci);
                if (period === "Ma") {
                    this.columnsInMa.push({ label: formattedHour, field: newField, id: element.id});
                    this.columnsEgMa.push({ label: formattedHour, field: newField, id: element.id});
                } else if (period === "Ta") {
                    this.columnsInTa.push({ label: formattedHour, field: newField, id: element.id});
                    this.columnsEgTa.push({ label: formattedHour, field: newField, id: element.id});
                } else {
                    this.columnsInNo.push({ label: formattedHour, field: newField, id: element.id });
                    this.columnsEgNo.push({ label: formattedHour, field: newField, id: element.id });
                }

                // Procesar filas de ingreso y egreso
                const parsedIngreso = JSON.parse(element.dataIngreso);
                const parsedEgreso = JSON.parse(element.dataEgreso);

                const allIn = Object.values(parsedIngreso).flat();
                const allEg = Object.values(parsedEgreso).flat();

                this.ingresoRows.forEach((row, rowIndex) => {
                    let value = allIn[rowIndex] ? allIn[rowIndex].value : "";
                    this.$set(row, newField, value);
                });
                this.egresoRows.forEach((row, rowIndex) => {
                    let value = allEg[rowIndex] ? allEg[rowIndex].value : "";
                    this.$set(row, newField, value);
                });
                const dataMeta = JSON.parse(this.data[0].metabolic_water);
                const dataPerdi = JSON.parse(this.data[0].insensible_losses);
                this.metabolic_water = dataMeta;
                this.insensible_losses =  dataPerdi;
            });
        },
        
        calculateSumIn () {
            this.clearIn();
            for (let m = 0; m < this.columnsInMa.length; m++) {
                this.valueInMa.push({ value: this.columnsInMa[m].field});
            }
            for (let t = 0; t < this.columnsInTa.length; t++) {
                this.valueInTa.push({ value: this.columnsInTa[t].field});
            }
            for (let n = 0; n < this.columnsInNo.length; n++) {
                this.valueInNo.push({ value: this.columnsInNo[n].field});
            }
            for (let i = 0; i < this.ingresoRows.length; i++) {
                let sumMa = 0;
                for (let j = 0; j < this.valueInMa.length; j++) {
                    let e = this.valueInMa[j].value;
                    if(parseFloat(this.ingresoRows[i][e])){
                        sumMa += parseFloat(this.ingresoRows[i][e]);
                    }
                }
                this.ingresoRows[i].totalMa = sumMa;

                let sumTa = 0;
                for (let j = 0; j < this.valueInTa.length; j++) {
                    let e = this.valueInTa[j].value;
                    if(parseFloat(this.ingresoRows[i][e])){
                        sumTa += parseFloat(this.ingresoRows[i][e]);
                    }
                }
                this.ingresoRows[i].totalTa = sumTa;

                let sumNo = 0;
                for (let j = 0; j < this.valueInNo.length; j++) {
                    let e = this.valueInNo[j].value;
                    if(parseFloat(this.ingresoRows[i][e])){
                        sumNo += parseFloat(this.ingresoRows[i][e]);
                    }
                }
                this.ingresoRows[i].totalNo = sumNo;


                this.ingresoRows[i].totalDiurno = this.ingresoRows[i].totalMa + this.ingresoRows[i].totalTa;
                this.ingresoRows[i].total = this.ingresoRows[i].totalDiurno + this.ingresoRows[i].totalNo;
            }
        },

        calculateSumEg () {
            this.clearEg();
            for (let m = 0; m < this.columnsEgMa.length; m++) {
                this.valueEgMa.push({ value: this.columnsEgMa[m].field});
            }
            for (let t = 0; t < this.columnsEgTa.length; t++) {
                this.valueEgTa.push({ value: this.columnsEgTa[t].field});
            }
            for (let n = 0; n < this.columnsEgNo.length; n++) {
                this.valueEgNo.push({ value: this.columnsEgNo[n].field});
            }
            for (let i = 0; i < this.egresoRows.length; i++) {
                let sumMaEg = 0;
                for (let j = 0; j < this.valueEgMa.length; j++) {
                    let e = this.valueEgMa[j].value;
                    if(parseFloat(this.egresoRows[i][e])){
                        sumMaEg += parseFloat(this.egresoRows[i][e]);
                    }
                }
                this.egresoRows[i].totalMa = sumMaEg;

                let sumTaEg = 0;
                for (let j = 0; j < this.valueEgTa.length; j++) {
                    let e = this.valueEgTa[j].value;
                    if(parseFloat(this.egresoRows[i][e])){
                        sumTaEg += parseFloat(this.egresoRows[i][e]);
                    }
                }
                this.egresoRows[i].totalTa = sumTaEg;

                let sumNoEg = 0;
                for (let j = 0; j < this.valueEgNo.length; j++) {
                    let e = this.valueEgNo[j].value;
                    if(parseFloat(this.egresoRows[i][e])){
                        sumNoEg += parseFloat(this.egresoRows[i][e]);
                    }
                }
                this.egresoRows[i].totalNo = sumNoEg;


                this.egresoRows[i].totalDiurno = this.egresoRows[i].totalMa + this.egresoRows[i].totalTa;
                this.egresoRows[i].total = this.egresoRows[i].totalDiurno + this.egresoRows[i].totalNo;
            }
        },

        createRow() {
            this.ingresoRows = [];
            this.egresoRows = [];
            if (!this.data.length) return;

            // Tomar el último registro
            const lastEntry = this.data[this.data.length - 1];
            const parsedIngreso = JSON.parse(lastEntry.dataIngreso || '{}');
            const parsedEgreso = JSON.parse(lastEntry.dataEgreso || '{}');

            // Procesar ingresos
            this.parsedIngresoData = parsedIngreso;
            for (let key of Object.keys(parsedIngreso)) {
                const groupData = parsedIngreso[key];
                groupData.forEach(element => {
                    this.ingresoRows.push({
                        name: element.label,
                        totalMa: 0,
                        totalTa: 0,
                        totalNo: 0,
                        totalDiurno: 0,
                        total: 0,
                        value0: element.value
                    });
                });
            }
            this.ingresoGroups = Object.values(parsedIngreso).map(group => group.length);

            // Procesar egresos
            this.parsedEgresoData = parsedEgreso;
            for (let key of Object.keys(parsedEgreso)) {
                const groupData = parsedEgreso[key];
                groupData.forEach(element => {
                    this.egresoRows.push({
                        name: element.label,
                        totalMa: 0,
                        totalTa: 0,
                        totalNo: 0,
                        totalDiurno: 0,
                        total: 0,
                        value0: element.value 
                    });
                });
            }
            this.egresoGroups = Object.values(parsedEgreso).map(group => group.length);
        },

        processNewItems() {
            const ingresoKeys = [
                "valuesParen", "valuesSedo", "valuesIno", "valuesVasoActivo",
                "valuesReto", "valuesMani", "valuesHemo", "valuesTrata", "valuesEn"
            ];
            const egresoKeys = [
                "valuesDiu", "valuesDep", "valuesVom", "valuesDre",
                "valuesTem", "valuesSeBo", "valuesSeTra", "valuesPe"
            ];

            //Ingreso
            this.data.forEach((record) => {
                ingresoKeys.forEach((key) => {
                    const currentData = JSON.parse(record.dataIngreso || "{}")[key] || [];
                    
                    //Encuentra los elementos nuevos
                    const newItems = currentData.filter(item => item.new);

                    this.data.forEach((entry) => {
                        const entryData = JSON.parse(entry.dataIngreso || "{}");
                        entryData[key] = entryData[key] || [];

                        //Agrega elementos nuevos
                        newItems.forEach((newItem) => {
                            if (!entryData[key].some(item => item.id === newItem.id)) {
                                entryData[key].push({
                                    id: newItem.id,
                                    label: newItem.label,
                                    value: null,
                                    value2: null
                                });
                            }
                        });
                        entry.dataIngreso = JSON.stringify(entryData);
                    });
                });
            });

            //Egreso
            this.data.forEach((record) => {
                egresoKeys.forEach((key) => {
                    const currentData = JSON.parse(record.dataEgreso || "{}")[key] || [];
                    
                    //Encuentra los elementos nuevos
                    const newItems = currentData.filter(item => item.new);

                    this.data.forEach((entry) => {
                        const entryData = JSON.parse(entry.dataEgreso || "{}");
                        entryData[key] = entryData[key] || [];

                        //Agrega elementos nuevos
                        newItems.forEach((newItem) => {
                            if (!entryData[key].some(item => item.id === newItem.id)) {
                                entryData[key].push({
                                    id: newItem.id,
                                    label: newItem.label,
                                    value: null,
                                    value2: null
                                });
                            }
                        });
                        entry.dataEgreso = JSON.stringify(entryData);
                    });
                });
            });
        },

        updateHour: function(value,index,array,turno){
            let id = null;
            switch (turno) {
                case 'inMa':
                    this.columnsInMa[index].label = value;
                    id = this.columnsInMa[index].id;
                    break;
                case 'inTa':
                    this.columnsInTa[index].label = value;
                    id = this.columnsInTa[index].id;
                    break;
                case 'inNo':
                    this.columnsInNo[index].label = value;
                    id = this.columnsInNo[index].id;
                    break;
                case 'egMa':
                    this.columnsEgMa[index].label = value;
                    id = this.columnsEgMa[index].id;
                    break;
                case 'egTa':
                    this.columnsEgTa[index].label = value;
                    id = this.columnsEgTa[index].id;
                    break;
                case 'egNo':
                    this.columnsEgNo[index].label = value;
                    id = this.columnsEgNo[index].id;
                    break;
                default:
                    console.error('Turno no válido:', turno);
                    return; 
            }
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/updateHourBalance",
                token: this.$store.getters.get__token,
                params: {
                    id: id,
                    hour: value,
                }
            })
            .then((response) => {
                if(response.statusCode == 200){
                    this.$emit("calculateBalanceUci", this.medical_act_id);
                    this.$emit("mtgetsheet");
                    this.$refs.SweetAlert.showSuccess('HORA EDITADA EXITOSAMENTE');
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        formatHour(hourUci) {
            return hourUci.substring(0, 5); 
        },

        clearIn() {
            this.valueInMa = [];
            this.valueInTa = [];
            this.valueInNo = [];
            this.ingresoRows.forEach(row => {
                row.totalMa = 0;
                row.totalTa = 0;
                row.totalNo = 0;
                row.totalDiurno = 0;
                row.total = 0;
            });
        },

        clearEg() {
            this.valueEgMa = [];
            this.valueEgTa = [];
            this.valueEgNo = [];
            this.egresoRows.forEach(row => {
                row.totalMa = 0;
                row.totalTa = 0;
                row.totalNo = 0;
                row.totalDiurno = 0;
                row.total = 0;
            });
        },

        calculateTotal() {
            if(this.ultimoHourUci){
                //let dayPage = this.datatime[this.pos].day_page;
                let dayPage = moment().format("YYYY-MM-DD");
                let diferencia = parseFloat(this.totalIngresos.total) - parseFloat(this.totalEgresos.total);
                let resta = diferencia.toFixed(2);

                this.$emit("update_total_balance", resta, this.ultimoHourUci, dayPage, this.medical_act_id);
            }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + " pm";
            }
        },
    },
};
</script>
<style scoped>
.table-padding th,
.table-padding td {
  padding: 6px; 
}
.nowrap{
    white-space: nowrap;
}
.table-responsive {
  overflow-x: auto;
}
</style>

<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row">
        <!-- <div
        style="border: none"
        class="close d-flex justify-content-end"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="mtdClose"
      >
        &times;
      </div> -->

        <div>
            <ul class="nav pb-0" id="myTab" role="tablist">
                <li v-for="(item, index) in carrito" :key="index" class="nav-item text-xs me-1" role="presentation">
                    <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                        {{ item.cabecera.name }}
                    </button>
                </li>
            </ul>
            <div class="tab-content-oc" v-if="tablaDetail.length > 0">
                <div v-for="(item, index) in tablaDetail" :key="index">
                    <div class="row d-flex mod-h py-3 px-4 mx-1">
                        <div class="row d-flex w-100 mt-4 responsive-proveedor" id="exampleModalLabel">
                            <div class="col-auto d-none  d-xl-inline">
                                <div class=" text-center bg-main rounded-circle p-3 d-flex align-items-center justify-content-center">
                                    <i class="fas fa-shopping-cart text-white"></i>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-4 col-md-6 px-2 py-2">
                                <div class="d-flex ms-2 w-100">
                                    <div class="form-inline">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" v-model="money.name" value="SOLES" @change="updateMoney()">
                                            <label class="form-check-label" for="inlineRadio1">SOLES</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" v-model="money.name" value="DOLARES" @change="updateMoney()">
                                            <label class="form-check-label" for="inlineRadio2">DOLARES</label>
                                        </div>
                                    </div>
                                    <input  type="number" v-model="money.type" :disabled="money.name === 'SOLES'"  placeholder="Moneda" class="form-control w-25 form-control-sm ms-2"
                                    id="monedaInput"  step="0.1" @input="validateInput('type')"/>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 bg-white d-flex py-2 px-4">
                                <div>OBSERVACIONES:</div>
                                <div class="ms-2 w-100">
                                    <input v-model="item.cabecera.observation" class="form-control form-control-sm" id="exampleFormControlTextarea1" />
                                </div>
                            </div>
                            <div class="col-md-12 col-xl-6">
                                <div class="row d-flex">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="bg-white d-flex py-2 me-2 responsive-contador">
                                            <div>COSTO ENVIO S/:</div>
                                            <div class="ms-2">
                                                <input v-model="item.cabecera.send" @input="validateAmountCostSend" class="form-control form-control-sm costo-de-envio" />
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="item.cabecera.payment_condition == 1 ? 'col-md-6' : 'col-md-8'">
                                        <div class="bg-white d-flex p-2 me-2 responsive-contador" style="border-radius: 10px">
                                            <div><label for="condition" class="">CONDICIÓN DE CONTRATO:</label></div>
                                            <div class="ms-2">
                                                <select id="condition" v-model="item.cabecera.payment_condition" class="form-control form-control-sm costo-de-envio" aria-label=".form-select-sm example">
                                                    <option value="" selected disabled>Seleccione...</option>
                                                    <option v-for="(item,index) in optionCondition" :key="index" :value="item.id">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="item.cabecera.payment_condition == 1 ? 'col-md-2' : ''" v-show="item.cabecera.payment_condition == 1">
                                        <div class="bg-white d-flex py-2 me-2 responsive-contador">
                                            <div>DIAS</div>
                                            <div class="ms-2">
                                                <input v-model="item.cabecera.expiration_days"  @keydown="filterKey" class="form-control form-control-sm costo-de-envio" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-4">
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <p id="mydesc">&nbsp;</p>
                            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                <thead class="bg-main" style="font-size: 12px">
                                    <tr class="text-white text-center" style="vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">GRABADO/ NO GRABADO</th>
                                        <th scope="col">MOLECULA</th>
                                        <th scope="col">COMERCIAL</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">PRESENTACIÓN</th>
                                        <th scope="col" colspan="1">DOSIS</th>
                                        <th scope="col" colspan="1">FORMA</th>
                                        <th scope="col" colspan="1">CANTIDAD</th>
                                        <th scope="col" colspan="1">UNIDAD</th>
                                        <th scope="col" colspan="1">PRECIO CON IGV</th>
                                        <th scope="col" colspan="1">S/ PREC. UNIT. CON IGV</th>
                                        <th scope="col" colspan="1">SUBTOTAL</th>
                                        <th scope="col" colspan="1" class="align-middle">DESCUENTO%</th>
                                        <th scope="col" colspan="1" class="align-middle">S/SUBTOTAL CON DESC</th>
                                        <th scope="col" colspan="1">ACCIONES</th>
                                    </tr>
                                </thead>

                                <tbody class="border-main" style="font-size: 12px; vertical-align: middle">
                                    <tr class="tbody-table1 text-center" v-for="(carro, ind) in item.detalle" :key="ind">
                                        <td>
                                            {{ ind + 1 }}
                                        </td>
                                        <td v-if="carro.cargado_state === null">
                                            <div class="form-check form-switch">
                                                <input v-model="carro.grabado" class="form-check-input" @change="mtdChangeState(index, ind)" type="checkbox" />
                                                {{ carro.cargado == 1 ? "GRAVADO" : "NO GRAVADO" }}
                                            </div>
                                        </td>
                                        <td v-else-if="carro.cargado === 1">GRAVADO</td>
                                        <td v-else>NO GRAVADO</td>
                                        <td>
                                            {{ carro.molecule }}
                                        </td>
                                        <td>
                                            {{ carro.commercial }}
                                        </td>
                                        <td>
                                            {{ carro.brand }}
                                        </td>
                                        <td>
                                            {{ carro.presentation }}
                                        </td>
                                        <td v-if="carro.dosis">
                                            {{ carro.dosis }}
                                        </td>
                                        <td v-else>
                                            <input v-model="carro.dosisedit" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Concentracion" />
                                        </td>
                                        <td v-if="carro.format">
                                            {{ carro.format }}
                                        </td>
                                        <td v-else>
                                            <input v-model="carro.formatedit" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Forma" />
                                        </td>
                                        <td v-if="posEditQuantity1 != ind" @dblclick="mtdEditQuantity(index, ind, 'cantidad')">
                                            {{ carro.quantity }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" v-model="carro.editQuantity" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'cantidad')" @blur="mtdOutFocusEditPrice(index, ind, 'cantidad')" @keypress="isNumber($event)" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="CANTIDAD" />
                                        </td>
                                        <td>
                                            <select class="form-control form-control-sm select-p" aria-label=".form-select-sm example" v-model="carro.unit" @change="mtdSelectUnit(index, ind)">
                                                <option v-for="(un, pos) in carro.units" :key="pos" :value="un.id">
                                                    {{ un.unit.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td v-if="posEditQuantity != ind" @dblclick="mtdEditQuantity(index, ind, 'Precio')">
                                            {{ carro.precio_purchase }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="PRECIO UNITARIO" v-model="carro.editPrecio" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'Precio')
                          " @blur="mtdOutFocusEditPrice(index, ind, 'Precio')" @input="validateAmount" />
                                        </td>
                                        <td>
                                            {{ carro.precio_purchase }}
                                        </td>

                                        <td>
                                            {{ calculateSubtotal(index, ind)}}
                                        </td>
                                        <!--Descuento-->
                                        <td v-if="posEditDiscount != ind" @dblclick="mtdEditQuantity(index, ind, 'Descuento')">
                                            {{ carro.discount !== null ? carro.discount + '%' : '0%' }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Descuento" v-model="carro.editDiscount" @keydown="filterKey" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'Descuento')
                          " @blur="mtdOutFocusEditPrice(index, ind, 'Descuento')" />
                                        </td>
                                        <!--Fin Descuento-->
                                        <td>
                                            {{
                          carro.price_discount = carro.editDiscount
                            ? (carro.price_purchase - (carro.editDiscount / 100 * carro.price_purchase)).toFixed(2)
                            : '0.00'
                        }}
                                        </td>
                                        <td>
                                            <a @click="mtdBeforeDelete(index, ind)" class="a-danger text-white" style="font-size: 14px">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex mt-3 justify-content-end">
                            <div class="px-2 py-1 bg-white">
                                NO GRAVADA {{ money.name !== 'SOLES' ? '$' : 'S/' }}: {{ mtdValornograbado() }}
                            </div>
                            <div class="px-2 py-1 bg-white">
                                VALOR VENTA {{ money.name !== 'SOLES' ? '$' : 'S/' }}: {{ mtdValor() }}
                            </div>
                            <div class="px-2 py-1 bg-white">IGV {{ money.name !== 'SOLES' ? '$' : 'S/' }}: {{ mtdGetIgv() }}</div>
                            <div class="px-2 py-1 mx-1 bg-white">
                                TOTAL C/ IGV: {{ mtdGetTotal() }}
                            </div>
                            <div class="mt-1">
                                <button @click="mtdPreview()" type="button" :disabled="cpDisabled" class="px-3 border-0 text-white bg-main btn-oc">
                                    ENVIAR
                                </button>
                                <div class="text-xxs text-danger mt-2" v-show="cpDisabled">
                                    <span>Hay precios de 0.00</span>
                                </div>
                            </div>
                        </div>
                        <!-- comentario -->
                        <div class="row d-flex mt-2 px-3">
                        <div class="col-sm-12 my-1">
                            <div id="div-story" class="form-group row align-left">
                                <label for="story" class="col-sm-12 col-md-12 color-main fw-bold">COMENTARIO</label>
                                <div class="col-xl-6 col-sm-12 col-md-8 mt-2">
                                    <textarea rows="3" v-model="item.cabecera.comment" placeholder="Comentario" class="form-control text-xs" aria-label="With textarea" id="story"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-viewCarritoOC",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    created() {
      //this.mtdTypeChanges();
    },
    data() {
        return {
            swal: "",
            transaction: {
                type: 1,
                motive: "",
            },
            agregarprovider: {
                comments: "",
                shipping_cost: "",
                payment_type: "",
            },
            optionCondition: [{
                    "id": 1,
                    "name": 'CREDITO'
                },
                {
                    "id": 2,
                    "name": 'CONTADO'
                },
            ],
            posEditQuantity: null,
            posEditQuantity1: null,
            posEditDiscount: null,
            newQuantity: null,
            oldQuantity: null,
            editingField: "",
            newValue: "",
            posOc: 0,
            tablaDetail: [],
            selectOcExecuted: false,
            indexTable: null,
            indexRow: null,
            money: {
                name: 'SOLES',
                type: 1,
                valor: 0,
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        data: {},
        provider: {},
        carrito: {
            type: Array,
            default: () => [],
        },
        numcarrito: {
            type: Number,
            default: 0
        },
        valorType: {
          type: Number,
          default: 0
        },
        valorTypeVen: {
          type: Number,
          default: 0
        }
    },
    watch: {
        //   carrito: {
        //         handler: function (newValue, oldValue) {
        //             if (newValue.length > 0 && !this.selectOcExecuted) {
        //                 this.selectOc(0);
        //                 this.selectOcExecuted = true;
        //             }
        //         },
        //         deep: true,
        //         immediate: true,
        //     },
        numcarrito: {
            handler: function (newValue, oldValue) {
                if (newValue > 0) {
                    this.selectOc(newValue - 1);

                }
            },
            deep: true,
            immediate: true,
        }
        //nestedArray: Array(5).fill().map(() => Array(3).fill(true))
    },

    computed: {
        cpDisabled() {
            return this.carrito.some(element =>
                element.detalle.some(detail => detail.precio_purchase === '0.00')
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (!b && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdSelectType: function (value) {
            this.transaction.type = value;
        },
        mtdClose() {
            this.money.name = 'SOLES';
            this.money.type = 1;
            this.$emit("closeModalCarrito");
        },
        mtdclosemodal() {
            this.money.name = 'SOLES';
            this.money.type = 1;
            this.$emit('closeModalCarrito')
        },
        mtdPreview: function () {
            let isValid = this.carrito.every(element => {
                const { payment_condition, expiration_days } = element.cabecera;
                return payment_condition !== "" && 
                (payment_condition == 1 ? expiration_days !== "" : payment_condition == 2);
            });

            if (isValid) {
                this.$emit("closeModalCarrito", 'preview', this.money);
            } else {
                this.$refs.SweetAlert.showWarning('Completar información sobre la condición de contrato');
            }

        },
        mtdInsertData: function () {
            this.selectOcExecuted = false
            this.carrito.forEach((carro) => {
                carro.detalle.forEach((detalleItem, ind) => {
                    detalleItem.formatedit = !detalleItem.format ?
                        detalleItem.formatedit :
                        detalleItem.format;
                    detalleItem.dosisedit = !detalleItem.dosis ?
                        detalleItem.dosisedit :
                        detalleItem.dosis;
                });
            });

            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/oc/store",
                    token: this.$store.getters.get__token,
                    params: {
                        carrito: this.carrito,
                        type: this.money.type,
                        name: this.money.name,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index];
                            window.open(
                                this.$store.getters.get__url +
                                "/Pharmacy/oc/viewpdf/" +
                                element,
                                "_blank"
                            );
                        }
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.selectOcExecuted = false
                        this.$emit("registro-exitoso");
                        this.mtdClose();
                        this.tablaDetail = [];
                        //     this.carrito.forEach((carro) => {
                        //         carro.detalle.forEach((detalleItem, ind) => {

                        //             detalleItem.format = ''
                        //             detalleItem.dosis = ''
                        //             detalleItem.formatedit = ''
                        //             detalleItem.dosisedit = ''
                        //         });
                        //    });
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto!",
                            text: "Error!",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {});
        },
        mtdEditQuantity: function (index, pos, edit) {
            if (edit === "Precio") {
                this.posEditQuantity = pos;
                this.posEditQuantity1 = -1;
                // Inicializa editPrecio con el valor actual
                this.tablaDetail[index].detalle[pos].editPrecio =
                    this.tablaDetail[index].detalle[pos].precio_purchase;
            } else if (edit === "cantidad") {
                this.posEditQuantity1 = pos;
                this.posEditQuantity = -1;
                // Inicializa editQuantity con el valor actual
                this.tablaDetail[index].detalle[pos].editQuantity =
                    this.tablaDetail[index].detalle[pos].quantity;
            } else if (edit === 'Descuento') {
                this.posEditDiscount = pos;
                this.posEditQuantity1 = -1;
                this.tablaDetail[index].detalle[pos].editDiscount =
                    this.tablaDetail[index].detalle[pos].discount;
            }
            this.indexRow = pos;
        },
        calculateSubtotal(ind, index) {
            const precioUnitario =
                parseFloat(this.tablaDetail[ind].detalle[index].precio_purchase) || 0;
            const cantidad =
                parseInt(this.tablaDetail[ind].detalle[index].quantity) || 0;
            const subtotal = precioUnitario * cantidad;
            this.tablaDetail[ind].detalle[index].price_purchase = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        mtdOutFocusEditPrice(ind, index, field) {
            if (index >= 0 && index < this.tablaDetail[ind].detalle.length) {
                if (field === "Precio") {
                    const newPrecio = parseFloat(
                        this.tablaDetail[ind].detalle[index].editPrecio
                    );
                    if (!isNaN(newPrecio)) {
                        this.tablaDetail[ind].detalle[index].precio_purchase =
                            newPrecio.toFixed(2);
                        this.mtdSelectUnit(ind, index);
                    }
                } else if (field === "cantidad") {
                    const newQuantity = parseInt(
                        this.tablaDetail[ind].detalle[index].editQuantity
                    );
                    if (!isNaN(newQuantity)) {
                        this.tablaDetail[ind].detalle[index].quantity = newQuantity;
                    }
                    this.mtdSelectUnit(ind, index);
                } else if (field === 'Descuento') {
                    const newDiscount = parseInt(this.tablaDetail[ind].detalle[index].editDiscount);
                    if (!isNaN(newDiscount)) {
                        this.tablaDetail[ind].detalle[index].discount = newDiscount.toFixed(2);
                    }
                }
                this.calculateSubtotal(ind, index);
                this.posEditDiscount = -1;
                this.posEditQuantity = -1;
                this.posEditQuantity1 = -1;
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            this.carrito[this.posOc].detalle[this.indexRow].editPrecio = sanitizedValue;
        },
        validateAmountCostSend(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.carrito[this.posOc].cabecera.send = sanitizedValue;
        },
        mtdChangeState: function (index, ind) {
            this.tablaDetail[index].detalle[ind].cargado = this.tablaDetail[index]
                .detalle[ind].grabado ?
                1 :
                0;
        },
        mtdBeforeDelete: function (index, ind) {
            if (this.tablaDetail[index].detalle.length === 1) {
                this.selectOcExecuted = false
                if (this.carrito.length === 1) {
                    this.carrito.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    this.mtdClose();
                } else {
                    this.carrito.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    if (this.posOc - 1 < 0) {
                        this.selectOc(this.posOc)
                    } else {
                        this.selectOc(this.posOc - 1)
                    }

                }
            } else {
                this.tablaDetail[index].detalle.splice(ind, 1);
            }

        },
        /** nuevo oc */
        selectOc: function (pos) {
            if (pos >= 0 && pos < this.carrito.length) {
                this.tablaDetail = [];
                this.posOc = pos;
                this.tablaDetail.push(this.carrito[this.posOc]);
            }
        },
        mtdGetTotal: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                let valornogravada = 0;
                let valor2 = 0;
                let valornogravada2 = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        let precio_sin_igv = element.price_purchase/1.18;
                        let precioDes_sin_igv = element.price_discount/1.18;
                        //valor2 += parseFloat(element.discount !== null ? precioDes_sin_igv : precio_sin_igv);
                        valornogravada2 += parseFloat(element.discount !== null ? precioDes_sin_igv : precio_sin_igv);
                        valornogravada =  valornogravada2;
                    }
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                        valor = valor2;
                    }
                });
                //let igv = valor * 0.18;
                let total =
                    //parseFloat(valor) + parseFloat(igv) + parseFloat(valornogravada);
                    parseFloat(valor) +  parseFloat(valornogravada);
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValornograbado: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                let valor2 = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        let precio_sin_igv = element.price_purchase/1.18;
                        let precioDes_sin_igv = element.price_discount/1.18;
                        valor2 += parseFloat(element.discount !== null ? precioDes_sin_igv : precio_sin_igv);
                    }
                });
                valor = valor2;
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValor: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                let valor2 = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                valor = valor2 - this.mtdGetIgv();
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdGetIgv: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                let valor2 = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                //valor = valor2 * this.money.type;
                //valor = valor2;
                let igv = (valor2 - (valor2 / 1.18)).toFixed(2);
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdSelectUnit: function (index, pos) {
            let cantidadUnidades = 1;
            this.tablaDetail[index].detalle[pos].units.forEach((element) => {
                if (element.id == this.tablaDetail[index].detalle[pos].unit) {
                    cantidadUnidades = element.unit.quantity;
                }
            });
            this.tablaDetail[index].detalle[pos].precio_unitario = parseFloat(
                this.tablaDetail[index].detalle[pos].precio_purchase / cantidadUnidades
            ).toFixed(2);
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdTypeChanges() {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/typeChanges",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                  this.money.valor = response.compra;
                })
                .catch((errors) => {});
        },
        updateMoney() {
          if(this.money.name == 'DOLARES'){
            this.money.type = this.valorTypeVen;
          }
          else if (this.money.name == 'SOLES') {
            this.money.type = 1;
          }
        },
        validateInput(field) {
            if (this.money[field] < 0) {
                this.money[field] = 0;
            }
        },
    },
};
</script>

<!-- <style scoped>


.h4-borde {
    border-radius: 50px;
}


.nav-link:hover {
    background: #900052 !important;
}

.nav-link.active {
    background: #900052;
}

.active {
    background-color: #900052 !important;
}

#btn-remove {
    color: #ff0000;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-remove:hover {
    color: #a30404;
}

</style> -->

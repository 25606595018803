<template>
<div class="h-100 py-3">
    <div class="px-2">
        <div class="d-xl-flex justify-content-between">
            <div v-if="this.type_patient == 'particular'" class="">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span>{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span>{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span>{{ formatDate(this.date_out) }}</span>
                    </div>
                </div>
            </div>
            <div v-if="this.type_patient == 'eps'" class="">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span>{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">CONVENIO: </span><span>{{ this.insurance_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span>{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span>{{ formatDate(this.date_out) }}</span>
                    </div>

                </div>
            </div>
            <div v-if=" rolname == 'ENFERMERA(O)'">
                <div class="d-flex justify-content-end">
                    <button v-if="datasheets.length > 0" @click="mtdaddgrafica" title="Guardar" class="btn btn-sm btn-success text-white px-3 ">
                        Hoja Grafica
                    </button>
                </div>
            </div>

        </div>
        <div v-if="!( rolname !== 'ENFERMERA(O)')" class="d-xl-flex justify-content-between">
            <div class="form-group  d-flex align-left me-xl-2 me-0">
                <div class="w-100">
                    <label for="peso" class="color-main fw-bold me-3 ">Peso (Kg)</label>
                    <div>
                        <input v-model="graphic_sheets.weight" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm w-100" id="peso">
                    </div>

                </div>
            </div>
            <div v-for="(item,index) in graphic_sheets.values" :key="index" class="form-group  d-flex align-left me-xl-3 me-0 ">
                <div class="w-100">
                    <label :for="'value'+index" class="color-main fw-bold me-2">{{ item.label }}</label>
                    <div class="w-100">
                        <input v-if="item.label !='PAM'" :id="'value'+index" v-model="item.value" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm w-100">
                        <input v-else v-model="cpValuePam" :id="'value'+index" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm w-10" disabled>
                        <!-- <input v-if="item.label =='PAD'" v-model="graphic_sheets.values[0].value2" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm"> -->
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-end mt-xl-0 mt-2">
                <button :disabled="disable" @click="mtdadd" title="Guardar" class="btn btn-sm btn-warning text-white ms-xl-3 ms-0 w-100">
                    <i class="fas fa-save text-black"></i>
                </button>
            </div>
        </div>
        <div class="row mx-0 mb-2 mt-3 px-0 mb-3" id="content-timelinetable">
            <table class="table table-bordered border-main mb-0 px-0 text-xss fw-bold">
                <caption class=" my-0 py-0"></caption>
                <thead class="">
                    <tr class="text-white border-white text-center bg-main">
                        <th rowspan="2" scope="col">FECHA</th>
                        <th rowspan="2" scope="col">SELECCIONE</th>
                        <th rowspan="2" scope="col">HORA</th>
                        <th rowspan="2" scope="col">PA</th>
                        <th rowspan="2" scope="col">PAM</th>
                        <th rowspan="2" scope="col">PULSO X'</th>
                        <th rowspan="2" scope="col">FR X'</th>
                        <th rowspan="2" scope="col">TEMP</th>
                        <th rowspan="2" scope="col">SO2</th>
                    </tr>

                </thead>
                <tbody v-if="this.datasheets.length > 0">
                    <template v-for="(item1) in datasheets">
                        <tr class="px-1 border-bottom">
                            <td class="text-center" :rowspan="item1.sheets.length">{{ item1.date }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">
                                <input class="form-check-input" :id="item1.sheets[0].id" v-model="item1.sheets[0].state" :disabled="item1.sheets[0].status == 1  || rolname != 'ENFERMERA(O)'" type="checkbox" @change="handleCheckboxSelection(item1.date, item1.sheets[0])">
                            </td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].hour }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].pa }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].pam }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].pulso }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].fr }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].temp }}</td>
                            <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].so2 }}</td>

                        </tr>
                        <template v-if="item1.sheets.length > 1">
                            <tr class="px-1 border-bottom" v-for="(item2) in item1.sheets.slice(1)" :key="item2.id">
                                <td class="text-center" :class="item2.color">
                                    <input class="form-check-input" :id="item2.id" v-model="item2.state" :disabled="item2.status == 1  || rolname != 'ENFERMERA(O)'" type="checkbox" @change="handleCheckboxSelection(item1.date, item2)">
                                </td>
                                <td class="text-center" :class="item2.color">{{ item2.hour }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.pa }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.pam }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.pulso }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.fr }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.temp }}</td>
                                <td class="text-center" :class="item2.color">{{ item2.so2 }}</td>
                            </tr>
                        </template>

                    </template>
                </tbody>
                <tbody class=" text-center" v-else>
                    <tr>
                        <td colspan="19" class=" text-center">
                            <strong> No hay resultados</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <cModalMonitoreo :payload="modal.payload" :title="modal.title" :dataId="modal.dataId" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdhorario="mtdhorario" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMonitoreo from '../../Hospitalizacion/modals/cModalMonitoreo.vue';
export default {
    name: 'c-asistencial-sop-historia-monitoreohemodinamico',
    data() {
        return {
            //page: null,
            swal: null,
            patient_name: '',
            insurance_id: '',
            insurance_name: '',
            type_patient: null,
            peso: '',
            datatime: [],
            datasheets: [],
            graphic_sheets: {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [{
                        id: 1,
                        label: 'PAS',
                        value: '',
                        value2: '',
                        inputs: 2
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 3,
                        label: 'PAM',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 4,
                        label: 'Pulso',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 5,
                        label: 'FR',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 6,
                        label: 'Temp',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 7,
                        label: 'S02',
                        value: '',
                        inputs: 1
                    }
                ],
                type: 1
            },

            graphic_sheets_pantilla: {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [{
                        id: 1,
                        label: 'PAS',
                        value: '',
                        value2: '',
                        inputs: 2
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 3,
                        label: 'PAM',
                        value: 0,
                        inputs: 1
                    },
                    {
                        id: 4,
                        label: 'Pulso',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 5,
                        label: 'FR',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 6,
                        label: 'Temp',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 7,
                        label: 'S02',
                        value: '',
                        inputs: 1
                    }
                ],
                type: 1
            },
            selectedItems: [],
            modal: {
                title: '',
                modal_form: false,
                payload: {},
                dataId: []
            },
        }
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        dete_out_doctor: {
            type: Boolean
        },
        rolname: String
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdtypepatient();
                    this.mtgetsheet()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        disable: function () {
            const areEqual = JSON.stringify(this.graphic_sheets) === JSON.stringify(this.graphic_sheets_pantilla);
            return areEqual
        },
        cpValuePam() {
            let div = null;
            //obtener el promedio 
            let pas = this.graphic_sheets.values[0].value;
            let pas1 = isNaN(pas) || pas == "" || pas == null ? 0 : pas;
            let pad = this.graphic_sheets.values[1].value;
            let pad1 = isNaN(pad) || pad == "" || pad == null ? 0 : pad;
            div = (parseFloat(pas1) + parseFloat(pad1)) / 2;
            this.graphic_sheets.values[2].value = div;
            return isNaN(div) ? null : div;
        },
    },

    components: {
        SweetAlert,
        cModalMonitoreo
    },
    methods: {
        ...mapActions(['get', 'post']),

        handleCheckboxSelection(date, item) {
            if (!this.selectedItems) {
                this.selectedItems = [];
            }
            if (item.state == false) {
                const index = this.selectedItems.indexOf(item);
                if (index !== -1) {
                    this.selectedItems = [
                        ...this.selectedItems.slice(0, index),
                        ...this.selectedItems.slice(index + 1)
                    ];
                }
            }
            if (item.state) {
                if (this.selectedItems.length == 0) {
                    this.selectedItems.push(item);
                } else {
                    this.selectedItems.forEach(i => {
                        if (i.date === item.date) {
                            if (i.id !== item.id) {
                                this.selectedItems.push(item);
                            }
                        } else {
                            this.$refs.SweetAlert.showWarning("Seleccione fechas iguales");
                            item.state = false;
                            document.getElementById(item.id).checked = false;
                        }

                    });
                }
            }
        },

        mtdtypepatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/salePatient",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id
                    },
                })
                .then((response) => {
                    this.sale = response.data
                    this.type_patient = response.data.type_patient
                    this.date_in = response.data.date_ind
                    this.date_out = response.data.date_out
                    this.insurance_id = response.data.insurance ? response.data.insurance.id : null
                    this.insurance_name = response.data.insurance ? response.data.insurance.name : ''
                    this.patient_name = response.data.patient.name
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        mtgetsheet: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Sop/indexSheetMonitoreo/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.datatime = response.data.dates;
                    this.graphic_sheets.day = response.data.day
                    this.graphic_sheets_pantilla.day = response.data.day
                    let fechas = []
                    this.datatime.forEach(item => {
                        let date = {
                            date: item,
                            sheets: []
                        }
                        fechas.push(date);
                    });

                    if (response.data.sheets.length > 0) {
                        const long = response.data.sheets.length - 1;
                        const dataSheets = JSON.parse(response.data.sheets[long].values);
                        const pesoSheets = response.data.sheets[long].weight;
                        this.graphic_sheets.values = dataSheets;
                        this.graphic_sheets.weight = pesoSheets;
                    }

                    response.data.sheets.forEach(horario => {
                        let num = 0
                        fechas.forEach(item => {
                            if (item.date == horario.datename) {
                                let parsedValues = JSON.parse(horario.values);
                                let detallesheet = {
                                    id: horario.id,
                                    hour: horario.hour,
                                    pa: parsedValues[0].value + '/' + parsedValues[0].value2,
                                    pam: parsedValues[2].value,
                                    pulso: parsedValues[3].value,
                                    fr: parsedValues[4].value,
                                    temp: parsedValues[5].value,
                                    so2: parsedValues[6].value,
                                    color: '',
                                    status: horario.status,
                                    state: horario.state,
                                    date: horario.date,
                                    weight: horario.weight,
                                }

                                if (horario.hour >= "07:31:00" && horario.hour < "19:30:00") {
                                    detallesheet.color = 'text-primary'
                                    item.sheets.push(detallesheet)
                                } else if (horario.hour >= "19:30:00" && horario.hour <= "23:59:00") {
                                    detallesheet.color = 'text-danger'
                                    item.sheets.push(detallesheet)
                                } else {
                                    detallesheet.color = 'text-danger'
                                    fechas[num - 1].sheets.push(detallesheet)
                                }
                            }
                            num++

                        })
                    })
                    this.datasheets = []
                    fechas.forEach(item2 => {
                        if (item2.sheets.length > 0) {
                            this.datasheets.push(item2)
                        }
                    })
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },

        validateDecimal: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdaddsheet: function () {
            this.graphic_sheets.medical_act_id = this.medical_act_id;
            this.graphic_sheets.values[0].value2 = this.graphic_sheets.values[1].value; //completar 
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeSheet",
                    token: this.$store.getters.get__token,
                    params: this.graphic_sheets,
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdvaciar();
                    this.mtgetsheet()

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdvaciar: function () {
            this.selectedItems = [];
            this.graphic_sheets = {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [{
                        id: 1,
                        label: 'PAS',
                        value: '',
                        value2: '',
                        inputs: 2
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 3,
                        label: 'PAM',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 4,
                        label: 'Pulso',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 5,
                        label: 'FR',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 6,
                        label: 'Temp',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 7,
                        label: 'S02',
                        value: '',
                        inputs: 1
                    }
                ],
            }
        },
        mtdadd: function () {
            this.$refs.SweetAlert.horario();
        },
        mtdhorario: function (hour, payload) {
            this.graphic_sheets.hour = hour
            this.mtdaddsheet()
        },
        mtdaddgraficaGuardar: function () {
            let datanew = []
            this.datasheets.forEach(element => {
                element.sheets.forEach(item => {
                    if (item.state) {
                        datanew.push(item.id)
                    }
                })
            });
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/editStatusSheet",
                    token: this.$store.getters.get__token,
                    params: {
                        data: datanew,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtgetsheet()

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        //Modales
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                payload: {},
            }
        },
        mtdaddgrafica: function () {
            let datanew = []
            this.datasheets.forEach(element => {
                element.sheets.forEach(item => {
                    if (item.state && item.status == 0) {
                        datanew.push({
                            id: item.id,
                            weight: item.weight,
                            date: item.date,
                            status: item.status
                        });
                    }
                });
            });

            let dataId = []
            this.datasheets.forEach(element => {
                element.sheets.forEach(item => {
                    if (item.state && item.status == 0) {
                        dataId.push(item.id)
                    }
                })
            });

            if (datanew.length == 0) {
                this.$refs.SweetAlert.showWarning("Seleccione fechas iguales");
            } else {
                this.post({
                        url: this.$store.getters.get__url + "/Sop/mtdGetDataGraphic",
                        token: this.$store.getters.get__token,
                        params: {
                            data: datanew,
                            medical_act_id: this.medical_act_id
                        }
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            this.modal.title = 'Monitoreo - Grafica';
                            this.modal.payload = response.data;
                            this.modal.dataId = dataId;
                            this.modal.modal_form = true;
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }
        },

        Datadetail: function (payload_detail, dataId) {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/editStatusSheet",
                    token: this.$store.getters.get__token,
                    params: {
                        data: dataId,
                        payload: payload_detail
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtgetsheet();
                    this.selectedItems = [];
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        }
    }
}
</script>

<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row px-2">
            <div class="col-xl-5 col-12 py-1">
              <div id="div-fio2" class="form-group row align-left">
                <label for="fio2" class="col-xl-6 color-main fw-bold">FECHA INICIO</label>
                <div class="col-xl-6">
                    <input type="date" v-model="date_start" class="form-control form-control-sm" :readonly="isDateStartReadOnly">
                </div>
              </div>
            </div>
    
            <div class="col-xl-5 col-12 py-1">
              <div id="div-liter" class="form-group row align-left">
                <label for="liter" class="col-xl-6 color-main fw-bold">FECHA CAMBIO</label>
                <div class="col-xl-6">
                    <input type="date" v-model="date_end" class="form-control form-control-sm">
                </div>
              </div>
            </div>
    
            <div class="col-xl-2 col-12 pt-xl-1 pt-md-3 align-center">
              <button type="button" class="btn btn-success btn-sm text-white w-100" @click="mtdvalidationProc"><i class="fas fa-save"></i> Guardar</button>
            </div>
          </div>
        
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
    name: "c-medico-modal-proc-invasivo",

    data() {
        return {
            swal: null,
            validation: false,
            sale: [],
            last_kardex: [],
            date_start: '',
            date_end: '',
            isDateStartReadOnly: false,
            createNew: false
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical: {
            type: Number,
            default: 0
        },
        patient_id: {
            type: Number,
            default: 0
        },
        type_kardex: {
            type: String,
            default: ''
        }
    },
    watch: {
        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdtypepatient();
                    this.mtdobtenervalores();
                }
            },
            deep: true,
            immediate: true,
        },
        patient_id: {
            handler: function (newValue) {
                
            },
            deep: true,
            immediate: true,
        },
        type_kardex: {
            handler: function (newValue) {
                
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.date_start = null;
    },
    mounted() {
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.date_start = null; // Restablecer date_start a null al cerrar el modal
            this.date_end = null; // Restablecer date_start a null al cerrar el modal
            this.$emit('mtdclosemodalprocinvasivo');
        },
        mtdprocinvasivonew: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/storeProcInvasivo",
                token: this.$store.getters.get__token,
                params: {
                    medical_act_id: this.id_medical,
                    date_start: this.date_start,
                    date_end: this.date_end,
                    type_kardex: this.type_kardex
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if (response.data != []) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                }
            });
        },
        mtdprocinvasivoedit: function (payload) {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/updateProcInvasivo",
                token: this.$store.getters.get__token,
                params: {
                    id: payload.id,
                    date_end: this.date_end
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if (response.data != []) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                }
            });
        },
        mtdtypepatient: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/salePatient",
                token: this.$store.getters.get__token,
                params: {
                    patient_id: this.patient_id,
                    medical_act_id: this.id_medical
                },
            })
            .then((response) => {
                this.sale = response.data;
                this.type_patient = response.data.type_patient;
                this.insurance_id = response.data.insurance_id
            })
            .catch((errors) => {
                console.log(errors);
            });
        },
        mtdvalidationProc: function () {
            if ((this.date_start === '' || this.date_start == null) && (this.date_end === '' || this.date_end == null)) {
                this.$refs.SweetAlert.showInfo('Campos vacios.');
            } else if (this.date_start == null && this.date_end != null) {
                this.$refs.SweetAlert.showInfo('Ingrese al menos fecha de inicio.');
            } else {
                this.validation = true;
                if (this.last_kardex === null || this.last_kardex == []) {
                    this.mtdprocinvasivonew();
                } else {
                    if(this.createNew){
                        this.mtdprocinvasivonew();
                    }else if(!this.createNew){
                        this.mtdprocinvasivoedit(this.last_kardex);
                    }
                }
            }
        },
        mtdobtenervalores: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/filterKardex",
                token: this.$store.getters.get__token,
                params: {
                    id_medical_act: this.id_medical,
                    patient_id: this.patient_id,
                    type_kardex: this.type_kardex,
                    type_service : 6,
                },
            })
            .then((response) => {
                this.last_kardex = response.data;
                if (response.data !== null) {
                    if((response.data.date != null) && (response.data.date_end != null)){
                        // Si date_start y date_end no son null, se creará un nuevo registro
                        this.date_start = null;
                        this.date_end = null;
                        this.createNew = true;
                        this.isDateStartReadOnly = false; // Desbloquear el campo si date_start es null
                    }else{
                        // Si date_start y date_end son null, se editará el registro existente
                        this.date_start = response.data.date;
                        this.createNew = false;
                        this.isDateStartReadOnly = true; // Bloquear el campo si date_start no es null
                    }
                } else {
                    this.date_start = null;
                    this.date_end = null;
                    this.createNew = false;
                    this.isDateStartReadOnly = false; // Desbloquear el campo si date_start es null
                }
            })
            .catch((errors) => {
                console.log(errors);
            });
        },
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

<style scoped>

</style>
<template>
<div style="height: 100%">
    <section class="mt-1 pb-3">
        
        <div class="d-lg-flex gap-2 px-2 mb-2 mt-3">
            <div class="col-md-12 d-md-block d-lg-none mb-2">
            <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="mtdstoredata()" v-if="rolname == 'DOCTOR(A)'">
                <i class="fas fa-save"></i> GUARDAR
            </button>
        </div>
            <!-- ANTECEDENTES -->
            <div class="col-xl-4 col-lg-4 col-md-12">
                <div class="panel-border mb-0 pt-1 px-3">
                    <div class="d-flex justify-content-center p-2">
                        <strong class="col-md-8">ARCHIVO EKG</strong>
                        <button v-if="!riesgo.ekg" class="btn btn-warning text-white ms-3 py-0" @click="triggerFileInput">
                            <i class="fas fa-upload text-black"></i> 
                        </button>
                        <button v-else class="btn btn-info text-white ms-3 py-0" @click="showFileInput">
                            <i class="fas fa-eye"></i> 
                        </button>
                        <input type="file" @change="getFile" ref="fileInput" style="display : none">
                    </div>
                </div>
                <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(0)">
                    <h6 class="fw-bold color-main d-inline-block">
                        Antecedentes
                    </h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[0].state && 'active']" />
                </div>
                <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[0].state">
                    <div class="row mb-1">
                        <div class=" col-12 mt-1">
                            <div id="div-dm" class="form-group  align-left">
                                <div class="">
                                    <div class="form-check form-switch d-flex justify-content-start ">
                                        <input class="form-check-input" type="checkbox" id="bg1" v-model="background[0].state" :disabled="edit">
                                        <label class="form-check-label fw-bold color-main " for="bg1">{{
                                                background[0].label }}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" v-model="background[0].value" class="form-control form-control-input form-control-sm" id="dm" :disabled="validationinputOperation || edit" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <div id="div-ar" class="form-group align-left">
                                <div class="">
                                    <div class="form-check form-switch d-flex justify-content-start ">
                                        <input class="form-check-input" type="checkbox" id="bg2" v-model="background[1].state" :disabled="edit">
                                        <label class="form-check-label fw-bold color-main " for="bg2">{{background[1].label}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" v-model="background[1].value" class="form-control form-control-input form-control-sm" id="ar" :disabled="validationinputhta || edit" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-1">
                            <div id="div-hta" class="form-group  align-left">
                                <div class="">
                                    <div class="form-check form-switch d-flex justify-content-start ">
                                        <input class="form-check-input" type="checkbox" id="bg3" v-model="background[2].state" :disabled="edit">
                                        <label class="form-check-label fw-bold color-main " for="bg3">{{background[2].label}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" v-model="background[2].value" class="form-control form-control-input form-control-sm" id="hta" :disabled="validationinputdm || edit" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <div id="div-otro" class="form-group  align-left">
                                <div class="">
                                    <div class="form-check form-switch d-flex justify-content-start ">
                                        <input class="form-check-input" type="checkbox" id="bg4" v-model="background[3].state" :disabled="edit">
                                        <label class="form-check-label fw-bold color-main " for="bg4">{{background[3].label}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12 mt-1 mb-1">
                                    <textarea v-model="background[3].value" class="form-control form-control-sm" id="otro" rows="3" style="resize: none" :disabled="validationinputcardio || edit" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-1">
                                <div id="div-alergia" class="form-group  align-left">
                                    <div class="">
                                        <div class="form-check form-switch d-flex justify-content-start ">
                                            <input class="form-check-input" type="checkbox" id="bg5"
                                                v-model="background[4].state" :disabled="edit">
                                            <label class="form-check-label fw-bold color-main "
                                                for="bg5">{{background[4].label}}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-1 mb-1">
                                        <textarea v-model="background[4].value" class="form-control form-control-sm"
                                            id="alergia" rows="3" style="resize: none"
                                            :disabled="validationinputpulmonar || edit" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-1">
                                <div id="div-cirugia" class="form-group row align-left">
                                    <div class="">
                                        <div class="form-check form-switch d-flex justify-content-start ">
                                            <input class="form-check-input" type="checkbox" id="bg6"
                                                v-model="background[5].state" :disabled="edit">
                                            <label class="form-check-label fw-bold color-main "
                                                for="bg6">{{background[5].label}}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 mt-1 mb-1">
                                        <textarea v-model="background[5].value" class="form-control form-control-sm"
                                            id="cirugia" rows="3" style="resize: none"
                                            :disabled="validationinputOtras || edit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12">
                    <div class="row">
                        <!-- Examen fisico -->
                        <div class="col-lg-12">
                            <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(1)">
                                <h6 class="fw-bold color-main d-inline-block">
                                    Examen Físico
                                </h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                                    class="dropdown-icon-content d-inline-block float-end"
                                    :class="[titleDropdown[1].state && 'active']" />
                            </div>
                            <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[1].state">
                                <div class="row d-md-flex">
                                    <div class="col-md-6 mt-1 align-left" v-for="(itemE, indexE) in physicalExam"
                                        :key="indexE">
                                        <div class="form-group row">
                                            <label :for="itemE.label" class="col-lg-4 col-md-12 color-main fw-bold">{{
                                                itemE.label }}</label>
                                            <div class="col-lg-8 col-md-12">
                                                <input type="text"
                                                    class="form-control form-control-input form-control-sm"
                                                    v-model="itemE.value" :id="itemE.label" :disabled="edit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- OTROS EXAMENES -->
                        <div class="col-lg-12">
                            <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(2)">
                                <h6 class="fw-bold color-main d-inline-block">
                                    Examen Auxiliares
                                </h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                                    class="dropdown-icon-content d-inline-block float-end"
                                    :class="[titleDropdown[2].state && 'active']" />
                            </div>
                            <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[2].state">
                                <div class="row d-md-flex">
                                    <div class="align-left col-sm-12 mt-1" :class="itemE.id > 4 ?'col-md-6': 'col-md-3'"
                                        v-for="(itemE, indexE) in auxExam" :key="indexE">
                                        <div class="form-group d-md-flex"">
                                    <label :for="'aux'+indexE" class="col-lg-4 col-md-3 col-sm-12 color-main fw-bold">
                                            {{ itemE.label }}</label>
                                            <div class="col-lg-8 col-md-9 col-sm-12">
                                                <input type="text"
                                                    class="form-control form-control-input form-control-sm"
                                                    v-model="itemE.value" :id="'aux'+indexE" :disabled="edit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-1 mb-3 align-left">
                                        <div id="div-conclusion" class="form-group row">
                                            <label for="conclusion"
                                                class="col-sm-12 color-main fw-bold text-xs">Conclusion</label>
                                            <div class="col-sm-12 mt-1 mb-1">
                                                <textarea v-model="riesgo.conclusion"
                                                    class="form-control form-control-sm" id="conclusion" rows="3"
                                                    style="resize: none" :disabled=" edit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-lg-flex gap-1 px-2">
                <div class="col-md-12 col-lg-6">
                    <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(3)">
                        <h6 class="fw-bold color-main d-inline-block">
                            DIAGNOSTICO
                        </h6>
                        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                            class="dropdown-icon-content d-inline-block float-end"
                            :class="[titleDropdown[0].state && 'active']" />
                    </div>
                    <div class="panel-border mb-0 pt-1" v-if="titleDropdown[3].state">
                        <div class="row my-1 px-3">
                            <div class="col-md-12" id="content-timelinetable">
                                <table class="w-100">
                                    <div v-if="!edit" class="d-flex justify-content-end">
                                        <button type="button" @click="openmodaldx" :disabled="edit"
                                            class="btn btn-info btn-sm py-0 mb-2">
                                            Nuevo
                                        </button>
                                    </div>
                                    <table class="w-100">
                                        <caption class="div-text"></caption>
                                        <thead class="text-xs">
                                            <tr>
                                                <th colspan="4">Seleccione Diagnostico :</th>
                                            </tr>
                                        </thead>
                                        <tbody class="div-text">
                                            <tr class="px-1">
                                                <td>
                                                    <input type="text" v-model="diagnostics_unit.name"
                                                        @keyup.enter="mtdgetCies"
                                                        class="form-control form-control-input form-control-sm"
                                                        placeholder="Buscar CIE" :disabled="edit" />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="diagnostics_unit.codigo"
                                                        class="form-control form-control-input form-control-sm"
                                                        placeholder="CIE10" :disabled="edit" readonly />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="diagnostics_unit.type"
                                                        class="form-control form-control-input form-control-sm"
                                                        placeholder="TIPO" :disabled="edit" readonly />
                                                </td>
                                                <td>
                                                    <button type="button" @click="mtdaddDiagnostico"
                                                        :disabled="disabledx" class="btn btn-success btn-sm px-1 py-0">
                                                        <i class="fas fa-plus text-white"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="w-100 table table-bordered">
                                        <caption class="div-text"></caption>
                                        <thead class="div-text div-title">
                                            <tr>
                                                <th class="">N°</th>
                                                <th class="">Dx</th>
                                                <th class="">CIE10</th>
                                                <th class="">TIPO</th>
                                            </tr>
                                        </thead>
                                        <tbody class="div-text">
                                            <tr v-for="(
                                  item1, index
                                ) in riesgo.diagnostics" :key="index" class="px-1">
                                                <td>
                                                    {{ index + 1 }}
                                                </td>
                                                <td>
                                                    {{ item1.name }}
                                                </td>

                                                <td>
                                                    {{ item1.codigo }}
                                                </td>
                                                <td>{{ item1.type }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="riesgo.diagnostics.length == 0" class="div-text">
                                            <tr>
                                                <td colspan="4" class="text-center">
                                                    No hay diagnosticos
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="panel-border mt-1 mb-2 pt-1 px-3 text-xs">
                        <!-- Riesgo -->
                         <div class="row">

                             <div class="col-xl-6 col-md-12 mt-1 mb-3 align-left">
                                 <div class="form-group row">
                                     <label for="rxqx" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                         QUIRÚRGICO</label>
                                     <div class="col-sm-12 mt-1 mb-1">
                                         <textarea v-model="riesgo.surgical_risk" class="form-control form-control-sm"
                                             id="rxqx" rows="3" style="resize: none" :disabled=" edit" />
                                     </div>
                                 </div>
                             </div>
                             <div class="col-xl-6 col-md-12 mt-1 mb-3 align-left">
                                 <div class="form-group row">
                                     <label for="rxa" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                         ANESTESICO</label>
                                     <div class="col-sm-12 mt-1 mb-1">
                                         <textarea v-model="riesgo.anesthetic_risk" class="form-control form-control-sm"
                                             id="rxa" rows="3" style="resize: none" :disabled=" edit" />
                                     </div>
                                 </div>
                             </div>
                             <div class="col-xl-6 col-md-12 mt-1 mb-3 align-left">
                                 <div class="form-group row">
                                     <label for="rxn" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                         NEUMOLOGICO</label>
                                     <div class="col-sm-12 mt-1 mb-1">
                                         <textarea v-model="riesgo.pneumological_risk" class="form-control form-control-sm"
                                             id="rxn" rows="3" style="resize: none" :disabled=" edit" />
                                     </div>
                                 </div>
                             </div>
                             <div class="col-xl-6 col-md-12 mt-1 mb-3 align-left">
                                 <div class="form-group row">
                                     <label for="sugerencias" class="col-sm-12 color-main fw-bold text-xs">SUGERENCIAS</label>
                                     <div class="col-sm-12 mt-1 mb-1">
                                         <textarea v-model="riesgo.suggestions" class="form-control form-control-sm"
                                             id="sugerencias" rows="3" style="resize: none" :disabled=" edit" />
                                     </div>
                                 </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
    </section>
        <!-- modAL DX -->
        <cModaldiagnosticoVue :title="modaldx.title" :boo_modal="modaldx.modal_form" @mtdclosemodaldx="mtdclosemodaldx"
            @mtdcie="mtdcie" />
        <cModalCie @mtdaddDiagnosticoUnit="mtdaddDiagnosticoUnit" :dataCies="dataCies" :title="modalCie.title"
            :boo_modal="modalCie.modal_form" @mtdclosemodalCie="mtdclosemodalCie" />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModaldiagnosticoVue from "@/components/Asistencial/Consulta/modals/cModaldiagnostico.vue";
import cModalCie from '@/components/Asistencial/Emergency/modals/cModalCie.vue'

export default {
    name: 'c-asistencial-sop-historia-rxqx',
    components: {
        SweetAlert,
        cModaldiagnosticoVue,
        cModalCie
    },
    data() {
        return {
            swal: null,
            edit: true,
            inputchecked: false,
            diagnostics_unit: {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            },
            fileInput: '',
            file: '',
            modaldx: {
                title: "",
                modal_form: false,
            },
            modalCie: {
                title: "",
                modal_form: false,
            },
            background: [{
                    id: 1,
                    label: "OPERACIONES",
                    state: false,
                    value: "",
                },
                {
                    id: 2,
                    label: "HTA",
                    state: false,
                    value: "",
                },
                {
                    id: 3,
                    label: "DM",
                    state: false,
                    value: "",
                },
                {
                    id: 4,
                    label: "PATOLOGIA CV",
                    state: false,
                    value: "",
                },
                {
                    id: 5,
                    label: "PAT. PARTICULAR",
                    state: false,
                    value: "",
                },
                {
                    id: 6,
                    label: "OTROS",
                    state: false,
                    value: "",
                },

            ],
            titleDropdown: [{
                    title: "Antecedentes",
                    state: true,
                },
                {
                    title: "Examen Físico",
                    state: true,
                },
                {
                    title: "Signos",
                    state: true,
                },
                {
                    title: "Signos vitales",
                    state: true,
                },
                {
                    title: "Plan",
                    state: true,
                },
                {
                    title: "DX",
                    state: true,
                },
                {
                    title: "Relato",
                    state: true,
                },
                {
                    title: "Otros examenes",
                    state: true,
                },
                {
                    title: "Funcion Biologica",
                    state: true,
                },
            ],
            physicalExam: [{
                    id: 1,
                    label: "Estado general",
                    value: "",
                },
                {
                    id: 2,
                    label: "Estado Nutricion",
                    value: "",
                },
                {
                    id: 3,
                    label: "PA",
                    value: "",
                },
                {
                    id: 4,
                    label: "FC",
                    value: "",
                },
                {
                    id: 5,
                    label: "SaO2",
                    value: "",
                },
                {
                    id: 6,
                    label: "Palidez",
                    value: "",
                },
                {
                    id: 7,
                    label: "Ictericia",
                    value: "",
                },
                {
                    id: 8,
                    label: "Edemas",
                    value: "",
                },
                {
                    id: 9,
                    label: "Respiratorio",
                    value: "",
                },
                {
                    id: 10,
                    label: "Cardiovascular",
                    value: "",
                },
                {
                    id: 11,
                    label: "Abdomen",
                    value: "",
                },
                {
                    id: 12,
                    label: "Genitourinario",
                    value: "",
                },
                {
                    id: 13,
                    label: "Neurológico",
                    value: "",
                },
                {
                    id: 14,
                    label: "Gestion",
                    value: "",
                },
            ],
            auxExam: [{
                    id: 1,
                    label: "Hto",
                    value: "",
                },
                {
                    id: 2,
                    label: "HB",
                    value: "",
                },
                {
                    id: 3,
                    label: "G",
                    value: "",
                },
                {
                    id: 4,
                    label: "Urea",
                    value: "",
                },
                {
                    id: 5,
                    label: "Orina",
                    value: "",
                },
                {
                    id: 6,
                    label: "P. de Coagulacion",
                    value: "",
                },
                {
                    id: 7,
                    label: "Creatinina",
                    value: "",
                },
                {
                    id: 8,
                    label: "VDRL",
                    value: "",
                },
                {
                    id: 9,
                    label: "RX de Torax",
                    value: "",
                },
                {
                    id: 10,
                    label: "TC",
                    value: "",
                },
                {
                    id: 11,
                    label: "TS",
                    value: "",
                },
                {
                    id: 12,
                    label: "GS",
                    value: "",
                },
                {
                    id: 13,
                    label: "EKG Fc",
                    value: "",
                },
                {
                    id: 14,
                    label: "Ritmo",
                    value: "",
                },
                {
                    id: 15,
                    label: "Eje",
                    value: "",
                },
                {
                    id: 16,
                    label: "PR",
                    value: "",
                },
                {
                    id: 17,
                    label: "Signos Isquemia",
                    value: "",
                },
                {
                    id: 18,
                    label: "QRS",
                    value: "",
                },
                {
                    id: 18,
                    label: "QT",
                    value: "",
                },
            ],
            riesgo: {
                id: null,
                ekg: "",
                conclusion: "",
                riesgo: '',
                suggestions: "",
                diagnostics: [],
                surgical_risk: "",
                cardiac_risk: "",
                anesthetic_risk: "",
                pneumological_risk: "",

            },
            dataCies: [],
            name: "",
            cie_id: '',
            cie_code: '',
            cie: "",
        };
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdViewRxqx();
                }
            },
            deep: true,
            immediate: true,
        },
        addRxqx: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addRxqx: {
            type: Number,
            default: 0
        },
        rolname: String,
    },
    computed: {
        validationinputOperation: function () {
            let value = true;
            if (this.background[0].state) {
                value = false;
            }
            return value;
        },
        validationinputhta: function () {
            let value = true;
            if (this.background[1].state) {
                value = false;
            }
            return value;
        },
        validationinputdm: function () {
            let value = true;
            if (this.background[2].state) {
                value = false;
            }
            return value;
        },
        validationinputcardio: function () {
            let value = true;
            if (this.background[3].state) {
                value = false;
            }
            return value;
        },
        validationinputpulmonar: function () {
            let value = true;
            if (this.background[4].state) {
                value = false;
            }
            return value;
        },
        validationinputOtras: function () {
            let value = true;
            if (this.background[5].state) {
                value = false;
            }
            return value;
        },
        disabledx: function () {
            return !this.diagnostics_unit.name || !this.diagnostics_unit.codigo;
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        triggerFileInput() {

        this.$refs['fileInput'].click();
        },
        mtdViewRxqx: function () {
            this.edit = this.rolname !== 'DOCTOR(A)' ? true:false;
            this.post({
                    url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'rxqx'
                    }
                })
                .then((response) => {
                    let viewRxqx = response.data;
                    if (viewRxqx) {
                        this.riesgo = viewRxqx;
                        this.riesgo.diagnostics = viewRxqx.diagnostics ? JSON.parse(viewRxqx.diagnostics): [];
                        this.auxExam =  viewRxqx.auxiliary_exams ? JSON.parse(viewRxqx.auxiliary_exams) : this.auxExam;
                        this.physicalExam =  viewRxqx.physical_exams ? JSON.parse(viewRxqx.physical_exams) : this.physicalExam;
                        this.background =  viewRxqx.background ? JSON.parse(viewRxqx.background) : this.background;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdstoredata: function () {
            //registrar el order sop
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeRxqx",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.riesgo,
                        medical_act_id: this.medical_act_id,
                        background: this.background,
                        auxiliary_exams: this.auxExam,
                        physical_exams: this.physicalExam,
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.mtdViewRxqx();
                        this.$emit('finishAddOrder');
                        this.$refs.SweetAlert.showSuccess(response.message);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getFile(e) {
            this.fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                this.fileInput.value = '';
            } else {
                this.file = file;
                let formData = new FormData();
                formData.append("id", this.riesgo.id);
                formData.append("medical_act", this.medical_act_id);
                formData.append("file", this.file);
                //GUARDAR EL ARCHIVO EN LA BD
                this.post({
                    url: this.$store.getters.get__url + "/Sop/upfileEkg",
                    token: this.$store.getters.get__token,
                    params: formData
                }).then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.riesgo.id = response.data.id;
                    }
                }).catch((errors) => {});
            }
        },
        toggleDropdown(title) {
            this.titleDropdown[title].state = !this.titleDropdown[title].state;
        },
        mtdcie: function (itemdetalle) {
            this.diagnostics_unit = {
                id: itemdetalle.id,
                codigo: itemdetalle.code,
                name: itemdetalle.name,
                type: "",
            };
            this.diagnostico = itemdetalle.name;
        },
        mtdgetCies: function () {
            if (this.diagnostics_unit.name.length > 4) {
                this.dataCies = [];

                this.get({
                        url: this.$store.getters.get__url +
                            "/Consultation/cies/" +
                            this.diagnostics_unit.name,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                            this.modalCie = {
                                title: "Lista de Diagnostico",
                                modal_form: true,
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.diagnostics_unit = {
                id: diagnostico.id,
                codigo: diagnostico.code,
                name: diagnostico.name,
                type: diagnostico.type,
                cie: diagnostico.cie,
            };
            this.modalCie = {
                title: "",
                modal_form: false,
            }
            this.dataCies = [];
        },
        openmodaldx: function () {
            if (this.inputchecked == false) {
                this.modaldx = {
                    title: "Nuevo Diagnostico",
                    modal_form: true,
                };
                this.mtdhidediagnosticounit();
            }
        },
        mtdaddDiagnostico: function () {
            const exists = this.riesgo.diagnostics.some(
                (item) => item.id === this.diagnostics_unit.id
            );
            if (exists) {
                this.$refs.SweetAlert.showWarning("Diagnostico ya agregado");
                this.mtdhidediagnosticounit();
                return;
            }
            this.riesgo.diagnostics.push(this.diagnostics_unit);
            this.mtdhidediagnosticounit();
        },
        mtdhidediagnosticounit: function () {
            this.diagnostics_unit = {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            };
            this.diagnostico = null;
        },
        mtdclosemodaldx: function () {
            this.modaldx = {
                title: "",
                modal_form: false,
            };
            this.inputchecked = false;
        },
        mtdclosemodalCie: function () {
            this.modalCie = {
                title: "",
                modal_form: false,
            }
            this.diagnostics_unit = {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            }
            this.dataCies = [];
        },
        showFileInput: function(){
            let enlace = this.riesgo.ekg;
            if(enlace){
                window.open(
                    enlace,
                    "_blank"
                );
            }
        }
    },
}
</script>

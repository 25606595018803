<template>
    <div>
      <div class="px-3 py-5">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
          <div class="text-center p-5">
            <div
              class="d-grid gap-2 mx-auto col-lg-3 col-md-3 col-sm-12 col-xs-12"
            >
              <button
                class="btn btn-lg btn-bm mt-100"
                @click="generarVenta"
                type="button"
              >
                <strong>VENTA MEDICAMENTO</strong>S
              </button>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="p-1">
            <div class="col-md-6 px-sm-2 px-md-4">
              <div class="row">
                <div id="btn_refresh" class="col-md-12">
                  <button type="button" @click="mtdCleanClient" class="btn">
                    <i class="fa fa-repeat"></i>
                  </button>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 mt-200">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    @keypress="isNumber($event)"
                    v-model="client.document"
                    @keypress.enter="mtdSearchDocument"
                    maxlength="12"
                    placeholder="Documento"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.fullName"
                    :disabled="disabledPatient"
                    placeholder="Nombres del Paciente"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    v-model="client.celphone"
                    maxlength="9"
                    placeholder="Teléfono"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.email"
                    placeholder="Correo del Paciente"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    @keyup="mtdVerifEdad"
                    v-model="client.yearOld"
                    maxlength="3"
                    placeholder="Edad"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-md-6 p-2">
                    <button :class="
                    client.type == 'particular'
                    ? 'form-control form-control-lg inpunt-rounded bg-main text-white'
                    : 'form-control form-control-lg inpunt-rounded'
                    " @click="mtdSelectType('particular')" type="button" aria-label=".form-control-lg example">
                    Particular
                    </button>
                </div>
                <div class="col-md-6 p-2">
                    <button :class="
                    client.type == 'eps'
                    ? 'form-control form-control-lg inpunt-rounded bg-main text-white'
                    : 'form-control form-control-lg inpunt-rounded'
                    " @click="mtdSelectType('eps')" type="button" aria-label=".form-control-lg example">
                    Eps
                    </button>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-6" v-if="client.type == 'eps'">
              <div id="list-eps" class="d-flex justify-content-evenly">
                <span
                  v-for="(item, index) in insurances"
                  :key="index"
                  :class="
                    client.insurance == item.id
                      ? 'badge inpunt-rounded btn-bm text-white'
                      : 'badge inpunt-rounded'
                  "
                  @click="mtdSelectEps(item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>
            <div class="col-md-6 mt-1" v-if="client.type == 'eps'">
              <div id="list-eps" class="d-flex justify-content-evenly">
                <input
                  type="text"
                  class="form-control form-control-sm w-25 inpunt-rounded"
                  placeholder="Costo fijo"
                  v-model="client.fijo"
                  v-on:keypress="isNumber($event)"
                />
                <input
                  type="text"
                  class="form-control form-control-sm w-25 inpunt-rounded"
                  placeholder="% CUBRE EPS"
                  v-model="client.copago"
                  v-on:keypress="isNumber($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
</template>
  
<script>
  import { mapActions } from "vuex";
  export default {
    name: "venta_directa",
    props: {
      verifBead: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        page: null,
        disabledPatient:true,
        client: {
          document: "",
          celphone: "",
          email: "",
          fullName: "",
          yearOld: "",
          type: "",
          insurance: null,
          copago: "",
          fijo:"",
        },
        insurances: [],
      };
    },
    created() {
      this.mtdGetData();
    },
    methods: {
      ...mapActions(["get", "post"]),
      mtdGetData: function () {
        this.get({
          url: this.$store.getters.get__url + "/Admision/listInsurances",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            
            this.insurances = response.data;
          })
          .catch((errors) => {});
      },
      mtdSearchDocument: function () {
        if (this.client.document.length == 8) {
          this.get({
            url: this.$store.getters.get__url + "/reniec/" + this.client.document,
            token: this.$store.getters.get__token,
          })
            .then((response) => {
             
              if (response.data.boo == 3) {
                Swal.fire({
                  text: "DNI no encontrado",
                  icon: "warning",
                  confirmButtonColor: "#900052",
                });
                this.disabledPatient=false;
                this.client.fullName = "";
              } else {
                this.client.fullName = response.data.name + " " + response.data.last_name;
                if (response.data.hasOwnProperty("patient")) {
                  this.client.celphone = response.data.patient.phone;
                  this.client.email = response.data.patient.email;
                  this.client.yearOld = response.data.patient.year;
                } else {
                  this.client.celphone = "";
                  this.client.email = "";
                  this.client.yearOld = "";
                  Swal.fire({
                    text: "Paciente Nuevo",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                  });
                  this.disabledPatient=true;
                }
              }
            })
            .catch((errors) => {});
        }else{
          this.disabledPatient=false;
          this.client.celphone= "";
          this.client.email= "";
          this.client.fullName="";
          this.client.yearOld= "";
        }
      },
      mtdSelectType: function (type) {
        this.client.type = type;
      },
      mtdSelectEps: function (id) {
        this.client.insurance = id;
      },
      mtdCleanClient: function () {
        this.client = {
          document: "",
          celphone: "",
          email: "",
          fullName: "",
          yearOld: "",
          type: "",
          insurance: "",
          copago:"",
          fijo:""
        };
      },
      mtdVerifClient: function () {
        if (this.client.document.trim() == '11111111' && this.client.type.trim() == 'eps') {
          return { msg: "Cuando Documento es 11111111 el Tipo de Paciente debe ser Paticular", status: false };
        }
        
        if (this.client.type.trim() == 'eps' && !this.client.insurance ) {
        return { msg: "Debe completar el campo de Seguro si el Tipo de Paciente es EPS", status: false };
        }

        if (
            this.client.document.trim().length > 0 &&
            this.client.fullName.trim().length > 0 &&
            this.client.type.trim().length > 0
        ) {
            return { msg: "Cliente válido.", status: true };
        } else {
            if (!this.client.document.trim().length > 0) {
                return { msg: "Ingrese datos del Cliente", status: false };
            } else if (!this.client.type.trim().length > 0) {
                return { msg: "Escoja un Tipo", status: false };
            }
            return { msg: '', status: false };
        }
      },
      generarVenta() {
        if (this.mtdVerifClient().status == true) {
          this.$emit("changePageVen", "venta", this.client);
        } else {
          Swal.fire({
            text: this.mtdVerifClient().msg,
            icon: "warning",
            confirmButtonColor: "#900052",
          });
        }
      },
      generarEgreso() {
        this.$emit("mtdChangePage", "egreso", this.client);
      },
      otrasViews() {
        this.$emit("mtdChangePage", "laboratorio"); 
      },
      mtdVerifEdad: function () {
        if (this.client.yearOld.length > 0) {
          if (this.client.yearOld > 0 && this.client.yearOld <= 110) {
          } else {
            this.client.yearOld = "";
            Swal.fire({
              text: "Edad Incorrecta",
              icon: "warning",
              confirmButtonColor: "#900052",
            });
          }
        }
      },
      mtdCloseBead() {
        this.$emit("mtdChangePage", "reporteClose");
      },
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      returnHome: function () {
        this.$emit("returnHome");
      },
    },
  };
</script>
  
<style scoped>
  .btn-bm-home {
    background: rgb(144, 0, 82);
    background-color: rgb(144, 0, 82);
    color: white;
  }
  #list-eps span {
    cursor: pointer;
  }
  #btn_home {
    z-index: 1;
  }
  #form_home {
    z-index: 2;
    bottom: 100px;
  }
  #div_login {
    background: #ffffff;
    height: 100vh;
    border: 5px solid #900052;
  }
  #btn_refresh button {
    font-size: 25px;
    align-items: center;
  }
  #btn_refresh button:hover {
    align-items: center;
    transform: scale(1.5);
  }
  #btn-open-caja {
    /* position: absolute !important;
    margin-top: 15px !important;
    right: 20px !important; */
  
    justify-content: center !important;
  }
  @media (max-width: 800px) {
    #form_home {
      z-index: 1;
      position: relative !important;
      margin-bottom: 30px !important;
    }
  }
  @media (min-width: 800px) {
    #form_home {
      position: absolute !important;
      margin-bottom: 0px !important;
    }
  }
  @media (min-width: 844px) {
    #form_home {
      position: relative !important;
      margin-bottom: 30px !important;
    }
  }
  @media (min-width: 916px) {
    #form_home {
      position: absolute !important;
      margin-bottom: -40px !important;
    }
  }
  @media (min-width: 1024px) {
    #form_home {
      position: relative !important;
      margin-bottom: 0px !important;
    }
  }
  @media (min-width: 1025px) {
    #form_home {
      position: absolute !important;
    }
  }
  .custom-input {
    border-color: #EB5077;
    color: #EB5077;
  }

  .custom-input::placeholder {
    color: #EB5077;
  }
</style>
  
<template>
<CModal @mtdclosemodal="mtd_close" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" 
:btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex flex-wrap align-items-end px-3">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="birthday" class="mb-1">Nombres</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <input type="text" class="form-control" id="name" placeholder="Nombre" 
                v-model="data_detail.name" @keydown="filterKeyLetter"/>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="birthday" class="mb-1">Apellidos</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <input type="text" class="form-control" id="last_name" placeholder="Apellidos" 
                v-model="data_detail.last_name" @keydown="filterKeyLetter"/>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="birthday" class="mb-1">N° de Documento</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-id-card"></i>
                    </span>
                </div>
                <input type="text" class="form-control" id="num_document" placeholder="N° de Documento"
                v-model="data_detail.num_document" maxlength="8" @keydown="filterKey"/>
            </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 mt-1">
            <label for="birthday" class="mb-1">Fec. de Nacimiento</label>
            <div class="input-group">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-calendar"></i></span>
                </div>
                <input v-model="data_detail.birthday" type="date" class="form-control" id="birthday" :max="maxDate" />
            </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-9 col-sm-12 col-xs-12 mt-1">
            <label for="birthday" class="mb-1">Archivo</label>
            <div class="input-group">
                <input
                    @change="getFile"
                    type="file"
                    class="form-control"
                    id="file"
                    lang="es"
                    name="file"
                    ref="fileInput"
                />
            </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12 mt-3">
            <button class="btn btn-sm text-white bg-main" @click="mtdAddFam" :disabled="isButtonDisabled">
                <i class="fas fa-plus"></i>&nbsp; Agregar
            </button>
        </div>
    </div>
    
    <!--Listado-->
    <div class="px-3">
        <hr class="border-main" style="border: 1px solid;">
    </div>
    <div class="row d-flex px-2 mt-3">
        <div class="col-md-12" id="content-timelinetable">
            <table class="table table-bordered table-responsive text-xs">
                <caption class="py-0 my-0"></caption>
                <thead class="bg-main">
                    <tr class="text-white align-middle">
                        <th>N°</th>
                        <th>Documento</th>
                        <th>Nombre</th>
                        <th>Fec. de Nacimiento</th>
                        <th>Edad</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody class="border-main">
                    <tr v-if="cpData.length === 0" class="text-center align-middle">
                        <td colspan="7">Sin Registros</td>
                    </tr>
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                        <td>{{index + 1}}</td>
                        <td>{{ item.num_document  }}</td>
                        <td>{{ item.name }} {{ item.last_name }}</td>
                        <td>
                            <span v-if="item.birthday">{{ item.birthday | formatDate }}</span>
                            <span v-else>-</span>
                        </td>
                        <td>{{ calculateAge(item.birthday).age }}</td>
                        <td>
                            <div id="state_speciality" v-if="calculateAge(item.birthday).isActive">
                                <div class="bg-success text-white">Activo</div>
                            </div>
                            <div id="state_speciality" v-else>
                                <div class="bg-secondary text-white">Inactivo</div>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-sm text-white bg-main-file me-2" style="font-size: 12px;" v-if="item.file"  @click="openFile(item.file)">
                                <i class="fas fa-file"></i>
                            </button>
                            <button class="btn btn-sm text-white btn btn-danger" style="font-size: 12px;" @click="mtdDeleteFam(item.id)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-doctor",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                num_document: "",
                name: "",
                last_name: "",
                birthday: "",
                file: "",
            },
            maxDate: moment().format('YYYY-MM-DD')
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        idEm: Number,
        payload: {
            type: Array,
            default: null
        },
    },
    watch: {
    },
    computed: {
        cpData() {
            return this.payload.map(element => element);
        },
        isButtonDisabled() {
            return (
                !this.data_detail.num_document.trim() || 
                this.data_detail.num_document.trim().length < 8 ||
                !this.data_detail.name.trim() ||
                !this.data_detail.last_name.trim() ||
                !this.data_detail.birthday.trim()
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtd_close() {
            this.clear();
            this.$emit("mtd_close_hijos");
        },
        clear() {
            this.data_detail= {
                num_document: "",
                name: "",
                last_name: "",
                birthday: "",
                file: "",
            }
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]$/.test(e.key);
            if (!b && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        filterKeyLetter: function (e) {
            let b = false;
            b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (
            !/\.(pdf)$/i.test(file.name) &&
            !/\.(png)$/i.test(file.name) &&
            !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)
            ) {
            Swal.fire({
                text: "Seleccione un archivo formato PDF, PNG O JPG",
                icon: "info",
                confirmButtonColor: "#900052",
            });
            fileInput.value = '';
            this.data_detail.file = '';
            } else {
            this.data_detail.file = file;
            }
        },

        mtdAddFam: function(){
            this.$emit('registerDataFam', this.data_detail, this.idEm);
            this.clear();
            let fileInput = this.$refs.fileInput;
            fileInput.value = '';
        },

        mtdDeleteFam: function(id){
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro de eliminar este Registro?", "warning", "Eliminar")
            .then((result) => {
                if (result.value) {
                    this.$emit('deleteDataFam', id, this.idEm);
                }
            });
        },
        
        openFile: function(url){
            window.open('assets/' + url, '_blank');
        },

        calculateAge(birthday) {
            if (!birthday) {
                return { 
                    age: "-", 
                    isActive: false 
                };
            }
            const today = moment();
            const birthDate = moment(birthday);
            const years = today.diff(birthDate, "years");
            const months = today.diff(birthDate.add(years, "years"), "months");
            const days = today.diff(birthDate.add(months, "months"), "days");

            return {
                age: `${years} Años, ${months} Meses, ${days} Días`,
                isActive: years < 18,
            };
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    }
};
</script>
<style scoped>
.bg-main-file{
    background-color: #EB5D77
}
</style>


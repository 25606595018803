<template>
<div class="row px-3 mt-2">
    <div class="col-md-12" v-if="columns.length > 1">
        <div class="row d-flex">

            <!-- Grafica temperatuta -->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row d-flex justify-content-center px-4">
                    <div class="p-1 text-center border border-3 shadow col-md-11">
                        <div id="chart">
                            <apexchart ref="apexchart1" type="line" height="260" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Grafica pad y pas-->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row d-flex justify-content-center px-4">
                    <div class="p-1 text-center border border-3 shadow col-md-11">
                        <div id="chart1">
                            <apexchart ref="apexchart" type="rangeBar" height="260" :options="chartOptions2" :series="series2"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="p-5 text-center">
        <h5>No hay datos registrados aún</h5>
    </div>
    <div class="col-md-12 mt-2 pb-2" id="content-timelinetable" v-if="columns.length > 1">
        <div v-if="pageOrigin !== 'report'">
            <button title="Guardar" class="btn btn-sm btn-success text-white px-3" @click="mtdaddgrafica">
                Hoja Grafica
            </button>
        </div>
        <table class="tablesheet table-bordered text-center border-main mt-2">
            <thead>
                <tr>
                    <!-- Renderiza dinámicamente los encabezados de las columnas -->
                    <th v-for="(col, index) in columns" :key="index" :colspan="index == 0 ? 2:1" class="py-2">
                        <!-- {{ col.label }} -->
                        <template v-if="pageOrigin !== 'report' && rolname == 'ENFERMERA(O)'">
                            <editable-cell :typeInput="'hour'" :value="col.label" :index="index" @update-label="updateHour" />
                        </template>
                        <template v-else>
                            {{ col.label }}
                        </template>
                    </th>
                </tr>
                <tr v-if="pageOrigin !== 'report'">
                    <th v-for="(col, index) in columns" :key="index" :colspan="index == 0 ? 2 : 1" class="py-2">
                        <div v-if="index > 0" class="d-flex justify-content-center">
                            <input class="form-check-input" :id="`checkbox-${index}`" type="checkbox" v-model="data[index - 1].checked" :disabled="data[index - 1] && data[index - 1].status === 1">
                        </div>
                        <div v-else class="text-center">SELECCIONE</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in rows" :key="rowIndex" class="tab-hover">
                    <!-- Verifica si es la primera fila de un grupo de tamaño definido en `rowGroups` -->
                    <template v-if="isFirstRowOfGroup(rowIndex)">
                        <td :rowspan="getRowSpan(rowIndex)" class="rotate-text sticky-col" :class="mtdBgTitle(rowIndex)">
                            <div class="py-2 px-3 fw-semibold">{{getRowTitle(rowIndex)}}</div>
                        </td>
                    </template>

                    <!-- Renderiza dinámicamente las celdas según las columnas -->
                    <td v-for="(col, colIndex) in columns" :class="colIndex === 0 ? ['fw-semibold sticky-col-0',mtdColorColumn(colIndex,rowIndex)]  : mtdColorColumn(colIndex,rowIndex)" :key="colIndex">
                        <div :class="['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? 'p-2' : ''">
                            {{ ['Item 1', 'Item 2', 'Item 3'].includes(row[col.field]) ? '' : row[col.field] }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <cModalMonitoreo :payload="modal.payload" :title="modal.title" :dataId="modal.dataId" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
    <SweetAlert :swal="swal" ref="SweetAlert" />

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMonitoreo from '@/components/Asistencial/UciAdult/modals/cModalMonitoreo.vue';
import EditableCell from '@/components/shared/column/EditableLabel.vue';
export default {
    data() {
        return {
            swal: null,
            data: [],
            columns: [],
            rows: [],
            rowGroups: [],
            rowTitle: ['FUNCIONES VITALES', 'OTROS', 'GLASGOW', 'VASOACTIVOS', 'SEDACION', 'OXIGENOTERAPIA', 'MONITOREO RESPIRATORIO'],
            columnCount: 2,
            temperatura: [],
            series: [{
                name: "T",
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 260,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Temperatura',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " °C"
                        }
                    }
                }
            },
            //grafic 2
            series2: [{
                name: "PAS-PAD",
                data: []
            }],
            dataPas: [],
            dataPad: [],
            chartOptions2: {
                chart: {
                    height: 350,
                    type: 'rangeBar',
                    zoom: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        isDumbbell: true,
                        columnWidth: 3,
                        dumbbellColors: [
                            ['#008FFB', '#00E396']
                        ]
                    },

                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'top',
                    horizontalAlign: 'left',
                    customLegendItems: ['PAD', 'PAS']
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        type: 'vertical',
                        gradientToColors: ['#00E396'],
                        inverseColors: true,
                        stops: [0, 100]
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                xaxis: {
                    tickPlacement: 'on'
                },
            },
            selectedData: [],
            modal: {
                title: '',
                modal_form: false,
                payload: {},
                dataId: []
            },
        };
    },
    props: {
        sendSheet: {
            type: Array
        },
        pos: Number,
        medical_act_id: {
            type: Number,
            default: 0
        },
        pageOrigin: {
            type: String,
            default: ''
        },
        rolname: {
            type: String,
            default: ''
        },
    },
    components: {
        apexchart: VueApexCharts,
        SweetAlert,
        cModalMonitoreo,
        EditableCell
    },
    watch: {
        sendSheet: {
            handler: function (newValue) {
                if (newValue && newValue.length > 0) {
                    const updatedItems = newValue.map(item => {
                        // Agregar una nueva propiedad
                        return {
                            ...item,
                            checked: (item.status == 1)
                        };
                    });
                    this.data = updatedItems;
                    this.mtgetsheet();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdColorColumn(col, rowIndex) {

            // this.rowGroups =  [7, 3, 4, countvaluesVasoActivo,countvaluesVasoActivo,3, 18];

            let numMin4 = 15 + this.rowGroups[3];
            let numMin5 = numMin4 + this.rowGroups[4];

            if (rowIndex >= 0 && rowIndex < 8) {
                return 'bg-light-blue';
            } else
            if (rowIndex >= 8 && rowIndex < 11) {
                return 'bg-light-green';
            } else
            if (rowIndex >= 11 && rowIndex < 15) {
                return 'bg-light-yellow';
            } else
            if (rowIndex >= 15 && rowIndex < numMin4) {
                return 'bg-light-orange';
            } else
            if (rowIndex >= numMin4 && rowIndex < numMin5) {
                return 'bg-light-pink';
            } else
            if (rowIndex >= numMin5 && rowIndex < (numMin5 + 3)) {
                return 'bg-indigo';
            } else
            if (rowIndex >= (numMin5 + 3) && rowIndex < (numMin5 + 21)) {
                return 'bg-light-red';
            }
        },
        mtdBgTitle(rowIndex) {
            //color a los titulos
            let title = this.getRowTitle(rowIndex);
            switch (title) {
                case 'FUNCIONES VITALES':
                    return 'bg-title-blue';
                case 'OTROS':
                    return 'bg-title-green';
                case 'GLASGOW':
                    return 'bg-title-yellow';
                case 'VASOACTIVOS':
                    return 'bg-title-orange';
                case 'SEDACION':
                    return 'bg-title-pink';
                case 'OXIGENOTERAPIA':
                    return 'bg-title-purple';
                default:
                    return 'bg-title-red';
            }
        },
        isFirstRowOfGroup(rowIndex) {
            let cumulativeRows = 0;
            for (let groupSize of this.rowGroups) {
                if (rowIndex === cumulativeRows) {
                    return true;
                }
                cumulativeRows += groupSize;
            }
            return false;
        },
        getRowSpan(rowIndex) {
            let cumulativeRows = 0;
            for (let groupSize of this.rowGroups) {
                if (rowIndex === cumulativeRows) {
                    return groupSize;
                }
                cumulativeRows += groupSize;
            }
            return 1; // Por defecto, si no coincide con ningún grupo
        },
        getRowTitle(rowIndex) {
            let cumulativeRows = 0;
            let index = -1;
            for (let groupSize of this.rowGroups) {
                index += 1;
                if (rowIndex === cumulativeRows) {
                    return this.rowTitle[index];
                }
                cumulativeRows += groupSize;
            }
            return "OTROS";
        },
        mtgetsheet() {
            this.columns = [];
            this.temperatura = [];
            this.dataPad = [];
            this.dataPas = [];
            this.columns.push({
                label: "",
                field: "name"
            });
            this.createRow();

            this.data.forEach((element, index) => {

                /* TRAER HORAS */
                const newField = `value${index}`;

                this.columns.push({
                    label: element.hour,
                    field: newField
                });
                let all = [];
                const propertyNames = [
                    'values',
                    'valuesOthers',
                    'valuesGlasgow',
                    'valuesVasoActivo',
                    'valuesSedacion',
                    'valuesOxinoterapia',
                    'valuesmonitoreo'
                ];
                propertyNames.forEach(property => {

                    const parsedValues = JSON.parse(element[property]);
                    if (['valuesGlasgow', 'valuesVasoActivo', 'valuesSedacion'].includes(property)) {
                        let lengLast = JSON.parse(this.data[this.data.length - 1][property]);
                        let lengActual = parsedValues.length;
                        let diff = lengLast.length - lengActual;
                        //console.log('row' + index + " - "+ diff);
                        let ultimo = null;

                        if (diff > 0) {
                            if (property == 'valuesGlasgow') {
                                ultimo = parsedValues.pop();
                            }

                            parsedValues.forEach(elementValues => {
                                all.push(elementValues);
                            });
                            //COMPLETAR 
                            for (let i = 0; i < diff; i++) {
                                const newItem = lengLast[lengActual + i];
                                newItem.value = "";
                                all.push(newItem);

                            }
                            if (property == 'valuesGlasgow') {
                                all.push(ultimo);

                            }
                        } else {
                            parsedValues.forEach(elementValue => {
                                all.push(elementValue);
                            });
                        }
                    } else if (property == 'valuesOxinoterapia') {
                        parsedValues
                            .filter(el => el.id !== 4 && el.id !== 5)
                            .forEach(el => all.push(el));
                    } else {
                        parsedValues.forEach(element => {
                            all.push(element);
                        });
                    }

                });
                //unirlo

                this.rows.forEach((row, indexrow) => {
                    let value = all[indexrow] ? all[indexrow].value : "";
                    this.$set(row, newField, value);

                    /* completar valores para graficos */
                    if (indexrow == 4) {
                        this.temperatura.push(value);
                    }

                    if (indexrow == 0) {
                        this.dataPas.push(value);
                    }
                    if (indexrow == 1) {
                        this.dataPad.push(value);
                    }

                });

            });

            this.updateChartData();
            this.updateChartDataPad();

        },
        createRow() {
            this.rows = [];
            const propertyNames = [
                'values',
                'valuesOthers',
                'valuesGlasgow',
                'valuesVasoActivo',
                'valuesSedacion',
                'valuesOxinoterapia',
                'valuesmonitoreo'
            ];

            // Itera sobre los nombres de las propiedades y las procesa dinámicamente
            let page = this.data.length - 1;
            let countvaluesVasoActivo = JSON.parse(this.data[page]['valuesVasoActivo']).length;
            let countvaluesSedacion = JSON.parse(this.data[page]['valuesSedacion']).length;
            propertyNames.forEach(property => {
                const parsedValues = JSON.parse(this.data[page][property]);
                parsedValues.forEach(element => {
                    if (property == 'valuesOxinoterapia') {
                        //se elimina minutos
                        if (element.id !== 4 && element.id !== 5) {
                            this.rows.push({
                                name: element.label,
                                value0: element.value,
                            });
                        }
                    } else {
                        this.rows.push({
                            name: element.label,
                            value0: element.value,
                        });
                    }
                });
            });

            this.rowGroups = [8, 3, 4, countvaluesVasoActivo, countvaluesSedacion, 3, 18];
        },
        updateChartData() {
            this.series = [{
                name: 'Temperatura',
                data: this.temperatura.map(item => item)
            }];
            let cabeceras = []
            this.columns.forEach((element, ind) => {
                if (ind > 0) {
                    cabeceras.push(element);
                }
            });
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    ...this.chartOptions.xaxis,
                    categories: cabeceras.map(item => item.label)
                }
            };

            this.$nextTick(() => {
                if (this.$refs.apexchart1) {
                    this.$refs.apexchart1.updateSeries(this.series);
                    this.$refs.apexchart1.updateOptions(this.chartOptions);
                }
            });

        },
        updateChartDataPad() {
            let dataSerie2 = [];
            let cabeceras = []
            this.columns.forEach((element, ind) => {
                if (ind > 0) {
                    cabeceras.push(element);
                }
            });

            // Itera sobre los elementos restantes
            cabeceras.forEach((element, ind) => {
                dataSerie2.push({
                    x: element.label,
                    y: [this.dataPad[ind], this.dataPas[ind]]
                });
            });

            this.series2[0].data = dataSerie2;

            this.$nextTick(() => {
                if (this.$refs.apexchart) {
                    this.$refs.apexchart.updateSeries(this.series2);
                }
            });

        },
        addColumn() {
            this.columnCount += 1; // Incrementamos el contador de columnas
            const newField = `field${this.columnCount}`;

            // Añadimos una nueva columna al array de columnas
            this.columns.push({
                label: `Columna ${this.columnCount}`,
                field: newField
            });

            // Añadimos el nuevo campo a cada fila con un valor predeterminado
            this.rows.forEach(row => {
                this.$set(row, newField, 'Valor'); // Puedes cambiar 'Valor' por un valor por defecto
            });
        },

        mtdaddgrafica() {
            this.selectedData = [];
            this.data.forEach(element => {
                if (element.checked == true && element.status == 0) {
                    let newItem = {
                        id: element.id,
                        weight: element.weight,
                        date: element.date,
                        status: element.status
                    };
                    this.selectedData.push(newItem);
                }
            });

            //console.log("Datos seleccionados para guardar:", this.selectedData);

            if (this.selectedData.length == 0) {
                this.$refs.SweetAlert.showWarning("Seleccione Fechas");
            } else {
                this.post({
                        url: this.$store.getters.get__url + "/Uciadulto/mtdGetDataGra",
                        token: this.$store.getters.get__token,
                        params: {
                            data: this.selectedData,
                            medical_act_id: this.medical_act_id
                        }
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            this.modal.title = 'Monitoreo - Grafica';
                            this.modal.payload = response.data;
                            this.modal.dataId = this.selectedData.map(item => item.id);
                            this.modal.modal_form = true;
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }
        },

        //Modal
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, dataId) {
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/editStatusSheet",
                    token: this.$store.getters.get__token,
                    params: {
                        data: dataId,
                        payload: payload_detail
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit("mtgetsheet");
                    this.selectedData = [];
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        //cambiar la hora en la cabecera
        updateHour: function (value, index) {
            let id = this.data[index - 1].id;
            this.columns[index].label = value;
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/updateHour",
                    token: this.$store.getters.get__token,
                    params: {
                        id: id,
                        hour: value,
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$emit("mtgetsheet");
                        this.$refs.SweetAlert.showSuccess('HORA EDITADA EXITOSAMENTE');
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        }

    }
};
</script>

<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='HistoriaSop'">
                <Hc :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></Hc>
            </div>
            <!-- PRE OPERATORIO -->
             <div id="myTabContentHijo">
                 <div v-if="component=='Ordensop'">
                     <Ordensop :medical_act_id="medical_act_id" :addOrder="addOrder" @finishAddOrder="finishAddOrder" :rolname="rolname"></Ordensop>
                 </div>
                 <div v-if="component=='Rxqx'">
                     <Rxqx :medical_act_id="medical_act_id" :rolname="rolname" :addRxqx="addRxqx" @finishAddOrder="finishAddOrder" ></Rxqx>
                 </div>
                 <div v-if="component=='consentimientos'">
                     <Consentimiento :medical_act_id="medical_act_id"/>
                 </div>
                 <div v-if="component=='Informeoperatorio'">
                     <Informeoperatorio :medical_act_id="medical_act_id" :rolname="rolname" ></Informeoperatorio>
                 </div>
                 <div v-if="component=='preanestecia'">
                     <Preanestecia :medical_act_id="medical_act_id" :addPreAnestecia="addPreAnestecia" @finishAddOrder="finishAddOrder"></Preanestecia>
                 </div>
             </div>
             <!-- INTRAOPERATORIO -->
             <div id="myTabContentHijo" v-if="component=='Rp'">
                 <Rp :medical_act_id="medical_act_id" :dete_out="date_out" :process="process" :rolname="rolname"  :dete_out_doctor="date_out_doctor"></Rp>
             </div>
            <div id="myTabContentHijo" v-if="component=='Kardex'">
                <Kardex :medical_act_id="medical_act_id" :process="process" :datapatients="datapatients" :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></Kardex>
            </div>
            
            <div id="myTabContentHijo" v-if="component=='Bhe'">
                <Bhe :medical_act_id="medical_act_id" :rolname="rolname" :process="process"></Bhe>
            </div>
            <div id="myTabContentHijo" v-if="component=='Notaenfermeria'">
                <Notaenfermeria :medical_act_id="medical_act_id" :process="process"  :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></Notaenfermeria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hojaanestecia'">
                <Hojaanestecia :medical_act_id="medical_act_id" ></Hojaanestecia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Postanestecia'">
                <Postanestecia :medical_act_id="medical_act_id" @mtdaddrpexit="mtdaddrpexit" ></Postanestecia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Listaverificacion'">
                <Listaverificacion :medical_act_id="medical_act_id" @mtdaddrpexit="mtdaddrpexit" ></Listaverificacion>
            </div>
            <!-- POST OPERATORIO -->
            <div id="myTabContentHijo" v-if="component=='notaingreso'">
                <NotaIngreso :addNota="addNota" :medical_act_id="medical_act_id" :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor" @mtdaddrpexit="mtdaddrpexit"></NotaIngreso>
            </div>
            <div id="myTabContentHijo" v-if="component=='Mhemodinamico'">
                <Mhemodinamico :medical_act_id="medical_act_id"  :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor"></Mhemodinamico>
            </div>
            <div id="myTabContentHijo" v-if="component=='HojaG'">
                <HojaGrafica :medical_act_id="medical_act_id"  :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor"></HojaGrafica>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import Hc from '@/components/Asistencial/Sop/Historia/Hcsop.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue';
import NotaIngreso from '@/components/Asistencial/Sop/Historia/NotaIngresoUrpa.vue';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Bhe from '@/components/Asistencial/Sop/Historia/Bhe.vue';
import Mhemodinamico from '@/components/Asistencial/Sop/Historia/Mhemodinamico.vue';
import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Rxneumologico from '@/components/Asistencial/Sop/Historia/Rxneumologico.vue';
import Ci from '@/components/Asistencial/Sop/Historia/Ci.vue';
import Hojaanestecia from '@/components/Asistencial/Sop/Historia/Hojaanestecia.vue';
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
import Listaverificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';
import Consentimiento from '@/components/Asistencial/Sop/Historia/Consentimientos.vue';
import Notaenfermeria from '@/components/Asistencial/Sop/Historia/Notaenfermeria.vue';
import HojaGrafica from '@/components/Asistencial/Sop/Historia/Hojagrafica.vue';

export default {
    name: 'dropdown-component-Sop',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        },
        //new
        medical_act_id: {
            type: Number,
            default: 0
        },
        date_out: {
            type: Boolean
        },
        date_out_doctor: {
            type: Boolean
        },
        rolname: String,
        //order
        addOrder: {
            type: Number,
            default: 0
        },
        addRxqx: {
            type: Number,
            default: 0
        },
        addRp: {
            type: Number,
            default: 0
        },
        addReportSop: {
            type: Number,
            default: 0
        },
        addPreAnestecia: {
            type: Number,
            default: 0
        },
        addNota: {
            type: Number,
            default: 0
        },
        process: Number,
    },
    components: {
        Hc,
        Kardex,
        NotaIngreso,
        Rp,
        Bhe,
        Mhemodinamico,
        Ordensop,
        Preanestecia,
        Rxqx,
        Rxneumologico,
        Ci,
        Hojaanestecia,
        Postanestecia,
        Informeoperatorio,
        Listaverificacion,
        Consentimiento,
        Notaenfermeria,
        HojaGrafica,
    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.transformOrigin = 'top'; 
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        enter(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease';
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        beforeLeave(el) {
            el.style.transformOrigin = 'top'; 
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        leave(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease'; 
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        //funciones
        finishAddOrder: function(){
            this.$emit('finishAddOrder');
        },
        mtdaddrpexit:function(){
            this.$emit('mtdaddrpexit')
        }
    }
}
</script>

import { render, staticRenderFns } from "./Directa.vue?vue&type=template&id=643d1bd6&scoped=true"
import script from "./Directa.vue?vue&type=script&lang=js"
export * from "./Directa.vue?vue&type=script&lang=js"
import style0 from "./Directa.vue?vue&type=style&index=0&id=643d1bd6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643d1bd6",
  null
  
)

export default component.exports
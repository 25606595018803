<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show.sync="boo_modal"
      :close-on-backdrop="false"
      :centered="false"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row mx-0  mb-2">
                <div class="col-xl-8 px-0">
                    <label for="exampleFormControlInput1" class="form-label">Medicamento</label>
                    <input v-model="table_detail.name" type="text" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Digite Medicamento">
                </div> 
                <div class="col-xl-2 ps-xl-1 px-0">
                    <label for="exampleFormControlInput2" class="form-label">Cantidad</label>
                    <input v-model="table_detail.quantity" @keypress="validateNumberInput" type="text" class="form-control form-control-sm" id="exampleFormControlInput2" placeholder="Digite Cantidad">
                </div> 
                <div class="col-xl-2 ps-xl-1 mt-xl-0 mt-1 px-0 d-flex align-items-end">
                    <div class="w-100">
                        <button :disabled="mtddisabled"  @click="mtdGetData" type="button" class="btn btn-success btn-sm w-100">Agregar</button>
                    </div>
                </div> 

            </div>
            <div v-if="commonmedicationfrequency.length > 0">

              <table class="w-100 table table-bordered">
                  <thead class=" div-text div-title text-center">
                      <tr id="cabecera-fija">
                          <th class="align-middle" scope="col" rowspan="2">N°</th>
                          <th class="align-middle" scope="col" rowspan="2">INDICACIONES MEDICAS</th>
                          <th class="align-middle" scope="col" rowspan="2">HORA</th>
                      </tr>
                     
                  </thead>
                  <tbody v-if="commonmedicationfrequency.length > 0">
                      <template v-for="(item1) in commonmedicationfrequency">
                          <tr>
                              <td>{{item1.medicamentos[0].count  }}</td>
                              <td>
                                  {{ item1.medicamentos[0].name }}
                              </td>
                              
                              <td style="width: 120px;">
                                  <button @click="mtdaddhorariofrecuencia(item1.medicamentos[0])" type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                              </td>
                          </tr>
                          <template v-if="item1.medicamentos.length > 1">
                              <tr v-for="(medicine, index) in item1.medicamentos.slice(1)" >
                                      <td>{{ medicine.count  }}</td>
                                      <td>
                                          {{ medicine.name }}
                                      </td>
                                      <td style="width: 120px;">{{ medicine.hora }}
                                          <button @click="mtdaddhorariofrecuencia(medicine)"  type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                      </td>
                                  </tr> 
                          
                          </template>
  
                      </template>
  
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td colspan="6" class="text-center">
                              <span class="fw-bold"> No hay medicamentos </span>
                          </td>
                      </tr>
                  </tbody>
                  
              </table>
            </div>
            <!-- {{commonmedicationfrequency  }} -->
  
        </div>
      </div>
      <template #footer>
        <!-- <button class="btn btn-success btn-sm" @click="mtdGetData"  :disabled = "mtddisabledsave" >
            <i class="fas fa-save" />&nbsp; Agregar 
        </button> -->
        <button class="btn btn-danger btn-sm" @click="mtdClose"  >
            <i class="fas fa-times" />&nbsp; Cerrar
        </button>
     </template>
     <SweetAlert :swal="swal" ref="SweetAlert"  @mtddeletefrequency="mtddeletefrequency" />
    </CModal>
  </template>
  
  <script>
  import CModal from "@/components/shared/modals/CModal.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import { mapActions } from "vuex";
  export default {
    name: "c-pharmacy-cModalDetalle",
    components: {
      CModal,
      SweetAlert
    },
    data() {
      return {
        swal:null,
        table: [],
        table_detail:{
            name:'',
            quantity: 1
        },
        recomendation:'',
      };
    },
    created () {
    },
    computed:{
      mtddisabled:function(){
        return !(this.table_detail.name && this.table_detail.quantity)
      },
      mtddisabledsave:function(){
        return !this.table.length >0
      }
    },
    props: {
      title: String,
      boo_modal: Boolean,
      id_medical_frequency:Number,
      id_patient:Number,
      commonmedicationfrequency:Array
    },
    methods: {
      ...mapActions(["get", "post"]),
      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },
  
      mtdClose() {
        this.$emit("mtdCloseModalData");
        this.table=[]
        this.table_detail = {
            name:'',
            quantity: 1,
            medical_act_id: 0,
            patient_id:0
        }
        this.recomendation=''
      },
      mtdadd:function(){
        this.table.push(this.table_detail);
        // if (this.recomendation) {
        //     this.recomendation += ` \n${this.table_detail.name} ${this.table_detail.quantity}`;
        // } else {
        //     this.recomendation = `${this.table_detail.name} ${this.table_detail.quantity}`;
        // }
        this.table_detail = {
            name:'',
            quantity: 1
        }
      },
      mtddelete: function (index, msg) {
        this.$refs.SweetAlert.showDelete(index, msg);
      },
      mtddeletefrequency: function (index) {
        this.table.splice(index, 1);
      },
      mtdGetData: function () {
            // let data ={
               this.table_detail.medical_act_id = this.id_medical_frequency,
               this.table_detail.patient_id= this.id_patient,
               this.$emit('mtdaddfrecuencia',this.table_detail)
               this.mtdClose()
                // table: this.table,
                // recomendation:  this.recomendation
            // }
            // this.post({
            //     url: this.$store.getters.get__url + "/Hospitalization/AddFrequency",
            //     token: this.$store.getters.get__token,
            //     params:this.table_detail
            // }).then((response) => {
            //     this.patient = response.data;
            //     this.$refs.SweetAlert.showSuccess(response.message);
            //     
            // }).catch((error) => {
            //     this.$refs.SweetAlert.showError(error);
            // });
        },
        mtdaddhorariofrecuencia :function(payload){
          this.$emit('mtdaddhorariofrecuencia',payload)
        }
    },
  };
  </script>
  <style scoped>
  .align-middle{
    vertical-align: middle;
  }
  </style>
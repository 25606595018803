<template>
<div class="">
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content" style="background: white">
        <Home v-if="page == 'home' && verifBead == true" @changePage="changePage" @mtdBackCampus="mtdBackCampus" />
        <Open :boxes="boxes" v-if="verifBead == false" @mtdOpen="mtdOpen" @mtdBackCampus="mtdBackCampus" />
        <Close v-if="verifBead == true && page == 'reporteClose'" @mtdChangePage="changePage" :bead="bead" :originPage="area" :close='close' @mtdBackCampus="mtdBackCampus" />
        <Recetas v-if="page == 'recetas'" @returnHome="returnHome" @devoluciones="devoluciones" />
        <Directa v-if="page == 'directa'" @returnHome="returnHome" @changePageVen="changePageVen" />
        <Venta v-if="page == 'venta'" @returnHome="returnHome" :bead="bead" :client="client" @mtdCommitPayment="mtdCommitPayment" @changePageVen="changePageVen" />
        <Devoluciones v-if="page == 'return'" @returnHome="returnHome" :devolutions="devolutions" :subpage="subpage" :dataPatient="dataPatient" />
        <Anular v-if="page == 'anular'" @returnHome="returnHome" />
        <Inventario v-if="page == 'inventario'" @returnHome="returnHome" />
        <MedicSinStock v-if="page == 'sinStock'" @returnHome="returnHome" />
        <Mantenimiento v-if="page == 'maintenance'" @returnHome="returnHome" />
        <Movimiento v-if="page == 'movement'" @returnHome="returnHome" />
        <HistoMov v-if="page == 'histoMov'" @returnHome="returnHome" />
        <ProductDetails v-if="page == 'producto'" @returnHome="returnHome" />
        <MedicFrecuentes v-if="page == 'frecuente'" @returnHome="returnHome" />
        <Epp v-if="page == 'epp'" @returnHome="returnHome" />
        <OC v-if="page == 'oc'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" @mtdChangeFilter="mtdChangeFilter" />
    </div>
    <div class="footer">
        <FooterVue v-if="verifBead == true" @changePage="changePage" :pageView="page" />
    </div>
    <!-- notificaciones -->
    <template>
        <div>
            <!-- Contenedor del toast -->
            <div class="toast-container position-fixed top-0 end-0 p-3">
                <div class="toast mb-2" :id="'liveToast'+item.id" role="alert" aria-live="assertive" aria-atomic="true" ref="toastElement" v-for="(item, index) in notifications" :key="index">
                    <div class="toast-header ">
                        <strong class="me-auto color-main">
                            <i class="fa fa-bell"></i> DEVOLUCIONES N° {{ index + 1 }}
                        </strong>
                        <small class="text-body-secondary">Hace {{item.time}}</small>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body rounded-bottom text-xs bg-light-blue" @click="mtdShowNotification(item)">
                        <p class="fw-semibold mb-0">HOLA {{ user }}:</p>
                        <div class="" v-html="item.message">
                        </div>
                    </div>
                </div>
                <!-- Ver la cantidad que resta -->
                <div class="toast align-items-center text-bg-info border-0" id="msg" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body fw-bold">
                            + {{ diff }} devoluciones mas.
                        </div>
                        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <!-- button -->
    <div style="position: fixed; top: 90%; transform: translateY(-50%); right: 20px;">
        <button class="btn btn-secondary rounded-circle text-md" title="Devoluciones" type="button" data-bs-toggle="offcanvas" @click="mtdHiddenNotification(0)" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" data-bs-placement="top">
            <i class="fa fa-bell"></i>
        </button>
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="notificationsCanvas.length > 0">
            {{notificationsCanvas.length}}
            <span class="visually-hidden">unread messages</span>
        </span>
    </div>
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <div class="offcanvas-header border">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Devoluciones</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="border border-main p-2">
                <div class="home__wait-div">
                    <div class="row my-1">
                        <div class="col-md-12 mt-2" v-for="(item, index) in notificationsCanvas" :key="index">
                            <div class="card div-pointer" @click="mtdShowNotification(item)">
                                <div class="card-header text-xs" :class="item.state == 1 ? 'bg-title-blue':''">
                                    <div class="">
                                        <strong>N° {{index + 1}} - Hace {{item.time}}</strong>
                                    </div>
                                </div>
                                <div class="card-body text-xs" :class="item.state == 1 ? 'bg-light-blue':'bg-white'">
                                    <p class="card-text mb-1" v-html="item.message"></p>
                                    <small v-if="item.state == 2" class="fst-italic text-success">{{ item.time_read }}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="notificationsCanvas.length == 0" class="text-center py-5">
                            <strong>No hay devoluciones actualmente</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import {
    onMounted
} from "vue";
import Close from "../../components/Admision/pages/ReportClose.vue";
import Nav from "../../components/Farmacia/Nav.vue";
import FooterVue from "../../components/Farmacia/Footer.vue";
import Home from "../../components/Farmacia/Home.vue";
import Recetas from "../../components/Farmacia/pages/Reci.vue";
import Directa from "../../components/Farmacia/pages/Directa.vue";
import Venta from "../../components/Farmacia/pages/Venta.vue";
import Devoluciones from "../../components/Farmacia/pages/Dev.vue";
import Anular from "../../components/Farmacia/pages/Anular.vue";
import Open from '@/components/Admision/pages/Open.vue';
import Inventario from '@/components/Farmacia/pages/Inventory.vue';
import MedicSinStock from '@/components/Farmacia/pages/MediSinStock.vue';
import Mantenimiento from '@/components/Farmacia/pages/Maintenance.vue';
import Movimiento from '@/components/Farmacia/pages/Movimiento.vue';
import HistoMov from '@/components/Farmacia/pages/HistoMov.vue';
import ProductDetails from '@/components/Farmacia/pages/ProductDetails.vue';
import MedicFrecuentes from '@/components/Farmacia/pages/MedicFrecuentes.vue';
import Epp from '@/components/Farmacia/pages/Epp.vue';
import OC from "@/components/Farmacia/pages/Ordendecompra.vue";
export default {
    name: "FarmaciaView",
    components: {
        Nav,
        Close,
        FooterVue,
        Home,
        Recetas,
        Directa,
        Venta,
        Devoluciones,
        Anular,
        Open,
        Inventario,
        MedicSinStock,
        Mantenimiento,
        Movimiento,
        HistoMov,
        ProductDetails,
        MedicFrecuentes,
        Epp,
        OC,
    },
    data() {
        return {
            prefix: "Pharmacy",
            area: 'Farmacia',
            bead: {},
            verifBead: false,
            boxes: [],
            page: "home",
            client: null,
            close: 0,
            user: "",
            devolutions: null,
            notifications: [],
            notificationsCanvas: [],
            diff: 0,
            subpage: "",
            dataPatient: {},
            //Oc
            filter: {},
        }
    },
    props: {
        rolname: String,
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdDataBead();
        this.user = this.$store.getters.get__user.name;

    },
    mounted() {
        this.mtdGetNotificatons();
        this.mtdGetAllNotifications();

    },
    watch: {
        page(newValue) {
            this.mtdGetAllNotifications();
            if (newValue === 'home') {
                this.mtdGetNotificatons();
            } else {
                this.mtdHiddenNotification();
            }
        },
    },

    methods: {
        ...mapActions(['get', 'post']),
        mtdDataBead() {
            this.get({
                    url: this.$store.getters.get__url + "/" + this.prefix + "/listBeads/" +
                        this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.boxes = response.data.boxes;
                    this.bead = response.data.bead;
                    this.verifBead = response.data.bead != null ? true : false;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        changePageVen: function (page, client) {
            this.client = page == "venta" ? client : page == "home" ? this.client : null;
            this.page = page;
        },
        changePage: function (page) {
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        devoluciones: function (payload) {
            this.devolutions = payload
            this.page = "return"
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdBackRol: function () {
            this.$emit("mtdBackRol");
        },
        mtdCommitPayment: function (res) {
            this.bead = res;
            this.page = "directa";
        },
        /** apertura */
        mtdOpen: function (data) {

            this.verifBead = true;
            this.page = "home";
            this.bead = data;
        },

        //Oc
        mtdChangePage: function (page) {
            this.page = page;
        },

        mtdChangeFilter: function (data) {
            this.filter = data;
        },


        //Notificaciones
        mtdGetNotificatons: function () {
            this.get({
                    url: this.$store.getters.get__url + "/" + this.prefix + "/returns/notifications/0",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let notifications = response.data;
                    this.notifications = notifications.slice(0, 3);
                    this.diff = notifications.length - this.notifications.length;
                    this.$nextTick(() => {
                        this.notifications.forEach(element => {
                            const toastElement = document.getElementById("liveToast" + element.id);
                            if (toastElement) {
                                const toast = new bootstrap.Toast(toastElement, {
                                    autohide: false
                                });
                                toast.show();
                            }
                        });
                        if (notifications.length > 3) {
                            //habilitar el mensaje restante
                            const toastElementMsg = document.getElementById("msg");
                            if (toastElementMsg) {
                                const toast = new bootstrap.Toast(toastElementMsg, {
                                    autohide: false
                                });
                                toast.show();
                            }
                        }
                    });

                })
                .catch((errors) => {
                    console.log(errors);
                });

        },
        mtdShowNotification(item) {
            this.page = 'return';
            this.subpage = 'devolucion'+item.id;
            this.dataPatient = {
                patient_id: item.patient_id,
                medical_act_id: item.medical_act_id,
            };
            
            this.viewAndClose();
            this.updateState(item);
        },
        viewAndClose() {
            let offcanvasElement = document.getElementById('offcanvasScrolling');
            let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
            if (offcanvasInstance) {
                offcanvasInstance.hide();
            }
        },
        updateState(item){
            if(item.state == 1){
                this.post({
                        url: this.$store.getters.get__url + "/" + this.prefix + "/returns/notifications/update",
                        token: this.$store.getters.get__token,
                        params: {
                            id: item.id
                        }
                    })
                    .then((response) => {
                        Swal.fire({
                        icon: "success",
                        title: "Notificacion Leida",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }
        },
        mtdHiddenNotification(option) {
            this.notifications = [];
            const toastElementMsg = document.getElementById("msg");
            if (toastElementMsg) {
                const toast = new bootstrap.Toast(toastElementMsg, {
                    autohide: false // El Toast no se ocultará automáticamente
                });
                toast.hide(); // Oculta el Toast
            }
            if (option == 0) {
                this.mtdGetAllNotifications();
            }
        },
        mtdGetAllNotifications: function () {
            this.get({
                    url: this.$store.getters.get__url + "/" + this.prefix + "/returns/notifications/1",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.notificationsCanvas = response.data;

                })
                .catch((errors) => {
                    console.log(errors);
                });
        }
    }

}
</script>

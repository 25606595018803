<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title"
        :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :scrollable="true">

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-4" id="document_type">CANTIDAD</span>
                        <input type="text" class="form-control form-control-sm" placeholder="Ingrese Cantidad"
                            v-model="dataRe.cantidad" @input="validateInput('cantidad')">
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-success btn-sm" @click="registrarReci">
                <i class="fas fa-save" />&nbsp; Agregar
            </button>
        </template>
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import { mapActions } from "vuex";
export default {
    name: "c--modal-carroVenta",
    props: {
        title: String,
        boo_modal: Boolean,
        item: [],
    },
    components: {
        CModal,
    },
    data() {
        return {
            dataRe: {
                cantidad: null,
            },
        };
    },
    created() {
    },
    computed: {
        cpData() {
            return this.item.map((element) => element);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        validateInput(field) {
            this.dataRe[field] = this.dataRe[field].replace(/[^0-9]/g, '');
            if (this.dataRe[field] === '0' || parseInt(this.dataRe[field]) < 1) {
                this.dataRe[field] = '';
            }
        },

        mtdClose() {
            this.$emit("mtdCloseModalDeta");
        },

        registrarReci() {

            if (this.dataRe.cantidad && parseInt(this.dataRe.cantidad) > 0) {
                const newItem = {
                    ...this.item,
                    cantidad: this.dataRe.cantidad,
                };
                this.$emit("registrarReci", newItem);
                this.dataRe.cantidad = '';
                this.mtdClose();
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Cantidad no válida',
                    text: 'La cantidad debe ser un número mayor a 0.',
                    confirmButtonColor: '#900052',
                });
            }
        },


        handleDatosAgregados(datos) {
            this.$emit("registrarReci", datos);
            this.mtdClose();
        },
    },
};
</script>


<style scoped>

</style>
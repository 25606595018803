<template>
<div id="app">
    <div class="d-flex justify-content-center text-white h-100 w-100 mt-3 p-content" v-if="option == 0">
        <div class="mx-0 col-md-12 h-100 order-lg-1 rounded-3 px-0 bg-blue mb-2">
            <div class="row mx-0">
                <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                    <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                    <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                </div>
                <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                    <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                    <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center  ">{{datapatients.patient_year }}</span>
                </div>
            </div>
        </div>
    </div>
    <section class="mt-4" v-if="option == 0">
        <div class="p-content-sop">
            <div class="d-lg-flex gap-2 mt-2 p-2">
                <!-- Card 1 -->
                <div class="col-md-12 col-lg-4 mb-4" @click="mtdShowProcess(1)">
                    <article class="card card-book shadow h-100 text-center">
                        <h4 class="card-title mt-3">PRE OPERATORIO</h4>
                        <div class="icon-book p-3">
                            <img class="img-fluid-bed rounded" src="../../../assets/sop/pre_sop.png" alt="Icono de Consultas" />
                        </div>
                        <div class="px-3">
                            <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="percentagePre" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="bgprogressbar(percentagePre)" :style="'width: '+percentagePre+'%;'">{{percentagePre}}%</div>
                            </div>
                        </div>
                    </article>
                </div>
                <!-- Card 2 -->
                <div class="col-md-12 col-lg-4 mb-4" @click="mtdShowProcess(2)">
                    <article class="card card-book shadow h-100 text-center">
                        <h4 class="card-title mt-3">OPERATORIO</h4>
                        <div class="icon-book p-3">
                            <img class="img-fluid-bed rounded" src="../../../assets/sop/cirugia.png" alt="Icono de Emergencia" />
                        </div>
                        <div class="px-3">
                            <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="percentageIntra" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="bgprogressbar(percentageIntra)" :style="'width: '+percentageIntra+'%;'">{{percentageIntra}}%</div>
                            </div>
                        </div>
                    </article>
                </div>
                <!-- Card 3 -->
                <div class="col-md-12 col-lg-4 mb-4" @click="mtdShowProcess(3)">
                    <article class="card card-book shadow h-100 text-center">
                        <h4 class="card-title mt-3">POST OPERATORIO</h4>
                        <div class="icon-book p-3">
                            <img class="img-fluid-bed rounded" src="../../../assets/sop/post.png" alt="Icono de Hospitalización" />
                        </div>
                        <div class="px-3">
                            <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="percentagePost" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="bgprogressbar(percentagePost)" :style="'width: '+percentagePost+'%;'">{{percentagePost}}%</div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
    <cPreOperatorioVue v-if="option == 1" :rolname="rolname" :datapatients="datapatients" @mtdBackSop="mtdBackSop"></cPreOperatorioVue>
    <cIntraOperatorio v-if="option == 2" :rolname="rolname" :date_out="date_out" :date_out_doctor="date_out_doctor" :datapatients="datapatients" @mtdBackSop="mtdBackSop"></cIntraOperatorio>
    <cPostOperatorio v-if="option == 3" :rolname="rolname" :typeDoctor="typeDoctor" :datapatients="datapatients" @mtdBackSop="mtdBackSop"></cPostOperatorio>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import cPreOperatorioVue from './Process/PreOperatorio.vue';
import cIntraOperatorio from './Process/IntraOperatorio.vue';
import cPostOperatorio from './Process/PostOperatorio.vue'
export default {
    name: 'c-asistencial-sop-v2',
    components: {
        cPreOperatorioVue,
        cIntraOperatorio,
        cPostOperatorio
    },
    data() {
        return {
            option: 0,
            datapatients: {
                patient_id: '',
                patient_name: '',
                patient_year: '',
                medical_act_id: 0,
            },
            percentagePre: 0,
            percentageIntra: 0,
            percentagePost: 0,
        }
    },
    props: {
        stateHistoryShow: {
            type: Number,
        },
        viewInput: {
            type: Number,
        },
        newhistorial: {
            type: Object,
            default: () => ({})
        },
        newhistorialtable: {
            type: Object,
            default: () => ({})
        },
        rolname: String,
        typeDoctor: {
            type: Number,
            default: 1,
        }

    },
    watch: {
        newhistorial: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.getPatient(newValue, 'cola');

                }
            },
            deep: true,
            immediate: true,
        },
        newhistorialtable: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.getPatient(newValue, 'table');
                    
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ... mapActions(['get', 'post']),
        bgprogressbar: function(porcentaje){
            return {
                'bg-danger': porcentaje > 0 && porcentaje <= 25,
                'bg-warning': porcentaje > 25 && porcentaje <= 50,
                'bg-primary': porcentaje > 50 && porcentaje <= 75,
                'bg-info': porcentaje > 75 && porcentaje < 100,
                'bg-success': porcentaje === 100,
            };
        },
        getPatient: function (datos, origin) {
            this.date_out_doctor = datos.status_medical == 1 ? true : false
            this.date_out = datos.date_out ? true : false
            this.origin = (origin == 'cola');
            this.showHistoryComplet = 1;
            this.patient = datos;
            this.mtdClear();
            this.mtdViewPorcentage();
            if(origin == 'cola'){
             this.$emit('validation',datos);
            }

        },
        mtdClear: function () {
            this.datapatients = {
                patient_id: this.patient.patient_id,
                patient_year: this.patient.patient_year,
                patient_name: this.patient.patient_name,
                medical_act_id: this.patient.medical_act_id,
            }
            this.viewinputs = 0
            this.viewMedico = 0
            this.Clinicahistory = this.patient;
        },
        //NUEVAS FUNCIONES
        mtdShowProcess: function (numberProcess) {
            this.option = numberProcess;
            this.mtdViewPorcentage();

        },
        mtdBackSop: function () {
            this.option = 0;
            this.mtdViewPorcentage();

        },
        mtdViewPorcentage: function(){
            //ver los porcentajes 
            this.get({
                url: this.$store.getters.get__url + "/Sop/percentageProcess/"+this.patient.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.percentagePre =  parseInt(response.data.totalPreoperatorio);
                this.percentageIntra =  parseInt(response.data.totalIntraoperatorio);
                this.percentagePost =  parseInt(response.data.totalPostoperatorio);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

}
</script>

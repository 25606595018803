
<template>
    <div>
        <div class="container">
            <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            <div id="div-modal-cxc" class="container d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>MEDICAMENTOS FRECUENTES</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-12 px-1">
                                        <div class="input-group">
                                            <span class="input-group-text text-white bg-main">
                                                <i class="fas fa-search"></i>
                                            </span>
                                            <input 
                                                v-model="search" 
                                                @keydown.enter="mtdGetData" 
                                                type="text" 
                                                class="form-control" 
                                                placeholder="Buscar por DNI" 
                                                @keypress="validateNumberInput"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3 d-flex justify-content-center" id="content-timelinetable">
                                <div class="col-3" v-if="data == 2">
                                    <div v-if="patient" class="border-main text-center border border-2 card col m-2 p-2"  style="height: 250px;">
                                        <div class="card-body" >
                                            <h6 class="card-title color-main">{{ patient.reservation.patient.name }}</h6>
                                            <div v-if="!patient.reservation.patient.sex_type || patient.reservation.patient.sex_type ==2 " class="icon-book">
                                                <img src="../../../assets/avatar-de-hombre.png" alt="" class="img-fluid-bed-sop rounded-start">
                                            </div>
                                            <div v-else class="icon-book">
                                                <img src="../../../assets/avatar-de-mujer.png" alt="" class="img-fluid-bed-sop rounded-start">
                                            </div>
                                            <span v-if="patient.bed" class="fw-bold"> CAMA: {{ patient.bed.name }}</span>
                                            <span v-else class="fw-bold"> CONSULTA</span>
                                            <div class="row ">
                                                <div class="col-xl-6 px-1">
                                                    <button @click="mtdopenmodal" type="button" class="btn btn-success w-100 btn-sm"><i class="fa fa-save"></i> Agregar</button>
                                                </div>
                                                <div class="col-xl-6 px-1">
                                                    <button @click="mtdopenmodalview" type="button" class="btn btn-info w-100 btn-sm"><i class="fa fa-eye"></i> Ver</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex justify-content-center fw-bold">
                                        <div>No hay resultados</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalMedicFrequency :patient_id="patient_id" :medical_act_id="medical_act_id"  :title="modal.title" :boo_modal="modal.modal_form" @mtdCloseModalData="mtdCloseModalData"  />
        <cModalMedicFrequencyview  :medical_act_id="medical_act_id_view"  :title="modalview.title" :boo_modal="modalview.modal_form" @mtdCloseModalDataview="mtdCloseModalDataview"  />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalMedicFrequency from "../Modals/cModalMedicFrequency.vue";
import cModalMedicFrequencyview from "../Modals/cModalMedicFrequencyview.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "listadoRecetas",
    components: {
        SweetAlert,
        cModalMedicFrequency,
        cModalMedicFrequencyview
    },
    data() {
        return {
            swal: null,
            search: '',
            patient: null,
            data:1,
            modal: {
                title: '',
                modal_form: false
            },
            modalview: {
                title: '',
                modal_form: false
            },
            patient_id:null,
            medical_act_id:null,
            medical_act_id_view:null
        };
    },
    created() {
        // this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/CommonMedications",
                token: this.$store.getters.get__token,
                params: {
                    dni: this.search
                }
            }).then((response) => {
                this.patient = response.data;
                this.data = 2
            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdopenmodal:function(){
            this.modal = {
                title: 'MEDICAMENTOS FRECUENTES',
                modal_form: true
            } 
            this.medical_act_id = this.patient.medical_act_id
            this.patient_id = this.patient.reservation.patient.id
        },
        mtdopenmodalview:function(){
            this.modalview = {
                title: ' LISTADO DE MEDICAMENTOS FRECUENTES',
                modal_form: true
            } 
            this.medical_act_id_view = this.patient.medical_act_id
           
        },
        mtdCloseModalData: function () {
            this.modal = {
                title: '',
                modal_form: false
            }
           
        },
        mtdCloseModalDataview: function(){
            this.modalview = {
                title: '',
                modal_form: false
            }
            this.medical_act_id_view = null
        },
        validateNumberInput(event) {
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (!regex.test(event.key)) {
            event.preventDefault();
            }
        },
    },
};
</script>

<style scoped>
    @media (max-width: 536px) {
        .responsive-default {
            flex-direction: column;
        }
        .responsive-default > .py-1 {
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 0px;
        }
        .responsive-default-div {
            border-radius: 10px !important;
            text-align: center !important;
            font-size: 11px;
        }
    }
    .bootom {
        background-color: #900052 !important;
        border: 0.5px solid #900
    }
                                       
</style>

  
<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row d-flex mx-0 px-0">
                <div class="mb-2 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">LOTE</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.lote" placeholder="Lote">
                </div>
                <div class="mb-2 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">CANTIDAD ENTRANTE</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.quantity_receipt" 
                        placeholder="Cantidad"
                        @keydown="filterKeyDni"
                    > 
                </div>
                <div class="mb-2 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">PRECIO CON IGV</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.price" 
                        placeholder="Precio"
                        @keypress="isNumber($event)" 
                    >
                </div>
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">CODIGO DE BARRAS (11)</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.barcode" 
                        placeholder="Código de Barras"
                        maxlength="11" 
                        @keydown="filterKeyDni"
                        >
                </div>
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">F. VENCIMIENTO</label>
                    <input type="date" class="form-control form-control-sm" v-model="data_detail.date" 
                        :min="minDate"
                        @keypress="isNumber($event)"
                        >
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
              Editar
            </button>
        </template>
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import moment from "moment";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                barcode: '',
                lote: '',
                price: '',
                quantity_receipt: '',
                date: ''
            },
            minDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
          
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
            const item = this.data_detail;
            return (
                !item.lote || 
                !item.barcode || item.barcode.length !== 11 || 
                !item.date || 
                !item.price || parseFloat(item.price) <= 0 || 
                !item.quantity_receipt || parseFloat(item.quantity_receipt) <= 0
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.mtdClear();
            this.$emit("closeModal");
        },

        mtdClear() {
            this.data_detail = {
                barcode: '',
                lote: '',
                price: '',
                quantity_receipt: '',
                date: ''
            }
        },

        DataDetail() {
            this.$emit('Datadetail', this.data_detail);
            this.mtdClose();
        },

        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        filterKeyDni: function (e) {
            let b = false;
            b = /^\d$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
    },
    

};
</script>
<template>
<CModal class="" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="modalSize" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div  class="row m-4">
        <div class="row mx-0 px-0" v-if="dataCies.length > 0">
            <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in dataCies" :key="index">
                <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100 border border-main border-3" @click="mtdaddDiagnosticoUnit(item)">
                    <button type="button" class="btn w-100 text-xs h-100 ">
                        {{item.name}} <span v-if=" item.code"> ({{ item.code }})  </span> 
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center fw-bold" v-else>
            <span>No hay registros</span>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            data: [],
            datapresentation: [],
            pos: null,
            windowWidth: window.innerWidth
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    computed: {
        modalSize() {
            return this.windowWidth >= 992 ? 'xl' : 'md';
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    props: {
        title: String,
        boo_modal: Boolean, 
        dataCies:{
            type: Array,
            default: () => []
        }
    },
    watch: {
        

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalCie')
        },
        mtdaddDiagnosticoUnit: function (payload) {
            this.$emit('mtdaddDiagnosticoUnit', payload)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
}
</script>    
<template>
  <div class=" mt-2">
    <div class="row mb-2 px-2">
      <div class="col-md-12 d-md-block d-lg-none my-2">
        <button type="button" class="btn btn-sm btn-outline-bm text-xs w-100" @click="mtdstoredata()">
          <i class="fas fa-save"></i> GUARDAR
        </button>
      </div>
      <!-- Ficha de Recuperación post Anestesia -->
      <div class="col-md-12">
        <div class="d-md-flex gap-1">
          <div class="col-md-4 mb-2 align-left" v-for="(item2, index) in postAnestecia.staff" :key="index">
            <div class="">
              <label :for="'input2' + index" class="text-xs color-main fw-bold">{{ item2.label }}</label>
              <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm"
                :id="'input2' + index" />
            </div>
          </div>
        </div>
      </div>
      <!-- Evaluación a su ingreso -->
      <div class="col-md-12 mt-2">
        <div class="row mb-2">
          <div class="col text-center py-2 div-title rounded">
            <strong class="text-uppercase">Evaluación a su ingreso</strong>
          </div>
        </div>
        <div class="d-md-flex gap-2">
          <div class="col-lg-4 col-md-4 mb-2 align-left">
            <div>
              <label for="hora" class="text-xs color-main fw-bold mb-2">Hora</label>
              <input type="time" v-model="postAnestecia.hour" class="form-control form-control-input form-control-sm"
                id="hora" />
            </div>
          </div>
          <div class="col-lg-4 col-md-4 mb-2 align-left">
            <div class="form-group form-group-sm w-100">
              <label for="nurse" class="w-100 text-sm-start color-main fw-bold text-xs">Enfermera</label>
              <v-select class="form-control-sm rounded" input-id="nurse" :options="dataNurse"
                v-model="postAnestecia.nurse" :reduce="nurse => nurse" label="fullname" placeholder="- Buscar Enfermera -" required :clearable="false" @input="mtdCompletedCie()">
                <template slot="no-options">
                  No hay diagnósticos disponibles.
                </template>
              </v-select>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 mb-2 align-left">
            <div>
              <label for="cep" class="text-xs color-main fw-bold mb-2">CEP</label>
              <input type="text" class="form-control form-control-input form-control-sm" id="cep" disabled v-model="postAnestecia.cep" />
            </div>
          </div>
        </div>
        <!-- score -->
        <div class="d-lg-flex gap-1 px-2 mt-2">
          <div class="col-lg-4 col-md-12 mb-2">
            <!-- score_aldrete -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold ">SCORE ALDRETE</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_aldrete">
                <div class="d-md-flex">
                  <div class="col-md-6 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-md-6">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa'+index" :id="'d1sa'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d1sa'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa'+index" :id="'d2sa'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d2sa'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa'+index" :id="'d3sa'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d3sa'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 my-2">
                <div class="d-md-flex">
                  <div class="col-lg-6 col-md-6 align-left">
                    <label for="total" class="fw-bold color-main ">Total: </label>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <input class="form-control form-control-sm form-control-input" type="text" name="total" id="total" v-model="cpTotalScoreAldrete"
                     disabled>
                  </div>
                </div>
              </div>
            </div>
            <!-- score_aldrete -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">SCORE STEWARD</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_steward">
                <div class="d-md-flex">
                  <div class="col-md-6 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-md-6">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorss'+index" :id="'d1ss'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d1ss'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorss'+index" :id="'d2ss'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d2ss'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorss'+index" :id="'d3ss'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d3ss'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-12 my-2">
                <div class="d-md-flex">
                  <div class="col-lg-6 col-md-6 align-left">
                    <label for="total" class="fw-bold color-main ">Total: </label>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <input class="form-control form-control-sm form-control-input" type="text" name="total" id="total" v-model="cpTotalSteward"
                     disabled>
                  </div>
                </div>
              </div>
            </div>
            <!-- score_bromage -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">SCORE BROMAGE</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_bromage">
                <div class="d-md-flex">
                  <div class="col-md-6 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-md-6">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsb'+index" :id="'d1sb'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d1sb'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsb'+index" :id="'d2sb'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d2sb'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsb'+index" :id="'d3sb'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d3sb'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- sintomas -->
          <div class="col-lg-4 col-md-12 mb-3">
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">OTROS SIGNOS / SINTOMAS</span>
            </div>
            <div class="row d-flex py-2 px-1 div-border pt-0">
              <div v-for="(item, index) in postAnestecia.signs" :key="index"
                class="custom-control custom-checkbox color-main align-left px-2 col-sm-12 col-md-12 col-xl-6 col-lg-12 mt-1">
                <input type="checkbox" class="custom-control-input visually-hidden" :id="'checkbox' + index"
                  v-model="item.state">
                <label class="custom-control-label fw-semibold" :for="'checkbox' + index">
                  <span class="checkmark"></span>
                  {{ item.label }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 mb-2">
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">Otros</span>
            </div>
            <div class="row py-2 px-1 div-border pt-0">
              <div class="col-md-12 mt-2 align-left" v-for="(item2, index) in postAnestecia.others" :key="index">
                <div class="form-group d-md-flex">
                  <label :for="'others' + index" class="col-xl-4 col-md-4 col-12 color-main fw-semibold">{{
                    item2.label }}</label>
                  <div class="col-xl-8 col-md-8 col-sm-12">
                    <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm"
                      :id="'others' + index" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- terapia -->
      <div class="col-md-12 my-2">
        <div class="row">
          <div class="col text-center fw-bold py-2 div-title rounded">
            <span class="text-uppercase">Terapeutico Proporcionado</span>
          </div>
        </div>
        <div class="row px-3 mt-2">
          <div class="col-md-12 mb-xl-0 mb-3 px-0 mx-0" id="content-timelinetable">
            <table class="w-100">
              <div class="row mx-0 mb-2 px-0">
                <table>
                  <caption class="div-text my-0 py-0"></caption>
                  <thead class=" txt-xs ">

                    <tr>
                      <th class=" text-start text-xs pb-2 " scope="col" colspan="8">
                        Seleccione Medicamentos:</th>

                    </tr>

                  </thead>
                  <tbody class="div-text  ">
                    <tr class="px-1">
                      <td colspan="2" class="w-25 z-index-select">
                        <input type="text" v-model="receta" placeholder="Molecula"
                          class="form-control form-control-input form-control-sm" @keyup.enter="mtdopenmodal" />
                      </td>
                      <td v-if="recipe_unit.presentation != '-'">
                        <input type="text" v-model="recipe_unit.presentation" placeholder="Presentacion"
                          class="form-control form-control-input form-control-sm" disabled />
                      </td>
                      <td v-if="!type">
                        <input type="text" v-model="recipe_unit.quantity" placeholder="Concentración"
                          class="form-control form-control-input form-control-sm" disabled />
                      </td>
                      <td v-if="!type || type_name == 3">
                        <input type="text" v-model="recipe_unit.via" placeholder="Via"
                          class="form-control form-control-input form-control-sm" />
                      </td>
                      <td v-if="!type">
                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency"
                          placeholder="Frecuencia (Horas) " class="form-control form-control-input form-control-sm" />
                      </td>
                      <td v-if="!type">
                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration"
                          placeholder="Duración (Días)" class="form-control form-control-input form-control-sm" />
                      </td>
                      <td v-if="type">
                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total"
                          placeholder="Total" class="form-control form-control-input form-control-sm" />
                      </td>
                      <td v-if="!type">
                        <button type="button" :disabled="validationInputrecipe || validation" @click="mtdaddrecipe()"
                          class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                      </td>
                      <td v-else style=" width: 50px;">
                        <button type="button" :disabled="validationtotal" @click="mtdaddrecipe()"
                          class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="row d-flex div-text mx-0 px-0 mb-2">
                <div class="col-xl-12 col-md-12 px-0 pe-xl-1 pe-0">
                  <table class="w-100 table table-bordered">
                    <caption class="div-text my-0 py-0"></caption>
                    <thead class=" div-text div-title text-center">
                      <tr>
                        <th scope="col">Rp.</th>
                        <th>Medicamentos</th>
                        <th>Presentacion</th>
                        <th>Concentración</th>
                        <th>Via</th>
                        <th>Dosis de la Mezcla</th>
                        <th>Frecuencia (Horas)</th>
                        <th>Duracion (Días)</th>
                        <th>Tipo</th>
                        <th>Grupo</th>
                        <th>PRN</th>
                        <th>Acciones</th>
                      </tr>

                    </thead>
                    <tbody class="div-text" v-if="postAnestecia.recipe.length >0">

                      <tr v-for="(recipe, index) in postAnestecia.recipe" :key="index" class="px-1">
                        <td>
                          <span>{{ index + 1 }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.molecule }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.presentation }} </span>
                        </td>
                        <td>
                          <span>{{recipe.group != 0 ? recipe.dosis : recipe.quantity
                            }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.via }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN DOSIS': '-') : recipe.quantity}}</span>
                        </td>
                        <td>
                          <span>{{ recipe.frequency }}</span>
                        </td>

                        <td>
                          <span>{{ recipe.duration }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.type == 2 ? 'INSUMO' : recipe.type == 3 ?
                            'SOLUCION': 'MEDICAMENTO' }}</span>
                        </td>
                        <td>
                          <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipe.group
                            }}</span>
                        </td>
                        <td>
                          <span v-if="recipe.type == 1 ">
                            <div class="form-check form-switch">
                              <input @input="mtdPrnGroup(recipe)" v-model="recipe.prn" class="form-check-input"
                                type="checkbox" id="flexSwitchCheckDefault">
                              <label class="form-check-label">{{ recipe.prn ?
                                'SI':'NO' }}</label>
                            </div>
                          </span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <button type="button" v-if="recipe.new" @click="mtddelete(index, 'mtddeleterecipe')"
                            class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody class=" text-center" v-else>
                      <tr>
                        <td colspan="12" class=" text-center">
                          <strong> No hay resultados</strong>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </table>

          </div>
        </div>
      </div>
      <!-- Evaluacion a su egreso -->
      <div class="col-md-12">
        <div class="row mb-2">
          <div class="col text-center fw-bold py-2 div-title rounded">
            <span class="text-uppercase">Evaluación a su egreso</span>
          </div>
        </div>
        <div class="d-lg-flex gap-3 px-3">
          <div class="col-lg-6 col-md-12">
            <!-- Scores -->
            <!-- score_aldrete -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">SCORE ALDRETE</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_aldrete_expense">
                <div class="d-md-flex">
                  <div class="col-lg-8 col-md-7 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa1'+index" :id="'d11sa'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d11sa'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa1'+index" :id="'d21sa'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d21sa'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsa1'+index" :id="'d31sa'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d31sa'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-12 my-2">
                <div class="d-md-flex">
                  <div class="col-lg-6 col-md-6 align-left">
                    <label for="total" class="fw-bold color-main ">Total: </label>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <input class="form-control form-control-sm form-control-input" type="text" name="total" id="total" v-model="cpTotalScoreAldreteExpense"
                     disabled>
                  </div>
                </div>
              </div>
            </div>
            <!-- score_aldrete -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">SCORE STEWARD</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_steward_expense">
                <div class="d-md-flex">
                  <div class="col-lg-8 col-md-7 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsse'+index" :id="'d1sse'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d1sse'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsse'+index" :id="'d2sse'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d2sse'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsse'+index" :id="'d3sse'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d3sse'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-12 my-2">
                <div class="d-md-flex">
                  <div class="col-lg-6 col-md-6 align-left">
                    <label for="total" class="fw-bold color-main ">Total: </label>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <input class="form-control form-control-sm form-control-input" type="text" name="total" id="total" v-model="cpTotalStewardExpense"
                     disabled>
                  </div>
                </div>
              </div>
            </div>
            <!-- score_bromage -->
            <div class="row  px-2 div-border pt-2 text-center">
              <span class="color-main fw-bold">SCORE BROMAGE</span>
            </div>
            <div class="row d-flex p-1 div-border pt-0">
              <div class="col-12" v-for="(item, index) in postAnestecia.score_bromage_expense">
                <div class="d-md-flex">
                  <div class="col-lg-8 col-md-7 align-left">
                    <span class="fw-semibold color-main">{{ item.label }}</span>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <div class="form-inline mx-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsbe'+index" :id="'d1sbe'+index"
                          :value="0" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d1sbe'+index">0</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsbe'+index" :id="'d2sbe'+index"
                          :value="1" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d2sbe'+index">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'valorsbe'+index" :id="'d3sbe'+index"
                          :value="2" v-model="item.value">
                        <label class="form-check-label text-md" :for="'d3sbe'+index">2</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 pb-3">
            <div class="row mb-2">
              <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                  <label for="tratamiento" class="fw-bold color-main">Ex. Auxiliares</label>
                  <textarea class="form-control input-textarea" id="tratamiento" rows="2" style="resize: none;"
                    v-model="postAnestecia.auxiliary_exams" />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                  <label for="resultados" class="fw-bold color-main">Resultados</label>
                  <textarea class="form-control input-textarea" id="resultados" rows="2" style="resize: none;"
                    v-model="postAnestecia.results" />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                  <label for="pendientes" class="fw-bold color-main">Pendientes</label>
                  <textarea class="form-control input-textarea" id="pendientes" rows="2" style="resize: none;"
                    v-model="postAnestecia.pending" />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                  <label for="complicaciones" class="fw-bold color-main">Complicaciones</label>
                  <textarea class="form-control input-textarea" id="complicaciones" rows="2" style="resize: none;"
                    v-model="postAnestecia.complications" />
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                  <label for="indicaciones" class="fw-bold color-main">Indicaciones Especiales</label>
                  <textarea class="form-control input-textarea" id="indicaciones" rows="2" style="resize: none;"
                    v-model="postAnestecia.special_indications" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleterecipe="mtddeleterecipe" />
    <Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title"
      :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
  </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Medicine from '@/components/Asistencial/UciAdult/modals/cModalMedicine.vue';

export default {
    name: 'c-asistencial-sop-historia-postanestecia',
    components: {
        SweetAlert,
        Medicine,
    },
    data() {
        return {
          dataNurse: [],
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false,
                new: true,
            },
            swal: null,
            type: false,
            posmedicine: null,
            validation: false,
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            postAnestecia: {
                staff: [{
                        id: 1,
                        label: 'Anestesiólogo',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Cirujano',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Tipo de Anestesia',
                        value: ''
                    },
                ],
                hour: '',
                nurse: '',
                nurse_id: null,
                cep: "",
                score_aldrete: [{
                        id: 1,
                        label: 'Parametros',
                        value: '',
                    },
                    {
                        id: 2,
                        label: 'Actividad Muscular',
                        value: '',
                    },
                    {
                        id: 3,
                        label: 'Respiracion',
                        value: '',
                    },
                    {
                        id: 4,
                        label: 'Circulación',
                        value: '',
                    },
                    {
                        id: 5,
                        label: 'Conciencia',
                        value: '',
                    },
                    {
                        id: 6,
                        label: 'Color',
                        value: '',
                    },

                ],
                score_steward: [{
                        id: 1,
                        label: 'Act. Motora',
                        value: '',
                    },
                    {
                        id: 2,
                        label: 'Conciencia',
                        value: '',
                    },
                    {
                        id: 3,
                        label: 'Respiracion',
                        value: '',
                    }
                ],
                score_bromage: [{
                    id: 1,
                    label: 'Act. Motora',
                    value: '',
                }],
                score_aldrete_expense: [{
                        id: 1,
                        label: 'Parametros',
                        value: '',
                    },
                    {
                        id: 2,
                        label: 'Actividad Muscular',
                        value: '',
                    },
                    {
                        id: 3,
                        label: 'Respiracion',
                        value: '',
                    },
                    {
                        id: 4,
                        label: 'Circulación',
                        value: '',
                    },
                    {
                        id: 5,
                        label: 'Conciencia',
                        value: '',
                    },
                    {
                        id: 6,
                        label: 'Color',
                        value: '',
                    },

                ],
                score_steward_expense: [{
                        id: 1,
                        label: 'Act. Motora',
                        value: '',
                    },
                    {
                        id: 2,
                        label: 'Conciencia',
                        value: '',
                    },
                    {
                        id: 3,
                        label: 'Respiracion',
                        value: '',
                    }
                ],
                score_bromage_expense: [{
                    id: 1,
                    label: 'Act. Motora',
                    value: '',
                }],
                signs: [{
                        id: 1,
                        label: 'Palidez',
                        value: false,
                    },
                    {
                        id: 2,
                        label: 'Cianosis',
                        value: false,
                    },
                    {
                        id: 3,
                        label: 'Hemorragia',
                        value: false,
                    },
                    {
                        id: 4,
                        label: 'Reac. Cutan. Anafil',
                        value: false,
                    },
                    {
                        id: 5,
                        label: 'Diaforesis',
                        value: false,
                    },
                    {
                        id: 6,
                        label: 'Tos',
                        value: false,
                    },
                    {
                        id: 7,
                        label: 'Bronco Espamo',
                        value: false,
                    },
                    {
                        id: 8,
                        label: 'Nauseas y/o vomitos',
                        value: false,
                    },
                    {
                        id: 9,
                        label: 'Hipo',
                        value: false,
                    },
                    {
                        id: 10,
                        label: 'Excitacion',
                        value: false,
                    },
                    {
                        id: 11,
                        label: 'Dolor',
                        value: false,
                    },
                    {
                        id: 12,
                        label: 'Escalofrios',
                        value: false,
                    },
                    {
                        id: 13,
                        label: 'Hipertension',
                        value: false,
                    },
                    {
                        id: 14,
                        label: 'Hipotermia',
                        value: false,
                    },
                    {
                        id: 15,
                        label: 'Hipertermia',
                        value: false,
                    },
                ],
                others: [{
                        id: 1,
                        label: 'Via área',
                        value: '',
                    },
                    {
                        id: 2,
                        label: 'Tubo endotraq',
                        value: '',
                    },
                    {
                        id: 3,
                        label: 'Tubo Orofaring',
                        value: '',
                    },
                    {
                        id: 4,
                        label: 'Fluidos',
                        value: '',
                    },
                    {
                        id: 5,
                        label: 'Soluc. Salina',
                        value: '',
                    },
                    {
                        id: 6,
                        label: 'Dextrosa 5% AD',
                        value: '',
                    },
                    {
                        id: 7,
                        label: 'Sangre',
                        value: '',
                    },
                    {
                        id: 8,
                        label: 'Otros',
                        value: '',
                    },
                    {
                        id: 9,
                        label: 'Cateterismo',
                        value: '',
                    },
                    {
                        id: 10,
                        label: 'S.N.G',
                        value: '',
                    },
                    {
                        id: 11,
                        label: 'Vesical',
                        value: '',
                    },
                    {
                        id: 12,
                        label: 'Arterial',
                        value: '',
                    },
                    {
                        id: 13,
                        label: 'Venoso',
                        value: '',
                    }
                ],
                auxiliary_exams: "",
                results: "",
                pending: "",
                complications: "",
                special_indications: "",
                recipe: [],
            },
            receta: '',
        };
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addPostAnestecia: {
            type: Number,
            default: 0
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdView();
                    this.mtdGetDataNurses();
                }
            },
            deep: true,
            immediate: true,
        },
        addPostAnestecia: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }
    },
    computed: {
        validationInputrecipe () {
            let value = true
            if (this.receta && this.recipe_unit.dosis && this.recipe_unit.presentation && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        cpTotalScoreAldrete (){
          let tot = 0;
          this.postAnestecia.score_aldrete.forEach(element => {
              let value = element.value?? 0;
              tot += parseInt(value);
          });
          return isNaN(tot) ? 0 : tot;
        },
        cpTotalScoreAldreteExpense(){
          let tot = 0;
          this.postAnestecia.score_aldrete_expense.forEach(element => {
              let value = element.value?? 0;
              tot += parseInt(value);
          });
          return isNaN(tot) ? 0 : tot;
        },
        cpTotalSteward(){
          let tot = 0;
          this.postAnestecia.score_steward.forEach(element => {
              let value = element.value?? 0;
              tot += parseInt(value);
          });
          return isNaN(tot) ? 0 : tot;
        },
        cpTotalStewardExpense(){
          let tot = 0;
          this.postAnestecia.score_steward_expense.forEach(element => {
              let value = element.value?? 0;
              tot += parseInt(value);
          });
          return isNaN(tot) ? 0 : tot;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetDataNurses: function () {
            this.get({
                url: this.$store.getters.get__url + "/nurse",
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataNurse = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        mtdCompletedCie: function (){
          this.postAnestecia.cep = this.postAnestecia.nurse.cep;
          this.postAnestecia.nurse_id = this.postAnestecia.nurse.id;
        },
        mtdstoredata: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storePostAnestecia",
                    token: this.$store.getters.get__token,
                    params: {
                        ... this.postAnestecia,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.postAnestecia = response.data;
                    this.$emit('mtdaddrpexit');
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2 || payload.type == 3) ? true : false
            this.type_name = payload.type
            this.receta = payload.molecule
            this.recipe_unit.order = this.postAnestecia.recipe.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            if (this.type) {
                this.recipe_unit.via = this.type_name == 3 ? '' : '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.quantity = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                this.recipe_unit.quantity = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
            }
        },
        mtdaddrecipe: function () {
            const exists = this.postAnestecia.recipe.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
            } else {
                this.postAnestecia.recipe.push(this.recipe_unit);

            }
            this.type = false
            this.receta = null
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false,
                new: true,
            }

        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);

        },
        mtddeleterecipe: function (indexTemp) {
            let index = this.postAnestecia.recipe.findIndex(item => item.order === this.idDelete);
            let num = this.postAnestecia.recipe[index].group
            if (this.postAnestecia.recipe[index].group != 0) {
                for (let j = this.postAnestecia.recipe.length - 1; j >= 0; j--) {
                    if (this.postAnestecia.recipe[j].group == num) {
                        this.postAnestecia.recipe.splice(j, 1);
                    }
                }
            } else {
                this.postAnestecia.recipe.splice(index, 1);
            }
            for (let i = 0; i < this.postAnestecia.recipe.length; i++) {
                this.postAnestecia.recipe[i].order = i + 1;
                if (this.postAnestecia.recipe[i].type == 1 && this.postAnestecia.recipe[i].molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            }
            if (!this.insuline_state) {
                this.postAnestecia.insuline = ''
            }
        },
        mtdopenmodal: function () {
            if (this.receta) {
                this.posmedicine = 1;
                this.modalmedicine = {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
            } else {
                this.$refs.SweetAlert.showWarning('Digite Molecula');
            }
        },
        mtdView: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Sop/indexPostAnestecia/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let data = response.data;
                    if (data) {
                        this.postAnestecia = data;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.receta = '',
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    quantity: '',
                    group: 0,
                    prn: false,
                    new: true,
                }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
}
</script>

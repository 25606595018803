<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true" :title="title">
    <div class="row">

        <div>
            <ul class="nav pb-0" id="myTab" role="tablist">
                <li v-for="(item, index) in dataDispatches" :key="index" class="nav-item p-1" role="presentation">
                    <button @click="selectPost(index)" :class="['item-button', 'nav-link', 'text-white', { active: index === posDis }]" :id="'tab-' + index" type="button">
                        Despacho {{ index + 1 }}
                    </button>
                </li>
            </ul>
            <div class="tab-content-oc" id="myTabContent-1" v-if="dataDispatches.length > 0">
                <!---CONTENIDO-->
                <div>
                    <div class="d-flex mod-h py-3 px-4 mx-1">
                        <div class="modal-title d-flex w-100 mt-4" id="exampleModalLabel">
                            <div class="bg-white d-flex w-25 px-2 py-2 me-2" style="width: 200px; border-radius: 10px">
                                <div>OBSERVACIONES:</div>
                                <div class="ms-2 w-100">
                                    <input class="form-control w-100 form-control-sm" id="observaciones" v-model="dataDispatches[posDis].observation" readonly />
                                </div>
                            </div>
                            <div class="bg-white d-flex w-25 px-2 py-2 me-2" style="width: 200px; border-radius: 10px">
                                <div>GUIA DE REMISION:</div>
                                <div class="ms-2 w-100">
                                    <input class="form-control w-100 form-control-sm" v-model="dataDispatches[posDis].guide_remission" readonly />
                                </div>
                            </div>
                            <div class="bg-white d-flex w-25 px-2 py-2 me-2" style="width: 200px; border-radius: 10px">
                                <div>FECHA:</div>
                                <div class="ms-2 w-100">
                                    <input class="form-control w-100 form-control-sm" v-model="dataDispatches[posDis].date_dispatch" readonly />
                                </div>
                            </div>
                            <div class="bg-white d-flex w-25 px-2 py-2 me-2" style="width: 200px; border-radius: 10px">
                                <div>ALMACEN:</div>
                                <div class="ms-2 w-100">
                                    <input class="form-control w-100 form-control-sm" id="exampleFormControlTextarea1" v-model="dataDispatches[posDis].almacens.name" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-4">
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <thead class="bg-main" style="font-size: 12px">
                                    <tr align="center" class="text-white">
                                        <th scope="col">N°</th>
                                        <th scope="col">PRODUCTO</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">PRESENTACIÓN</th>
                                        <th scope="col">CANTIDAD RECIBIDA</th>
                                        <th scope="col">BONUS</th>
                                        <th scope="col">LOTE</th>
                                        <th scope="col">FECHA DE VENCIMIENTO</th>
                                        <th scope="col">REGISTRO SANITARIO</th>
                                        <th scope="col">CODIGO DE BARRAS</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main" style="font-size: 12px">
                                    <tr class="tbody-table1" align="center" v-for="(fila, ind) in cpData" :key="ind">
                                        <td scope="row">
                                            {{ind+1}}
                                        </td>
                                        <td>{{ fila.productodetail.molecule }}</td>
                                        <td>{{ fila.productodetail.brand ? fila.productodetail.brand.name : '-' }}</td>
                                        <td>{{ fila.productodetail.presentation}}</td>
                                        <td>{{ fila.quantity_receipt }}</td>
                                        <td>{{ fila.bonus ? fila.bonus : '-' }}</td>
                                        <td>{{ fila.lote }}</td>
                                        <td>{{ fila.date }}</td>
                                        <td>{{ fila.register_sanitary }}</td>
                                        <td>{{ fila.barcode }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";
export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            posDis: 0,
            tablaDispatch: [],
            tablaDispatchDetail: [],
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataDispatches: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        cpData: function () {
            this.tablaDispatchDetail = this.dataDispatches[this.posDis].dispatch_details;
            return this.tablaDispatchDetail;

        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        selectPost: function (index) {
            this.posDis = index;
        },
        mtdclosemodal() {
            this.posDis = 0,
                this.$emit('mtdCloseModalViewDispatches')
        },

    }

}
</script>

<!-- <style scope>
.tab-content .d-flex.mod-h {
    background-color: white;
}

/* Anular el color de fondo para los botones dentro de la pestaña activa */
.nav-tabs .nav-link.active {
    background-color: white;
}

.item-button {
    background: #a9a9a9 !important;
    border-radius: 10px 10px 0px 0px;
    border: none !important;
    color: white !important;
    width: auto;
}

.nav-link.active {
    background: #900052;
}
.nav-link {
    color: white !important;
    font-weight: bold;
}

.active {
    background-color: #900052 !important;
}

.tab-content .d-flex.mod-h {
    background-color: white;
}
</style> -->

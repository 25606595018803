<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row d-flex">
            <div class="mb-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="name" class="form-label">DOSIS</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.dosis" 
                    placeholder="Dosis"
                >
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" :disabled="cpButton" @click="DataDetail">
            <i class="fas fa-save"/>&nbsp; Editar
        </button>
    </template>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import {mapActions} from "vuex";

export default {
    name: "c-logistica-modals-service",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                dosis: '',
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: Object,
        filter: Object,
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                
            },
            immediate: true,
        },
    },
    computed: {
        cpButton() {
            return this.data_detail.dosis === '';
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        
        mtdClose() {
            this.mtdClear();
            this.$emit("closeModal");
        },

        mtdClear() {
            this.data_detail.dosis = '';
        },

        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.filter);
            this.mtdClose();
        },
    },
};
</script>

<template>
<div>
    <!--tabla-->
    <div class="px-4">
        <!-- <h6 class="mt-1">
            <i class="fas fa-shopping-cart"></i> ORDENES DE COMPRAS - PENDIENTES
        </h6> -->
        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <p id="mydesc"></p>
            <table id="tb-anular" class="table table-bordered" aria-describedby="mydesc">
                <thead class="bg-main" style="font-size: 9px">
                    <tr class="text-white text-center">
                        <th scope="col" class="align-middle">FECHA OC</th>
                        <th scope="col" class="align-middle">ORDEN COMPRA</th>
                        <th scope="col" class="align-middle">PROVEEDOR</th>
                        <th scope="col" class="align-middle">RUC</th>
                        <th scope="col" class="align-middle">N° FACTURA</th>
                        <th scope="col" class="align-middle nowrap">F. FACTURA</th>
                        <th scope="col" class="align-middle nowrap">F. VENCIMIENTO</th>
                        <!-- <th scope="col" class="align-middle">S/ EXONERADO</th>
                        <th scope="col" class="align-middle">S/ INAFECTO</th> -->
                        <th scope="col" class="align-middle">MONEDA</th>
                        <!-- <th scope="col" class="align-middle">DETRACCION/ NO APLICA</th> -->
                        <th scope="col" class="align-middle">NO GRAVADA</th>
                        <th scope="col" class="align-middle">S/ VALOR DE VENTA</th>
                        <th scope="col" class="align-middle">S/ IGV</th>
                        <th scope="col" colspan="1" class="align-middle">
                            S/ TOTAL CON IGV
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            S/ COSTO DE ENVIO
                        </th>
                        <th scope="col" class="align-middle">S/ TOTAL FACTURA</th>
                        <th scope="col" class="align-middle">S/ PAGADO FACTURA</th>
                        <th scope="col" colspan="1" class="align-middle nowrap">S/ PENDIENTE F.</th>
                        <th scope="col" class="align-middle">DESPACHOS</th>
                    </tr>
                </thead>

                <tbody class="border-main" style="font-size: 9.8px">
                    <tr class="tbody-table" v-for="(items, index) in cpData" :key="index">
                    <template v-if="items.bill_id">
                        <td>
                            {{ items.date }}
                        </td>
                        <td>
                            {{ mtdGenerateCode(items.id) }}
                        </td>
                        <td>
                            {{ items.provider_name }}
                        </td>
                        <td>
                            {{ items.provider_ruc }}
                        </td>
                        <td @click="mtdOutFocusInput(index, items.id)">
                            <input id="input-factura" type="text" class="form-control form-control-sm input-cantidad" 
                                :class="{'input-with-opacity': isButtonDisabled && posEditnumber !== index,}" style="text-align: center" placeholder="N° de Factura" 
                                v-if="items.number == null" v-model="bill[index].number" 
                            />
                            <span v-else>
                                {{ items.number }}
                            </span>
                        </td>
                        <!---EDITAR FECHA DE EMISION FACTURA-->
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-f-factura" type="date" class="form-control form-control-sm input-cantidad" 
                                :class="{'input-with-opacity': isButtonDisabled && posEditnumber !== index,}" 
                                v-if="items.bill_date == null" v-model="bill[index].date" @change="mtdCalculeExperation(index)" 
                            />
                            <span v-else>
                                {{ items.bill_date }}
                            </span>
                        </td>
                        <!---EDITAR FECHA DE VENCIMIENTO FACTURA-->
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-f-factura" type="date" class="form-control form-control-sm input-cantidad" 
                                :class="{'input-with-opacity': isButtonDisabled && posEditnumber !== index,}"  
                                v-if="items.bill_due_date == null" v-model="bill[index].due_date" readonly 
                            />
                            <span v-else>
                                {{ items.bill_due_date }}
                            </span>
                        </td>
                        <td>
                            {{ items.money}}
                        </td>
                        <td>{{ items.not_engraved }}</td>
                        <td>{{ items.total_sin_IGV }}</td>
                        <td>{{ mtdGetIgv(index) }}</td>
                        <td>{{ items.total_IGV }}</td>
                        <td>{{ items.shipping_cost }}</td>
                        <!---EDITAR TOTAL DE PAGAR FACTURA-->
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" @input="validateAmount" 
                                :class="{'input-with-opacity': isButtonDisabled && posEditnumber !== index,}" style="text-align: center" placeholder="0.00" 
                                v-if="items.bill_total == null" v-model="bill[index].total" 
                            />
                            <span v-else>
                                {{ items.bill_total }}
                            </span>
                            <div v-if="!isValidInput[index]" class="error-message">
                                Monto no permitido
                            </div>
                        </td>
                        <!---paid-->
                        <td>
                            {{ items.paid == null ? '-' : items.paid }}
                        </td>
                        <td>
                            {{ items.pending == null ? '-' : items.pending }}
                        </td>
                        <td class="align-middle  d-flex justify-content-center">
                           <!--  <a class="acciones text-center px-2 py-2" href="#" @click="openModal(items.id, index)" :class="{ disabledbutton: !isValidInput[index] }">
                                <i class="fas fa-plus text-white"></i>
                            </a> -->
                            <a v-if="items.bill_id" class="acciones-2 text-center px-2 py-2" href="#" title="Mostrar Depachos" @click="openModalViewDispatch(items.bill_id, index)" style="margin-left: 2px">
                                <i class="fas fa-dolly text-white"></i>
                            </a>
                            <a class="acciones-3 text-center px-2 py-2" title="PDF OC" href="#" @click="viewfactura(items.id)" style="margin-left: 2px">
                                <i class="fas fa-file-pdf text-white"></i>
                            </a>
                            <!-- <a v-if="items.bill_id && !items.file" class="upload_pdf text-center px-2 py-2" title="Subir PDF Factura" @click="upload(items)" style="margin-left: 2px">
                                <i class="fas fa-upload text-white"></i>
                            </a> -->
                            <a v-if="items.bill_id && items.file" class="upload_pdf text-center px-2 py-2" title="PDF Factura" @click="viewPdf(items.file)" style="margin-left: 2px; cursor: pointer;">
                                <i class="fas fa-file-pdf text-white"></i>
                            </a>
                            <a v-if="items.bill_id" class="acciones-4 text-center px-2 py-2" href="#" title="Central de costos" @click="mtdModalCentralCost(index,2)" style="margin-left: 2px">
                                <i class="fas fa-chart-pie"></i>
                            </a>
                            <!-- <a class="acciones-5 text-center px-2 py-2 bg-warning" v-if="showButton(items)" href="#" @click="openModalStoreBill(items,index)" style="margin-left: 2px">
                                <i class="fas fa-file-invoice-dollar text-white"></i>
                            </a> -->

                        </td>
                    </template>
                    </tr>
                    <tr v-if="!cpData.length > 0" class="text-center">
                        <th scope="col" colspan="18">NO SE ENCONTRARON RESULTADOS</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-center mt-3" style="align-items: center">
            <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                    </li>
                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                    </li>
                    <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] <
                stepPagination.length - 1
              " class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] !==
                stepPagination.length
              " class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                    </li>
                    <li class="page-item">
                        <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <!-- <div class="d-flex justify-content-end mt-5">
        <button class="d-flex justify-content-end bg-main border-0 text-white px-5 py-1">
            NOTIFICAR
        </button>
    </div> -->

    <cModalStoreDispatch :title="modalStoreDispatch.title" :bill="modalStoreDispatch.bill_id" :cabecera="cabecera" :dataordendetalle="dataordendetalle" :boo_modal="modalStoreDispatch.modal_form" @mtdCloseModalFactura="mtdCloseModalFactura" :dataBillDetailEdit="dataBillDetailEdit" :codigo="modalStoreDispatch.cod_purchase_order" />
    <cModalViewDispatch :title="modalViewDispatch.title" :dataDispatches="dataDispatches" :boo_modal="modalViewDispatch.modal_form" @mtdCloseModalViewDispatches="mtdCloseModalViewDispatches" />
    <!-- <cModaladdPay :title="modalPay.title" :boo_modal="modalPay.modal_form" @mtdcloseModalPay="mtdcloseModalPay" :billpay="billpay"/> -->
    <cModalGenerarFactura :title="modalStoreBill.title" :boo_modal="modalStoreBill.modal_form" @closeModalStoreBill="closeModalStoreBill" @mtdShowModalStoreDispatch="mtdShowModalStoreDispatch" :purcharseOrder="purcharseOrder" />
    <cModalCentralCost :title="modalCentral.title" :boo_modal="modalCentral.modal_form" @mtdContinuenRegister="mtdContinuenRegister" @mtdcloseModal="closeModalStoreBill" :data="modalCentral.bill" />
    <RegisterPDF :boo_modal="modal_upload.boo_modal" :title="modal_upload.title" :id="modal_upload.id" :page="modal_upload.page" @mtdcloseModal="mtdCloseUpload" />
</div>
</template>

<script>
import { mapActions } from "vuex";
import cModalStoreDispatch from "@/components/Farmacia/Modals/OrdenCompra/cModalStoreDispatch.vue";
import cModalViewDispatch from "@/components/Farmacia/Modals/OrdenCompra/cModalViewDispatch.vue";
import cModalGenerarFactura from "@/components/Farmacia/Modals/OrdenCompra/cModalGenerarFactura.vue";
import cModalCentralCost from "@/components/Farmacia/Modals/OrdenCompra/cModalCentralCost.vue";
import RegisterPDF from "@/components/Tesoreria/modals/cModalUpload.vue";

export default {
    components: {
        cModalStoreDispatch,
        cModalViewDispatch,
        cModalGenerarFactura,
        cModalCentralCost,
        RegisterPDF
    },
    data() {
        return {
            search: "",
            dataorders: [], //inicializa paginacion
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 5,
            modalStoreDispatch: {
                title: "",
                modal_form: false,
                cod_purchase_order: "",
                bill_id: null
            },
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            modalPay: {
                title: "",
                modal_form: false,
            },
            modalCentral: {
                title: "",
                modal_form: false,
                bill: {}
            },
            modalStoreBill: {
                title: "",
                modal_form: false,
            },
            dataordendetalle: [],
            dataDispatches: [],
            dataBillPayment: [],
            cabecera: {
                name: "",
                id: null,
                ruc: "",
                total_IGV: 0,
                total_sin_IGV: 0,
                not_engraved: 0,
                activeButtonFormBill: false,
                observation: "",
            },
            bill: [],
            isButtonDisabled: false,
            posEditnumber: null,
            dataBillDetailEdit: [],
            filter_id: "",
            billpay: {
                bill_id: null,
                paid: "",
                pending: "",
                total: "",
                type: "",
            },
            purcharseOrder: {
                id: "",
                number: "",
                date: "",
                provider: "",
                provider_id: "",
                total: "",
                pending: "",
            },
            isValidInput: [],
            modal_upload: {
                boo_modal: false,
                title: '',
                id: 0,
                page: "",
            }
        };
    },

    props: {
        filter_oc: String,
    },
    watch: {
        filter_oc(newValue, oldValue) {
            this.filter_id = newValue;
            this.mtdGetData();
        },
        dataShow: {
            immediate: true, // Para ejecutar el watcher inmediatamente al inicio
            handler(newArrayDetalle) {
                this.isValidInput = Array(newArrayDetalle.length).fill(true);
            }
        }
    },

    computed: {
        cpData() {
            let filteredData = this.dataorders;
            let totalPages = Math.ceil(filteredData.length / 10);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 10;
            let endIndex = startIndex + 9;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            //Bill es el array de la pagina que se está mostrando
            this.bill = Array(this.dataShow.length)
                .fill("")
                .map((_, index) => ({
                    id: null,
                    number: null,
                    date: null,
                    due_date: null,
                    central_cost_id: "",
                    purchase_orders_id: null,
                    money: "soles",
                    paid: null,
                    days: this.dataShow[index].expiration_days,
                    total: this.dataShow[index].total,
                    detraction: 0,
                }));
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },

    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdCalculeExperation(index) {
            let date = new Date(this.bill[index].date);
            let daysToAdd = this.bill[index].days;

            let dateExpiration = new Date(date);
            dateExpiration.setDate(dateExpiration.getDate() + daysToAdd); // Sumamos los días

            let formattedDate = dateExpiration.toISOString().split('T')[0];

            this.bill[index].due_date = formattedDate;

        },
        showButton(item) {
            let total = 0
            this.dataorders.forEach(element => {
                if (element.id == item.id) {
                    total += parseFloat(element.bill_total);
                }
            })
            return item.bill_id && total !== parseFloat(item.total) && item.dispatchState !== 3;
        },
        mtdGetData: function () {
            if (this.filter_id) {
                this.post({
                        url: this.$store.getters.get__url + "/Pharmacy/oc/filter",
                        token: this.$store.getters.get__token,
                        params: {
                            filter_id: this.filter_id,
                        },
                    })
                    .then((response) => {
                        this.dataorders = response.data;
                        this.mtdHide();
                    })
                    .catch((errors) => {});
            } else {
                this.get({
                        url: this.$store.getters.get__url + "/Pharmacy/oc/index",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataorders = response.data;
                        this.calculatePagination();
                    })
                    .catch((errors) => {});
            }
        },
        mtdOutFocusInputt(index) {
            if (this.posEditnumber !== index && this.posEditnumber !== null) {
                Swal.fire({
                    title: "Desea cambiar de fila?",
                    text: "Se borrarán los datos registrados!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, cambiar!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.posEditnumber = index;
                    }
                });
            } else {
                this.posEditnumber = index;
            }

            this.isButtonDisabled = true;
        },
        mtdOutFocusInput(index) {
            
        },
        calculatePagination: function () {
            let cantidad = Math.ceil(this.dataorders.length / 10);
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
        },
        mtdInsertBill: function (id, index) {
            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/oc/storeBill",
                    token: this.$store.getters.get__token,
                    params: this.bill[index],

                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Factura registrada",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                        const state = this.dataShow[index].pending === 0 ? "CONTANDO" : "CREDITO";
                        const type = this.bill[index].number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
                        this.modalStoreDispatch.title = "DESPACHO DE LA " + type + " " + response.data.bill.number + " - " +
                            state + " ESTADO: " + response.data.stateBill; //agregar el estado de la factura
                        this.modalStoreDispatch.bill_id = response.data.bill.id;
                        this.modalStoreDispatch.cod_purchase_order = this.mtdGenerateCode(id);
                        //completar los centra de costos
                        this.mtdGetProducts(id);
                        this.mtdModalCentralCost(index, 1, response.data.bill);
                    }
                })
                .catch((errors) => {});
        },
        mtdModalCentralCost: function (index, option, bill) {
            let number = bill ? bill.number : this.dataShow[index].number;
            let newArray = [];
            if (option == 1) {
                newArray = this.dataShow[index].provider.central_has.map(item => ({
                    id: item.central.id,
                    name: item.central.name,
                }));
            } else {
                newArray = this.dataShow[index].centrales;

            }
            //let title = option == 1 ? 'Agregar' : '';
            this.modalCentral = {
                title: "Central de Costos - " + number,
                modal_form: true,
                bill: {
                    central: newArray,
                    totalBill: bill ? bill.total : this.dataShow[index].totalBill,
                    moneda: this.dataShow[index].money,
                    id: bill ? bill.id : this.dataShow[index].bill_id,
                    option: option, //1 registrar - 2 -> ver
                }

            };
        },
        mtdContinuenRegister() {
            this.modalCentral.modal_form = false;
            this.modalStoreDispatch.modal_form = true;

        },
        mtdShowModalStoreDispatch: function (data) {
            this.mtdGetProducts(data.id);
            this.closeModalStoreBill();
            const selectedOc = this.dataShow.find(
                (item) => item.id === data.id
            );
            //completar los datos para el modal de store
            this.modalStoreDispatch.title = data.title;
            this.modalStoreDispatch.bill_id = data.bill.id;
            this.modalStoreDispatch.cod_purchase_order = this.mtdGenerateCode(data.id);
            //abrir el modal de registro de central de costos
            this.modalCentral = {
                title: "Central de Costos - " + data.bill.number,
                modal_form: true,
                bill: {
                    central: selectedOc.centrales,
                    totalBill: data.bill.total,
                    moneda: data.bill.money,
                    id: data.bill.id,
                    option: 1, //1 registrar - 2 -> ver
                }

            };

        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0,decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            this.bill[this.posEditnumber].total = sanitizedValue;
            //validar que no sea mayor que el total
            const ocTotal = parseFloat(this.dataShow[this.posEditnumber].total).toFixed(2);
            const billTotal = parseFloat(sanitizedValue);
            let amount = parseFloat(sanitizedValue).toFixed(2);
            let validation = (amount > 0.00) ? billTotal > ocTotal : true
            if (validation == false) {
                this.bill[this.posEditnumber].total = sanitizedValue;
                this.isValidInput[this.posEditnumber] = true;

            } else {
                this.isValidInput[this.posEditnumber] = false;
            }

        },
        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        openModal: function (id, index) {
            //si ya hay datos de factura abrir modal
            if (this.dataShow[index].bill_id !== null) {
                this.mtdGetProducts(id);
                const state = this.dataShow[index].pending === 0 ? "CONTANDO" : "CREDITO";
                const type = this.dataShow[index].number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
                this.modalStoreDispatch = {
                    title: "DESPACHO DE LA " + type + " " + this.dataShow[index].number + " - " +
                        state + " / ESTADO: " + this.dataShow[index].bill_state,
                    modal_form: true,
                    bill_id: this.dataShow[index].bill_id,
                    cod_purchase_order: this.mtdGenerateCode(id),
                };
            } else {
                //verificar si no hay campos nulos
                if (
                    this.bill[index].number !== null &&
                    this.bill[index].money !== null &&
                    this.bill[index].date !== null &&
                    this.bill[index].total !== null
                ) {
                    if (this.posEditnumber !== index) {
                        Swal.fire({
                            icon: "error",
                            title: "El boton no pertenece a la fila",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        this.bill[index].purchase_orders_id = id;
                        Swal.fire({
                            title: "¿Desea registrar la factura?",
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si, guardar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.mtdInsertBill(id, index);
                            }
                        });
                    }
                } else {
                    if (this.posEditnumber === null) {
                        Swal.fire({
                            icon: "error",
                            title: "No hay factura registrada",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Debe registrar todo los campos",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                }
            }
        },
        openModalViewDispatch: function (bill_id, index) {
            if (this.dataShow[index].bill_id !== null) {
                const type = this.dataShow[index].number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
                const title = "DESPACHOS DE LA " + type + " " + this.dataShow[index].number;
                this.mtdGetDispatches(bill_id, title);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "No hay factura registrada",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        },
        openModalStoreBill: function (oc, index) {
            let total = 0
            this.dataorders.forEach(element => {
                if (element.id == oc.id) {
                    total += parseFloat(element.bill_total);
                }
            })
            let debe = oc.total - total.toFixed(2)
            this.purcharseOrder = {
                id: oc.id,
                index: index,
                number: this.mtdGenerateCode(oc.id),
                date: oc.date,
                provider: oc.provider_name,
                provider_id: oc.provider_id,
                ruc: oc.provider_ruc,
                total: oc.total,
                pending: oc.pending,
                pendiente: debe.toFixed(2),
                centralCost: oc.provider,
            }
            this.modalStoreBill = {
                title: "Agregar Factura",
                modal_form: true,
            };
        },
        closeModalStoreBill: function () {
            this.modalStoreBill = {
                title: "",
                modal_form: false,
            };
            this.posEditnumber = null;
            this.filter_id = "";
            this.modalCentral.modal_form = false;
        },
        mtdGetProducts: function (id) {
            this.get({
                    url: this.$store.getters.get__url + "/Pharmacy/oc/getProducts/" + id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataordendetalle = response.data.details;
                    this.dataDispatches = response.dispatch;
                    this.cabecera = {
                        id: response.data.proveedor.provider.id,
                        name: response.data.proveedor.provider.name,
                        ruc: response.data.proveedor.provider.ruc,
                        total_IGV: response.data.proveedor.total_IGV,
                        total_sin_IGV: response.data.proveedor.total_sin_IGV,
                        not_engraved: response.data.proveedor.not_engraved,
                        observation: response.data.proveedor.observation,
                    };
                    this.dataBillDetailEdit = Array(this.dataordendetalle.length)
                        .fill("")
                        .map((_, index) => ({
                            id_product_details: null,
                            lote: "",
                            date: null,
                            incoming_quantity: null,
                            register_sanitary: "",
                            quantity_pending_order: null,
                            id_purcharse_order_detail: null,
                            barcode: "",
                            bonus: null,
                            state: 1,
                        }));
                })
                .catch((errors) => {});
        },
        mtdGetDispatches: function (idbill, titleModal) {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Pharmacy/oc/getDispatches/" +
                        idbill,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataDispatches = response.data;
                    if (this.dataDispatches.length > 0) {
                        this.modalViewDispatch = {
                            title: titleModal,
                            modal_form: true,
                        };
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "No hay despachos para mostrar",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});
        },

        mtdCloseModalFactura: function () {
            this.modalStoreDispatch = {
                title: "",
                modal_form: false,
            };
            this.posEditnumber = null;
            this.mtdGetData();
        },
        mtdCloseModalViewDispatches: function () {
            this.modalViewDispatch = {
                title: "",
                modal_form: false,
            };
            this.dataDispatches = [];
            this.posEditnumber = null;
            this.mtdGetData();
        },

        mtdGetIgva: function (ind) {
            if (this.dataorders[ind].total_sin_IGV) {
                const igv = 0.18 * this.dataorders[ind].total_sin_IGV;
                return parseFloat(igv).toFixed(2);
            } else {
                return 0.0;
            }
        },
        mtdGetIgv: function (ind) {
            if (this.cpData[ind].total) {
                const igv = this.cpData[ind].total - this.cpData[ind].total / 1.18;
                return parseFloat(igv).toFixed(2);
            } else {
                return 0.0;
            }
        },
        mtdGenerateCode(item) {
            if (0 < item && item < 10) {
                return " OC-000" + item;
            }

            if (9 < item && item < 100) {
                return " OC-00" + item;
            }
            if (99 < item && item < 1000) {
                return " OC-0" + item;
            }
            if (999 < item && item < 10000) {
                return " OC-" + item;
            }
        },

        viewfactura: function (id) {
            window.open(
                this.$store.getters.get__url + "/Pharmacy/oc/viewpdf/" + id,
                "_blank"
            );
        },
        /** view pdf*/
        viewPdf: function (ruta) {
            window.open(
                "https://clinicabm.v2.howertpi.com/assets/" + ruta,
                "_blank"
            );
        },
        upload: function (item) {
            let type = item.number.slice(0, 1) == 'F' ? 'FACTURA' : 'BOLETA';
            this.modal_upload = {
                boo_modal: true,
                title: 'AGREGAR DOC A LA ' + type + " - " + item.number,
                id: item.bill_id,
                page: 'logistica'
            };
        },
        mtdCloseUpload: function () {
            this.modal_upload = {
                boo_modal: false,
                title: "",
                data: {},
            };
            this.mtdGetData();
        }
    },
};
</script>

<style scoped>
.input-p::placeholder {
    font-size: 10px;
}

.input-p {
    font-size: 10px;
}

.input-with-opacity {
    opacity: 0.4;
    /* Otros estilos necesarios */
}

.input-factura {
    width: 100px;
    font-size: 11px;
}

#btn-save {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
}

.acciones {
    background-color: #900052;
    border-radius: 10px;
    width: 25px;
}

.acciones-2 {
    background-color: #39b54a;
    border-radius: 10px;
    width: 25px;
}

.acciones-3 {
    background-color: blue;
    border-radius: 10px;
    width: 25px;
}

.acciones-4 {
    background-color: gray;
    color: white;
    border-radius: 10px;
    width: 25px;
}

.acciones-5 {
    border-radius: 10px;
    width: 25px;
}

.anterior-siguiente:focus {
    background: #900052;
}

.anterior-siguiente:hover {
    background: #bb026c;
}

#input-f-factura {
    font-size: 10px;
    width: 98px;
}

#select-moneda {
    font-size: 10px;
    width: 52px;
}

#select-remision {
    font-size: 10px;
    width: 62px;
}

#input-factura {
    font-size: 10px;
    width: 79px;
}

.error-message {
    font-size: 10px;
    color: red;
}

.disabledbutton {
    pointer-events: none;
    background-color: #e180b7;
    cursor: not-allowed;
    text-decoration: none;
}
</style>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show.sync="boo_modal"
      :close-on-backdrop="false"
      :centered="false"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered mb-0">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 13px">
                        <tr class="text-white text-center align-middle">
                          <th scope="col">N°</th>
                          <th scope="col">Medicamento</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Recomendaciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.table.length > 0">
                      <template v-for="(item1) in table">
                          <tr class="px-1 border-bottom">
                            <td class="text-center" >{{ item1.commonmedicationsdetail[0].num}}</td>
                              <td class="text-center" >{{ item1.commonmedicationsdetail[0].name}}</td>
                              <td class="text-center" >{{ item1.commonmedicationsdetail[0].total }}</td>
                              <td class="text-start" :rowspan="item1.commonmedicationsdetail.length" v-html="item1.indications.replace(/\n/g, '<br>')"></td>
                          </tr>
                          <template v-if="item1.commonmedicationsdetail.length > 1">
                              <tr class="px-1 border-bottom" v-for="(item2) in item1.commonmedicationsdetail.slice(1)" :key="item2.id">
                                 <td class="text-center" :class="item2.color">{{ item2.num}}</td>
                                  <td class="text-center" :class="item2.color">{{ item2.name}}</td>
                                  <td class="text-center" :class="item2.color">{{ item2.total}}</td>
                              </tr>
                          </template>

                      </template>
                  </tbody>
                  <tbody class=" text-center" v-else>
                      <tr>
                          <td colspan="19" class=" text-center">
                              <strong> No hay resultados</strong>
                          </td>
                      </tr>
                  </tbody>
                </table>
            </div>
        </div>
      </div>
      <template #footer>
        <button class="btn btn-danger btn-sm" @click="mtdClose"  >
            <i class="fas fa-times" />&nbsp; Cerrar
        </button>
     </template>
    </CModal>
  </template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import { mapActions } from "vuex";
  export default {
    name: "c-pharmacy-cModalDetalle",
    components: {
      CModal,
      SweetAlert
    },
    data() {
      return {
        swal:null,
        table: [],
       
      };
    },
    created () {
    },
   
    props: {
      title: String,
      boo_modal: Boolean,
      medical_act_id:Number,
    },
    watch:{
      medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0 && newValue !=null) {
                   this.mtdGetData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("mtdCloseModalDataview");
        this.table=[]
      },
     
      mtdGetData: function () {
           
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/ViewFrequency/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.table=  response.data
            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },
    },
  };
  </script>
  <style scoped>
  .align-middle{
    vertical-align: middle;
  }
  </style>
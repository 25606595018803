<template>
    <div>
        <div class="container" v-if="view == 'data'">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            <div id="div-modal-cxc" class="container d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>RECETAS</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 px-1">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main">
                                                <i class="fas fa-search"></i>
                                            </span>
                                            <input v-model="search" type="text" class="form-control form-control"
                                                placeholder="Buscar Paciente" @keyup="calculateData(7)" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 px-1 mt-1">
                                        <div class="form-check custom-input">
                                            <input class="form-check-input custom-checkbox" type="checkbox" value=""
                                                id="flexCheckDefault" v-model="listado">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Ver Todas
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-center text-white border-white bg-main align-middle">
                                            <th scope="col" class="align-middle table-th-number">N°</th>
                                            <th scope="col" class="align-middle">FECHA</th>
                                            <th scope="col" class="align-middle">ORIGEN</th>
                                            <th scope="col" class="align-middle">LUGAR</th>
                                            <th scope="col" class="align-middle">PACIENTE</th>
                                            <th scope="col" class="align-middle">TIPO PAC.</th>
                                            <th scope="col" class="align-middle">DOCTOR</th>
                                            <th scope="col" class="align-middle table-th-number">ESTADO</th>
                                            <th scope="col" class="align-middle table-th-acciones">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-size: 12px;">
                                        <tr class="tbody-table align-middle" v-for="(item, index) in cpData"
                                            :key="index">
                                            <td class="clickCarrito">
                                                {{ mtdNumber(index) }}
                                            </td>
                                            <td>
                                                {{ item.created_at | formatDateTime }}
                                            </td>
                                            <td class="text-nowrap" v-if="item.origin">
                                                {{ item.origin }} - {{ item.type | formatType }}
                                            </td>
                                            <td class="text-nowrap" v-else>
                                                {{ item.type | formatType }}
                                            </td>
                                            <td>
                                                {{ item.cama ? item.cama : ' ' }}
                                            </td>
                                            <td>
                                                {{ item.patient ? item.patient.name : ' ' }} ({{ item.patient ?
                                                    item.patient.document : ' ' }})
                                            </td>
                                            <td>
                                                {{ item.type_patient ? item.type_patient.toUpperCase() : ' ' }}
                                            </td>
                                            <td>
                                                {{ item.doctor ? item.doctor : '-' }}
                                            </td>
                                            <td class="text-center">
                                                <div class="badge text-bg-danger" v-if="item.state == 1">
                                                    <div>
                                                        <strong>SIN ATENDER</strong>
                                                    </div>
                                                </div>
                                                <div class="badge text-bg-warning" v-else-if="item.state == 2">
                                                    <div>
                                                        <strong>EN PROCESO</strong>
                                                    </div>
                                                </div>
                                                <div class="badge text-bg-success" v-else-if="item.state == 3">
                                                    <div>
                                                        <strong>COMPLETADO</strong>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <button @click="opendevoluciones(item)"
                                                        class="btn btn-primary btn-sm rounded-circle bootom me-1"
                                                        title="Despachar Recetas">
                                                        <i class="fas fa-pills icono-pills"></i>
                                                    </button>
                                                    <button @click="viewRecomend(item.indicaciones)"
                                                        class="btn btn-primary btn-sm rounded-circle bootom me-1"
                                                        title="Indicaciones Medicas">
                                                        <i class="fas fa-notes-medical"></i>
                                                    </button>
                                                    <button @click="pdfRecipe(item)"
                                                        class="btn btn-primary btn-sm rounded-circle bootom me-1"
                                                        title="Pdf Recetas">
                                                        <i class="fas fa-solid fa-file-pdf"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!cpData.length > 0" class="text-center">
                                            <th scope="col" colspan="9">NO SE ENCONTRARON RESULTADOS</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--Paginado-->
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index"
                                                class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                                class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                                class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)"
                                                    @click="selectedPage(stepPagination.length)">{{
                                                        stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3" v-else-if="view == 'tablaRecipe'">
            <cRecipe ref="cRecipes" :acto="acto" :recipe="selectedItem" :destino="destino" @mtdBack="retrocederCheck"
                @update="mtdRecipeDetail" @updateRecipe="updateRecipe" />
        </div>
        <cModalViewDetailVue :title="modalDeta.title" :boo_modal="modalDeta.boo_modal" :item="modalDeta.item"
            @mtdCloseModalView="mtdCloseModal" />
        <cModalViewRecomendVue :title="modalRe.title" :boo_modal="modalRe.boo_modal" :item="modalRe.item"
            @mtdCloseModalRe="mtdCloseModalRe" />
        <SweetAlert @devolucionCancelada="devolucionCancelada" @openRecipeDetail="openRecipeDetail" :swal="swal" ref="SweetAlert" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalViewDetailVue from "@/components/Farmacia/Modals/cModalViewDetail.vue";
import cModalViewRecomendVue from "@/components/Farmacia/Modals/cModalViewRecomend.vue";
import cRecipe from "@/components/Farmacia/Recipes/cRecipes.vue";

export default {
    name: "listadoRecetas",
    components: {
        SweetAlert,
        cModalViewDetailVue,
        cModalViewRecomendVue,
        cRecipe
    },
    data() {
        return {
            swal: null,
            view: "data",
            search: "",
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 7,
            modalDeta: {
                title: "Detalles de Receta",
                item: [],
                boo_modal: false,
                pos: null,
            },
            modalRe: {
                title: "Recomendación",
                item: null,
                boo_modal: false,
            },
            selectedItem: [],
            acto: [],
            recipe: [],
            actoHisto: [],
            listaRecipe: [],
            listado: false,
            destino: null
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
        listado() {
            this.mtdGetData();
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            const urlBase = this.$store.getters.get__url + "/Pharmacy/recipe/listadoRecipe";
            const url = this.listado ? `${urlBase}/3` : `${urlBase}/1`;

            this.get({
                url: url,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.listaRecipe = response.data;
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        calculateData(items) {
            if (!this.listaRecipe || !this.listaRecipe.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.listaRecipe;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.listaRecipe.filter((element) => {
                    let patientName = element.patient ? element.patient.name.toString().toUpperCase() : "";
                    let patientDocument = element.patient ? element.patient.document.toString() : "";
                    return (
                        patientName.includes(query) || patientDocument.includes(query)
                    );
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        opendevoluciones:function(data){
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/CountDevolution/"+data.medical_act_id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                let count = response.data;
                if(count ==0){
                    this.openRecipeDetail(data)
                }else{
                    this.$refs.SweetAlert.devolucionPendiente(data);
                }
              
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Componentes 
        openRecipeDetail: function (data) {
            const acto = {
                acto_id: data.medical_act_id,
                patient: data.patient,
                patient_id: data.patient_id
            };
            this.acto = acto;
            switch (data.clinical_historie_type) {
                case 6:
                    this.mtdRecipeDetail(data.id);
                    this.destino = "SOP";
                    break;
                case 1:
                    this.mtdRecipeDetail(data.id);
                    this.destino = "CONSULTAS";
                    break;
                case 3:
                    this.mtdRecipeDetail(data.id);
                    this.destino = "HOSPITALIZACION";
                    break;
                case 4:
                    this.mtdRecipeDetail(data.id);
                    this.destino = "UCI";
                    break;
                case 7:
                    this.mtdRecipeDetail(data.id);
                    this.destino = "EMERGENCIA";
                    break;
                default:
                    console.warn('Tipo de historia clínica no reconocido:', data.clinical_historie_type);
                    break;
            }
        },

        mtdRecipeDetail: function (id) {
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdRecipeDetail/" + id,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.selectedItem = response.data;
                    this.view = "tablaRecipe";
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        updateRecipe: function (id) {
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdRecipeDetail/" + id,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.selectedItem = response.data;
                    this.view = "tablaRecipe";
                    //Recargar State
                    const item = this.cpData.find((item) => item.id === response.data.id);
                    if (item) {
                        item.state = response.data.state;
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        viewDetail(item) {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/viewDetail",
                params: { id: item.id },
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        this.modalDeta.item = response.data;
                        this.modalDeta.boo_modal = true;
                    } else {
                        this.modalDeta.boo_modal = false;
                        this.$refs.SweetAlert.showWarning("Sin Detalles de Receta");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        mtdCloseModal: function () {
            this.modalDeta = {
                title: "Detalles de Despacho",
                item: [],
                boo_modal: false,
                pos: null,
            };
        },

        viewRecomend(indicaciones) {
            if (indicaciones && indicaciones.trim()) {
                this.modalRe.title = "Indicaciones Med.";
                this.modalRe.item = indicaciones.charAt(0).toUpperCase() + indicaciones.slice(1);
                this.modalRe.boo_modal = true;
            } else {
                this.modalRe.boo_modal = false;
                this.$refs.SweetAlert.showWarning("No existe Indicaciones Med.");
            }
        },

        // pdfRecipe(id) {
        //   window.open(
        //           this.$store.getters.get__url +
        //           "/viewpdfRecipes/" +
        //           id,
        //           "_blank"
        //       );
        // },

        async pdfRecipe(item) {
            const baseUrl = this.$store.getters.get__url;
            const pdfUrl = `${baseUrl}/viewpdfRecipes/${item.id}`;
            

            const swalOptions = {
                title: "¿Qué deseas hacer?",
                text: "Selecciona una opción:",
                icon: "info",
                showCancelButton: true,
                confirmButtonText: '<i class="fas fa-file-pdf"></i> Ver PDF',
                cancelButtonText: '<i class="fas fa-envelope"></i> Enviar por correo',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-secondary",
                    actions: "d-flex justify-content-between gap-3",
                },
                buttonsStyling: false,
                allowOutsideClick: false,
            };

            const emailTitle = "Envio de comprobante al cliente";
            const emailContent = await this.mtdGetEmailContentByTitle(emailTitle);
            let img_path = null;

            if (emailContent) {
                const files = JSON.parse(emailContent.files);
                img_path = files.find(file => /\.(jpeg|jpg|png)$/i.test(file));
            }

            const result = await Swal.fire(swalOptions);
            if (result.isConfirmed) {
                window.open(pdfUrl, "_blank");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                let email = item.patient.email;

                if (!email) {
                    const emailInput = await Swal.fire({
                        title: "No hay correo registrado",
                        text: "Por favor, ingresa un correo electrónico para enviar el comprobante.",
                        icon: "warning",
                        input: "email",
                        inputPlaceholder: "example@domain.com",
                        showCancelButton: true,
                    });

                    if (emailInput.isConfirmed && emailInput.value) {
                        email = emailInput.value;
                    } else {
                        return;
                    }
                } else {
                    const emailConfirm = await Swal.fire({
                        title: "Confirmar correo electrónico",
                        text: "Por favor, revisa o edita el correo antes de enviar",
                        icon: "info",
                        input: "email",
                        inputValue: email,
                        showCancelButton: true,
                    });

                    if (emailConfirm.isConfirmed && emailConfirm.value) {
                        email = emailConfirm.value;
                    } else {
                        return;
                    }
                }

                try {
                    const res = await this.post({
                        url: `${baseUrl}/send/emailRecipe`,
                        token: this.$store.getters.get__token,
                        params: {
                            type: 1,
                            title: emailTitle,
                            dni: item.patient.document,
                            destinatario: [email],
                            body: `Hola ${item.patient.name}, aquí tienes tu comprobante.`,
                            archivo_path: pdfUrl,
                            xml_path: '',
                            img_body: `https://clinicabm.v2.howertpi.com/assets/${img_path}`
                        },
                    });

                    Swal.fire({
                        title: "Correo enviado",
                        text: `El comprobante se ha enviado correctamente a ${email}.`,
                        icon: "success",
                    });
                } catch (error) {
                    Swal.fire({
                        title: "Error",
                        text: "No se pudo enviar el correo. Inténtalo de nuevo.",
                        icon: "error",
                    });
                }
            }
        },

        async mtdGetEmailContentByTitle(title) {
            try {
                const response = await this.get({
                    url: `${this.$store.getters.get__url}/EmailContent/index`,
                    token: this.$store.getters.get__token,
                });

                if (response.data) {
                    return response.data.data.find(item => item.title === title) || null;
                }
            } catch (error) {
                console.error(error);
            }
            return null;
        },


        mtdCloseModalRe() {
            this.modalRe = {
                title: "",
                item: null,
                boo_modal: false,
            };
        },

        retrocederCheck: function () {
            this.view = "data";
        },

        /*Pag*/
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 7 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        devolucionCancelada:function(payload){
            this.$emit("devoluciones",payload);
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
        formatType(type) {
            const types = {
                1: 'CONSULTAS',
                2: 'INTERCONSULTA',
                3: 'HOSPITALIZACIÓN',
                4: 'UCI ADULT.',
                5: 'UCINEO',
                6: 'SOP Y URPA',
                7: 'EMERGENCIA'
            };
            return types[type] || '-';
        }
    },

    
}
</script>

<style scoped>
    @media (max-width: 536px) {
        .responsive-default {
            flex-direction: column;
        }

        .responsive-default>.py-1 {
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 0px;
        }

        .responsive-default-div {
            border-radius: 10px !important;
            text-align: center !important;
            font-size: 11px;
        }
    }

    .bootom {
        background-color: #900052 !important;
        border: 0.5px solid #900052 !important;
    }
</style>

<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
    class="custom-modal"
  >
    <!-- <template #header>
      <div class="d-flex justify-content-between align-items-center header-container">
        <div style="font-size: 16px; font-weight: bold">{{ title }}: {{ producto }}</div>
        <button @click="mtdClose" class="btn text-white close-button float-right ml-auto">
          <i class="fas fa-times" style="font-size: 12px"></i>
        </button>
      </div>
    </template> -->
    
    <div class="row" v-for="(row, index) in Math.ceil(filteredData.length / 3)" :key="index">
      <div class="d-flex flex-row">
        <div
          id="div-listcola"
          class="flex-grow-1 m-3"
          v-for="(item, subIndex) in filteredData.slice(index * 3, index * 3 + 3)"
          :key="subIndex"
        >
          <div
            class="card-header text-white h6 header-main text-center"
            style="padding: 5px"
          >
            <strong>{{ item.lote }}</strong>
          </div>
          <div
            class="card-body"
            id="content-timeline"
            :style="{ height: contentTimelineHeight }"
          >
            <div class="row">
              <div class="px-sm-1 px-md-3">
                <table
                  aria-describedby="tb-lote"
                  id="tb-lote"
                  class="table table-bordered table-container align-middle p-3 mt-3 mb-3"
                >
                  <thead class="bg-main align-middle">
                    <tr class="text-white tr-table text-center">
                      <th style="white-space: nowrap">FECHA VEN.</th>
                      <th style="white-space: nowrap">CANT. LOTE</th>
                      <th class="table-th-state" style="white-space: nowrap">
                        CANT. DESPACHADA
                      </th>
                    </tr>
                  </thead>
                  <tbody class="border-main text-center">
                    <tr
                      v-for="(item1, index1) in item.dates"
                      :key="index1"

                    >
                      <template>
                        <td>
                          {{ item1.date.split("-").reverse().join("/") }}
                        </td>
                        <td>{{ item1.total_quantity }}</td>
                        <td class="table-th-state">
                          <input
                            type="text"
                            class="input-p form-control form-control-sm text-center"
                            v-model="item1.quantity_dispath"
                            @keydown="filterKey"
                            placeholder="Ingrese Cant."
                            @input="validateInput(item1, subIndex, index1)"
                            style="font-size: 10px; padding: 0.25rem 0.5rem"
                            disabled
                          />
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div
        class="badge p-2 mb-1"
        style="
          background-color: #0cb500;
          color: white;
          font-weight: bold;
          font-size: 13px;
        "
      >
        TOTAL SOLICITADO:
        <span style="font-weight: bold; font-size: 15px">{{ total }}</span>
      </div>
      <div
        class="badge p-2 mb-1"
        style="
          background-color: #f17908;
          color: white;
          font-weight: bold;
          font-size: 13px;
        "
      >
        TOTAL DESPACHADO:
        <span style="font-weight: bold; font-size: 15px">{{ totalDespachada }}</span>
      </div>
      <div
        class="badge bg-danger p-2 mb-1"
        style="
          background-color: #ff0000;
          color: white;
          font-weight: bold;
          font-size: 13px;
        "
      >
        FALTANTE:
        <span style="font-weight: bold; font-size: 15px">{{
          total - totalDespachada
        }}</span>
      </div>

      <button
        class="btn btn-primary btn-sm"
        type="button"
        @click="addmedicament()"
        style="
          font-weight: bold;
          background-color: #900052;
          border: 2px solid #900052;
          font-size: 15px;
        "
      >
        OK
      </button>
      &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert"  @mtdClose="mtdClose" @loteadd="loteadd"/>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
export default {
  name: "c-Pharmacy-cModalLoti",
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
      swal: null,
      tabla: [],
      selectedLot: null,
      selectedDate: null,
      isValidInput: [],
      totalDespachada: 0,
    };
  },
  mounted() {
    this.validarAutomaticamente();
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        let newElement = { ...element };
        newElement.dates = element.dates.map((date) => ({
          ...date,
          quantity_dispath: "",
          idProduct: this.idProducto,
          idRecipeDetail: this.idRecipeDetail,
        }));
        show.push(newElement);
      });
      this.tabla = show;
      this.isValidInput = new Array(show.length).fill(null).map(() => []);
      return show;
    },
    contentTimelineHeight() {
      const rows = Math.ceil(this.tabla.length / 3);
      let maxRowLength = 0;

      for (let i = 0; i < rows; i++) {
        let rowLength = 0;

        for (let j = i * 3; j < (i + 1) * 3 && j < this.tabla.length; j++) {
          rowLength += this.tabla[j].dates.length;
        }

        if (rowLength > maxRowLength) {
          maxRowLength = rowLength;
        }
      }
      const minHeight = 100;
      const dynamicHeight = maxRowLength * 40;
      return Math.max(minHeight, dynamicHeight) + "px";
    },
    filteredData() {
    return this.cpData.filter(item =>
      item.dates.some(date =>
        date.quantity_dispath !== null &&
        date.quantity_dispath !== '' &&
        date.quantity_dispath !== 0
      )
    );
  },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    total: Number,
    producto: String,
    idProducto: Number,
    idRecipeDetail: Number,
    marca: String,
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    validarAutomaticamente() {
      this.tabla.forEach((lote, index) => {
        lote.dates.forEach((date, subIndex) => {
          if (this.primeraVez) {
            if (index === 0) {
              const totalQuantity = parseFloat(date.total_quantity);
              if (totalQuantity < this.total) {
                date.quantity_dispath = totalQuantity;
                this.totalDespachada += totalQuantity;
              }
            }
          } else {
            let cantidadRestante = this.total - this.totalDespachada;
            const cantidadLote = parseFloat(date.total_quantity);

            if (index === 0 && cantidadLote > this.total) {
              date.quantity_dispath = this.total;
              this.totalDespachada += this.total;
            } else {
              if (cantidadRestante > 0) {
                if (cantidadRestante >= cantidadLote) {
                  date.quantity_dispath = cantidadLote;
                  this.totalDespachada += cantidadLote;
                } else {
                  date.quantity_dispath = cantidadRestante;
                  this.totalDespachada += cantidadRestante;
                }
              }
            }
          }
        });
      });
      this.primeraVez = false;
    },

    mtdClose(item) {
      this.$emit("mtdCloseModalLote", item);
      this.totalDespachada = 0;
      this.selectedLot = null;
      this.selectedDate = null;
    },

    validateInput(i, index, index1) {
      const inputValue = parseFloat(i.quantity_dispath);
      const totalQuantity = parseFloat(i.total_quantity);

      this.totalDespachada = this.calcularTotalDespachada();

      if (this.totalDespachada > this.total) {
        this.$refs.SweetAlert.showWarning("Cantidad máxima alcanzada");
        this.isValidInput[index][index1] = false;
        i.quantity_dispath = null;
        this.totalDespachada = this.calcularTotalDespachada();
        return;
      }

      if (isNaN(inputValue) || inputValue > totalQuantity) {
        this.isValidInput[index][index1] = false;
        i.quantity_dispath = null;
        this.totalDespachada = this.calcularTotalDespachada();
      } else {
        this.isValidInput[index][index1] = true;
        i.quantity_dispath = inputValue;
      }

    },

    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    calcularTotalDespachada() {
      let total = 0;
      this.cpData.forEach((item) => {
        item.dates.forEach((date) => {
          const quantity =
            date.quantity_dispath !== null &&
            date.quantity_dispath !== undefined &&
            date.quantity_dispath !== ""
              ? parseFloat(date.quantity_dispath)
              : 0;
          total += quantity;
        });
      });
      return total;
    },
    addmedicament() {
        
        const tablaCopia = this.tabla.slice();
        const tablaFiltrada = tablaCopia.map(item => {
            item.dates = item.dates.filter(dateItem => {
                return dateItem.quantity_dispath !== null && dateItem.quantity_dispath !== "" && dateItem.quantity_dispath !== 0;
            });
            return item;
        }).filter(item => item.dates.length > 0);

        const tabla = tablaFiltrada.length > 0 ? tablaFiltrada : tablaCopia;
        this.$refs.SweetAlert.addlote(tabla);
       
    },

    loteadd:function(payload){
      this.$emit('agregarlote', payload)
      this.totalDespachada = 0;
      this.selectedLot = null;
      this.selectedDate = null;
    }
  },
};
</script>
<style scoped>
#content-timeline {
  border: 1px solid #aaa;
  height: 100px;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#content-timeline::-webkit-scrollbar {
  -webkit-appearance: none;
}

#content-timeline::-webkit-scrollbar:vertical {
  width: 10px;
}

#content-timeline::-webkit-scrollbar-button:increment,
#content-timeline::-webkit-scrollbar-button {
  display: none;
}

#content-timeline::-webkit-scrollbar:horizontal {
  height: 10px;
}

#content-timeline::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#content-timeline::-webkit-scrollbar-track {
  border-radius: 10px;
}

.header-main {
  background-color: #900052;
}

@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}

.text-doctor {
  font-weight: bold;
  font-size: 14px;
  color: #900052;
}
.selected {
  background-color: yellow;
}

.table-th-state {
  width: 20% !important;
}

#tb-lote th {
  font-size: 9.5px;
}

#tb-lote td {
  font-size: 11px;
}

.error-message {
  color: red;
  font-size: 9px;
}

/* X a la derecha*/
.header-container {
  width: 100%;
  margin-right: 10px;
}

.close-button {
  border: none;
  background-color: transparent;
}

.modal-body {
  margin-right: 20px;
}
.custom-modal .modal-title {
  font-size: 11px; 
  text-align: justify;
}
</style>

<template>
<CModal @mtdclosemodal="mtd_close" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" 
:btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex px-3">
        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-3">
            <div class="row d-flex">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label for="description" class="mb-2">Descripción</label>
                    <div class="input-group input-group-sm">
                        <textarea 
                            class="form-control" 
                            id="description" 
                            placeholder="Descripción" 
                            v-model="data_detail.description" 
                            @keydown="filterKeyLetter">
                        </textarea>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                    <div class="d-flex align-items-center justify-content-end">
                       <!--  <div class="me-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    v-model="data_detail.state"
                                    :checked="data_detail.state == 1 ? true : false"
                                    aria-checked="false"
                                />
                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                    {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                                </label>
                            </div>
                        </div> -->
                        <!-- Button -->
                        <button class="btn btn-sm text-white bg-main" :disabled="isButtonDisabled" @click="mtdAddPos">
                            <i class="fas fa-plus"></i>&nbsp; Agregar
                        </button>
                    </div>
                </div>
            </div>
            <div class="border-end-xl mt-3"></div>
        </div>
        <!--Listado-->
        <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" id="content-timelinetable">
            <table class="table table-bordered table-responsive text-xs">
                <caption class="py-0 my-0"></caption>
                <thead class="bg-main">
                    <tr class="text-white align-middle">
                        <th>N°</th>
                        <th>Descripción</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody class="border-main">
                    <tr v-if="cpData.length === 0" class="text-center align-middle">
                        <td colspan="4">Sin Registros</td>
                    </tr>
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                        <td>{{index + 1}}</td>
                        <td class="description">{{ item.description  }}</td>
                        <td>
                            <div id="state_speciality" v-if="item.state == 1">
                                <div class="bg-success text-white">Activo</div>
                            </div>
                            <div id="state_speciality" v-else>
                                <div class="bg-secondary text-white">Inactivo</div>
                            </div>
                        </td>
                        <td>
                            <div class="text-center d-flex justify-content-center">
                                <button class="btn btn-sm a-success text-white" @click="mtdEditPos(item.id)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-sm a-danger text-white" @click="mtdDeletePos(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-doctor",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                description: "",
                state: "",
            },
            maxDate: moment().format('YYYY-MM-DD')
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        idPo: Number,
        payload: {
            type: Array,
            default: null
        },
    },
    watch: {
    },
    computed: {
        cpData() {
            return this.payload.map(element => element);
        },
        isButtonDisabled() {
            return (
                !this.data_detail.description.trim()
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtd_close() {
            this.clear();
            this.$emit("mtd_close");
        },
        clear() {
            this.data_detail= {
                description: "",
                state: "",
            }
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]$/.test(e.key);
            if (!b && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        filterKeyLetter: function (e) {
            let b = false;
            b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        mtdAddPos: function(){
            this.$emit('registerDataPos', this.data_detail, this.idPo);
            this.clear();
        },

        mtdDeletePos: function(id){
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro de eliminar este Registro?", "warning", "Eliminar")
            .then((result) => {
                if (result.value) {
                    this.$emit('deleteDataPos', id, this.idPo);
                }
            });
        },

        mtdEditPos: function(id) {
            this.$emit('mtdEditPos', id);
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    }
};
</script>
<style scoped>
.bg-main-file{
    background-color: #EB5D77
}
.border-end-xl {
    border-right: 1px solid #900052;
}
@media (max-width: 1199.5px) {
    .border-end-xl {
        border-right: none;
        border-bottom: 1px solid #900052;
    }
}
.description {
    text-align: left;    
    font-size: 10.5px;     
}
</style>


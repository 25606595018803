<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
    :fullScreen="true"
  >
    <div class="row mx-0 mt-2">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">MOLECULA</th>
                        <th scope="col">COMERCIAL</th>
                        <th scope="col">MARCA</th>
                        <th scope="col">PRESENTACION</th>
                        <th scope="col">DOSIS</th>
                        <th scope="col">CANTIDAD ENTRANTE</th>
                        <th scope="col" style="white-space: nowrap;">PRECIO CON IGV</th>
                        <th scope="col">LOTE</th>
                        <th scope="col">CODIGO DE BARRAS</th>
                        <th scope="col" style="white-space: nowrap;">F. VENCIMIENTO</th>
                        <th scope="col" style="white-space: nowrap;">REG. SANITARIO</th>
                        <!--
                        <th scope="col" style="white-space: nowrap;">SECTOR</th>
                        -->
                        <th scope="col" style="white-space: nowrap;">S/ SUBTOTAL</th>
                        <th scope="col" style="white-space: nowrap;" v-if="state == 1">ACCIONES</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 10px">
                    <template v-if="cpData.length > 0">
                      <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                        <template>
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.product.molecule }}</td>
                          <td>{{ item.product.commercial }}</td>
                          <td>{{ item.product.brand ? item.product.brand.name : '-' }}</td>
                          <td>{{ item.product.presentation }}</td>
                          <td>{{ item.product.dosis }}</td>
                          <td>{{ item.quantity_receipt }}</td>
                          <td>{{ item.price }}</td>
                          <td>{{ item.lote }}</td>
                          <td>{{ item.barcode }}</td>
                          <td>{{ item.date | formatDate}}</td>
                          <td>{{ item.register_sanitary ? item.register_sanitary : '-'}}</td>
                          <!--
                          <td>{{ item.sector_id ? item.sector.name : '-' }}</td>
                          -->
                          <td>S/ {{ (item.price * item.quantity_receipt).toFixed(2) }}</td>
                          <td v-if="state == 1">
                            <div class="text-center d-flex justify-content-center">
                                <button
                                    class="btn btn-sm a-success text-white" @click="mtdModalShow(item, index)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <!-- <button 
                                    class="btn btn-sm a-danger text-white" @click="mtdDeleteShow(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button> -->
                            </div>
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="14" class="text-center">
                          Sin Registros
                        </td>
                      </tr>
                    </template>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
    <cModalEditNoteVue :payload="modal.payload" :title="modal.title" 
        :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail"/>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalEditNoteVue from '@/components/Logistica/modals/NotaIngreso/cModalEditNote.vue'
export default {
  name: "c-pharmacy-cModalDetalle",
  components: {
    SweetAlert,
    CModal,
    cModalEditNoteVue,
  },
  data() {
    return {
        swal: null,
        tabla: [],
        modal: {
            title: '',
            modal_form: false,
            payload: {},
        },
    };
  },
  created () {
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    idNote: Number,
    state: Number,
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.$emit("closeModal");
    },

    //Acciones
    mtdModalShow: function (payload, index) {
      let marca = payload.product.brand ? payload.product.brand.name : '';
      let number = index + 1;
      this.modal.title = 'Editar Nota N° ' + number;
      if (marca) {
          this.modal.title += ' - ' + marca;
      }
      this.modal.modal_form = true;
      this.modal.payload = payload;
    },

    closeModal: function () {
        this.modal = {
            title: '',
            modal_form: false,
            payload: {},
        }
    },

    Datadetail: function (payload_detail) {
        this.post({
            url: this.$store.getters.get__url + "/NotaIngreso/editNoteIncome",
            token: this.$store.getters.get__token,
            params: payload_detail,
        })
        .then(response => {
          if(response.statusCode == 200){
            this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
            this.$emit("upGetDetail", this.idNote, this.state);
          }else{
            this.$refs.SweetAlert.showError("Error");
          }
        })
        .catch(error => {
            this.$refs.SweetAlert.showError(error);
        })
    },

    mtdDeleteShow: function (id) {
      this.$refs.SweetAlert.showConfirmSimple2("", "¿Desea Eliminar este Detalle de Nota de Ingreso?", "warning", "Eliminar")
      .then((result) => {
          if (result.value) {
            this.mtdDelete(id);
          }
      });
    },

    mtdDelete: function (id) {
        this.get({
            url: this.$store.getters.get__url + "/NotaIngreso/deleteNoteIncomeDet/" + id,
            token: this.$store.getters.get__token,
        })
        .then((response) => {
            if(response.statusCode == 200){
              this.$refs.SweetAlert.showSuccess("Eliminación Exitosa");
              this.$emit("upGetDetail", this.idNote, this.state);
            } else{
              this.$refs.SweetAlert.showError("Error");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

  },
  filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>

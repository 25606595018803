<template>
<div>
    <div class="px-lg-5 px-md-3 px-sm-2 px-1 mt-2">
        <div class="d-flex title-option">
            <div class="btn-home mt-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <ul class="nav pb-0 " id="myTab" role="tablist">
            <li v-if="page =='cola'" class="nav-item" role="presentation">
                <button @click="changePage('cola')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'cola' }" id="home-tab" type="button">
                    <strong>COLA</strong>
                </button>
            </li>
            <li v-if="page =='historia'" class="nav-item" role="presentation">
                <button @click="changePage('historia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'historia' }" id="profile-tab" type="button">
                    <strong>H. CLÍNICA</strong>
                </button>
            </li>
        </ul>
        <div class="tab-content px-lg-3 px-md-2 px-sm-2 px-1 mb-3" id="myTabContent">
            <Cola v-if="page=='cola'" @mtdHistorial="mtdHistorial" @mtdHistorialtable="mtdHistorialtable" :rolname="rolname"/>
            <Historia v-if="page=='historia'"  :rolname="rolname" @changePage="changePage" :newhistorial="newhistorial" :newhistorialtable="newhistorialtable" :viewInput="viewInput" :stateHistoryShow="stateHistory" @validation="validation" @vaciarpatient="vaciarpatient" />
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Cola from '@/components/Asistencial/Consulta/ColaC.vue';
import Historia from '@/components/Asistencial/Consulta/HistoriaC.vue';

export default {
    name: 'c-asistencial-consulta',
    components: {
        Cola,
        Historia
    },
    data() {
        return {
            page: 'cola',
            stateHistory: null,
            viewInput: null,
            newhistorial: {},
            newhistorialtable: {}
        }
    },
    props: {
        patientold: {
            type: Object,
        },
        rolname: String
    },
    watch: {
        patientold: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.indexhistory(newValue)
                    // this.mtdHistorialtable(newValue)
                } else {
                    this.changePage2('cola')
                }
            },
            deep: true,
            immediate: true,
        },

    },

    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            if (Object.keys(this.newhistorial).length == 0 && Object.keys(this.newhistorialtable).length == 0) {
                // this.$emit('vaciarpatient')
                this.page = page;
                this.stateHistory = (page === 'historia') ? 1 : 0;
                this.viewInput = (page === 'historia') ? 1 : 0;
                this.newhistorial = {}
                this.newhistorialtable = {}
            }

        },
        changePage2: function (page) {
            this.page = page;
            this.stateHistory = (page === 'historia') ? 1 : 0;
            this.viewInput = (page === 'historia') ? 1 : 0;
            this.newhistorial = {}
            this.newhistorialtable = {}
        },
        vaciarpatient: function () {
            this.$emit('mtdFinish')
        },
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage','home')
        },
        mtdHistorial: function (payload) {
            this.page = 'historia'
            this.newhistorial = payload
            this.$emit('mtdpacienteold', payload)
        },
        mtdHistorialtable: function (payload, num) {
            this.page = 'historia'
            this.newhistorialtable = payload
            if(num != 1){
               this.$emit('mtdpaciente', payload)
            }
        },
        validation: function (payload) {
            this.$emit('mtdpaciente', payload)
        },
        indexhistory:function(payload){
            this.get({
                    url: this.$store.getters.get__url + "/Consultation/history/"+ payload.patient.medical_act_id,
                    token: this.$store.getters.get__token,   
                })
                .then((response) => {
                     let newhistory = response.data;
                      this.mtdHistorialtable({patient:newhistory} , 1)
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }
    }
}
</script>

<template lang="es">
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0 d-flex">
            <div class="col-lg-6 col-md-6">
                <label for="dni" class="mb-3 mt-2">DNI</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-id-card"></i>
                        </span>
                    </div>
                    <!-- <input type="text" class="form-control" id="dni" placeholder="12345678" @keydown="filterKeyDni" @keypress.enter="mtdSearchDocEmployee" v-model="data_detail.dni" maxlength="8" /> -->
                    <input type="text" class="form-control" name="documento" placeholder="Ingrese documento y presione enter" v-model="data_detail.dni" @keypress.enter="mtdSearchDocEmployee" maxlength="8" />
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <label for="name" class="mb-3 mt-2">Nombre</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="name" placeholder="Nombres" v-model="data_detail.name" @keydown="filterKeyLetter" :disabled="disabledEmployee"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <label for="lastName" class="mb-3 mt-2">Apellidos</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="lastName" @keydown="filterKeyLetter" placeholder="Apellidos" v-model="data_detail.last_name" :disabled="disabledEmployee"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <label for="honorario" class="mb-3 mt-2">Especialidad</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <v-select :options="specialtys" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="---- Seleccione una especialidad----" required v-model="data_detail.specialty_id" />
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <label for="cpm" class="mb-3 mt-2">CEP</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="CEP" id="cep" v-model="data_detail.cep" />
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6">
                <label for="rne" class="mb-3 mt-2">Contrato</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="PLANILLA" id="rne" v-model="data_detail.type_contract" />
                </div>
            </div> -->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-1">
                <label class="mb-3 mt-2">EMAIL</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user"></i>
                        </span>
                    </div>
                    <input type="email" class="form-control" name="email" placeholder="Email" v-model="data_detail.email" />
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 mt-1">
                <label class="mb-3 mt-2 form-label" for="formFile">FIRMA</label>
                <div class="input-group input-group-sm d-flex flex-nowrap">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-signature"></i>
                        </span>
                    </div>
                    <div class="input-group-text w-auto">
                        <input class="form-control form-control-sm" type="file" id="formFile" ref="fileInput" lang="es" name="file" @change="getFile">
                    </div>
                </div>
            </div>
            <div class="col-xl-1 col-lg-2 col-md-6 mt-5">
                <div v-if="data_detail.img_signature">
                    <!-- <img src="@/assets/user/firma/img/528b1359-329b-4c6a-8a2c-59c1299da941.png" :alt="'firma de'+data_detail.name" style="width: 50px; height: auto;"> -->
                    <img :src="'https://clinicabm.v2.howertpi.com/assets/'+data_detail.img_signature" :alt="'firma '+data_detail.name" style="width: 80px; height: auto;">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5" v-if="num === 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

import {
  mapActions
} from "vuex";

export default {
  name: "c-maintenance-modal-nurse",
  components: {
    CModal,
    SweetAlert
  },
  data() {
    return {
      disabledEmployee: true,
      data_detail: {
        employee_id: null,
        name: "",
        documento: "DNI",
        last_name: "",
        dni: "",
        specialty_id: "",
        cep: "",
        type_contract: "",
        state: "",
        email: "",
        file: "",
      },
      swal: "",
    };
  },
  watch: {
    payload: {
      handler(newVal) {
        if (this.num == 1) {
          if (newVal) {
            this.data_detail = {
              ...newVal
            };
          }
        }
      },
      immediate: true,
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    num: Number,
    payload: {
      type: Object,
      default: null
    },
    specialtys: Array,
  },
  computed: {
    cpButton() {
      return !(this.data_detail.name !== '' && this.data_detail.specialty_id !== '' && this.data_detail.dni !== '' && this.data_detail.dni.length == 8);

    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    getFile(e) {
      let fileInput = this.$refs.fileInput;
      let file = e.target.files[0];
      if (!/\.(jpeg)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
        this.$refs.SweetAlert.showInfo('Seleccione un archivo formato JPEG, PNG O JPG');
        fileInput.value = '';
      } else {
        this.data_detail.file = file;
      }
    },
    mtdClose() {
      this.data_detail = {
        employee_id: null,
        name: "",
        last_name: "",
        dni: "",
        specialty_id: "",
        cep: "",
        type_contract: "",
        state: "",
        documento: 'DNI',
      };
      this.$emit("closeModal");
    },
    filterKeyDni: function (e) {
      let b = false;
      b = /^\d$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    filterKeyLetter: function (e) {
      let b = false;
      b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    DataDetail() {
      this.$emit('Datadetail', this.data_detail, this.num)
      this.mtdClose();
    },
    mtdSearchDocEmployee: function () {
      this.get({
        url: this.$store.getters.get__url + "/RecursosHumanos/getDocEmployee/" + this.data_detail.dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.boo == 0) {
            this.$refs.SweetAlert.showWarning("El colaborador: " + response.data.name + " " + response.data.last_name + " se encuentra inhabilitado.");
            this.disabledEmployee = true;
            this.data_detail.name = "";
            this.data_detail.last_name = "";
            this.data_detail.employee_id = null;
          } else
            if (response.data.boo == 1) {
              if (response.data.hasOwnProperty("employee")) {
                this.data_detail.name = response.data.name;
                this.data_detail.last_name = response.data.last_name;
                this.data_detail.employee_id = response.data.id;
              } else {
                this.data_detail.name = "";
                this.data_detail.last_name = "";
                this.data_detail.employee_id = null;
                this.disabledEmployee = true;
              }
            } else
              if (response.data.boo == 2) {
                this.$refs.SweetAlert.showWarning("El colaborador: " + response.data.name + " " + response.data.last_name + " ya tiene usuario creado.");
                this.disabledEmployee = true;
                this.data_detail.name = "";
                this.data_detail.last_name = "";
                this.data_detail.employee_id = null;
              } else
                if (response.data.boo == 3) {
                  this.$refs.SweetAlert.showWarning("Documento no encontrado.");
                  this.disabledEmployee = true;
                  this.data_detail.name = "";
                  this.data_detail.last_name = "";
                  this.data_detail.employee_id = null;
                } else {
                  this.$refs.SweetAlert.showWarning("Error al buscar empleado.");
                  this.disabledEmployee = true;
                  this.data_detail.name = "";
                  this.data_detail.last_name = "";
                  this.data_detail.employee_id = null;
                }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },

};
</script>

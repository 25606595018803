<template>
    <div  v-if="rps.length > 0">
        <div id="app" class=" mt-3 text-center ">
            <div class="row  responsive-container ">
                <div class=" mx-0 px-3 d-flex justify-content-between">
                    <div >
                        <button v-if="rps.length > 1 && rp.date_name  == 'RP ACTUAL' " @click ="mtdcambiar()" type="button" class="btn text-white btn-sm bg-main text-xs px-5  ">
                           Duplicar Rp
                        </button>
                    </div>
                    <div class=" px-0">
                        <nav aria-label="Page navigation example" class="pagination-container">
                            <ul class="pagination pagination-xs justify-content-end">
                                <li class="page-item">
                                    <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                                </li>
                                <template>
                                    <li class="page-item">
                                        <a class="page-link active" href="javascript:void(0)">{{ rp.date_name }}</a>
                                    </li>
                                </template>
                                <li class="page-item">
                                    <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class=" row mx-0 px-3 d-xl-flex justify-content-between col-12 mb-3">
                    <div class="row mx-0 d-sm-flex px-0 col-xl-9  text-xs ">
                        <div class="col-xl-1 px-0 col-sm-3    d-flex   ">
                            <strong class="color-main">Fecha Actual</strong>
                        </div>
                        <div class="col-xl-2 col-sm-9 px-0   ">
                            <input type="date" class="form-control form-control-input form-control-sm " v-model="rp.date" placeholder="" readonly>
                        </div>
                        <div class="col-xl-1  col-sm-3 px-0 ms-xl-4   d-flex   ">
                            <strong class="color-main">Hora</strong>
                        </div>
                        <div class="col-xl-2 col-sm-9 px-0">
                            <input type="time" class="form-control form-control-input form-control-sm" v-model="rp.hour" readonly>
                        </div>
                    </div>
                    <div v-if="rp.date_name  != 'RP ACTUAL'" class="col-xl-3 text-xs   px-0 mt-xl-0 mt-2 ">
                        <div class=" row mx-0 px-0">
                            <div class="col-xl-3 px-0 col-sm-3    ">
                                <strong class="color-main">Medico</strong>
                            </div>
                            <div class="col-xl-9 col-sm-9 px-0   ">
                                <input v-model="rp.doctor_name" type="text" class="form-control form-control-input form-control-sm " placeholder="" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="  responsive-container-div col-xl-3 col-lg-12 px-4">
                    <div class="row">
                        <table class="w-100">
                            <caption class="div-text my-0 py-0"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded">Días hospitalizado </th>
                                    <th class="encabezado-rounded">Días SOP </th>
                                    <th class="encabezado-rounded">Días UCI</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
    
                                    <td>
                                        <input v-model="rp.day_hosp" type="number" class="form-control form-control-input form-control-sm" readonly />
                                    </td>
                                    <td>
                                        <input v-model="rp.day_sop" type="text" class="form-control form-control-input form-control-sm" readonly />
                                    </td>
                                    <td>
                                        <input v-model="rp.day_uci" type="text" class="form-control form-control-input form-control-sm" readonly />
                                    </td>
                                </tr>
    
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
    
                                    <th class="encabezado-rounded">Observaciones </th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
    
                                    <td>
                                        <textarea v-model="rp.observation" class="form-control form-control-sm" id="observaciones" rows="5" :disabled="validation"></textarea>
                                    </td>
    
                                </tr>
    
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="  responsive-container-div col-xl-4 col-lg-12 px-xl-1 px-4 mb-4 mb-xl-2">
                    <div class="row mx-xl-0 ">
                        <table class="w-100">
                            <caption class="div-text py-0 my-0"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded">Medicamentos</th>
                                    <th class="encabezado-rounded px-1">Fecha</th>
                                    <th class="encabezado-rounded px-1">Acción</th>
                                </tr>
                            </thead>
                        </table>
    
                        <div class=" table-responsive px-0" >
                            <table class="w-100">
                                <tbody class="div-text" v-if="medicine.length > 0">
        
                                    <tr class="px-1 mb-1" v-for="(item , index) in medicine" :key="index">
        
                                        <td class="border">
                                            {{ item.name }} 
                                            <span v-if="item.group !=0 " class=" px-2 rounded bg-info"> {{ 'Grupo '+ item.group  }}</span>
                                        </td>
                                        <td class="border">
                                            {{ item.fecha }}
                                        </td>
                                        <td class="border">
                                            <button :disabled="item.cancellation_date || dete_out || dete_out_doctor || rolname != 'DOCTOR(A)'" type="button" class=" btn btn-warning btn-sm px-1 py-0 text-white " @click="cancellation(item)"> <i class="fas fa-times"></i> </button>
                                        </td>
                                    </tr>
        
                                </tbody>
                                <tbody class=" text-center" v-else>
                                    <tr>
                                        <td colspan="4" class=" text-center">
                                            <strong> No hay resultados</strong>
                                        </td>
                                    </tr>
        
                                </tbody>
                            </table>
    
                        </div>
                    </div>
                </div>
    
                <div class=" responsive-container-div col-xl-5 col-lg-12 px-4 mb-3">
                    <div class="row">
                        <table class="w-100">
                            <caption class="div-text py-0 my-0"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class=""></th>
                                    <th class="encabezado-rounded">Concepto solicitud</th>
                                    <th class="encabezado-rounded">Fecha de solicitud</th>
                                    <th class="encabezado-rounded">Fecha de resultado</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" v-if="rp.plan.length > 0">
                                <tr>
                                    <td :rowspan="rp.plan.length + 1" class="fw-bold title-rp-2 p-2">PLAN</td>
                                </tr>
                                <tr class="px-1 mb-1" v-for="(plan , index) in rp.plan" :key="index">
    
                                    <td class="border">
                                        {{ plan.name }}
                                    </td>
                                    <td class="border">
                                        {{ plan.aplicacion }}
                                    </td>
                                    <td class="border">
                                        {{ plan.resultado ? plan.resultado : '-' }}
                                    </td>
                                </tr>
    
                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="4" class=" text-center">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>
    
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <div class="col-md-12 row  mx-0 ">
                    <div class="col-md-12 mb-xl-0 mb-3 px-0 mx-0" id="content-timelinetable">
                        <table class="w-100">
    
                            <div class="row mx-0 mb-2 px-0">
                                <table class="w-100">
                                    <caption class="div-text my-0 py-0"></caption>
                                    <thead class=" txt-xs ">
            
                                        <tr>
                                            <th class=" text-start text-xs pb-2 " scope="col" colspan="8"> Seleccione Medicamentos:</th>
                                           
                                        </tr>
                                       
                                    </thead>
                                    <tbody class="div-text  ">
                                        <tr class="px-1">
                                            <td colspan="2" class="w-25 z-index-select">
                                                <input type="text" v-model="receta" placeholder="Molecula" class="form-control form-control-input form-control-sm" :disabled="validation" @keyup.enter="mtdopenmodal" />
                                            </td>
                                            <td v-if="recipe_unit.presentation != '-'">
                                                <input type="text" v-model="recipe_unit.presentation" placeholder="Presentacion" class="form-control form-control-input form-control-sm" disabled />
                                            </td>
                                            <td v-if="!type">
                                                <input type="text" v-model="recipe_unit.quantity" placeholder="Concentración" class="form-control form-control-input form-control-sm" disabled />
                                            </td>
                                            <td v-if="!type || type_name == 3">
                                                <input type="text" v-model="recipe_unit.via" placeholder="Via" class="form-control form-control-input form-control-sm" :disabled="validation" />
                                            </td>
                                            <td v-if="!type">
                                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency" placeholder="Frecuencia (Horas) " class="form-control form-control-input form-control-sm" :disabled="validation" />
                                            </td>
                                            <td v-if="!type">
                                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration" placeholder="Duración (Días)" class="form-control form-control-input form-control-sm" :disabled="validation" />
                                            </td>
                                            <td v-if="type">
                                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm" :disabled="validation" />
                                            </td>
                                            <td v-if="!type">
                                                <button type="button" :disabled="validationInputrecipe || validation" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                            </td>
                                            <td v-else style=" width: 50px;">
                                                <button type="button" :disabled="validationtotal" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                            </td>
                                        </tr>
            
                                    </tbody>
                                </table>
                            </div>
                             <div v-if="!validation" class="d-flex justify-content-end mb-2">
                                <button @click="mtdViewMezclas"  type="button" class="btn  btn-info btn-sm px-4 py-0 text-xs text-white" :disabled="$NoInsumosUci.length <  2" >Mezclas</button>
                             </div>
                             <div class="row d-flex div-text mx-0 px-0 mb-2">
                                <div class="col-xl-8 col-md-12 px-0 pe-xl-1 pe-0">
                                    <table class="w-100 table table-bordered">
                                        <caption class="div-text my-0 py-0"></caption>
                                        <thead class=" div-text div-title text-center">
                                            <tr>
                                                <th colspan="12"  class="bg-main py-0">
                                                    <span class="fw-bold text-white">MEDICAMENTOS</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th  scope="col">Rp.</th>
                                                <th >Medicamentos</th>
                                                <th >Presentacion</th>
                                                <th >Concentración</th>
                                                <th >Via</th>
                                                <th >Dosis de la Mezcla</th>
                                                <th >Frecuencia (Horas)</th>
                                                <th >Duracion (Días)</th>
                                                <th >Tipo</th>
                                                <th >Grupo</th>
                                                <th >PRN</th>
                                                <th >Acciones</th>
                                            </tr>
                
                                        </thead>
                                        <tbody class="div-text" v-if="cpRecipe.length >0">
                
                                            <tr v-for="(recipe, index) in cpRecipe" :key="index" class="px-1">
                                                <td>
                                                    <span>{{ index + 1 }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.molecule }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.presentation }} </span>
                                                </td>
                                                <td>
                                                    <span>{{recipe.group != 0  ?  recipe.dosis  : recipe.quantity }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.via }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN DOSIS': '-') : recipe.quantity}}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.frequency }}</span>
                                                </td>
                
                                                <td>
                                                    <span>{{ recipe.duration }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.type == 2 ? 'INSUMO' : recipe.type == 3 ? 'SOLUCION': 'MEDICAMENTO' }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipe.group  }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="recipe.type == 1 ">
                                                        <div class="form-check form-switch">
                                                            <input @input="mtdPrnGroup(recipe)" :disabled="validation" v-model="recipe.prn" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                                            <label class="form-check-label" for="flexSwitchCheckDefault">{{ recipe.prn ? 'SI':'NO' }}</label>
                                                        </div>
                                                    </span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>
                                                    <button type="button" @click="mtddelete(index,recipe.order, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 " :disabled="validation"> <i class="fas fa-trash-alt text-white"></i> </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody class=" text-center" v-else>
                                            <tr>
                                                <td colspan="12" class=" text-center">
                                                    <strong> No hay resultados</strong>
                                                </td>
                                            </tr>
                
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xl-4 col-md-12 px-0">
                                    <table class="w-100 table table-bordered mb-1">
                                        <caption class="div-text my-0 py-0"></caption>
                                        <thead class=" div-text div-title text-center">
                                            <tr>
                                                <th colspan="4"  class="bg-main py-0">
                                                    <span class="fw-bold text-white">INSUMOS</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th  scope="col">Rp.</th>
                                                <th >Insumo</th>
                                                <th >Presentacion</th>
                                                <th >Acciones</th>
                                            </tr>
                
                                        </thead>
                                        <tbody class="div-text" v-if="cpInsumo.length >0">
                                            <tr v-for="(recipe, index) in cpInsumo" :key="index+'1'" class="px-1">
                                                <td>
                                                    <span>{{ index + 1 }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.molecule }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipe.presentation }} </span>
                                                </td>
                                                <td>
                                                    <button type="button" @click="mtddelete(index,recipe.order, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 " :disabled="validation"> <i class="fas fa-trash-alt text-white"></i> </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody class=" text-center" v-else>
                                            <tr>
                                                <td colspan="12" class=" text-center">
                                                    <strong> No hay resultados</strong>
                                                </td>
                                            </tr>
                
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </table>
    
                    </div>
    
                    <div v-if=" insuline_state " class="row mx-0 px-0 col-xl-12 mt-2">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded"> Insulina</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
    
                                    <td>
                                        <textarea v-model="rp.insuline" class="form-control form-control-sm" id="insulina" rows="4" :disabled="validation"></textarea>
                                    </td>
    
                                </tr>
    
                            </tbody>
                        </table>
                    </div>
                    <div class="row mx-0 px-0 col-xl-6">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded"> Indicaciones y/o Recomendaciones </th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
    
                                    <td>
                                        <textarea v-model="rp.recomendation" class="form-control form-control-sm" id="indicaciones" rows="3" :disabled="validation"></textarea>
                                    </td>
    
                                </tr>
    
                            </tbody>
                        </table>
                    </div>
                    <div class="row mx-0 px-0 ps-xl-2 col-xl-6">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded"> Dieta </th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
                                    <td>
                                        <textarea v-model="rp.dieta" class="form-control form-control-sm" id="dieta" rows="3" :disabled="validation"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row my-1 mx-0 px-0" v-if="rp.date_name  == 'RP ACTUAL'">
                        <div class="col-xl-2 px-0  div-text  h6 fw-bold d-flex align-items-center">
                            <span>MÉDICO GENERAL</span>
                        </div>
                        <div class="col-xl-3 px-0 py-2">
                            <input v-model="rp.doctor_name" type="text" class="form-control form-control-input form-control-sm" readonly />
                        </div>
                        <div class="col-xl-2 px-0 ms-xl-5 h6 fw-bold d-flex align-items-center div-text">
                            <span>MÉDICO ESPECIALISTA</span>
                        </div>
                        <div class="col-xl-3 px-0 py-2">
                            <input type="text" class="form-control form-control-input form-control-sm" />
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleterecipe="mtddeleterecipe" @mtdCancellation="mtdCancellation" />
        <Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title" :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
        <Mezclas @mtdchange="mtdchange" :medicinemezclas="medicinemezclas" :title="modalmezcla.title" :boo_modal="modalmezcla.modal_form" @mtdclosemodalmezclas="mtdclosemodalmezclas"/>
        <Rp :insulina_estado="insulina_estado"  :rp_replicado="rp_replicado" :title="modalrp.title" :boo_modal="modalrp.modal_form" @mtdclosemodalrp="mtdclosemodalrp " @mtdchangerp="mtdchangerp" />
    </div>
    <div v-else>
        <div class="text-center mt-5">
            <h2>No se encontraron resultados</h2>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex';
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import Medicine from '@/components/Asistencial/UciAdult/modals/cModalMedicine.vue';
    import Mezclas from '@/components/Asistencial/UciAdult/modals/cModalMezclas.vue';
    import Rp from '@/components/Asistencial/Emergency/modals/cModalRp.vue'
    export default {
        name: 'c-asistencial-sop-historia-rp',
        components: {
            SweetAlert,
            Medicine,
            Mezclas,
            Rp,
        },
        data() {
            return {
                productdetails: [],
                receta: '',
                swal: null,
                rps: [],
                idDelete: null,
                rp: {
                    date_name: 'RP ACTUAL',
                    id: null,
                    medical_act_id: null,
                    date: null,
                    hour: null,
                    day_hosp: 0,
                    day_sop: 0,
                    day_uci: 0,
                    doctor_name: '',
                    user_id: null,
                    recipe: [],
                    observation: '',
                    plan: [],
                    recomendation: '',
                    dieta: '',
                    insuline:''
                },
                recipe_unit: {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    type: null,
                    total: null,
                    quantity:'',
                    group : 0,
                    prn:false
                },
                pos: null,
                dataplan: {},
                validation: false,
                modalmedicine: {
                    title: "",
                    modal_form: false,
                },
                posmedicine: null,
                type: false,
                medicine: [],
                modalmezcla: {
                    title: "",
                    modal_form: false,
                },
                medicinemezclas:[],
                group_state: false,
                noInsumos:[],
                modalrp: {
                    title: "",
                    modal_form: false,
                },
                rp_replicado:{},
                insuline_state:false,
                insulina_estado:false,
                type_name : 0,
            }
        },
    
        created() {
            this.setCurrentDateTime();
    
        },
        props: {
            medical_act_id: {
                type: Number,
                default: 0
            },
            addRp: {
                type: Number,
                default: 0
            },
            dete_out :{
                type: Boolean
            },
            dete_out_doctor:{
                type: Boolean
            },
            rolname: String,
            process: Number,
        },
        watch: {
            medical_act_id: {
                handler: function (newValue) {
                    console.log(newValue);
                    if (newValue != 0) {
                        this.mtdday();
                        this.mtdGetRps();
                        this.indextime()
    
                    }
                },
                deep: true,
                immediate: true,
            },
            addRp: {
                handler: function (newValue) {
                    if (newValue == 1) {
                        this.mtdstoredata()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        computed: {
            validationInputrecipe: function () {
              let value = true
                if (this.receta && this.recipe_unit.dosis && this.recipe_unit.presentation && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                    value = false
                }
                return value
            },
            validationtotal: function () {
                let value = true
                if (this.recipe_unit.total) {
                    value = false
                }
                return value
            },
            cpInsumo: function(){
                let result = [];
                //console.log(this.rp.recipe);
                this.rp.recipe.forEach(element => {
                    if(element.type == 2){
                        result.push(element);
                    }
                });
                return result;
            },
            cpRecipe(){
                let rpTotal = this.rp.recipe;
                let result = [];
                rpTotal.forEach(element => {
                    if(element.type !== 2){
                        result.push(element);
                    }
                });
                return result;
            }
        },
        methods: {
            ...mapActions(['get', 'post']),
            mtdhidde: function () {
                this.rp = {
                    date_name: 'RP ACTUAL',
                    id: null,
                    date: null,
                    hour: null,
                    day_hosp: 0,
                    day_sop: 0,
                    day_uci: 0,
                    doctor_name: '',
                    user_id: null,
                    recipe: [],
                    observation: '',
                    plan: [],
                    recomendation: '',
                    dieta: '',
                    insuline:''
                }
                this.$miVariableGlobalRpSop.recipe = this.rp.recipe
                this.$miVariableGlobalRpSop.observation = this.rp.observation
                this.$miVariableGlobalRpSop.recomendation = this.rp.recomendation
                this.$miVariableGlobalRpSop.dieta = this.rp.dieta
                this.$miVariableGlobalRpSop.medical_act_id = null
                this.$miVariableGlobalRpSop.day_sop = this.rp.day_sop
                this.$miVariableGlobalRpSop.insuline = this.rp.insuline
                this.$NoInsumosUci=[]
                this.insuline_state = false
            },
            setCurrentDateTime() {
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset() * 60000;
                const localDate = new Date(now.getTime() - timezoneOffset);
                this.$miVariableGlobalRpSop.date = localDate.toISOString().split('T')[0];
                this.$miVariableGlobalRpSop.hour = now.toTimeString().split(' ')[0];
                this.$miVariableGlobalRpSop.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
                this.$miVariableGlobalRpSop.user_id = this.$store.getters.get__user.id;
                this.$miVariableGlobalRpSop.medical_act_id = this.medical_act_id;
    
                this.$miVariableGlobalRpSop_plantilla.date = localDate.toISOString().split('T')[0];
                this.$miVariableGlobalRpSop_plantilla.hour = now.toTimeString().split(' ')[0];
                this.$miVariableGlobalRpSop_plantilla.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
                this.$miVariableGlobalRpSop_plantilla.user_id = this.$store.getters.get__user.id;
                this.$miVariableGlobalRpSop_plantilla.medical_act_id = this.medical_act_id;
            },
            addreceta: function (payload) {
                this.type = (payload.type == 2 || payload.type == 3) ? true : false
                this.type_name= payload.type
                this.receta = payload.molecule
                this.recipe_unit.order = this.rp.recipe.length + 1;
                this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
                this.recipe_unit.type = payload.type
                this.posmedicine = null;
                this.modalmedicine = {
                    title: "",
                    modal_form: false,
                }
                if (this.type) {
                    this.recipe_unit.via = this.type_name ==3 ? '': '-'
                    this.recipe_unit.frequency = '-'
                    this.recipe_unit.quantity = '-'
                    this.recipe_unit.duration = '-'
                    this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                    this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
                } else {
                    this.recipe_unit.dosis = payload.dosis;
                    this.recipe_unit.quantity = payload.dosis;
                    this.recipe_unit.presentation = payload.presentation;
                }
            },
            mtdaddrecipe: function () {
                const exists = this.rp.recipe.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
                if (exists) {
                    this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
                } else {
                    this.rp.recipe.push(this.recipe_unit);
                    
                }
                this.type = false
                this.receta = null
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    type: null,
                    total: null,
                    quantity: '',
                    group : 0,
                    prn:false
                }
                this.$NoInsumosUci=[]
                this.insuline_state = false
                this.rp.recipe.forEach( item =>{
                    if(item.type == 1){
                        this.$NoInsumosUci.push(item)
                    }
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
                })
    
    
            },
            mtddelete: function (index,order, msg) {
                this.idDelete = order;
                this.$refs.SweetAlert.showDelete(index, msg);
    
            },
            mtddeleterecipe: function (indexTemp) {
                let index = this.rp.recipe.findIndex(item => item.order === this.idDelete);
                let num = this.rp.recipe[index].group
                if (this.rp.recipe[index].group != 0) {
                    for (let j = this.rp.recipe.length - 1; j >= 0; j--) {
                        if (this.rp.recipe[j].group == num) {
                            this.rp.recipe.splice(j, 1);
                        }
                    }
                } else {
                    this.rp.recipe.splice(index, 1);
                }
                this.insuline_state = false
                for (let i = 0; i < this.rp.recipe.length; i++) {
                    this.rp.recipe[i].order = i + 1;
                    if (this.rp.recipe[i].type == 1 && this.rp.recipe[i].molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
                }
                if(!this.insuline_state){
                  this.rp.insuline = ''
                }
            },
            mtdday: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Sop/day/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        let day = response.data.sop.date_entry;
                        let currentDate = new Date();
                        let entryDate = new Date(day);
                        currentDate.setHours(0, 0, 0, 0);
                        entryDate.setHours(0, 0, 0, 0);
                        let differenceInMilliseconds = currentDate - entryDate;
                        let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                        this.rp.day_sop = differenceInDays + 1;
                        this.$miVariableGlobalRpSop.day_sop = this.rp.day_sop
                        this.$miVariableGlobalRpSop_plantilla.day_sop = this.rp.day_sop
                    }
                });
            },
            mtdGetRps: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Sop/indexRp/" + this.medical_act_id+"/"+this.process,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        let dataRps = response.data.rps;
                        this.dataplan = response.data.planes;
                        this.rps = []
                        dataRps.forEach(element => {
                            let rpdetails = {
                                doctor_name: element.doctor.name,
                                date_name: element.datename,
                                id: element.id,
                                medical_act_id: element.medical_act_id,
                                date: element.date,
                                hour: element.hour,
                                day_hosp: element.day_hosp,
                                day_sop: element.day_sop,
                                day_uci: element.day_uci,
                                observation: element.observation,
                                recipe: JSON.parse(element.recipe),
                                plan: [],
                                recomendation: element.recomendation,
                                dieta: element.dieta,
                                insuline:element.insulin
                            }
                            this.rps.push(rpdetails)
                        });
    
                        this.rp = this.$miVariableGlobalRpSop
                        this.rp.plan = []
                        this.dataplan.forEach(item => {
                            const rpDate = this.rp.date.split(' ')[0];
                            const itemDate = item.aplication_date.split(' ')[0];
                            // if (rpDate === itemDate) {
                            let concepto = {
                                name: item.subsubcategorie.name,
                                aplicacion: item.aplication_date_new,
                                resultado: item.result_date_new
                            }
                            this.rp.plan.push(concepto)
    
                            // }
                        });
                        this.$miVariableGlobalRpSop_plantilla.plan = this.rp.plan;
                        this.rps.push(this.rp);
                        this.pos = this.rps.length - 1
                        if(this.dete_out || this.rolname != 'DOCTOR(A)' || this.dete_out_doctor){
                            this.rps.pop(); 
                            if (this.rps.length > 0) {
                                this.backPage()
                            }
                        }
                        this.$NoInsumosUci = []
                        this.insuline_state = false
                        this.rp.recipe.forEach(item => {
                            if (item.type == 1) {
                                this.$NoInsumosUci.push(item)
                            }
                            if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                                this.insuline_state = true
                            }
    
                        })
                    }
                });
            },
            backPage: function () {
                this.pos = (this.pos == 0) ? this.pos : this.pos - 1
                this.rp = this.rps[this.pos]
                this.rp.plan = [];
                this.dataplan.forEach(item => {
                    const rpDate = this.rp.date.split(' ')[0];
                    const itemDate = item.aplication_date.split(' ')[0];
                    // if (rpDate === itemDate) {
                    let concepto = {
                        name: item.subsubcategorie.name,
                        aplicacion: item.aplication_date_new,
                        resultado: item.result_date_new
                    }
                    this.rp.plan.push(concepto)
                    // }
                });
                this.insuline_state = false
                this.rp.recipe.forEach(item => {
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
    
                })
                this.validation = this.rp.date_name != 'RP ACTUAL'
            },
            nextPage: function () {
                this.pos = (this.pos == this.rps.length - 1) ? this.pos : this.pos + 1
                this.rp = this.rps[this.pos]
                this.rp.plan = [];
                this.dataplan.forEach(item => {
                    const rpDate = this.rp.date.split(' ')[0];
                    const itemDate = item.aplication_date.split(' ')[0];
                    // if (rpDate === itemDate) {
                    let concepto = {
                        name: item.subsubcategorie.name,
                        aplicacion: item.aplication_date_new,
                        resultado: item.result_date_new
                    }
                    this.rp.plan.push(concepto)
                    // }
                });
                this.insuline_state = false
                this.rp.recipe.forEach(item => {
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
                })
                this.validation = this.rp.date_name != 'RP ACTUAL'
            },
            mtdstoredata: function () {
                this.mtdhidde()
                this.mtdday()
                this.setCurrentDateTime()
                this.mtdGetRps()
                this.indextime()
                this.group_state= false
                this.$emit('mtdaddrpexit')
            },
            mtdopenmodal: function () {
                if (this.receta) {
                    this.posmedicine = 1;
                    this.modalmedicine = {
                        title: "Lista de Medicamentos",
                        modal_form: true,
                    }
                } else {
                    this.$refs.SweetAlert.showWarning('Digite Molecula');
                }
            },
            mtdclosemodalmedicine: function () {
                this.posmedicine = null;
                this.modalmedicine = {
                    title: "",
                    modal_form: false,
                }
                this.receta = '',
                    this.recipe_unit = {
                        order: null,
                        product_id: null,
                        molecule: '',
                        presentation: '',
                        dosis: '',
                        via: '',
                        frequency: '',
                        duration: '',
                        quantity:'',
                        group : 0,
                        prn:false
                    }
            },
            isNumber: function (evt) {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
    
            indextime: function () {
                this.medicine = []
                this.get({
                        url: this.$store.getters.get__url + "/Sop/indexschedule/" + this.medical_act_id+"/"+this.process,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        response.data.results.forEach(recipe => {
                            recipe.recipedetails.forEach(recipedetail => {
                                if (recipedetail.supplied_state == 0 && recipedetail.type ==1) {
                                    let medicenedetail = {
                                        recipe_id: recipedetail.recipe_id,
                                        recipedetail_id: recipedetail.id,
                                        name: recipedetail.molecule + ' ' + recipedetail.presentation + ' ' + recipedetail.dosis + ' ' + recipedetail.via ,
                                        quantity: recipedetail.quantity,
                                        group: recipedetail.mix,
                                        hora: recipedetail.frequency,
                                        fecha: recipedetail.date_start,
                                        cancellation_date: recipedetail.cancellation_date
                                    }
                                    this.medicine.push(medicenedetail)
                                }
    
                            });
    
                        });
    
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            },
    
            cancellation: function (payload) {
                this.$refs.SweetAlert.cancellation(payload);
            },
    
            mtdCancellation: function (payload) {
                this.post({
                        url: this.$store.getters.get__url + "/Uciadulto/cancellation",
                        token: this.$store.getters.get__token,
                        params: {
                            id: payload.recipedetail_id
                        }
                    })
                    .then((response) => {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.medicine = [];
                        this.indextime()
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            },
            mtdViewMezclas: function(){
                this.modalmezcla= {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
                this.medicinemezclas=this.rp.recipe  
            },
            mtdclosemodalmezclas:function(){
                this.modalmezcla= {
                    title: "",
                    modal_form: false,
                }
            },
    
            mtdchange : function(payload){
                this.rp.recipe = payload
                this.group_state= true
                this.mtdclosemodalmezclas()
            },
            mtdcambiar: function (){
                this.modalrp = {
                    title: "Replicacion de Rp",
                    modal_form: true,
                }
                this.rps[this.rps.length -2].recipe.forEach ( item =>{
                     item.value = true
                })
                this.rp_replicado = this.rps[this.rps.length -2]
                this.insulina_estado = false
                this.rp_replicado.recipe.forEach(item => {
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insulina_estado = true
                    }
    
                })
            },
            mtdclosemodalrp: function(){
                this.modalrp = {
                    title: '',
                    modal_form: false ,
                }
            },
            mtdchangerp:  function(payload){
                 this.rps[this.rps.length -1].dieta = payload.dieta
                 this.rps[this.rps.length -1].observation = payload.observation
                 this.rps[this.rps.length -1].recomendation = payload.recomendation
                 this.rps[this.rps.length -1].recipe = payload.recipe
                 this.rps[this.rps.length - 1].insuline = payload.insuline
                this.insuline_state = false
                this.rps[this.rps.length - 1].recipe.forEach(item => {
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
    
                })
                 this.mtdclosemodalrp()
            },
            mtdPrnGroup:function(payload){
              if(payload.group !=0){
                this.rp.recipe.forEach ( item =>{
                  if(item.group == payload.group){
                    item.prn = !item.prn
                  }
                })
              } 
            }
    
    
        }
    }
    </script>
    <style scoped>
        .table-responsive{
            overflow-y: auto;
            max-height: 170px;
            overflow-x: hidden;
            scrollbar-width: thin;  
            scrollbar-color: #888 #f1f1f1; 
        }
    
        .table-responsive::-webkit-scrollbar {
            width: 4px;  
            background-color: #f1f1f1; 
        }
    
        .table-responsive::-webkit-scrollbar-thumb {
            background-color: #888;  
            border-radius: 10px;  
        }
        .table-responsive::-webkit-scrollbar-track {
            border-radius: 10px;  
        }
    
        .table-responsive::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
    </style>
<template>
<div class="col-sm-5 col-xl-5 col-lg-12" v-if="typeInput !== 'hour'">
        <label class=" color-main fw-bold" v-if="!isEditing" @dblclick="startEditing">
            {{ value }}
        </label>
        <div v-else class="px-2">
            <input type="text" :name="value" class="form-control form-control-sm" @keyup.enter="finishEditing" @blur="finishEditing" v-model="inputValue">
        </div>
</div>
<div v-else>
    <span v-if="!isEditing" @dblclick="startEditing">
        {{ value }}
    </span>
    <div v-else class="w-100">
        <input type="time" step="1" :name="value" class="form-control form-control-sm" @keyup.enter="finishEditing"  v-model="inputValue">
    </div>
</div>
</template>

<script>
export default {
    props: ['value', 'index', 'array', 'typeInput','turno'],
    data() {
        return {
            isEditing: false,
            inputValue: this.value,
            isValid: true
        };
    },
    methods: {
        startEditing() {
            this.isEditing = true;
        },
        finishEditing() {
            this.$emit('update-label', this.inputValue, this.index, this.array,  this.turno);
            this.isEditing = false;
        },
    }
};
</script>

import { render, staticRenderFns } from "./Staff.vue?vue&type=template&id=8bb78272&scoped=true"
import script from "./Staff.vue?vue&type=script&lang=js"
export * from "./Staff.vue?vue&type=script&lang=js"
import style0 from "./Staff.vue?vue&type=style&index=0&id=8bb78272&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bb78272",
  null
  
)

export default component.exports
<template>
<div class="container py-3">
    <div class="d-flex title-option mt-3 mb-4">
        <button type="button" class="btn btn-outline-main custom-btn" @click="returnHome()">
            <i class="fas fa-arrow-left"></i> Volver
        </button>
    </div>
    <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="text-center card-header text-white h4 bg-main">
                <strong>DEVOLUCIONES</strong>
            </div>
            <div class="card-body w-100">
            <div class="row mx-0">
                <div class="col-md-12 ">
                    <div class=" d-lg-flex justify-content-between">
                        <div class="mt-2 col-xl-2 col-lg-2 col-md-12">
                            <select v-model="type" class=" form-control form-control-sm" aria-label="Default select example" @change="mtdDevoluciones">
                                <option v-for="(item, index) in destino" :key="index" :value="item">
                                    <span>
                                        {{
                                            item == 1 ? 'CONSULTA' : 
                                            item == 3 ? 'HOSPITALIZACIÓN':
                                            item == 4 ? 'UCI ADULTO': 'EMERGENCIA' 
                                        }}
                                    </span>
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-12 mt-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-text">Paciente</div>
                                <input type="text" class="form-control form-control-sm" placeholder="Username" v-model="patient_name" readonly>
                            </div>
                        </div>
                        <div>
                            <button v-if="devoluciones.length >0" class="btn btn-success btn-sm mt-2 px-3" @click="addReturns" >
                              Devolver
                            </button>
                        </div>
                    </div>

                    <div class="row div-text mx-0 px-0 mb-2 text-center mt-3">
                        <table id="tb-anular" class="table table-bordered">
                            <caption class="mx-0 px-0"></caption>
                            <thead class="bg-main" style="font-size: 12px">
                                <tr class="text-white">
                                    <th scope="col" class="align-middle " style="width: 10%;">Seleccionar</th>
                                    <th scope="col" class="align-middle  " style="width: 70%;">Nombre de Producto</th>
                                    <th scope="col" class="align-middle " style="width: 20%;">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody class="border-main" style="font-size: 11 px" v-if="devoluciones.length >0">
                                <tr class="tbody-table" v-for="(item, index) in devoluciones " :key="index">
                                    <td class="align-middle">
                                        <input class="form-check-input" v-model="item.status" type="checkbox" value="" :id="'flexCheckDefault'+index">
                                    </td>
                                    <td class="align-middle">
                                        {{item.molecule }} - {{ item.presentation }} - {{ item.dosis }}
                                    </td>

                                    <!--                                     
                                    <td class="text-center align-middle">
                                        <input class="form-control form-control-input form-control-sm text-center"  :disabled="!item.status || item.type " v-on:keypress="isNumber($event)" type="text" v-model="item.quantity" placeholder="Cantidad Entrante" />
                                    </td> 
                                    -->

                                    <td class="text-center align-middle">
                                        {{ item.quantity }}
                                    </td>

                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="3" class=" text-center">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        </div>
       
    </div>
    <cModalDevVue
        :title="modalDev.title"
        :boo_modal="modalDev.boo_modal"
        :item="modalDev.item"
        :patient_id="patient_id"
        :id_medical="id_medical"
        :selectedItems="modalDev.selectedItems"
        :typeAc="modalDev.typeAc"
        @updateDev ="mtdDevolucionesUp"
        @mtdClose="mtdCloseModalDev"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalDevVue from '@/components/Farmacia/pages/Dev/Modals/cModalDev.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-asistencial-devoluciones",

    data() {
        return {
            swal: null,
            devoluciones: [],
            destino: [],
            type: null,
            patient_name: "",
            modalDev: {
                title: "DEVOLUCIONES",
                item: {},
                boo_modal: false,
                selectedItems: [],
                typeAc: null
            },
        }
    },
    components: {
        SweetAlert,
        cModalDevVue
    },

    watch: {
        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdselect();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {

    },
    props: {
        patient_id: {
            type: Number,
            default: 0
        },
        id_medical: {
            type: Number,
            default: 0
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdselect: function () {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/indexMovimientos",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    let destinoold = response.data 
                    this.destino =[]
                    destinoold.forEach(item => {
                        if(item != 1){
                            this.destino.push(item)
                        }
                    })
                    let num = this.destino.length
                    this.type = this.destino[num - 1];
                    this.mtdDevoluciones()
                }
            });
        },

        mtdDevoluciones: function () {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/returns/indexDevolucionesPharmacy",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical,
                    type: this.type
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.devoluciones = response.data.devoluciones;
                    this.patient_name = response.data.patient_name;
                }
            });
        },

        mtdDevolucionesUp: function (id_medical, type) {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/returns/indexDevolucionesPharmacy",
                token: this.$store.getters.get__token,
                params: {
                    id: id_medical,
                    type: type
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.devoluciones = response.data;
                }
            });
        },

        addReturns: function () {


            const selectedItems = this.devoluciones
            .filter(item => item.status) // Solo seleccionados
            .map(item => (
                { 
                    id: item.id, 
                    recipe_detail_id: item.recipe_detail_id,
                    total: item.quantity 
                }));

            if (selectedItems.length === 0) {
                this.$refs.SweetAlert.showWarning("No ha seleccionado ningún Producto para Devolver");
                return;
            }

            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/returns/addProductDetail",
                token: this.$store.getters.get__token,
                params: {
                    selectedItems: selectedItems,
                    id_medical: this.id_medical 
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if(response.data.length == 0){
                        this.modalDev = {
                            title: "DEVOLUCIONES",
                            item: {},
                            boo_modal: false,
                            selectedItems: [],
                        };
                        this.$refs.SweetAlert.showWarning("Sin Datos");
                    }else{
                        this.modalDev.item = response.data;
                        this.modalDev.selectedItems = selectedItems;
                        this.modalDev.typeAc = this.type;
                        this.modalDev.boo_modal = true;
                    }
                }
            });

            /* this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de realizar la Devolución?","warning","Confirmar") 
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/Pharmacy/returns/addReturns",
                        token: this.$store.getters.get__token,
                        params: {
                            selectedItems
                        }
                    }).then((response) => {
                        if (response.statusCode === 200) {
                            this.$refs.SweetAlert.showSuccess("Devolucion registrada correctamente");
                            this.mtdDevoluciones();
                        }
                    });
                }
            }); */
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        returnHome: function () {
            this.$emit("mtdBack");
        },

        //Modal
        
        mtdCloseModalDev: function () {
            this.modalDev = {
                title: "DEVOLUCIONES",
                item: {},
                boo_modal: false,
                selectedItems: [],
            };
        },
    },
}
</script>
<style>
.arrow{
    align-self: flex-end;
}
.custom-btn {
    color: #900052;
    border-color: #900052;
}

.custom-btn:hover {
    background-color: #900052;
    color: white;
}

.custom-btn i {
    color: #900052;
}
.custom-btn:hover i {
    color: white;
}
</style>

<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="d-lg-flex mt-3">
            <div class="col-lg-1 mb-1">
                <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackSop">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7 col-md-12 rounded-3 px-0 bg-blue mb-2 text-white">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-2 mb-2 px-1 px-md-1 px-xl-3">
                <div class="card border border-main pt-1">
                    <span class="color-main fw-bold text-uppercase">PreOperatorio</span>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 mb-1 d-none d-lg-flex">
                <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="mtdSave()" :disabled="cpDisabledButton">
                    <i class="fas fa-save"></i> GUARDAR
                </button>
            </div>
        </div>
    </div>
    <!-- TABs -->
    <div class="px-2 mt-2 ">

<ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
    
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('ordensop')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage=='ordensop' }" id="profile-tab" type="button">
            <strong class="text-xxs">ORDEN DE SOP</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('rxqx')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rxqx' }" id="profile-tab" type="button">
            <strong class="text-xxs">RX QX</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('consentimientos')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'consentimientos' }" id="profile-tab" type="button">
            <strong class="text-xxs">CONSENTIMIENTOS</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('informeoperatorio')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'informeoperatorio' }" id="profile-tab" type="button">
            <strong class="text-xxs">INFORME OPERATORIO</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('preanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'preanestecia' }" id="profile-tab" type="button">
            <strong class="text-xxs">PRE ANESTECIA</strong>
        </button>
    </li>
</ul>

<div class="tab-content mb-3 d-none d-lg-block" id="myTabContentHijo">
    <Ordensop v-if="subpage=='ordensop'" :medical_act_id="medical_act_id" :addOrder="addOrder" @finishAddOrder="finishAddOrder" :rolname="rolname" />
    <Rxqx v-if="subpage=='rxqx'" :medical_act_id="medical_act_id" :addRxqx="addRxqx" @finishAddOrder="finishAddOrder" :rolname="rolname"/>
    <Informeoperatorio v-if="subpage=='informeoperatorio'" :medical_act_id="medical_act_id" :addReportSop="addReportSop" @finishAddOrder="finishAddOrder" :rolname="rolname" />
    <Consentimiento v-if="subpage == 'consentimientos'" :medical_act_id="medical_act_id"/>
    <Preanestecia v-if="subpage=='preanestecia'" :medical_act_id="medical_act_id" :addPreAnestecia="addPreAnestecia" @finishAddOrder="finishAddOrder"/>
</div>

<!-- Tablet -->
<div id="app" class="root d-md-block d-lg-none">
    <div class="wrapper-list">
        <dropdown title="Orden de Sop" component="Ordensop" :medical_act_id="medical_act_id" :addOrder="addOrder" @finishAddOrder="finishAddOrder" :rolname="rolname"></dropdown>
        <dropdown title="Rx qx" component="Rxqx" :medical_act_id="medical_act_id" :addRxqx="addRxqx" :rolname="rolname" @finishAddOrder="finishAddOrder"></dropdown>
        <dropdown title="Consentimientos" component="consentimientos" :medical_act_id="medical_act_id"></dropdown>
        <dropdown title="Informe operatorio" component="Informeoperatorio" :medical_act_id="medical_act_id" :rolname="rolname" :addReportSop="addReportSop" @finishAddOrder="finishAddOrder" @changeSubPage="changeSubPage"></dropdown>
        <dropdown title="Pre anestecia" component="preanestecia" :medical_act_id="medical_act_id"  :addPreAnestecia="addPreAnestecia" @finishAddOrder="finishAddOrder"></dropdown>
    </div>
</div>
<!--  -->
</div>
</div>
</template>

<script>

import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Consentimiento from '@/components/Asistencial/Sop/Historia/Consentimientos.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';

export default {
    name: 'c-asistencial-sop-preoperatorio',
    props: {
        rolname: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        
    },
    data() {
        return {
            subpage: 'ordensop',
            medical_act_id: 0, 
            addOrder: null,
            addRxqx: null,
            addReportSop: null,
            addPreAnestecia: null,
        }
    },
    components: {
        Ordensop,
        Preanestecia,
        Rxqx,
        Consentimiento,
        Informeoperatorio,
        Dropdown,
    },
    watch: {
        datapatients: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.medical_act_id = newValue.medical_act_id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed:{
        cpDisabledButton: function(component){
            let dbutton = false;
            if(this.rolname !== 'DOCTOR(A)'){
                if(this.subpage =='ordensop' || this.subpage =='rxqx' || this.subpage=='informeoperatorio' || this.subpage == ''){
                    dbutton = true;
                }
            }
            return dbutton;
        }
    },
    methods: {
        mtdBackSop: function(){
            this.$emit('mtdBackSop');
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        mtdSave: function(){
            if(this.subpage=='ordensop'){
                this.addOrder = 1;
            }else if(this.subpage == 'rxqx'){
                this.addRxqx = 1;
            }else if(this.subpage == 'informeoperatorio'){
                this.addReportSop = 1;
            }else if(this.subpage == 'preanestecia'){
                this.addPreAnestecia = 1;
            }
        },
        finishAddOrder: function(){
            this.addOrder = 0;
            this.addRxqx = 0;
            this.addReportSop = 0;
            this.addPreAnestecia = 0;
        }
    }
}
</script>

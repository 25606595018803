<template>
<div>
    <div class="container">
        <div id="btn-home" class="col-md-12 mt-3 mb-4 d-flex justify-content-end btn-home">
            <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center" style="background: #900052">
                    <strong>MOV. ENTRE ALMACENES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex mx-0 px-0">
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 mt-2">
                            <label for="" class="mb-1"><strong>Almacén Origen: </strong></label>
                            <v-select
                                class="text-dark flex-grow-1"
                                v-model="almacen"
                                label="name"
                                :options="dataAlmacenes"
                                placeholder="-- Selecionar Almacenes de Origen --"
                                :reduce="(almacen) => almacen.id"
                                required
                                :clearable="false"
                                @input="mtdGetDataProductos()"
                            >
                                <template slot="no-options">
                                No hay Almacenes para la búsqueda.
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-9 col-sm-9 col-xs-9 mt-2">
                            <label for="" class="mb-1"> <strong>Almacén Destino: </strong></label>
                            <v-select
                                class="text-dark flex-grow-1"
                                v-model="destino"
                                label="name"
                                :options="dataDestino"
                                placeholder="-- Selecionar Almacenes de Destino --"
                                :reduce="(almacen) => almacen.id"
                                required
                                :clearable="false"
                            >
                                <template slot="no-options">
                                No hay Almacenes para la búsqueda.
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-3 mt-2">
                            <label for="" class="">&nbsp;</label>
                            <div>
                                <button type="button" class="btn w-100 bg-main text-white" @click="mtdGuardar()" :disabled="mtddisabled">
                                    <i class="fas fa-save"></i> GUARDAR
                                </button>
                            </div>
                        </div>
                        <div v-if="num == 1" class="col-xl-12 mt-3 mb-4 table-responsive " id="content-timelinetable">
                            <div class="d-xl-flex justify-content-between px-1 mb-2 ">
                                <p class="msg">{{ msg }}</p>
                                <div v-if="dataProductos.length > 0 && cpData.length > 0">
                                    <nav aria-label="Page navigation example ">
                                        <ul class="pagination mb-0 ">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 ">
                                <div class="input-group input-group mb-3">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="search" type="text" class="form-control form-control" placeholder="Escriba un producto para realizar la búsqueda" @input="calculateData(6)" />
                                </div>
                            </div>
                            <table class="table text-xxs table-bordered border-main text-center align-middle" style="font-size: 12px;">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">FECHA DE VENCIMIENTO </th>
                                        <th scope="col">LOTE</th>
                                        <th scope="col">NOMBRE</th>
                                        <th scope="col">MOLECULA</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">COMERCIAL</th>
                                        <th scope="col">PRESENTACIÓN</th>
                                        <th scope="col">CONCENTRACIÓN</th>
                                        <th scope="col">CANTIDAD</th>
                                        <th scope="col">SALIENTE</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="10" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">
                                        <td>{{ item.dispatch_detail.date }}</td>
                                        <td>{{ item.dispatch_detail.lote }}</td>
                                        <td>{{ item.dispatch_detail.productodetail.subsubcategorie.name }}</td>
                                        <td>{{ item.dispatch_detail.productodetail.molecule }}</td>
                                        <td>{{ item.dispatch_detail.productodetail.brand ?  item.dispatch_detail.productodetail.brand.name : '-' }}</td>
                                        <td>{{ item.dispatch_detail.productodetail.commercial ? item.dispatch_detail.productodetail.commercial : '-'}}</td>
                                        <td>{{ item.dispatch_detail.productodetail.presentation ? item.dispatch_detail.productodetail.presentation : '-' }}</td>
                                        <td>{{ item.dispatch_detail.productodetail.dosis ? item.dispatch_detail.productodetail.dosis : '-' }}</td>
                                        <td>{{ mtdcantidad(item) }}</td>
                                        <td> <input type="text" class="form-control form-control-sm text-center" v-model="item.saliente" @input="mtdvalidatecantidad(item)" 
                                        placeholder="Saliente" @keypress="onlyNumbers($event)" ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-pharmacy-pages-movimientos",
    data() {
        return {
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataAlmacenes: [],
            almacen: '',
            destino: '',
            dataProductos: [],
            dataAlmacen: {},
            num: 0,
            dataDestino: [],
            numproduct: 0,
            search: '',
            vencido: true,
            msg: ''
        }
    },
    created() {
        this.mtdGetData();
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
        almacen(newAlmacen) {
            if (newAlmacen) {
                this.destino = '';
                const selectedAlmacen = this.dataAlmacenes.find(
                    (almacen) => almacen.id === newAlmacen
                );
                if (selectedAlmacen && selectedAlmacen.destino) {
                    this.dataDestino = selectedAlmacen.destino;
                } else {
                    this.destino = '';
                    this.dataDestino = [];
                }
            } else {
                this.destino = '';
                this.dataDestino = [];
            }
        },

    },
    computed: {
        mtddisabled() {
            return !(this.almacen != '' && this.destino != '' && this.dataProductos.length != 0 && this.numproduct != 0)
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdvalidatecantidad(item) {
            let salientenum = []
            this.dataProductos.forEach(item => {
                if (item.saliente) {
                    salientenum.push(item.saliente)
                }
            })
            this.numproduct = salientenum.length
            if (item.new_quantity < item.saliente) {
                Swal.fire({
                    title: "¡Advertencia!",
                    text: "El valor ingresado excede el permitido. El valor máximo permitido es: " + parseInt(item.new_quantity),
                    icon: "warning",
                    width: "400px",
                    confirmButtonColor: "rgb(170, 2, 95)",
                });
                item.saliente = '';
                this.numproduct = this.numproduct - 1
            }
            if(item.saliente == 0){
                Swal.fire({
                    title: "¡Advertencia!",
                    text: "El valor ingresado no puede ser 0 o Menor",
                    icon: "warning",
                    width: "400px",
                    confirmButtonColor: "rgb(170, 2, 95)",
                });
                item.saliente = '';
                this.numproduct = this.numproduct - 1
            }
        },

        mtdGetDataProductos: function () {
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/movement/prodAlmacen/" + this.almacen,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataProductos = response.data.productos;
                this.dataAlmacen = response.data.almacen;
                this.num = 1;
                this.msg = 'PRODUCTOS DEL ' + this.dataAlmacen.name
                this.calculateData(6)
                this.vencido = false
            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },

        calculateData(items) {

            let filteredData = this.dataProductos;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataProductos.filter((element) => {
                    let molecule = element.dispatch_detail.productodetail.molecule.toString().toUpperCase();
                    let name = element.dispatch_detail.productodetail.subsubcategorie.name.toString().toUpperCase();
                    return molecule.includes(query) || name.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/movement/almacen/"+ this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataAlmacenes = response.data
            }).catch((error) => {
                this.$refs.SweetAlert.showError(error);
            });
        },

        mtdcantidad(cantidad) {
            return parseInt(cantidad.new_quantity)
        },

        mtdGuardar: function () {
            Swal.fire({
                icon: "warning",
                title: "¿Está seguro de realizar este Movimiento?",
                input: "textarea",
                inputAttributes: {
                    autocapitalize: "off",
                    placeholder: "OBSERVACIÓN",
                },
                width: "400px",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    const note = result.value;
                    let productsaliente = []
                    this.dataProductos.forEach(item => {
                        if (item.saliente) {
                            productsaliente.push(item)
                        }
                    })
                    let data = {
                        id_origen: this.almacen,
                        id_destino: this.destino,
                        note: note,
                        productsaliente: productsaliente
                    }
                    this.post({
                        url: this.$store.getters.get__url + "/Pharmacy/movement/storeMovimiento",
                        token: this.$store.getters.get__token,
                        params: data
                    }).then((response) => {
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.dataProductos = [];
                        this.almacen = "";
                        this.destino = "";
                        this.num = 0;
                        this.numproduct = 0;
                        this.msg = '';
                        this.search= '';
                        this.viewpdf(response.data.id);
                    }).catch((error) => {
                        this.$refs.SweetAlert.showError(error);
                    })
                }
            });
        },

        onlyNumbers(event) {
            const key = event.key;
            if (!/^[0-9]*$/.test(key)) {
                event.preventDefault();
            }
        },

        viewpdf: function (id) {
            window.open(
                this.$store.getters.get__url +
                "/Pharmacy/movement/viewpdfHistMov/" +
                id,
                "_blank"
            );
        },

        //Pag
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        changePage(page) {
            this.$emit("returnHome", page);
        },

    },
}
</script>
<style>
.msg {
    font-style: italic;
    color: #900052;
    font-size: 13px;
    font-weight: bold;
}
</style>

<template>
<div class="p-content-sop">
    <div class="row">
        <div class="col-md-12 d-md-block d-lg-none my-2">
            <button type="button" class="btn btn-sm btn-outline-bm text-xs w-100" @click="mtdstoredata()">
                <i class="fas fa-save"></i> GUARDAR
            </button>
        </div>
        <!-- TABLA DATOS INICIALES -->
        <div class="col-md-12">
            <div class="row">
                <div class="col-xl-12 col-md-12">
                    <div class="row pt-2">
                        <div class="col-xl-4 col-lg-4 col-md-12 mb-2 align-left" v-for="(item2, index) in hoja.staff" :key="index">
                            <div class="form-group d-md-flex">
                                <label :for="'inputreq' + index" class="col-xl-5 col-md-4 col-sm-12 color-main fw-bold">{{ item2.label }}</label>
                                <div class="col-xl-7 col-md-8 col-sm-12">
                                    <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputreq' + index" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="d-md-flex gap-2">
                <div class="col-md-6 mt-2">
                    <!-- Evaluaciones -->
                    <div class="row px-2 div-border pt-2">
                        <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in hoja.values" :key="index">
                            <div class="form-group row">
                                <label :for="'inputev' + index" class="col-xl-4 col-md-12 color-main fw-bold">{{
                                        item2.label }}</label>
                                <div class="col-xl-8 col-md-12 col-sm-12">
                                    <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputev' + index" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 mt-2">
                    <!-- Inubacion Oral -->
                    <div class="row px-2 div-border pt-2 text-center">
                        <span class="color-main fw-bold">INUBACION ORAL</span>
                    </div>
                    <div class="row div-border py-2">
                        <div class="col-xl-6 col-md-12 mb-2">
                            <div class="form-group d-flex">
                                <label for="tet" class="col-md-4 col-sm-12 color-main fw-bold text-start">TET</label>
                                <div class="col-xl-8 col-md-7 col-sm-12">
                                    <input type="text" v-model="hoja.inubacion.tet" class="form-control form-control-input form-control-sm" id="tet" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12">
                            <div class="form-group d-flex">
                                <label for="mm" class="col-md-4 col-sm-12 color-main fw-bold text-start">MM</label>
                                <div class="col-xl-8 col-md-7 col-sm-12">
                                    <input type="text" v-model="hoja.inubacion.mm" class="form-control form-control-input form-control-sm" id="mm" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-5 mt-2">
                            <div class="form-inline mx-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioTipo" id="inlineRadio1" :value="'nasal'" v-model="hoja.inubacion.tipo">
                                    <label class="form-check-label text-md" for="inlineRadio1">Nasal</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioTipo" id="inlineRadio2" :value="'bronquial'" v-model="hoja.inubacion.tipo">
                                    <label class="form-check-label text-md" for="inlineRadio2">Bronquial</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-7 mt-2">
                            <div class="row">
                                <div class="col-md-12 col-xl-3 text-start">
                                    <span class="color-main fw-bold ms-2">Dificultad</span>
                                </div>
                                <div class="col-xl-9 col-md-12">
                                    <div class="form-inline mx-1">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d1" :value="1" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d1">1</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d2" :value="2" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d2">2</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d3" :value="3" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d3">3</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d4" :value="4" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d4">4</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 mt-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-start">
                                    <span class="color-main fw-bold">Posicion</span>
                                </div>
                                <div class="col-sm-12 col-md-9">
                                    <div class="form-inline mx-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="dli" :value="'dli'" v-model="hoja.inubacion.posicion">
                                            <label class="form-check-label text-md" for="dli">DLI</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="dlo" :value="'dlo'" v-model="hoja.inubacion.posicion">
                                            <label class="form-check-label text-md" for="dlo">DLO</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-7 mt-2">
                            <div class="row">
                                <div class="col-md-12 col-xl-4 text-start">
                                    <span class="color-main fw-bold ms-2">Cateter</span>
                                </div>
                                <div class="col-xl-8 col-md-12">
                                    <div class="form-inline mx-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="cateter" id="si" :value="true" v-model="hoja.inubacion.cateter">
                                            <label class="form-check-label text-md" for="si">Si</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="cateter" id="no" :value="false" v-model="hoja.inubacion.cateter">
                                            <label class="form-check-label text-md" for="no">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  medicamento y liquidos -->
        <div class="col-md-12 mt-2">
            <div class="row">
                <div class="col-md-12 col-xl-8 mb-2">
                    <div class="row px-2 div-border pt-2 text-center">
                        <span class="color-main fw-bold">MEDICAMENTOS</span>
                    </div>
                    <div class="row p-2 div-border pt-0 text-center">
                        <div class="col-md-12 mb-xl-0 mb-3 px-0 mx-0" id="content-timelinetable">
                            <table class="w-100">
                                <div class="row mx-0 mb-2 px-0">
                                    <table>
                                        <caption class="div-text my-0 py-0"></caption>
                                        <thead class=" txt-xs ">

                                            <tr>
                                                <th class=" text-start text-xs pb-2 " scope="col" colspan="8">
                                                    Seleccione Medicamentos:</th>

                                            </tr>

                                        </thead>
                                        <tbody class="div-text  ">
                                            <tr class="px-1">
                                                <td colspan="2" class="w-25 z-index-select">
                                                    <input type="text" v-model="receta" placeholder="Molecula" class="form-control form-control-input form-control-sm" @keyup.enter="mtdopenmodal" />
                                                </td>
                                                <td v-if="recipe_unit.presentation != '-'">
                                                    <input type="text" v-model="recipe_unit.presentation" placeholder="Presentacion" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td v-if="!type">
                                                    <input type="text" v-model="recipe_unit.quantity" placeholder="Concentración" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td v-if="!type || type_name == 3">
                                                    <input type="text" v-model="recipe_unit.via" placeholder="Via" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td v-if="!type">
                                                    <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency" placeholder="Frecuencia (Horas) " class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td v-if="!type">
                                                    <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration" placeholder="Duración (Días)" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td v-if="type">
                                                    <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td v-if="!type">
                                                    <button type="button" :disabled="validationInputrecipe || validation" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                </td>
                                                <td v-else style=" width: 50px;">
                                                    <button type="button" :disabled="validationtotal" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="!validation" class="d-flex justify-content-end mb-2">
                                    <button @click="mtdViewMezclas" type="button" class="btn  btn-info btn-sm px-4 py-0 text-xs text-white" :disabled="$NoInsumosUci.length <  2">Mezclas</button>
                                </div>
                                <div class="row d-flex div-text mx-0 px-0 mb-2">
                                    <div class="col-xl-12 col-md-12 px-0 pe-xl-1 pe-0">
                                        <table class="w-100 table table-bordered">
                                            <caption class="div-text my-0 py-0"></caption>
                                            <thead class=" div-text div-title text-center">
                                                <tr>
                                                    <th scope="col">Rp.</th>
                                                    <th>Medicamentos</th>
                                                    <th>Presentacion</th>
                                                    <th>Concentración</th>
                                                    <th>Via</th>
                                                    <th>Dosis de la Mezcla</th>
                                                    <th>Frecuencia (Horas)</th>
                                                    <th>Duracion (Días)</th>
                                                    <th>Tipo</th>
                                                    <th>Grupo</th>
                                                    <th>PRN</th>
                                                    <th>Acciones</th>
                                                </tr>

                                            </thead>
                                            <tbody class="div-text" v-if="hoja.recipe.length >0">

                                                <tr v-for="(recipe, index) in hoja.recipe" :key="index" class="px-1">
                                                    <td>
                                                        <span>{{ index + 1 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.molecule }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.presentation }} </span>
                                                    </td>
                                                    <td>
                                                        <span>{{recipe.group != 0 ? recipe.dosis : recipe.quantity
                                                                }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.via }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN DOSIS': '-') : recipe.quantity}}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.frequency }}</span>
                                                    </td>

                                                    <td>
                                                        <span>{{ recipe.duration }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.type == 2 ? 'INSUMO' : recipe.type == 3 ?
                                                                'SOLUCION': 'MEDICAMENTO' }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipe.group }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="recipe.type == 1 ">
                                                            <div class="form-check form-switch">
                                                                <input @input="mtdPrnGroup(recipe)" v-model="recipe.prn" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                                                <label class="form-check-label">{{ recipe.prn ?
                                                                        'SI':'NO' }}</label>
                                                            </div>
                                                        </span>
                                                        <span v-else>-</span>
                                                    </td>
                                                    <td>
                                                        <button v-if="recipe.new" type="button" @click="mtddelete(index, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i> </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="12" class=" text-center">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </table>

                        </div>
                    </div>
                </div>
                <!-- liquidos -->
                <div class="col-xl-4">
                    <div class="row  px-2 div-border pt-2 text-center">
                        <span class="color-main fw-bold">LIQUIDOS</span>
                    </div>
                    <div class="row d-flex p-2 div-border pt-0 text-center">
                        <div class="col-md-6 col-lg-6 col-sm-12 mt-2">
                            <div class="mb-3">
                                <input type="type" class="form-control form-control-sm" v-model="perdida" id="perdida" placeholder="Perdida" @keyup.enter="mtdaddperdida">
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 mt-2">
                            <div class="mb-3">
                                <input type="type" class="form-control form-control-sm" v-model="aporte" id="floatingInput" placeholder="Aporte" @keyup.enter="mtdaddaporte">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-md-flex">
                                <div class="col-md-6 mt-2">
                                    <table class="table table-bordered">
                                        <caption> </caption>
                                        <thead class="div-text div-title">
                                            <tr>
                                                <th>PERDIDAS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index) in hoja.liquido.perdida" :key="index">
                                                <td>
                                                    <div class="align-left  d-flex justify-content-between">
                                                        {{item}}
                                                        <button @click="removeItem(index,1)" class="btn btn-sm btn-warning text-white ms-3 py-0">
                                                            <i class="fas fa-minus text-black text-xxs"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="hoja.liquido.perdida.length == 0">
                                                <td class="text-center" colspan="1">No hay registros</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <table class="table table-bordered">
                                        <caption> </caption>
                                        <thead class="div-text div-title">
                                            <tr>
                                                <th colspan="2">APORTES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index) in hoja.liquido.aporte" :key="index">
                                                <td>
                                                    <div class="align-left  d-flex justify-content-between">
                                                        {{item}}
                                                        <button @click="removeItem(index,2)" class="btn btn-sm btn-warning text-white ms-3 py-0">
                                                            <i class="fas fa-minus text-black text-xxs"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="hoja.liquido.aporte.length == 0">
                                                <td class="text-center" colspan="1">No hay registros</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2">
            <div class="row">
                <div class="col-md-12 col-lg-3">
                    <div class="row p-2 div-border pt-0 text-center">
                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-12 text-start">
                                    <span class="color-main fw-bold">Recuento de Gasas</span>
                                </div>
                                <div class="col-12 mt-1">
                                    <div class="form-inline justify-content-center">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gauze" id="gc" :value="'completo'" v-model="hoja.gauze.value">
                                            <label class="form-check-label text-md" for="gc">Completo</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gauze" id="gi" :value="'incompleto'" v-model="hoja.gauze.value">
                                            <label class="form-check-label text-md" for="gi">Incompleto</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="hoja.gauze.value === 'incompleto'">
                                <div class="col-12 d-flex align-items-center">
                                    <label for="faltante" class="col-4 color-main fw-bold text-start">Faltó:</label>
                                    <div class="col-8">
                                        <input type="text" v-model="hoja.gauze.faltante" v-on:keypress="isNumber($event)" class="form-control form-control-sm" id="faltante">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <!-- drenajes -->
                                <div class="col-md-12 mt-1 mb-3 align-left">
                                    <div id="div-drenaje" class="form-group row">
                                        <label for="drenaje" class="col-sm-12 color-main fw-bold">Drenaje</label>
                                        <div class="col-sm-12 mt-1 mb-1">
                                            <textarea v-model="hoja.drenaje" class="form-control form-control-sm" id="drenaje" rows="5" style="resize: none" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <!-- PAD Y PAS -->
                                    <div class="col-md-12 col-sm-12" 
                                        v-for="(signoVital, index1) in hoja.vital_sings" 
                                        :key="index1">
                                        <div class="d-flex mx-0 color-main fw-bold">
                                            <div class="col-xl-4 col-md-5 col-7 text-start">
                                                <label :for="'sv'+index1">{{ signoVital.label }}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-7 col-5 pb-1">
                                                <input v-model="signoVital.value" 
                                                    type="text"
                                                    class="form-control form-control-input form-control-sm" 
                                                    placeholder="" 
                                                    :id="'sv'+index1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-9">
                        <div class="row px-2 div-border pt-2 text-center">
                            <span class="color-main fw-bold">COMENTARIOS</span>
                        </div>
                        <div class="row div-border mb-1 p-2 ">
                            <!-- comentarios  -->
                            <div class="col-md-12 mt-1 mb-1 align-left" v-for="(comment, index) in hoja.comments"
                                :key="index">
                                <div class="form-group row">
                                    <label :for="'comment'+index"
                                        class="col-sm-12 color-main fw-bold text-xs">{{comment.label}}</label>
                                    <div class="col-sm-12 mt-1 mb-1">
                                        <textarea v-model="comment.value" class="form-control form-control-sm"
                                            :id="'comment'+index" rows="2" style="resize: none" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2 div-border mt-2 pt-2 text-center">
                            <span class="color-main fw-bold">COMPLICACIONES</span>
                        </div>
                        <div class="row div-border mb-1 p-2">
                            <!-- comentarios  -->
                            <div class="col-lg-8 col-md-12 mt-1 mb-3 align-left">
                                <div id="div-sugerencia" class="form-group row">
                                    <label for="sugerencia"
                                        class="col-sm-12 color-main fw-bold text-xs">SUGERENCIAS</label>
                                    <div class="col-sm-12 mt-1 mb-1">
                                        <textarea v-model="hoja.suggestions" class="form-control form-control-sm"
                                            id="sugerencia" rows="6" style="resize: none" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row px-2 mt-2">
                                    <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in hoja.complications"
                                        :key="index">
                                        <div class="form-group d-md-flex">
                                            <label :for="'inputco' + index"
                                                class="col-md-4 col-sm-12 color-main fw-bold">{{ item2.label }}</label>
                                            <div class="col-md-8 col-sm-12">
                                                <input type="text" v-model="item2.value"
                                                    class="form-control form-control-input form-control-sm"
                                                    :id="'inputco' + index" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Condiciones paciente -->
            <div class="col-md-12 mt-2">
                <div class="row  px-2 div-border pt-2 text-center">
                    <span class="color-main fw-bold">CONDICIONES DEL PACIENTE AL SALIR DEL QUIROFANO</span>
                </div>
                <div class="row  px-2 div-border pt-2 text-center">
                    <!-- pad y pas -->
                    <div class="d-md-flex justify-content-between">
                        <div class="col-md-2 my-2 align-left" v-for="(item2, index) in hoja.conditions" :key="index">
                            <div id="div-estado" class="form-group row">
                                <label :for="'inputc' + index" class="col-sm-5 color-main fw-bold">{{ item2.label
                                    }}</label>
                                <div class="col-xl-7 col-sm-12">
                                    <input type="text" v-model="item2.value"
                                        class="form-control form-control-input form-control-sm"
                                        :id="'inputc' + index" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- estado de conciencia -->
                    <div class="d-md-flex mt-2">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12 col-xl-3 text-start">
                                    <span class="color-main fw-bold">ESTADO DE CONCIENCIA</span>
                                </div>
                                <div class="col-xl-9 col-md-12">
                                    <div class="form-inline mx-1">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="awareness" id="conciencia1"
                                                :value="'despierto'" v-model="hoja.awareness">
                                            <label class="form-check-label text-md" for="conciencia1">Despierto</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="awareness" id="conciencia2"
                                                :value="'despierto al llamado'" v-model="hoja.awareness">
                                            <label class="form-check-label text-md" for="conciencia2">Despierta al
                                                llamado</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="awareness" id="conciencia3"
                                                :value="'no responde'" v-model="hoja.awareness">
                                            <label class="form-check-label text-md" for="conciencia3">No responde</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12 col-xl-4 text-start">
                                    <span class="color-main fw-bold">VENTILACION</span>
                                </div>
                                <div class="col-xl-8 col-md-12">
                                    <div class="form-inline mx-1">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="ventilation" id="v1"
                                                :value="1" v-model="hoja.ventilation">
                                            <label class="form-check-label text-md" for="v1">Espontanea</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="ventilation" id="v2"
                                                :value="2" v-model="hoja.ventilation">
                                            <label class="form-check-label text-md" for="v2">Asistida</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Indicaciones -->
                    <div class="col-md-12 mt-2 mb-3 align-left">
                        <div class="form-group row">
                            <label for="indication" class="col-sm-12 color-main fw-bold">INDICACIONES</label>
                            <div class="col-sm-12 mt-1 mb-1">
                                <textarea v-model="hoja.indication" class="form-control form-control-sm"
                                    id="indication" rows="4" style="resize: none" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleterecipe="mtddeleterecipe" />
        <Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title"
            :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine"
            @addreceta="addreceta" />
        <Mezclas @mtdchange="mtdchange" :medicinemezclas="medicinemezclas" :title="modalmezcla.title"
            :boo_modal="modalmezcla.modal_form" @mtdclosemodalmezclas="mtdclosemodalmezclas" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Medicine from '@/components/Asistencial/UciAdult/modals/cModalMedicine.vue';
import Mezclas from '@/components/Asistencial/UciAdult/modals/cModalMezclas.vue';

export default {
    name: 'c-asistencial-sop-historia-hojaanestecia',
    components: {
        SweetAlert,
        Medicine,
        Mezclas
    },
    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.dosis && this.recipe_unit.presentation && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        validationtotal: function () {
            let value = true
            if (this.recipe_unit.total) {
                value = false
            }
            return value
        },
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addhojaanestecia: {
            type: Number,
            default: 0
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdView();
                }
            },
            deep: true,
            immediate: true,
        },
        addhojaanestecia: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }
    },
    data() {
        return {
            medicinemezclas: [],
            modalmezcla: {
                title: "",
                modal_form: false,
            },
            aporte: "",
            perdida: "",
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false,
                new: true,
            },
            receta: '',
            swal: null,
            type: false,
            posmedicine: null,
            validation: false,
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            hoja: {
                staff: [{
                        id: 1,
                        label: "Anestesiologo",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "Cirujano",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "Asistente",
                        value: "",
                    },

                ],
                values: [{
                        id: 1,
                        label: "Apto Cardiovascular",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "Apto Respiratorio",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "Hotoo",
                        value: "",
                    },
                    {
                        id: 4,
                        label: "HB",
                        value: "",
                    },
                    {
                        id: 5,
                        label: "Glucosa",
                        value: "",
                    },
                    {
                        id: 6,
                        label: "Premedicacion",
                        value: "",
                    },
                ],
                complications: [{
                        id: 1,
                        label: "Escala",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "Bromage",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "Stewart",
                        value: "",
                    },
                    {
                        id: 4,
                        label: "Aldrette",
                        value: "",
                    },
                    {
                        id: 5,
                        label: "Ramasay",
                        value: "",
                    },
                ],
                inubacion: {
                    tet: "",
                    mm: "",
                    tipo: "",
                    dificultad: "",
                    posicion: null,
                    cateter: null,
                },
                recipe: [],
                liquido: {
                    perdida: [],
                    aporte: [],
                },
                gauze: {
                    value: "",
                    faltante: "",
                },
                drenaje: "",
                vital_sings: [{
                        id: 1,
                        label: 'PANI',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'EKG',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'ETCO2',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'PAM',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'PVC',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'PAP',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'GC',
                        value: ''
                    },
                ],
                suggestions: "",
                comments: [{
                    id: 1,
                    label: "Induccion",
                    value: "",
                }, {
                    id: 2,
                    label: "Mantenimiento",
                    value: "",
                }, {
                    id: 3,
                    label: "Termino de la anestesia",
                    value: "",
                }],
                indication: "",
                ventilation: "",
                awareness: "",
                conditions: [{
                        id: 1,
                        label: "PA",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "PULSO",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "FR",
                        value: "",
                    },
                    {
                        id: 4,
                        label: "SATO2",
                        value: "",
                    },
                    {
                        id: 5,
                        label: "ETCO",
                        value: "",
                    }
                ],
            },
        };
    },
    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.dosis && this.recipe_unit.presentation && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdstoredata: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeAnesthesiaSheet",
                    token: this.$store.getters.get__token,
                    params: {
                        ... this.hoja,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.hoja = response.data;
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('mtdaddrpexit');
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdView: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/intraOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'hojaanestecia'
                    }
                })
                .then((response) => {
                    let data = response.data;
                    if(data){
                        this.hoja = data;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdaddperdida: function () {
            if (this.perdida !== "") {
                this.hoja.liquido.perdida.push(this.perdida);
            }
            this.perdida = "";
        },
        mtdaddaporte: function () {
            if (this.aporte !== "") {
                this.hoja.liquido.aporte.push(this.aporte);
            }
            this.aporte = "";
        },
        removeItem: function (index, type) {
            if (type == 1) {
                //remover liquidos perdidos
                this.hoja.liquido.perdida.splice(index, 1);
            } else {
                this.hoja.liquido.aporte.splice(index, 1);
            }
        },
        mtdchange: function (payload) {
            this.hoja.recipe = payload
            this.group_state = true
            this.mtdclosemodalmezclas()
        },
        mtdclosemodalmezclas: function () {
            this.modalmezcla = {
                title: "",
                modal_form: false,
            }
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2 || payload.type == 3) ? true : false
            this.type_name = payload.type
            this.receta = payload.molecule
            this.recipe_unit.order = this.hoja.recipe.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            if (this.type) {
                this.recipe_unit.via = this.type_name == 3 ? '' : '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.quantity = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                this.recipe_unit.quantity = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
            }
        },
        mtdaddrecipe: function () {
            const exists = this.hoja.recipe.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
            } else {
                this.hoja.recipe.push(this.recipe_unit);

            }
            this.type = false
            this.receta = null
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false,
                new: true,
            }

        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);

        },
        mtddeleterecipe: function (indexTemp) {
            let index = this.hoja.recipe.findIndex(item => item.order === this.idDelete);
            let num = this.hoja.recipe[index].group
            if (this.hoja.recipe[index].group != 0) {
                for (let j = this.hoja.recipe.length - 1; j >= 0; j--) {
                    if (this.hoja.recipe[j].group == num) {
                        this.hoja.recipe.splice(j, 1);
                    }
                }
            } else {
                this.hoja.recipe.splice(index, 1);
            }
            for (let i = 0; i < this.hoja.recipe.length; i++) {
                this.hoja.recipe[i].order = i + 1;
                if (this.hoja.recipe[i].type == 1 && this.hoja.recipe[i].molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            }
            if (!this.insuline_state) {
                this.hoja.insuline = ''
            }
        },
        mtdViewMezclas: function () {
            this.modalmezcla = {
                title: "Lista de Medicamentos",
                modal_form: true,
            }
            this.medicinemezclas = this.hoja.recipe
        },
        mtdopenmodal: function () {
            if (this.receta) {
                this.posmedicine = 1;
                this.modalmedicine = {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
            } else {
                this.$refs.SweetAlert.showWarning('Digite Molecula');
            }
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.receta = '',
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    quantity: '',
                    group: 0,
                    prn: false,
                    new: true,
                }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
}
</script>

<template>
    <CModal @mtdclosemodal="mtd_close" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title"
        :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row d-flex flex-wrap align-items-end px-3">
            <!-- Campo de Tipo de Pensión (AFP o ONP) -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <label for="type_pension" class="mb-1">Tipo de Pensión</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" :value="typePensionLabel" disabled />
                </div>
            </div>
            <!-- Selección de AFP -->
            <div v-if="type_pension === 2" class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <label for="afp_select" class="mb-1">Seleccionar AFP</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-building"></i>
                        </span>
                    </div>
                    <select class="form-control" v-model="data_detail.afp" :disabled="!afpOptions.length">
                        <option value="" disabled selected>--Seleccione una AFP--</option>
                        <option v-for="afp in afpOptions" :key="afp.id" :value="afp.name">{{ afp.name }}</option>
                    </select>
                </div>
            </div>
            <!-- Campo Fecha de Afiliación -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-1">
                <label for="affiliation_date" class="mb-1">Fecha de Afiliación</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i
                                class="fas fa-calendar"></i></span>
                    </div>
                    <input v-model="data_detail.affiliation_date" type="date" class="form-control"
                        id="affiliation_date" />
                </div>
            </div>
            <!-- Campo Fecha de Culminación -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-1">
                <label for="end_date" class="mb-1">Fecha de Culminación</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i
                                class="fas fa-calendar"></i></span>
                    </div>
                    <input v-model="data_detail.end_date" type="date" class="form-control" id="end_date" />
                </div>
            </div>
            <!-- Campo Número de Pensionista -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-1">
                <label for="pension_number" class="mb-1">Número de Pensionista</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-id-card"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="pension_number" placeholder="Número de Pensionista"
                        v-model="data_detail.pension_number" />
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12 mt-3">
                <button class="btn btn-sm text-white bg-main" @click="mtdAddPension" :disabled="isButtonDisabled">
                    <i class="fas fa-plus"></i>&nbsp; Agregar
                </button>
            </div>
            <!--Listado-->
            <div class="px-3">
                <hr class="border-main" style="border: 1px solid" />
            </div>
            <div class="row d-flex px-2 mt-3">
                <div class="col-md-12" id="content-timelinetable">
                    <table class="table table-bordered table-responsive text-xs">
                        <caption class="py-0 my-0"></caption>
                        <thead class="bg-main">
                            <tr class="text-white align-middle">
                                <th>N°</th>
                                <th>Fondo de pensión</th>
                                <th>Fecha de registro</th>
                                <th>Fecha de culminación</th>
                                <th>Número de pensionista</th>
                            </tr>
                        </thead>
                        <tbody class="border-main">
                            <tr v-if="cpData.length === 0" class="text-center align-middle">
                                <td colspan="5">Sin Registros</td>
                            </tr>
                            <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.pension_fund }}</td>
                                <td>{{ formatDate(item.registration_date) }}</td>

                                <td>
                                    {{ formatDate(item.date_of_change) }}
                                </td>
                                <td>
                                    {{ item.pensioner_number }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </CModal>
</template>
<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ModalPensionFund",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                affiliation_date: "",
                pension_number: "",
                end_date: "",
                afp: "",
            },
            afpOptions: []
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        idEm: Number,
        type_pension: Number,
        payload: Array,
    },
    computed: {

        cpData() {
            return this.payload.map(element => element);
        },
        isButtonDisabled() {
            return (
                !this.data_detail.affiliation_date ||
                !this.data_detail.end_date ||
                !this.data_detail.pension_number
            );
        },
        typePensionLabel() {
            return this.type_pension === 1 ? "ONP" : this.type_pension === 2 ? "AFP" : "Desconocido";
        }
    },
    created() {
        this.getAFP()
    },
    methods: {
        ...mapActions(["get", "post"]),
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },

        mtd_close() {
            this.clear();
            this.$emit("mtd_close_pension");
        },
        getAFP() {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/afp",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.afpOptions = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        clear() {
            this.data_detail = {
                affiliation_date: "",
                pension_number: "",
                end_date: "",
                afp: "",
            };
        },
        mtdAddPension: function () {
            if (this.type_pension === 1) {
                this.data_detail.afp = "ONP"
            }
            this.$emit('registerDataAfp', this.data_detail, this.idEm);
            this.clear();
        },
    },
};
</script>
<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Motivo:</label>
            <textarea v-model="notedetail.motive" class="form-control form-control-sm"  id="motivo" rows="3"></textarea>
        </div>
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Nueva Nota :</label>
            <textarea v-model="notedetail.comment_new" class="form-control form-control-sm"  id="nuevanota" rows="5"></textarea>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="editnote()"  :disabled="mtddisable">
            Editar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
           
            swal: null,
           
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    
    computed: {
        mtddisable: function () {
            return ! (this.notedetail.motive && this.notedetail.comment_new)
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        notedetail:{
            type:Object,
            default:{}
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalnote')
        },
        editnote:function(){
            this.$emit('editNurseNote' , this.notedetail)
        }
       
    },
}
</script>

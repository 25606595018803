<template>
    <div>
        <div class="container">      
            <div class="d-flex"> 
                <div  class="btn-home mb-2 mt-3 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>

            <ul class="nav pb-0" id="myTab" role="tablist">
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('almaPharmacy')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'almaPharmacy' }"
                      id="home-tab" 
                      type="button" 
                     >
                       <strong>Almacenes</strong> 
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('medic')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'medic' }"
                      id="home-tab" 
                      type="button" 
                     >
                       <strong>Medicamentos</strong> 
                    </button>
                </li>
            </ul>
            <div class="tab-content border border-main mb-4 p-2" style="height: 50%;" >
                <AlmaPharmacyVue  class="mt-2" v-if="page == 'almaPharmacy'" />
                <MedicPharmacyVue  class="mt-2" v-if="page == 'medic'" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import AlmaPharmacyVue from "./Mantenimiento/AlmaPharmacy.vue";
import MedicPharmacyVue from "./Mantenimiento/MedicPharmacy.vue";
export default {

name:"pharmacy-maintenance",
components:{
    AlmaPharmacyVue,
    MedicPharmacyVue
},
data(){
    return{
        page: "almaPharmacy",
    }
  },
created() {
},
 methods:{
    ...mapActions(["get", "post"]),

    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.$emit("returnHome");
    },
 }
}
</script>

<style scoped>
.h4-borde{
    border-radius: 50px;
}
#myTabContent{
    border:#900052 1.9px solid;
}

.item-button{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 155px;   
}
.item-button1{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 160px !important;    
}

.nav-link:hover{
    background:#900052 !important
}
.active {
  background-color: #900052 !important ;
 
}
.nav-item{
    width: 159.39px;
}
</style>
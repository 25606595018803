import { render, staticRenderFns } from "./Open.vue?vue&type=template&id=0305c925&scoped=true"
import script from "./Open.vue?vue&type=script&lang=js"
export * from "./Open.vue?vue&type=script&lang=js"
import style0 from "./Open.vue?vue&type=style&index=0&id=0305c925&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0305c925",
  null
  
)

export default component.exports
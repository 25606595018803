<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-lg-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>CUENTAS POR PAGAR</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-5">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12" id="content-timelinetable">
                            <table class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th class="table-th-number">N°</th>
                                        <th>FECHA</th>
                                        <th>FECHA DOCUMENTO</th>
                                        <th>SERIE</th>
                                        <th>NUMERO</th>
                                        <th>DOCUMENTO</th>
                                        <th>USUARIO</th>
                                        <th>MONTO TOTAL</th>
                                        <th>MONTO PAGADO</th>
                                        <th>MONTO PENDIENTE</th>
                                        <th>DESCRIPCION</th>
                                        <th class="table-th-number">ACCION</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="12" class="text-center">No se encontraron resultados</td>
                                    </tr>
                                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td>
                                            {{ item.date | formatDate("%d/%m/%y") }}
                                        </td>
                                        <td>
                                            {{ item.date_doc | formatDate("%d/%m/%y") }}
                                        </td>
                                        <td>
                                            {{ item.serie }}
                                        </td>
                                        <td>
                                            {{ item.number }}
                                        </td>
                                        <td>
                                            {{ item.document }}
                                        </td>
                                        <td>
                                            {{ item.company }}
                                        </td>
                                        <td>
                                            {{ item.total }}
                                        </td>
                                        <td>
                                            {{ item.amount }}
                                        </td>
                                        <td>
                                            {{ item.pending }}
                                        </td>
                                        <td>
                                            {{ item.description }}
                                        </td>
                                        <td>
                                            <span class="badge text-bg-warning text-xs" @click="mtdOpenModal(item)">Cancelar Pendiente</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalPendienteExpense :title="modal.title" :boo_modal="modal.boo_modal" :item="modal.item" :bead="bead" @mtdclosemodal="mtdCloseModal" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalPendienteExpense from "../modal/cModalPendienteExpense.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: "c-admision-pages-anular",
    components: {
        SweetAlert,
        cModalPendienteExpense
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            search: "",
            dataShow: [],
            modal: {
                boo_modal: false,
                item: {}
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();

    },
    props: {
        bead: {},

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Bead/cuentaPorPagar",
                    token: this.$store.getters.get__token,
                    params: {
                        campu: this.$store.getters.get__campus,
                        init: this.filter.begindate,
                        end: this.filter.enddate,
                    },
                })
                .then((response) => {
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.data = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    let dni = element.patient.document.toString().toUpperCase();
                    return name.includes(query) || dni.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        //abir modales
        mtdOpenModal(item) {
            this.modal = {
                boo_modal: true,
                title: "CANCELAR EGRESO " + item.serie + "-" + item.number,
                item: item,
            };
        },
        mtdCloseModal(op) {
            this.modal = {
                boo_modal: false,
                title: "",
                item: {},
            };
            if (op == 0) {
                this.mtdGetData();
            }
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

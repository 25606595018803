<template>
<div class="p-content-sop">
    <div class="row">
        <div class="col-md-12 d-md-block d-lg-none">
            <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="mtdstoredata()" v-if="rolname == 'DOCTOR(A)'">
                <i class="fas fa-save"></i> GUARDAR
            </button>
        </div>
        <!--parte 1-->
        <div class="col-xl-12 col-md-12">
            <div class="d-md-flex gap-2 px-2 mt-2">
                <div class="col-md-4 col-sm-12 col-12 mb-2 align-left" v-for="(item2, index) in hours" :key="index">
                    <div class="form-group row">
                        <label :for="'input2' + index" class="col-xl-5 col-sm-12 text-xss color-main fw-bold">{{ item2.label }}</label>
                        <div class="col-xl-7 col-sm-12">
                            <input type="time" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'input2' + index" :disabled="edit"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-md-12">
            <div class="d-md-flex">
                <!-- Columna 1: Datos Clínicos -->
                <div class="col-md-6 p-content">
                    <div class="row mb-3">
                        <div class="col text-center fw-bold py-2 div-title rounded">
                            <span class="text-uppercase">Datos Clínicos</span>
                        </div>
                    </div>
                    <div class="row gy-2">
                        <!-- Tiempo de enfermedad -->
                        <div class="col-12">
                            <div class="d-flex flex-column flex-sm-row align-items-start">
                                <label for="timeE" class="text-content w-100 text-sm-start color-main fw-bold mb-2">Tiempo de enfermedad</label>
                                <input type="text" class="form-control form-control-sm rounded" id="timeE" placeholder="Ingrese el tiempo" v-model="orderSop.te" :disabled="edit" />
                            </div>
                        </div>
                        <!-- Motivo de hospitalización -->
                        <div class="col-12">
                            <div class="d-flex flex-column flex-sm-row align-items-start">
                                <label for="hospitalReason" class="text-content w-100 text-sm-start color-main fw-bold mb-2">Motivo de hospitalización</label>
                                <input type="text" class="form-control form-control-sm rounded" id="hospitalReason" placeholder="Ingrese el motivo" v-model="orderSop.motive" :disabled="edit"/>
                            </div>
                        </div>
                        <!-- Diagnóstico -->
                        <div class="col-12">
                            <div class="form-group w-100">
                                <label for="cie" class="text-content w-100 text-sm-start color-main fw-bold mb-2">Diagnóstico</label>
                                <v-select class="form-control-sm rounded" input-id="cie" :disabled="edit" :options="dataCies" v-model="cie" :reduce="cie => cie" label="name" placeholder="- Buscar Cie -" required :clearable="false" @search="mtdgetCies" @input="mtdaddDiagnosticoUnit">
                                    <template slot="no-options">
                                        No hay diagnósticos disponibles.
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Columna 2: Tratamiento -->
                <div class="col-md-6 p-content">
                    <div class="row mb-3">
                        <div class="col text-center fw-bold py-2 div-title rounded">
                            <span class="text-uppercase">Tratamiento</span>
                        </div>
                    </div>
                    <div class="row gy-3">
                        <!-- Tipo de tratamiento -->
                        <div class="col-12">
                            <div class="d-flex flex-column flex-sm-row align-items-start">
                                <label for="tt" class="text-content w-100 text-sm-start color-main fw-bold mb-2">Tipo de tratamiento</label>
                                <input type="text" class="form-control form-control-sm rounded" id="tt" placeholder="Ingrese el tipo" v-model="orderSop.type_treatment" :disabled="edit" />
                            </div>
                        </div>
                        <!-- Procedimiento -->
                        <div class="col-12">
                            <div class="d-flex flex-column flex-sm-row align-items-start">
                                <label for="intervension" class="text-content w-100 text-sm-start color-main fw-bold mb-2">Intervension</label>
                                <input type="text" class="form-control form-control-sm rounded" id="intervension" placeholder="Ingrese intervension" v-model="orderSop.intervention" :disabled="edit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!--Columna 3-->
        <div class="col-xl-12 col-md-12 mt-1">
            <!--Parte 1-->
            <div class="row mb-2 p-content">
                <div class="col text-center fw-bold py-2 div-title rounded">
                    <span class="text-uppercase">REQUERIMIENTO QUIRÚRGICO</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-md-12">
                    <div class="row px-2 mt-2 div-border pt-2 text-center ">
                        <span class="color-main fw-bold">PERSONAL MEDICO</span>
                    </div>
                    <div class="row px-2 div-border pt-2">
                        <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in orderSop.medical_staff" :key="index">
                            <div class="form-group d-md-flex">
                                <label :for="'inputreq' + index" class="col-md-5 col-sm-12 color-main fw-bold">{{ item2.label }}</label>
                                <div class="col-xl-7 col-md-7 col-sm-12">
                                    <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputreq' + index" :disabled="edit" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-8 col-md-12">
                    <div class="d-md-flex gap-2">
                        <div class="col-xl-6 col-md-6">
                            <div class="row px-2 mt-2 div-border pt-2 text-center ">
                                <span class="color-main fw-bold">GASAS</span>
                            </div>
                            <div class="row px-2 div-border pt-2">
                                <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in orderSop.gauze" :key="index">
                                    <div class="form-group row">
                                        <label :for="'inputgasa' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                                        <div class="col-xl-7 col-sm-12">
                                            <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputgasa' + index" :disabled="edit"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-xl-6 col-md-6">
                            <div class="row px-2 mt-2 div-border pt-2 text-center ">
                                <span class="color-main fw-bold">SOLUCIONES</span>
                            </div>
                            <div class="row px-2 div-border pt-2">
                                <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in orderSop.solutions" :key="index">
                                    <div id="div-estado" class="form-group row">
                                        <label :for="'inputsol' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                                        <div class="col-xl-7 col-sm-12">
                                            <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputsol' + index"  :disabled="edit"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-md-flex gap-2">
                <div class="col-md-4">
                    <div class="row px-2 mt-2 div-border pt-2 text-center ">
                        <div class="col-md-12 mt-1 align-left">
                            <div id="div-instrumentista" class="form-group row">
                                <label for="instrumentista" class="col-sm-12 col-xl-5 color-main fw-bold text-xs">Equipo</label>
                                <div class="col-sm-12 col-xl-7">
                                    <input type="text" class="form-control input-text" id="instrumentista" v-model="orderSop.equipment" :disabled="edit">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-1 align-left">
                            <div id="div-img" class="form-group row">
                                <label for="img" class="col-sm-12 col-xl-5 color-main fw-bold text-xs">Imágenes</label>
                                <div class="col-sm-12 col-xl-7">
                                    <input type="text" class="form-control input-text" id="img" v-model="orderSop.images" :disabled="edit">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-1 mb-3 align-left">
                            <div id="div-instrumentista" class="form-group row">
                                <label for="implantes" class="col-sm-12 col-xl-5 color-main fw-bold text-xs">Implantes</label>
                                <div class="col-sm-12 col-xl-7">
                                    <input type="text" class="form-control input-text" id="implantes" v-model="orderSop.implants" :disabled="edit">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row px-2 mt-2 div-border pt-2 text-center ">
                        <span class="color-main fw-bold">INSUMOS</span>
                    </div>
                    <div class="row  px-2 div-border pt-2 mb-3">
                        <div class="col-md-12 mb-xl-0 mb-3 px-0 mx-0" id="content-timelinetable">
                            <table class="w-100 ">
                                <div class="row mx-0 mb-2 px-0">
                                    <table class="w-100">
                                        <caption class="div-text my-0 py-0"></caption>
                                        <thead class=" txt-xs ">
                                            <tr>
                                                <th class=" text-start text-xs pb-2 " scope="col" colspan="8"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="div-text  ">
                                            <tr class="px-1">
                                                <td colspan="2" class="z-index-select">
                                                    <input type="text" v-model="receta" placeholder="Molecula" class="form-control form-control-input form-control-sm" @keyup.enter="mtdopenmodal" :disabled="edit"/>
                                                </td>
                                                <td class="table-th-acciones">
                                                    <input v-on:keypress="isNumber($event)" :disabled="recipe_unit.molecule.length == 0" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm"  />
                                                </td>
                                                <td v-if="!type">
                                                    <button type="button" :disabled="validationInputrecipe" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                </td>
                                                <td v-else style=" width: 50px;">
                                                    <button type="button" :disabled="validationtotal" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="row d-flex div-text mx-0 px-0 mb-2">
                                    <div class="col-xl-12 col-md-12 px-0">
                                        <table class="w-100 table table-bordered mb-1">
                                            <caption class="div-text my-0 py-0"></caption>
                                            <thead class=" div-text div-title text-center">
                                                <tr>
                                                    <th scope="col">Rp.</th>
                                                    <th>Insumo</th>
                                                    <th>Presentacion</th>
                                                    <th>Total</th>
                                                    <th>Acciones</th>
                                                </tr>

                                            </thead>
                                            <tbody class="div-text" v-if="orderSop.recipe.length >0">
                                                <tr v-for="(recipe, index) in orderSop.recipe" :key="index" class="px-1">
                                                    <td>
                                                        <span>{{ index + 1 }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.molecule }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.presentation }} </span>
                                                    </td>
                                                    <td>
                                                        <span>{{ recipe.total }} </span>
                                                    </td>
                                                    <td>
                                                        <button v-if="recipe.new" type="button" @click="mtddelete(index, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i> </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="5" class=" text-center">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title" :type="2" :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
    <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleterecipe="mtddeleterecipe" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import Medicine from "@/components/Asistencial/Sop/modals/cModalMedicine.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-asistencial-sop-historia-ordendesop",
    components: {
        SweetAlert,
        Medicine,
    },
    data() {
        return {
            swal: null,
            type: null,
            edit: true,
            posmedicine: null,
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            receta: "",
            hours: [{
                    id: 1,
                    label: "Hora de Ingreso",
                    value: "",
                },
                {
                    id: 2,
                    label: "Hora de Inicio",
                    value: "",
                },
                {
                    id: 3,
                    label: "Hora Final",
                    value: "",
                },
            ],

            anestecia: [{
                    id: 1,
                    label: "Sevorane",
                    value: "",
                    state: false,
                },
                {
                    id: 2,
                    label: "Forane",
                    value: "",
                    state: false,
                }
            ],
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: "",
                presentation: "",
                dosis: "",
                via: "",
                frequency: "",
                duration: "",
                type: null,
                total: null,
                quantity: "",
                group: 0,
                prn: false,
                new: true,
            },
            //datos nuevos
            dataCies: [],
            plan_unit: null,
            dataProcedimiento: [],
            plan: [],
            cie: "",
            name: "",
            cie_id: '',
            cie_code: '',
            plans: {},
            orderSop: {
                te: '',
                intervention: '',
                motive: "",
                type_treatment: '',
                recipe: [],
                medical_staff: [{
                        id: 1,
                        label: "Cirujano",
                        value: "",
                        state: false,
                    },
                    {
                        id: 2,
                        label: "Asistente",
                        value: "",
                        state: false,
                    },
                    {
                        id: 3,
                        label: "Anestesiólogo",
                        value: "",
                        state: false,
                    },
                    {
                        id: 4,
                        label: "Instrumentista",
                        value: "",
                        state: false,
                    },
                    {
                        id: 5,
                        label: "Pediatra",
                        value: "",
                        state: false,
                    },

                    {
                        id: 6,
                        label: "Instrumental",
                        value: "",
                        state: false,
                    },
                    {
                        id: 7,
                        label: "T. de Anestecia",
                        value: "",
                        state: false,
                    },

                ],
                gauze: [{
                        id: 1,
                        label: "Zona",
                        value: "",
                        state: false,
                    },
                    {
                        id: 2,
                        label: "Esterica",
                        value: "",
                        state: false,
                    },
                    {
                        id: 3,
                        label: "Doble",
                        value: "",
                        state: false,
                    },
                    {
                        id: 4,
                        label: "Compresa",
                        value: "",
                        state: false,
                    },
                    {
                        id: 5,
                        label: "Otros",
                        value: "",
                        state: false,
                    },
                ],
                solutions: [{
                        id: 1,
                        label: "Crli",
                        value: "",
                        state: false,
                    },
                    {
                        id: 2,
                        label: "A. Yodado",
                        value: "",
                        state: false,
                    },
                    {
                        id: 3,
                        label: "L.Espuma",
                        value: "",
                        state: false,
                    },
                    {
                        id: 4,
                        label: "L. Solucion",
                        value: "",
                        state: false,
                    },
                    {
                        id: 5,
                        label: "Otros",
                        value: "",
                        state: false,
                    },
                ],
                equipment: "",
                implants: "",
                images: "",

            }
        };
    },
    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.dosis && this.recipe_unit.presentation && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        validationtotal: function () {
            let value = true
            if (this.recipe_unit.total) {
                value = false
            }
            return value
        },
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addOrder: {
            type: Number,
            default: 0
        },
        rolname: String,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                this.mtdgetSubsubcategories();
                if (newValue !== 0) {
                    this.mtdViewOrderSop();
                }
            },
            deep: true,
            immediate: true,
        },
        addOrder: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdViewOrderSop: function () {
            this.edit = this.rolname !== 'DOCTOR(A)' ? true:false;
            this.post({
                url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                token: this.$store.getters.get__token,
                params:{
                    medical_act_id: this.medical_act_id,
                    type: 'orderSop'
                }
            })
                .then((response) => {
                    let viewOrder = response.data;
                    if(viewOrder){
                        this.orderSop = response.data;
                        this.orderSop.recipe = response.data.inputs?  JSON.parse(response.data.inputs): [];
                        this.orderSop.gauze = JSON.parse(response.data.gauze);
                        this.orderSop.solutions = JSON.parse(response.data.solutions);
                        this.orderSop.medical_staff = JSON.parse(response.data.medical_staff);
                        this.hours[0].value  = response.data.entry_time;
                        this.hours[1].value  = response.data.start_time;
                        this.hours[2].value  = response.data.end_time;
                        this.cie_id = response.data.cie_id;
                        this.cie = response.data.cie_id ? response.data.cie.name : "";
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdstoredata: function () {
            //registrar el order sop
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeOrderSop",
                    token: this.$store.getters.get__token,
                    params: {
                        ... this.orderSop,
                        entry_time: this.hours[0].value,
                        start_time:this.hours[1].value,
                        end_time:this.hours[2].value,
                        cie_id: this.cie_id,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.mtdViewOrderSop();
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('finishAddOrder');
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.name = diagnostico.name;
            this.cie_id = diagnostico.id;
            this.cie_code = diagnostico.code;
        },
        mtdaddPlan: function () {
            const exist = this.plan.some(item => item.subsubcategory_id === this.plan_unit.subsubcategory_id);
            if (exist) {
                this.$refs.SweetAlert.showWarning("El item seleccionado a sido agregado");
            } else {
                if (this.plan_unit.category_id == 1) {
                    this.modalcomment = {
                        title: this.plan_unit.name,
                        modal_form: true,
                    }
                    //this.plan_unit.medical_act_id = this.medical_act_id
                    this.plans = this.plan_unit
                } else {
                    this.mtdaddComment(this.plan_unit)
                }
            }
            this.plan_unit = null
        },
        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataProcedimiento = response.data.dataProcedimiento;
                }
            });
        },
        mtdhiderecipeunit: function () {
            this.pam = "";
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: "",
                presentation: "",
                dosis: "",
                via: "",
                frequency: "",
                duration: "",
                type: null,
                total: null,
                quantity: "",
                group: 0,
                prn: false,
                new: true,
            };

            this.selectedProduct = null;
            this.plan_unit = null;
        },
        mtdaddrecipe: function () {
            const exists = this.orderSop.recipe.some(
                (item) =>
                item.molecule + " " + item.presentation + " " + item.dosis ===
                this.recipe_unit.molecule +
                " " +
                this.recipe_unit.presentation +
                " " +
                this.recipe_unit.dosis
            );
            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
            } else {
                this.orderSop.recipe.push(this.recipe_unit);
            }
            this.type = false;
            this.receta = null;
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: "",
                presentation: "",
                dosis: "",
                via: "",
                frequency: "",
                duration: "",
                type: null,
                total: null,
                quantity: "",
                group: 0,
                prn: false,
                new: true,
            };
            this.noInsumos = [];
            this.insuline_state = false;
            this.orderSop.recipe.forEach((item) => {
                if (item.type == 1) {
                    this.noInsumos.push(item);
                }
                if (
                    item.type == 1 &&
                    item.molecule.toLowerCase().includes("insulina")
                ) {
                    this.insuline_state = true;
                }
            });
        },
        mtdopenmodal: function () {
            if (this.receta) {
                this.posmedicine = 1;
                this.modalmedicine = {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
            } else {
                this.$refs.SweetAlert.showWarning('Digite Molecula');
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2 || payload.type == 3) ? true : false
            this.type_name = payload.type
            this.receta = payload.molecule
            this.recipe_unit.order = this.orderSop.recipe.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            if (this.type) {
                this.recipe_unit.via = this.type_name == 3 ? '' : '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.quantity = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                this.recipe_unit.quantity = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
            }
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            };
            (this.receta = ""),
            (this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: "",
                presentation: "",
                dosis: "",
                via: "",
                frequency: "",
                duration: "",
                quantity: "",
                group: 0,
                prn: false,
                new: true,
            });
        },
        mtddelete: function (index, msg) {
            // this.idDelete = order;
            this.$refs.SweetAlert.showDelete(index, msg);

        },
        mtddeleterecipe: function (index) {
            this.orderSop.recipe.splice(index, 1);

        },
    },
};
</script>

<template>
  <div id="app">
    <router-view />
    <Loading v-show="loading"></Loading>
    <button class="ir-arriba" @click="scrollToTop">↑</button>
  </div>
</template>

<script>
import Loading from './components/loading/Modal.vue'
import { bus } from './main'
import { mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
    Loading
  },
  data () {
    return {
      loading: false,
      check: false,
      rol: null,
      rolname: null,
      dataCampus: [],
      dataRoles: [],
    }
  },
  created () {
    const user = this.$store.getters.get__user
    if(user.roles.length == 1){
      this.rol = user.roles[0].role_id;
      this.mtdgetcheck();
    }

    bus.$on('loading', (data) => {
      this.loading = data
    })
    if (this.$store.getters.get__token) {
      this.$router.push('/home')
    } else {
      this.$router.push('/login')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Para verificar el estado inicial
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['get', 'post']),

    mtdgetcheck: function () {
      this.get({
          url: this.$store.getters.get__url + "/Authentication/check",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.check = response.data.check;
          if(this.check){
            if(this.rol == 1){
              this.$router.push('/gerencia')
            }else
            if(this.rol == 2){
              this.$router.push('/admision')
            }else
            if(this.rol == 3){
              this.$router.push('/vigilante')
            }else
            if(this.rol == 4){
              this.$router.push('/maintenance')
            }else
            if(this.rol == 5){
              this.$router.push('/logistica')
            }else
            if(this.rol == 6){
              this.$router.push('/medico')
            }else
            if(this.rol == 7){
              this.$router.push('/enfermera')
            }else
            if(this.rol == 8){
              this.$router.push('/contabilidad')
            }else
            if(this.rol == 9){
              this.$router.push('/jefatura')
            }else
            if(this.rol == 10){
              this.$router.push('/farmacia')
            }else
            if(this.rol == 11){
              this.$router.push('/rh')
            }else
            if(this.rol == 12){
              this.$router.push('/tesoreria')
            }else
            if(this.rol == 13){
              this.$router.push('/facturacion')
            }else
            if(this.rol == 14){
              this.$router.push('/director')
            }else
            if(this.rol == 15){
              this.$router.push('/limpieza')
            }else
            if(this.rol == 16){
              this.$router.push('/tecnico')
            }else
            if(this.rol == 17){
              this.$router.push('/marketing')
            }else
            if(this.rol == 18){
              this.$router.push('/jefaEnfermeria')
            }else{
              this.$router.push('/home')
            }
          }else{
            this.$router.push('/login')
          }
        })
    },
    /* mtdBoton() {
      $('.ir-arriba').click(function(){
        $('body, html').animate({
          scrollTop: '0px'
        }, 300);
      });

      $(window).scroll(function(){
        if( $(this).scrollTop() > 0 ){
          $('.ir-arriba').slideDown(300);
        } else {
          $('.ir-arriba').slideUp(300);
        }
      });
    } */
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      const button = document.querySelector('.ir-arriba');
      if (window.scrollY > 0) {
        button.style.display = 'block';
      } else {
        button.style.display = 'none';
      }
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/main.scss";
  .ir-arriba {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    font-weight: bold;
    background-color: #900052;
    color: white;
    border: none;
    padding: 5px 13px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
    z-index: 2000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }

  .ir-arriba:hover {
    background-color: #b00065;
    transform: scale(1.1);
  }
</style>
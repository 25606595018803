<template>
  <div>
    <div class="px-2">
      <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-main" @click="returnHome">
          <i class="fas fa-home-lg-alt"></i> Inicio
        </button>
      </div>
      <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class=" w-100">
            <div class="d-flex  responsive-search mt-3 mb-3">
                <div
                    v-if="name"
                    class="input-group input-group mb-1 custom-margin"
                    >
                    <span class="input-group-text spam" id="basic-addon1">
                        <strong>TEC.ENFERMERA(O)</strong>
                    </span>
                    <input
                        class="form-control form-control-sm text-center"
                        style="background-color: #ffffff; font-size: 15px"
                        disabled
                        :value="name ? name : ''"
                    />
                </div>
                <!-- <div class="d-flex">
                    <div class="input-group-append" style="margin-left: 10px">
                        <button
                        @click="mtdOpenDispath()"
                        class="btn btn-primary rounded-circle"
                        type="button"
                        style="background-color: #900052; border: 2px solid #900052"
                        >
                        <i class="fas fa-solid fa-file-pdf"></i>
                        </button>
                    </div>
                </div> -->
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-md-12 card" id="content-timelinetable ">
                            <div class="card-header text-white text-center h5 bg-main">
                                <div class="row d-flex align-items-center text-center" style="height: 100%;">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <strong>REQUERIMIENTOS EPP</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group mb-3 scroll-container  ">
                                <div v-if="listaRequeDetails.length ==0"
                                    >
                                    <div class="d-flex w-100 justify-content-center">
                                        <p class="mb-1">Sin EPP</p>
                                    </div>
                                </div>
                                <div v-else  class="row mx-0 py-2 px-3 " >
                                <div class="d-flex justify-content-between px-1 mb-2 ">
                                  <div>
                                    <button type="button" @click="adddespacho" :disabled="mtddisabled" class="btn btn-info btn-sm">Guardar Despacho</button>
                                  </div>
                                  <div class="form-check custom-input">
                                      <input class="form-check-input custom-checkbox" type="checkbox" value=""
                                          id="flexCheckDefault" v-model="listado">
                                      <label class="form-check-label fw-bold" for="flexCheckDefault">
                                          Ver Todos
                                      </label>
                                  </div>

                                </div>
                                  <div v-for="(item, index) in listaRequeDetails " :key="index" class="col-xl-2 col-lg-2 px-1 py-1 ">
                                      <div  v-if="item.totalQuantity ==0" class=" card p-2" >
                                            <div class="d-flex w-100 justify-content-between align-items-center mb-3">
                                                <div class="mb-1">
                                                    <span  class="   text-white bg-danger rounded rounded-2 px-1  ">
                                                        Stock:  {{ item.totalQuantity }}  
                                                    </span> 
                                                </div>
                                            </div>
                                            <div  class="">
                                              <div class="icon-book d-flex justify-content-center align-items-center mb-3">
                                                    <img src="../../../assets/kit_black.png" alt="" class="img-fluid-bed-sop rounded-start">
                                              </div>
                                              <div >
                                                <h6 class="mb-2 fw-bold text-center" style="font-size: 12px" >
                                                    {{ item.product.subsubcategorie.name }}
                                                </h6>
                                                <h5 class="mb-2" style="font-size: 11px">
                                                    <strong>MARCA:</strong>
                                                    {{ item.product.brand.name.toUpperCase() }}
                                                </h5>        
                                                <h5 class="mb-2" style="font-size: 11px">
                                                    <strong>TOTAL:</strong>
                                                        {{item.quantity_requested}}
                                                    <span>
                                                        <span>&nbsp;&nbsp;</span>
                                                        <strong>PENDIENTE:</strong>  {{item.quantity_missing}}
                                                    </span>
                                                </h5>

                                              </div>
                                            </div>
                                      </div>
                                      <div v-else class="card p-2" 
                                          :class="{
                                            'div-pointer hover-card': item.status_requirements != 3 && item.dispatch_detail.length === 0,
                                            'bg-lote': item.dispatch_detail.length > 0
                                          }"
                                          @click="item.dispatch_detail.length === 0 &&  item.status_requirements != 3   ? mtdLote(item, index) : null">
                                          <div class="d-flex w-100 justify-content-between align-items-center mb-3">
                                              <div class="d-flex flex-grow-1 justify-content-between">
                                                  <span  class="mb-1  text-white bg-success rounded rounded-2 px-1 ">
                                                        Stock: {{ item.totalQuantity }}
                                                  </span>
                                                  <h5 :class="getBadgeClass(item.status_requirements)" class="mb-1  ">
                                                      {{ getStatusText(item.status_requirements) }}
                                                  </h5>
                                              </div>
                                          </div>
                                          <div  class="">
                                            <div class="icon-book d-flex justify-content-center align-items-center mb-3">
                                                  <img src="../../../assets/kit.png" alt="" class="img-fluid-bed-sop rounded-start">
                                            </div>
                                            <div >
                                              <h6 class="mb-2 fw-bold text-center" style="font-size: 12px" >
                                                  {{ item.product.subsubcategorie.name }}
                                              </h6>
                                              <h5 class="mb-2" style="font-size: 11px">
                                                  <strong>MARCA:</strong>
                                                  {{ item.product.brand.name.toUpperCase() }}
                                              </h5>        
                                              <h5 class="mb-2" style="font-size: 11px">
                                                  <strong>TOTAL:</strong>
                                                      {{item.quantity_requested}}
                                                  <span>
                                                      <span>&nbsp;&nbsp;</span>
                                                      <strong>PENDIENTE:</strong>  {{item.quantity_missing}}
                                                  </span>
                                              </h5>

                                            </div>
                                          </div>
                                      </div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    <cModalLoteReq
       ref="modalLoteComponent"
      :title="modalDeta.title"
      :boo_modal="modalDeta.modal_form"
      :item="modalDeta.item"
      :total="modalDeta.total"
      :producto="modalDeta.producto"
      :marca="modalDeta.marca"
      :idProducto="modalDeta.idProducto"
      :idRecipeDetail="modalDeta.idRecipeDetail"
      @mtdCloseModalLote="mtdCloseModalLote"
      @agregarlote="agregarlote"
    />
   
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";

import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModalLoteReq from "../Modals/cModalLoteReq.vue";
export default {
  name: "c-Pharmacy-cReci",
  components: {
    SweetAlert,
    cModalLoteReq
  },
  props: {
    name:String,
    Req_id:Number,
    destination:String
  },
  data() {
    return {
      swal: null,
       listado: false,
       listaRequeDetails:[],
       modalDeta:{
          title: '',
          modal_form: false,
          item: [],
          total: null,
          producto: "",
          marca: "",
          idProducto: null,
          idRecipeDetail: null,
       },

       pos:null,

       
    };
  },
  watch: {
     Req_id: {
            handler(newVal) {
                if (newVal !=null ) {
                  this.mtdGetData()
                }

            },
            immediate: true
        },
        listado() {
            this.mtdGetData();
        },
    },

  computed:{
    mtddisabled:function(){
      let data = []
      this.listaRequeDetails.forEach((item, index) => {
          if(item.dispatch_detail.length > 0 ){
            data.push(item)
          }
      });

      return !data.length > 0
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    //metodo retornar a Home
    returnHome: function () {
      this.$emit("mtdBack");
    },
    mtdGetData: function () {
        const urlBase = this.$store.getters.get__url + "/Requirements/indexRequirementsDetails";
        const url = this.listado ? `${urlBase}/3` : `${urlBase}/1`;

        this.get({
            url: url + '/'+ this.Req_id,
            token: this.$store.getters.get__token,
        })
            .then((response) => {
                this.listaRequeDetails = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
    },
    getBadgeClass(state) {
        switch (state) {
        case 1:
            return 'badge-danger';
        case 2:
            return 'badge-warning';
        case 3:
            return 'badge-success';
        default:
            return 'badge-secondary';
        }
    },
    getStatusText(state) {
        switch (state) {
        case 1:
            return 'SIN ATENDER';
        case 2:
            return 'ATENDIDO PARCIALMENTE';
        case 3:
            return 'ATENDIDO';
        default:
            return 'ESTADO DESCONOCIDO';
        }
    },
    mtdLote:function(item ,index){
       this.pos = index
       this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/stockProductId",
        params: { productdetail: item.product_detail_id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 0 && response.data.data.length > 0) {
            this.modalDeta.item = response.data.data;
            this.modalDeta.total = item.quantity_missing;
            this.modalDeta.marca = item.product.brand.name;
            this.modalDeta.idProducto = item.product_detail_id;
            this.modalDeta.idRecipeDetail = item.id;
            this.modalDeta.title =  'SELECCIONAR LOTE : ' + item.product.subsubcategorie.name ;
            this.modalDeta.modal_form = true;
            this.$nextTick(() => {
              this.$refs.modalLoteComponent.validarAutomaticamente();
              });
          } else {
            this.modalDeta.modal_form = false;
            this.$refs.SweetAlert.showWarning("Sin Stocks");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });

    },
    mtdCloseModalLote:function(){
      this.modalDeta ={
          title: '',
          modal_form: false,
          item: [],
          total: null,
          producto: "",
          marca: "",
          idProducto: null,
          idRecipeDetail: null,
       }
       this.pos = null
    },
    agregarlote:function(payload){
      this.listaRequeDetails[this.pos].dispatch_detail = payload
      this.mtdCloseModalLote()
    },

    adddespacho:function(){
        let data = []
        this.listaRequeDetails.forEach((item, index) => {
            if(item.dispatch_detail.length > 0 ){
              data.push(item)
            }
        });

        Swal.fire({
          icon: "warning",
          title: "¿Está seguro de despachar el medicamento?",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
            placeholder: "NOTA",
          },
          width: "400px",
          confirmButtonText: "Permitir",
          confirmButtonColor: "#3085d6",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
        }).then((result) => {
          if (result.isConfirmed) {
            const note = result.value;
            let request = {
              data: data,
              note:note,
              input_requirement_id:this.Req_id,
              destination:this.destination
            }
            this.post({
              url: this.$store.getters.get__url + "/Requirements/storeRequirementdetail",
              token: this.$store.getters.get__token,
              params: request
            })
              .then((response) => {
                if (response.statusCode == 200) {
                    Swal.fire({
                        text: "Despachado Correctamente",
                        icon: "success",
                        confirmButtonColor: "#900052",
                    });
                    this.mtdGetData();
                    // window.open(
                    //     this.$store.getters.get__url + "/viewpdfDocInterno/" + response.data.idDis,
                    //     "_blank"
                    // );
                    // this.$emit("mtdMediDespachar", response.data.idRecipe);
                }

              })
              .catch((errors) => {
                    Swal.fire({
                        text: errors,
                        icon: "error",
                        confirmButtonColor: "#900052",
                    });
              });
          }
        });
    }

  }
};
</script>
<style scoped>
.bootom {
  background-color: #900052 !important;
  border: 0.5px solid #900052 !important;
}
.spam {
  background: #900052;
  color: #ffffff;
  width: 180px;
  line-height: 20px;
  justify-content: center;
  border-radius: 1rem;
}
.badge-danger {
    background-color: #fa0821;
    color: #ffffff;
    font-size: 12px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-warning {
    background-color: #f7ba03;
    color: #000000;
    font-size: 12px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-success {
    background-color: #0bc837;
    color: #ffffff;
    font-size: 12px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-type-medic {
    background-color: #096df1;
    color: #ffffff;
    font-size: 7.5px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-type-insumo {
    background-color: #abcd06;
    color: #ffffff;
    font-size: 7.5px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-secondary {
    background-color: #838383;
    color: #ffffff;
    font-size: 12px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }

  .scroll-container {
    max-height: 410px;
    overflow-y: auto;
    overflow-x: hidden; 
    width: 100%; 
    box-sizing: border-box; 
}

.recipe-item-orange {
    background-color: #fff4de; 
}

.recipe-item-green {
    background-color: #ebfde5; 
}

.icon-custom{
    color: #900052; 
    margin-left: 0.3em;
}

.hover-card:hover{
 background-color: #f3f9fd;
}
.bg-lote{
  background-color: #D2E0F2;
}

</style>

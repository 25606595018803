<template>
    <div>
        <div class="px-5 mt-2 pb-5">      
            <div class="tab-content" >
                <Home v-if="page == 'home'" @returnHome="returnHome" @changePage="changePage"/>
                <Administrativo v-if="page == 'administrativo' " @changePage="changePage" />
                <Medico v-if="page == 'medico' " @changePage="changePage" />
                <Enfermera v-if="page == 'enfermera' " @changePage="changePage" />
                <TecEnfermera v-if="page == 'tec_enfermero' " @changePage="changePage" />
                <TecLaboratorio v-if="page == 'tec_laboratorio' " @changePage="changePage" />
                <TecFarmacia v-if="page == 'tec_farmacia' " @changePage="changePage" />
                <Biologo v-if="page == 'biologo' " @changePage="changePage" />
                <Quimico v-if="page == 'quimico' " @changePage="changePage" />
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Home  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Home.vue';
import Administrativo  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Administrativo.vue';
import Medico  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Medico.vue';
import Enfermera  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Enfermera.vue';
import TecEnfermera  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/TecEnfermera.vue';
import TecLaboratorio  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Laboratorio.vue';
import TecFarmacia  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Farmacia.vue';
import Biologo  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Biologia.vue';
import Quimico  from '@/components/RecursosHumanos/Pages/Maintenance/Usuarios/Qft.vue';
export default {

name:"c-rrhh-mantenedores-user",
components:{
    SweetAlert,
    Home,
    Administrativo,
    Medico,
    Enfermera,
    TecEnfermera,
    TecLaboratorio,
    TecFarmacia,
    Biologo,
    Quimico
},
data(){
    return{
        swal: null,
        page: "home",
    }
  },
methods:{
    ...mapActions(["get", "post"]),

    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.$emit("returnHome");
    }
 }
}
</script>

<style scoped>
.h4-borde{
    border-radius: 50px;
}
#myTabContent{
    border:#900052 1.9px solid;
}

.item-button{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 155px;   
}
.item-button1{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
    border: none !important;
    color:white !important;
    width: 160px !important;    
}

.nav-link:hover{
    background:#900052 !important
}
.active {
  background-color: #900052 !important ;
 
}
.nav-item{
    width: 159.39px;
}
</style>
<template>
<div>
    <div class="px-4 text-center">
        <div>
            <div class="d-flex title-option">
                <div class="btn-home mt-3 mb-2 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome()">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="myTabContent" class="">
                <div class="row d-flex mb-2 mx-0 py-2 px-1 ">
                    <div class="col-xl-6 col-lg-12">
                        <div class="row d-flex justify-content-start">
                            <div class="col-md-12 rounded-3 px-0 bg-blue my-2">
                                <div class="row mx-0">
                                    <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                                        <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.patient_name }}</span>
                                        <span class=" align-middle col-sm-8 h-100 px-0 text-start"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 px-0" id="content-timelinetable">
                                <div class="mb-2">
                                    <table class="table table-bordered border-main mb-0 px-0">
                                        <caption></caption>
                                        <thead>
                                            <tr class="text-white border-white text-center bg-main text-xxs">
                                                <th scope="col">N°</th>
                                                <th scope="col">ORIGEN</th>
                                                <th scope="col">CONCEPTO</th>
                                                <th scope="col">F. SOLICITUD</th>
                                                <th scope="col">F. RESULTADO</th>
                                                <th scope="col">MÉDICO SOLICITA</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="data.length >0" class="text-xxs">
                                            <tr v-for="(item , index) in data" :key="index">
                                                <th scope="row" class="align-left"> {{index + 1 }}</th>
                                                <td class="align-left"> 
                                                    {{ 
                                                        item.type_service_id == 1 ? 'CONSULTA' : 
                                                        item.type_service_id == 3 ? 'HOSPITALIZACIÓN':
                                                        item.type_service_id == 4 ? 'UCI ADULTO': 'EMERGENCIA' 
                                                    }}
                                                </td>
                                                <td class="concept">
                                                    <div class="left"><span>{{item.product_name}}</span></div>
                                                    <div class="right" @click="showDetail(index, item.doctor_name,item.interconsulta,item.user_id ,item.type_service_id)"><span><a><i class="color-main fas fa-solid fa-eye"></i></a></span>
                                                    </div>
                                                </td>
                                                <td>{{item.date_solicitud}}</td>
                                                <td>{{item.date_result}}</td>
                                                <td class="align-left">{{item.doctor_name}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else class="text-xxs">
                                            <tr>
                                                <td class="text-center   " colspan="6"> <strong> No hay resultados </strong></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 px-3" v-if="isVisibleArray[pos] == true">
                        <div id="content-timelinetable ">
                            <div class="bg-main">
                                <div class="py-2 text-white">
                                    <h5>{{ data[pos].product_name }} {{ rolname }}</h5>
                                </div>
                            </div>

                            <div id="div-graph" class=" px-4 py-2 text-xs ">
                                <div class=" d-flex justify-content-between mx-0 px-0 w-100 mb-3">
                                    <div>
                                        <button v-if="interconsulta.ekg_ids" @click="mtdekg(interconsulta.ekg_ids)" class="btn mt-2 text-white btn-sm btn-info ml-auto" >Ver EKGS</button>
                                    </div>
                                    <div>
                                        <div v-if="status && validation && motive" class="mt-2 px-0 col-12 text-end">
                                            <button class="btn btn-sm btn-success ml-auto" @click="mtdcambiar">Aplicar</button>
                                        </div>
                                        <div v-if="!status && motive " class="mt-2 px-0 col-12 text-end">
                                            <button class="btn btn-sm btn-success ml-auto" @click="mtdSave">Guardar Motivo</button>
                                        </div>
                                        <div v-if=" !motive && mgmotive && !respuesta" class="mt-2 px-0 col-12 text-end">
                                            <button class="btn btn-sm btn-success ml-auto" @click="mtdSaveAnswer">Guardar </button>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="d-flex  justify-content-between w-100">
                                    <div> <strong> MOTIVO:</strong> </div>
                                    <div> <strong> MEDICO: {{ namedoctor }}</strong> </div>
                                </div>
                                <div class="mt-3">
                                    <textarea v-model="interconsulta.motive" class="form-control form-control-sm" id="motivo" rows="6" style="resize: none;" :disabled="status || !motive " />
                                    </div> 
                            </div>

                          </div>
                          <div class="mt-1 mb-5 ">
                              <div id="content-timelinetable " >
                                <div id="div-graph" class=" px-4 py-3 text-xs ">
                                <div class="d-flex  justify-content-between w-100">
                                    <div> <strong> RESPUESTA:</strong> </div>
                                    <div v-if ="!namerespuesta"> <strong> MEDICO: {{ user }}</strong> </div>
                                </div>
                                <div class="mt-3">
                                    <textarea v-model="interconsulta.answer" class="form-control form-control-sm" id="motivo" rows="4" style="resize: none;" :disabled=" motive || !mgmotive || respuesta" />
                                </div>
                                <div class="row mx-0 mb-2 mt-2">
                                    <div class="px-0 col-md-12" id="content-timelinetable">
                                        <table class="w-100">
                                            <caption class="div-text my-0 py-0"></caption>
                                            <thead class=" txt-xs ">
        
                                                <tr>
                                                    <th class=" text-start text-xs pb-2 " scope="col" colspan="8"> Seleccione Medicamentos:</th>
                                                </tr>
                                            </thead>
                                            <tbody class="div-text  ">
                                                <tr class="px-1">
                                                    <td colspan="2" class="w-25 z-index-select">
                                                        <input type="text" v-model="receta" placeholder="Molecula" class="form-control form-control-input form-control-sm"  @keyup.enter="mtdopenmodal"  :disabled="  motive || !mgmotive || respuesta" />
                                                    </td>
                                                    <td  v-if = "recipe_unit.presentation != '-'">
                                                        <input type="text" v-model="recipe_unit.presentation" placeholder="Presentacion" class="form-control form-control-input form-control-sm" disabled />
                                                    </td>
                                                    <td  v-if = "!type">
                                                        <input type="text" v-model="recipe_unit.dosis" placeholder="Concentración" class="form-control form-control-input form-control-sm" disabled />
                                                    </td >
                                                    <td  v-if = "!type">
                                                        <!-- <input type="text" v-model="recipe_unit.via" placeholder="Via" class="form-control form-control-input form-control-sm"  :disabled="  motive || !mgmotive || respuesta"   /> -->
                                                        <select class="form-control form-control-input form-control-sm" 
                                                            aria-label="Default select example" v-model="recipe_unit.via"  :disabled="  motive || !mgmotive || respuesta"  >
                                                            <option selected value="" >Seleccione...</option>
                                                            <option value="VO">VO</option>
                                                            <option value="EV">EV</option>
                                                        </select>
                                                    </td>
                                                    <!-- <td v-if="!type">
                                                        <input type="text" v-model="recipe_unit.quantity" placeholder="Frecuencia" class="form-control form-control-input form-control-sm" :disabled="  motive || !mgmotive || respuesta" />
                                                    </td> -->
                                                    <td  v-if = "!type && !mix_state">
                                                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency" placeholder="Frecuencia (Horas)" class="form-control form-control-input form-control-sm" :disabled=" motive || !mgmotive || respuesta"  />
                                                    </td>
                                                    <td v-if = "type || mix_state">
                                                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm" :disabled="validation" />
                                                    </td>
                                                    <td  v-if = "!type && !mix_state">
                                                        <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration" placeholder="Duración (Días)" class="form-control form-control-input form-control-sm"  :disabled="  motive || !mgmotive || respuesta"  />
                                                    </td>
                                                    <td  v-if = "!type" style="width: 50px;">
                                                        <button type="button" :disabled="validationInputmezcla " @click="mtdmezcla()" class=" btn btn-info btn-sm px-1 py-0 me-1 text-white" title="Infusión"> <i class="fas fa-prescription-bottle"></i> </button>
                                                        <button  v-if="!type && !mix_state" type="button" :disabled="validationInputrecipe " @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                        <button v-if="type || mix_state" type="button" :disabled="validationInputmezcla" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                    </td>
                                                    <td v-else  style=" width: 50px;"  class="d-flex" >    
                                                        <button type="button" :disabled= "validationtotal"  @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                                    </td>
                                                </tr>
        
                                            </tbody>
                                      </table>
                                    </div>
                    </div>
                    <div v-if="!(motive || !mgmotive || respuesta)" class="d-flex justify-content-end mb-2">
                        <button @click="mtdViewMezclas"  type="button" class="btn  btn-info btn-sm px-4 py-0 text-xs text-white" :disabled="noInsumos.length <  2" >Mezclas</button>
                    </div>
                    <div class="row div-text mx-0 mb-2 ">
                        <div class="col-md-12 px-0" id="content-timelinetable">
                            <table class="w-100 table table-bordered">
                                <caption class="div-text my-0 py-0"></caption>
                                <thead class=" div-text div-title text-center  ">
                                    <tr>
    
                                        <th class="" scope="col">Rp.</th>
                                        <th class=" ">Medicamentos</th>
                                        <th class="">Presentacion</th>
                                        <th class="">Concentración</th>
                                        <th class="">Via</th>
                                        <th class="">Dosis de Mezcla</th>
                                        <th class="">Frecuencia (Horas)</th>
                                        <th class="">Duracion (Días)</th>
                                        <th class="">Total</th>
                                        <th class="">Tipo</th>
                                        <th class="">Grupo</th>
                                        <th class="">PRN</th>
                                        <th class="">Acciones</th>
                                    </tr>
    
                                </thead>
                                <tbody class="div-text" v-if="interconsulta.recipe.length >0">
    
                                    <tr v-for="(recipe, index) in interconsulta.recipe" :key="index" class="px-1">
    
                                        <td class="">
                                             <span v-if="(recipe.pending_state==0 || recipe.pending_state == 1) && motive && rolname =='DOCTOR(A)'" class=" me-3  ">
                                                <input @change="mtdcambiostate(recipe)" type="checkbox" class="custom-control-input  " :id="'checkbox' + index" v-model="recipe.state" :disabled="recipe.pending_state == 1 ? true :false" >
                                            </span>
                                            <span>{{ index + 1 }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.molecule }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.presentation }}</span>
                                        </td>
                                        <td>
                                            <span>{{  recipe.dosis   }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.via }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN DOSIS': '-') : (recipe.quantity + ' ' + (recipe.medida == 1 ? 'CC' : (recipe.medida ==2 ? 'ML': 'GOTAS') ) + '/' + (recipe.tiempo == 1 ? 'H': 'MIN'))}}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.frequency }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.duration }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.total }}</span>
                                        </td>
                                        <td>
                                            <span>{{ recipe.type == 2 ? 'INSUMO' : recipe.type == 3 ? 'SOLUCION': 'MEDICAMENTO' }}</span>
                                        </td>
                                        <td >
                                            <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipe.group  }}</span>
                                        </td>
                                        <td>
                                            <span v-if="recipe.type == 1 ">
                                            <div class="form-check form-switch">
                                                <input  @input="mtdPrnGroup(recipe)" :disabled="respuesta" v-model="recipe.prn" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                                <label class="form-check-label" for="flexSwitchCheckDefault">{{ recipe.prn ? 'SI':'NO' }}</label>
                                            </div>
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <button type="button" @click="mtddelete(index, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 "  :disabled="respuesta"  > <i class="fas fa-trash-alt text-white"></i> </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class=" text-center" v-else>
                                    <tr>
                                        <td colspan="12" class=" text-center">
                                            <strong> No hay resultados</strong>
                                        </td>
                                    </tr>
    
                                </tbody>
                            </table>
                        </div>
                        <div v-if="insuline_state" class="mt-3 px-0 text-start">
                           <strong class="text-xs  ">Escala Correctiva </strong>
                          <textarea v-model="interconsulta.insuline" class="form-control form-control-sm mt-2" id="indicaciones" rows="4" style="resize: none;" :disabled=" motive || !mgmotive || respuesta" />
                        </div>
                        <div class="mt-3 px-0 text-start">
                           <strong class="text-xs  ">Indicaciones y/o Recomendaciones </strong>
                          <textarea v-model="interconsulta.recomendation" class="form-control form-control-sm mt-2" id="indicaciones" rows="3" style="resize: none;" :disabled=" motive || !mgmotive || respuesta" />
                        </div>
                        <div class="mt-2 px-0 text-start">
                            <strong class="text-xs "> Dieta </strong>
                                <div class="d-flex">

                                    <div v-if=" (interconsulta.dieta.pending_state==0 || interconsulta.dieta.pending_state == 1) && motive && rolname =='DOCTOR(A)' "  class="d-flex  color-main align-items-center me-2">
                                         <input type="checkbox" class="custom-control-input " id="'checkbox'" v-model="interconsulta.dieta.state" :disabled="interconsulta.dieta.pending_state == 1 ? true :false" >
                                    </div>

                                <div class="w-100">

                                     <textarea v-model="interconsulta.dieta.name" class="form-control form-control-sm mt-2" id="indicaciones" rows="2" style="resize: none;" :disabled=" motive || !mgmotive || respuesta" />
                                </div>
                             </div>

                        </div>
                    </div>

                    <div class=" text-start mb-2">
                        <strong class="">Seleccione Planes:</strong>
                    </div>
                    <div class="">
                        <div class="row mx-0 ">

                            <div class="col-xl-12 col-sm-12 px-0  ">
                                <div class=" flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                                    <span class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan" :options="dataLaboratorio" :reduce="(laboratorio) => laboratorio" label="name" placeholder="Laboratorio" v-model="plan_unit" required  :disabled="  motive || !mgmotive || respuesta" />
                                </div>
                            </div>
                            <div class="col-xl-12 col-sm-12   px-0  ">
                                <div class="flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                                    <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan" :options="dataImagenes" :reduce="(Imagenes) => Imagenes" label="name" placeholder="Imagenes" v-model="plan_unit" required :disabled=" motive || !mgmotive || respuesta" />
                                </div>
                            </div>
                            <div class="col-xl-12 col-sm-12   px-0   ">
                                <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                                    <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan" :options="dataProcedimiento" :reduce="(Procedimiento) => Procedimiento" label="name" placeholder="Procedimiento" v-model="plan_unit" required  :disabled=" motive || !mgmotive || respuesta"  />
                                </div>
                            </div>
                            <div class="col-xl-12 col-sm-12   px-0   ">
                                <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                                    <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataInterconsultas" :reduce="(Interconsulta) => Interconsulta" label="name" placeholder="Interconsulta" required @input="mtdaddPlan" v-model="plan_unit"  :disabled=" motive || !mgmotive || respuesta" />
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 px-0">
                            <div v-for="(planunit, index) in interconsulta.plan " :key="index" class="col-md-12 py-1 px-0  d-flex">
                                <div v-if=" (planunit.pending_state==0 || planunit.pending_state == 1) && motive && rolname =='DOCTOR(A)' "  class="d-flex  color-main align-items-center me-2">
                                    <input type="checkbox" class="custom-control-input " :id="'checkbox' + index" v-model="planunit.state" :disabled="planunit.pending_state == 1 ? true :false" >
                                </div>
                                <div  class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle  ">
                                    <button @click="mtddelete(index, 'mtddeleteplan')" type=" button" class="w-100 text-start"  :disabled="respuesta">{{ planunit.name }} </button>
                                </div>
                            </div>
                        </div>

                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title" :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
<SweetAlert :swal="swal" ref="SweetAlert" @mtddeleteplan="mtddeleteplan"  @mtddeleterecipe="mtddeleterecipe"/>
<cModalcomment @mtdaddComment="mtdaddComment" :plans="plans" :title="modalcomment.title" :boo_modal="modalcomment.modal_form" @mtdclosemodalcomment="mtdclosemodalcomment"  />
<Mezclas @mtdchange="mtdchange" :medicinemezclas="medicinemezclas" :title="modalmezcla.title" :boo_modal="modalmezcla.modal_form" @mtdclosemodalmezclas="mtdclosemodalmezclas"/>
<cModalEkg :ekg_ids="ekg_ids" :title="modalekg.title" :boo_modal="modalekg.modal_form" @mtdclosemodalekg="mtdclosemodalekg"/>
</div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import Medicine from '@/components/Asistencial/Hospitalizacion/modals/cModalMedicine.vue';
import Interconsulta from '@/components/Asistencial/Consulta/Historia/Interconsulta.vue';
import cModalcomment from '@/components/Asistencial/Hospitalizacion/modals/cModalcommet.vue'
import Mezclas from '@/components/Asistencial/Hospitalizacion/modals/cModalMezclas.vue'
import cModalEkg from '@/components/Asistencial/UciAdult/modals/cModalEkg.vue'
export default {
    name: 'c-medico-laboratorio',
    components: {
        SweetAlert,
        Medicine,
        cModalcomment,
        Mezclas,
        cModalEkg
    },
    data() {
        return {
            page: 'laboratorio',
            stateHistory: null,
            viewInput: null,
            isVisibleArray: [],
            isVisibleGraph: false,
            pos: -1,
            showGraph: false,
            data: [],
            swal: null,
            item: {
                comment: null,
                id: null,

            },
            namedoctor: '',
            receta: '',
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: null,
                group: 0,
                prn: false,
                medida: null,
                tiempo: null
            },
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            posmedicine: null,
            interconsulta: {
                id: null,
                medical_act_id: null,
                doctor_id: null,
                motive: '',
                answer: '',
                recipe: [],
                plan: [],
                case: null,
                type: null,
                patient_id: null,
                interconsulta_id: null,
                recomendation: '',
                dieta: {
                    id: null,
                    name: '',
                    pending_state: null
                },
                insuline: '',
                ekg_ids:null

            },
            dataLaboratorio: [],
            dataImagenes: [],
            dataProcedimiento: [],
            dataInterconsultas: [],
            plan_unit: null,
            status: false,
            validation: false,
            user: '',
            motive: false,
            mgmotive: false,
            respuesta: false,
            namerespuesta: false,
            plans: {},
            modalcomment: {
                title: "",
                modal_form: false,
            },
            type: false,
            modalmezcla: {
                title: "",
                modal_form: false,
            },
            medicinemezclas: [],
            group_state: false,
            noInsumos: [],
            type_service: null,
            insuline_state: false,
            ekg_ids:[],
            modalekg: {
                title: "",
                modal_form: false,
            },
            mix_state:false
        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient: {
            type: Object,
        },
        rolname:String
    },
    mounted() {

        this.$set(this.isVisibleArray, 0, true);
    },
    watch: {
        patient: {
            handler(newVal) {
                this.mtdGetData();
                this.mtdgetSubsubcategories();
                for (let i = 0; i < this.lengthArray; i++) {
                    let atributo = false;
                    i == 0 ? this.isVisibleArray.push(true) : this.isVisibleArray.push(atributo);
                }
            },
            immediate: true
        }
    },
    computed: {
        lengthArray() {
            return this.data.length;
        },
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        validationtotal: function () {
            let value = true
            if (this.recipe_unit.total) {
                value = false
            }
            return value
        },
        validationInputmezcla: function () {
        let value = true
            if (this.receta && this.recipe_unit.via ) {
                value = false
            }
            return value
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Hospitalization/getPlanDetailPendients/" + this.patient.medical_act_id + "/2/4",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdSave: function () {
            this.interconsulta.id = this.data[this.pos].id;
            this.interconsulta.medical_act_id = this.patient.medical_act_id;
            this.interconsulta.patient_id = this.patient.patient_id;
            this.interconsulta.case = 2;
            this.interconsulta.type = 4;
            this.post({
                    url: this.$store.getters.get__url + "/Admision/editHistoryPlanDetail",
                    token: this.$store.getters.get__token,
                    params: this.interconsulta,
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    let Interconsultadetails = response.data
                    this.mtdGetData();
                    this.showDetail(this.pos, Interconsultadetails.doctor_name, Interconsultadetails.interconsulta, Interconsultadetails.user_id, this.type_service)

                })
                .catch(error => {
                    console.log(error);
                })
        },
        returnHome() {
            window.location.reload();
        },
        showDetail(pos, name, interconsulta, user_id, type_service) {
            this.type_service = type_service
            this.mgmotive = false
            this.motive = false,

                this.user = (interconsulta && interconsulta.doctor2) ? interconsulta.doctor2.name + ' ' + interconsulta.doctor2.last_name : this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name

            if (this.$store.getters.get__user.id == user_id || this.rolname != 'DOCTOR(A)') {
                this.motive = true;
                this.namerespuesta = true
            }
            this.namerespuesta = (interconsulta && interconsulta.doctor2  )  ? false : true
            this.$set(this.isVisibleArray, this.pos, false);
            this.pos = pos;
            this.$set(this.isVisibleArray, pos, true);
            this.namedoctor = name
            this.status = false
            this.interconsulta = {
                id: null,
                medical_act_id: null,
                doctor_id: null,
                motive: '',
                answer: '',
                recipe: [],
                plan: [],
                case: null,
                type: null,
                dieta: {
                    id: null,
                    name: '',
                    pending_state: null,
                    state: false
                }

            }
            if (interconsulta) {
                this.status = true
                this.interconsulta.id = interconsulta.history_plan_detail_id,
                    this.interconsulta.medical_act_id = interconsulta.medical_act_id,
                    this.interconsulta.doctor_id = interconsulta.doctor_id,
                    this.interconsulta.motive = interconsulta.motive,
                    this.interconsulta.answer = interconsulta.answer,
                    this.interconsulta.recomendation = interconsulta.recomendation,
                    this.interconsulta.dieta = interconsulta.dieta ? interconsulta.dieta : this.interconsulta.dieta,
                    this.interconsulta.dieta.state = interconsulta.dieta ? (interconsulta.dieta.pending_state == 1 ? true : false) : false
                this.interconsulta.interconsulta_id = interconsulta.id
                this.interconsulta.insuline = interconsulta.insulin
                this.interconsulta.ekg_ids=interconsulta.ekg_ids
                interconsulta.planes.forEach(element => {
                    let plan = {
                        id: element.id,
                        pending_state: element.pending_state,
                        name: element.subsubcategorie.name,
                        subsubcategory_id: element.subsubcategorie.id,
                        category_id: element.subsubcategorie.subategorie.categorie_id,
                        state: element.pending_state == 1 ? true : false
                    }
                    this.interconsulta.plan.push(plan)
                });
                interconsulta.recipe.forEach(element1 => {
                    let recipe_unit = {
                        id: element1.id,
                        pending_state: element1.pending_state,
                        order: element1.other,
                        product_id: null,
                        molecule: element1.molecule,
                        presentation: element1.presentation,
                        dosis: element1.dosis,
                        via: element1.via,
                        frequency: element1.frequency,
                        duration: element1.duration,
                        state: element1.pending_state == 1 ? true : false,
                        type: element1.type,
                        quantity: element1.quantity ,
                        medida:element1.measure,
                        tiempo:element1.unit_time,
                        group: element1.mix,
                        prn: element1.prn == 1 ? true : false,
                        total: element1.total
                    }
                    this.interconsulta.recipe.push(recipe_unit)
                });
                this.validation = false
                let num = 0
                this.interconsulta.plan.forEach(plan => {

                    if (plan.pending_state == 0) {
                        num = num + 1
                    }
                });
                this.interconsulta.recipe.forEach(recipe => {

                    if (recipe.pending_state == 0) {
                        num = num + 1
                    }
                    if (recipe.type == 1 && recipe.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
                });
                if (this.interconsulta.dieta.pending_state == 0) {
                    num = num + 1
                };
                if (num != 0) {
                    this.validation = true
                }
            }
            this.mgmotive = this.interconsulta.motive ? true : false
            this.respuesta = (this.interconsulta.dieta.name || this.interconsulta.answer || this.interconsulta.plan.length > 0 || this.interconsulta.recipe.length > 0 || this.interconsulta.recomendation) ? true : false
        },
        mtdopenmodal: function () {
            if (this.receta) {
                this.posmedicine = 1;
                this.modalmedicine = {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
            } else {
                this.$refs.SweetAlert.showWarning('Digite Molecula');
            }
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.receta = '',
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    quantity: null,
                    group: 0,
                    prn: false,
                    medida: null,
                    tiempo: null
                }
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2 || payload.type == 3) ? true : false
            this.receta = payload.molecule
            this.recipe_unit.order = this.interconsulta.recipe.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            if (this.type) {
                this.recipe_unit.via = '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                // this.recipe_unit.quantity = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
                this.recipe_unit.duration = 1
            }
        },
        mtdaddrecipe: function () {
            this.recipe_unit.duration =  this.recipe_unit.total ? '-': this.recipe_unit.duration 
            this.recipe_unit.frequency =  this.recipe_unit.total ? '-': this.recipe_unit.frequency
            this.recipe_unit.total = this.recipe_unit.total ? this.recipe_unit.total : ''
            const exists = this.interconsulta.recipe.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
            } else {
                this.interconsulta.recipe.push(this.recipe_unit);
            }
            this.type = false
            this.receta = null
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: 1,
                type: null,
                total: null,
                quantity: null,
                group: 0,
                prn: false,
                medida: null,
                tiempo: null
            }
            this.noInsumos = []
            this.insuline_state = false
            this.interconsulta.recipe.forEach(item => {
                if (item.type == 1  && item.duration =='-') {
                    this.noInsumos.push(item)
                }
                if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            })
            this.mtdconcatenar()
            this.mix_state =  false
        },
        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataLaboratorio = response.data.dataLaboratorio;
                    this.dataImagenes = response.data.dataImagenes;
                    this.dataProcedimiento = response.data.dataProcedimiento;
                    this.dataInterconsultas = response.data.dataInterconsulta;
                }
            });
        },

        mtdaddPlan: function () {
            const exist = this.interconsulta.plan.some(item => item.subsubcategory_id === this.plan_unit.subsubcategory_id);
            if (exist) {
                this.$refs.SweetAlert.showWarning("El item seleccionado a sido agregado");
            } else {
                // this.interconsulta.plan.push(this.plan_unit);
                if (this.plan_unit.category_id == 1) {
                    this.modalcomment = {
                        title: this.plan_unit.name,
                        modal_form: true,
                    }
                    this.plan_unit.medical_act_id=this.interconsulta.medical_act_id
                    this.plans = this.plan_unit
                } else {
                    this.mtdaddComment(this.plan_unit)
                }
            }
            this.plan_unit = null
        },
        mtdaddComment: function (payload) {

            this.mtdclosemodalcomment()
            this.interconsulta.plan.push(payload);

        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        mtddeleteplan: function (index) {
            this.interconsulta.plan.splice(index, 1);
        },
        mtddeleterecipe: function (index) {
            let num = this.interconsulta.recipe[index].group
            if (this.interconsulta.recipe[index].group != 0) {
                for (let j = this.interconsulta.recipe.length - 1; j >= 0; j--) {
                    if (this.interconsulta.recipe[j].group == num) {
                        this.interconsulta.recipe.splice(j, 1);
                    }
                }
            } else {
                this.interconsulta.recipe.splice(index, 1);
            }
            this.insuline_state = false
            for (let i = 0; i < this.interconsulta.recipe.length; i++) {
                this.interconsulta.recipe[i].order = i + 1;
                if (this.interconsulta.recipe[i].type == 1 && this.interconsulta.recipe[i].molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            }
            if(!this.insuline_state){
              this.interconsulta.insuline = ''
            }
            this.mtdconcatenar()
        },
        mtdconcatenar:function(){
            this.interconsulta.recomendation = ''
            this.interconsulta.recipe.forEach(item2 =>{
                if(item2.type == 1){ 
                    let msg = '-' + item2.molecule + ' ' + item2.presentation + ' ' + item2.dosis + ' ' + item2.via;
                    if (item2.frequency !='-' && item2.duration !='-') {
                        msg += ' cada ' + item2.frequency + 'H por ' + item2.duration + 'D';
                    }
                    if (this.interconsulta.recomendation) {
                        this.interconsulta.recomendation += ` \n${msg}`;
                    } else {
                        this.interconsulta.recomendation = `${msg}`;
                    }
                }
            })
         },
        mtdcambiar: function () {
            let modificar = {
                dieta: this.interconsulta.dieta,
                plan: this.interconsulta.plan,
                recipe: this.interconsulta.recipe,
                recomendation: this.interconsulta.recomendation,
                insuline: this.interconsulta.insuline,
                id: this.data[this.pos],
                medical_act_id:this.interconsulta.medical_act_id,
                doctor_id: this.interconsulta.doctor_id,
                vital_sings: [{
                        id: 1,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'FR',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'PA',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'FIO2',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'T*',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'HGT',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'DLURESIS',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'BH',
                        value: ''
                    },
                ],
                physical_exam: [{
                        id: 1,
                        label: 'Estado General',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Neurologico',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Respiratorio',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'Hemodinamico',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'Pelianexos',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'Metabo/Infecc',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'Gastroinstestinal',
                        value: ''
                    },
                ],
                current_analytics: [{
                        id: 1,
                        label: 'LEUCOCITOS',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'LINFOCITOS',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'ABASTONADOS',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SEGMENTADOS',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'HB/HCTO',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'LDH',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'PCR',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'FERRTININA',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'DIMERO 0',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'TGO/TGP',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'PH',
                        value: ''
                    },
                    {
                        id: 12,
                        label: 'PO2',
                        value: ''
                    },
                    {
                        id: 13,
                        label: 'PCO2',
                        value: ''
                    },
                    {
                        id: 14,
                        label: 'HCO3',
                        value: ''
                    },
                    {
                        id: 15,
                        label: 'NA+',
                        value: ''
                    },
                    {
                        id: 16,
                        label: 'K+',
                        value: ''
                    },
                    {
                        id: 17,
                        label: 'D(A-a)O2',
                        value: ''
                    },
                ],
                diagnostics:[]
            }
            let url = ''
            if (this.type_service == 4) {
                url = this.$store.getters.get__url + "/Uciadulto/updatePendingState"
            } else if (this.type_service == 3) {
                url = this.$store.getters.get__url + "/Hospitalization/updatePendingState"
            } else if (this.type_service == 7) {
                url = this.$store.getters.get__url + "/Emergency/updatePendingState"
            } else if (this.type_service == 1) {
                url = this.$store.getters.get__url + "/Consultation/updatePendingState"
            }
            this.post({
                    url: url,
                    token: this.$store.getters.get__token,
                    params: modificar,
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    let Interconsultadetail = response.data
                    this.mtdGetData();
                    this.showDetail(this.pos, Interconsultadetail.doctor_name, Interconsultadetail.interconsulta, Interconsultadetail.user_id, this.type_service)
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdSaveAnswer: function () {
            this.interconsulta.patient_id = this.patient.patient_id;
            let url = ''
            if (this.type_service == 4) {
                url = this.$store.getters.get__url + "/Uciadulto/updateInterconsulting"
            } else if (this.type_service == 3) {
                url = this.$store.getters.get__url + "/Hospitalization/updateInterconsulting"
            } else if (this.type_service == 7) {
                url = this.$store.getters.get__url + "/Emergency/updateInterconsulting"
            } else if (this.type_service == 1) {
                url = this.$store.getters.get__url + "/Consultation/updateInterconsulting"
            }
            this.post({
                    url: url,
                    token: this.$store.getters.get__token,
                    params: this.interconsulta,
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    let Interconsultadetail = response.data
                    this.mtdGetData();
                    this.showDetail(this.pos, Interconsultadetail.doctor_name, Interconsultadetail.interconsulta, Interconsultadetail.user_id, this.type_service)

                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdclosemodalcomment: function () {
            this.modalcomment = {
                title: "",
                modal_form: false,
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdchange: function (payload) {
            this.interconsulta.recipe = payload
            this.group_state = true
            this.mtdclosemodalmezclas()
        },
        mtdViewMezclas: function () {
            this.modalmezcla = {
                title: "Lista de Medicamentos",
                modal_form: true,
            }
            this.medicinemezclas = this.interconsulta.recipe
        },
        mtdclosemodalmezclas: function () {
            this.modalmezcla = {
                title: "",
                modal_form: false,
            }
        },
        mtdPrnGroup: function (payload) {
            if (payload.group != 0) {
                this.interconsulta.recipe.forEach(item => {
                    if (item.group == payload.group) {
                        item.prn = !item.prn
                    }
                })
            }
        },
        mtdcambiostate:function(payload){
            let state = payload.state
            if (payload.group != 0) {
                this.interconsulta.recipe.forEach(item => {
                    if (item.group == payload.group) {
                        item.state = state
                    }
                })
            }
        },
        mtdekg:function(payload){
            this.ekg_ids=JSON.parse(payload)
            this.modalekg = {
                title: "Planes de EKG",
                modal_form: true,
            }
        },
        mtdclosemodalekg:function(){
            this.modalekg = {
                title: "",
                modal_form: false,
            }
        },
        mtdmezcla:function(){
            this.mix_state =  !this.mix_state
        }

    }
}
</script>
